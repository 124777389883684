enum routeConstants {
  BASE = '/',
  LOGIN = '/kun/login',
  FORGOT_PASSWORD = '/kun/forgotPassword',
  SET_NEW_PASSWORD = '/kun/set-new-password',
  DASHBOARD_BASE_ROUTE = '/kun/*',
  NAVIGATION = ':navigation',
  INFO = 'info',
  PAGE_NOT_FOUND = 'page not found',
}
export default routeConstants;
