import { useTranslation } from 'react-i18next';
import Input from '../inputComponent';

interface IReviewCard {
  category?: string;
}
const ReviewCard = ({ category }: IReviewCard) => {
  const [t] = useTranslation();
  return (
    <div className="flex flex-grow border border-borderBlack rounded-lg flex-col pt-5 px-6 overflow-y-scroll review ">
      <div className="flex flex-row justify-between pb-6">
        <div className="text-18 font-satoshi rtl:font-almarai font-bold capitalize">
          {t(`REVIEW.${category}`)}
        </div>
        <div className="text-primary font-general rtl:font-almarai text-13 flex items-center gap-2 cursor-pointer">
          <div>{t(`REVIEW.VIEW_ALL`)} </div>
          <span>
            <img
              className="rtl:rotate-180"
              src="/assets/rightArrow.svg"
              alt=""
            />
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-4 border-b border-borderBlack">
        <img
          src="/assets/profilePic.svg"
          className="w-10 h-10 rounded-[20px]"
          alt=""
        />
        <div className="flex flex-col gap-1">
          {' '}
          <div className="text-14 font-bold font-satoshi rtl:font-almarai">
            Daniel Walter Scott
          </div>
          <div className="flex flex-row gap-2">
            {' '}
            <div
              className={` ${
                category?.toLowerCase() === 'detractors' && 'bg-red text-white'
              } ${
                category?.toLowerCase() === 'passives' &&
                'bg-lightGreen text-black'
              } ${
                category?.toLowerCase() === 'promoters' &&
                'bg-primary text-white'
              } text-8 font-general rtl:font-almarai  rounded flex justify-center items-center px-2`}
            >
              2-{t(`REVIEW.DETRACTORS`)}
            </div>{' '}
            <div className="text-12 font-general rtl:font-almarai text-gray">
              10 min
            </div>
          </div>
          <div className="font-general rtl:font-almarai text-13 ">
            Aliquam porta nisl dolor, molestie pellentesque elit molestie in.
            Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt
            et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi.
            Integer commodo sapien lacus, nec interdum nisi vehicula aliquam.
            Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus.
          </div>
          <div className="flex gap-4">
            <div className="text-13 font-semibold font-general rtl:font-almarai text-primary">
              {t('REVIEW.REPLY')}
            </div>
            <div className="text-13 font-general rtl:font-almarai text-gray">
              {' '}
              0 {t(`REVIEW.REPLIES`)}{' '}
            </div>
          </div>
          <Input
            placeholder="write a reply"
            id={''}
            name={''}
            value={''}
            classname={''}
            onChange={(e) => e.target}
          ></Input>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
