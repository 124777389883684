const Button = ({ type = 'submit', className, children, onClick }: any) => {
  const attributes = {
    className,
    children,
    type,
    onClick,
  };
  return (
    <button {...attributes} type={type} className={className} onClick={onClick}>
      {children}
    </button>
  );
};
export default Button;
