enum coachDirectoryEndpoints {
  TABLEDATA = '/coaches/',
  NEWLY_CREATED_TABLEDATA = '/coach-requests/',
  FILE_TRANSFER = '/files/',
  TRADEINFO = '/trade-registers/',
  BANKINGINFO = '/banking-info',
  SOCIAL_MEDIA = '/social-medias/coaches/',
  SUBSCRIPTION = '/subscription-selection?coachId=',
  SUBSCRIPTION_DETAILS = '/subscriptions/',
  REPORT_BLOCK = '/coaches/report-block',
  UNBLOCK_COACH = '/coaches/unblock/',
  SETTLE_COACH = '/payment/withdraw/',
  APPROVE_REJECT_SUBSCRIPTION = '/payment/wire-transfer/',
  RECEIPT_DOWNLOAD = '/files/',
}
export default coachDirectoryEndpoints;
