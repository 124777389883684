import { useTranslation } from 'react-i18next';
import Table from '../table';

const PaymentTableBody = ({
  header,
  body,
  category,
  loading,
}: {
  header: any;
  body: any;
  category: string;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const headerData = () => {
    let temp;
    switch (category) {
      case 'COACHES':
        temp = [
          { value: 'name', header: t('PAYMENT_COMPONENT.NAME') },
          { value: 'email', header: t('PAYMENT_COMPONENT.EMAIL') },
          {
            value: 'subscription',
            header: t('PAYMENT_COMPONENT.SUBSCRIPTION'),
          },
          { value: 'amount', header: t('PAYMENT_COMPONENT.AMOUNT') },
          { value: 'invoiceNo', header: t('PAYMENT_COMPONENT.INVOICE_NO') },
          { value: 'date', header: t('PAYMENT_COMPONENT.DATE') },
        ];
        break;
      case 'CLIENTS':
        temp = [
          { value: 'name', header: t('PAYMENT_COMPONENT.NAME') },
          { value: 'email', header: t('PAYMENT_COMPONENT.EMAIL') },
          { value: 'amount', header: t('PAYMENT_COMPONENT.AMOUNT') },
          { value: 'date', header: t('PAYMENT_COMPONENT.DATE') },
          {
            value: 'serviceTitle',
            header: t('PAYMENT_COMPONENT.SERVICE_TITLE'),
          },
          { value: 'purchase', header: t('PAYMENT_COMPONENT.SERVICE_TYPE') },
        ];

        break;
      case 'SETTLEMENT_REQUESTS':
        temp = [
          { value: 'amount', header: t('PAYMENT_COMPONENT.AMOUNT') },
          { value: 'date', header: t('PAYMENT_COMPONENT.DATE') },
          { value: 'currency', header: t('PAYMENT_COMPONENT.CURRENCY') },
          {
            value: 'withdrawnStatus',
            header: t('PAYMENT_COMPONENT.WITHDRAWN_STATUS'),
          },
          { value: 'comments', header: t('PAYMENT_COMPONENT.COMMENTS') },
        ];
        break;
      case 'CLOSED_SETTLEMENTS':
        temp = [
          { value: 'amount', header: t('PAYMENT_COMPONENT.AMOUNT') },
          { value: 'date', header: t('PAYMENT_COMPONENT.DATE') },
          { value: 'currency', header: t('PAYMENT_COMPONENT.CURRENCY') },
          {
            value: 'withdrawnStatus',
            header: t('PAYMENT_COMPONENT.WITHDRAWN_STATUS'),
          },
          { value: 'comments', header: t('PAYMENT_COMPONENT.COMMENTS') },
        ];
        break;
    }
    return temp;
  };
  const handleBody = (category: string) => {
    let temp;
    switch (category) {
      case 'COACHES':
        temp = header.map((item: any) => ({
          name: item?.name,
          email: item?.email,
          subscription: item?.subscription,
          amount: item?.amount,
          invoiceNo: item?.invoiceNo,
          date: item?.date,
        }));
        break;
      case 'CLIENTS':
        temp = header.map((item: any) => ({
          name: item?.name,
          email: item?.email,
          amount: item?.amount,
          date: item?.date,
          serviceTitle: item?.serviceTitle,
          purchase: item?.purchase,
        }));

        break;
      case 'SETTLEMENT_REQUESTS':
        temp = header.map((item: any) => ({
          amount: item?.amount,
          date: item?.createdAt,
          currency: item?.currency,
          withdrawnStatus: item?.withdrawnStatus,
          comments: item?.comments,
        }));
        break;
      case 'CLOSED_SETTLEMENTS':
        temp = header.map((item: any) => {
          return {
            amount: item?.amount,
            date: item?.createdAt,
            currency: item?.currency,
            withdrawnStatus: item?.withdrawnStatus,
            comments: item?.comments,
          };
        });
        break;
    }
    return temp;
  };

  return (
    <div>
      <Table
        header={header && headerData()}
        data={header && handleBody(category)}
        loading={loading}
      />
    </div>
  );
};

export default PaymentTableBody;
