/* eslint-disable react/react-in-jsx-scope */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { languageSelect } from './components/header/slice';
import Alert from './components/notification';
import { languageAndDirections } from './constants';
import i18n from './i18n/i18n';
import RouteContainer from './routes';
import store from './store';
import { useAppSelector } from './_helpers';

const persistor = persistStore(store);

function App() {
  const { lang } = useAppSelector((state) => state.language);

  const dispatch = useDispatch();
  useEffect(() => {
    if (lang === languageAndDirections.ENGLISH_SYMBOL) {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.LEFT_TO_RIGHT
        );
      i18n.changeLanguage(languageAndDirections.ENGLISH_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ENGLISH_SYMBOL));
    } else {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.RIGHT_TO_LEFT
        );
      i18n.changeLanguage(languageAndDirections.ARABIC_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ARABIC_SYMBOL));
    }
  }, [document.querySelector('html')?.getAttribute('dir')]);
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Alert />
      <RouteContainer />
    </PersistGate>
  );
}

export default App;
