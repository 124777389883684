import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAppSelector } from '../../_helpers';
import Button from '../coachComponents/buttons';
import SubscriptionDetails from '../coachProfileSubscription';
import { alertActive } from '../notification/slices';
import Pagination from '../pagination';
import {
  getActiveSubscription,
  getCoachProfileBankingInfo,
  getCoachProfileCertificate,
  getCoachProfileContent,
  getCoachProfileFiles,
  getCoachProfileTradeInfo,
  getReportBlockDetails,
  getSocialMediaLinks,
  getSubscriptionDetails,
  settleCoach,
  unblockCoach,
} from './slices/index';

const CoachProfile = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [rejectToggle, setRejectToggle] = useState<{
    leaveComments?: boolean;
    report?: boolean;
  }>({
    leaveComments: false,
    report: false,
  });
  const dispatch = useDispatch<any>();
  const [info, setInfo] = useState<{ [key: string]: boolean }>({
    overview: true,
    bankingInfo: false,
    subscription: false,
  });
  const [showMore, setShowMore] = useState(false);
  const { lang } = useAppSelector((state) => state.language);
  const [searchParams, setSeaarchParams] = useSearchParams();
  const [data, setData] = useState<{ [key: string]: string }>({});
  let coachId: string;
  const temp: { [key: string]: string } = {};
  const {
    requestSuccess,
    requestPayload,
    requestLoading,
    coachProfileData,
    fileData,
    tradingDetails,
    bankingDetails,
    bankingInfoLoading,
    bankingInfoSuccess,
    tradingInfoLoading,
    isSocialMedia,
    socialMediaLinks,
    isSubscription,
    subscriptionData,
    isSubscriptionDetails,
    subscriptionDetails,
    isReportBlockLoading,
    isReportBlockError,
    isReportBlockSucess,
    reportBlockData,
    isUnblocked,
    isSettleCoach,
  } = useAppSelector((state) => state.coachProfile);

  useEffect(() => {
    isReportBlockError &&
      dispatch(
        alertActive({
          alertError: isReportBlockError,
          alertPayload: reportBlockData,
        })
      );
  }, [isReportBlockError]);

  useEffect(() => {
    async function fetchData() {
      coachId = await searchParams.get('coachId')!;
      await dispatch(getCoachProfileContent({ id: coachId }));
      await dispatch(getSocialMediaLinks(Number(coachId)));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchFile() {
      requestSuccess && coachProfileData?.profilePicId
        ? dispatch(getCoachProfileFiles(coachProfileData?.profilePicId))
        : '';
    }
    fetchFile();
  }, [requestSuccess]);
  // useEffect(() => {
  //   const params: { [key: string]: string } = {};
  //   searchParams.forEach((value, key) => {
  //     params[key] = value;
  //   });
  //   params['page'] = '0';
  //   params['size'] = '10';
  //   params['sort'] = 'created_at,desc';
  //   subscriptionData?.subscriptionId &&
  //     (params['subscriptionId'] = subscriptionData?.subscriptionId);
  //   info.subscription && setSeaarchParams(params);
  // }, [subscriptionData?.subscriptionId]);
  useEffect(() => {
    const temp = { coachId: searchParams.get('coachId') as string };
    setSeaarchParams(temp);
    info.overveiw ? dispatch(getCoachProfileContent({ id: coachId })) : '';
    info.bankingInfo
      ? (dispatch(getCoachProfileTradeInfo(Number(coachId))),
        dispatch(getCoachProfileBankingInfo({ coachId: Number(coachId) })))
      : '';
    info.subscription ? dispatch(getActiveSubscription(Number(coachId))) : '';
  }, [info]);

  // useEffect(() => {
  //   const temp: { [key: string]: string } = {};
  //   searchParams.forEach((value, key) => {
  //     temp[key] = value;
  //   });
  //   delete temp['coachId'];

  //   temp['id'] = searchParams.get('subscriptionId') as string;
  //   subscriptionData?.subscriptionId &&
  //     info.subscription &&
  //     dispatch(getSubscriptionDetails(temp));
  // }, [searchParams]);

  const redirect = () => {
    navigate(-1);
  };
  const handleData = async (id: number) => {
    try {
      await dispatch(getSubscriptionDetails(id)).unwrap();
    } catch (err) {
      return err;
    }
  };
  const ReportBlock_leaveComments = () => {
    const [value, setValue] = useState('');
    const [toggle, setToggle] = useState(0);

    const handleChangeInput = (v: any) => {
      setValue(v);
    };
    const handleSubmit = () => {
      value
        ? (setRejectToggle({ leaveComments: false, report: false }),
          dispatch(
            getReportBlockDetails({
              coachId: searchParams.get('coachId') as string,
              description: value,
            })
          ))
        : setToggle(1);
    };

    return (
      <>
        <div
          className={`fixed inset-0 bg-borderBlack bg-opacity-75 transition-opacity z-20 `}
        ></div>
        <div className="z-30  bg-white fixed top-[30vh] left-[40vw] p-11 rounded-xl flex flex-col w-[600px] h-[380px]   shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)]">
          <div className="flex justify-end">
            <img
              onClick={() =>
                setRejectToggle({ leaveComments: false, report: false })
              }
              className="cursor-pointer"
              src="/assets/closeButton.svg"
              alt=""
            />
          </div>
          <div className="flex items-start">
            <h1 className="font-satoshi rtl:font-almarai text-28 font-semibold py-7">
              {rejectToggle.leaveComments ? ' Enter comments' : ''}
              {rejectToggle.report ? `${t(`COACH_PROFILE.ENTER_REASON`)}` : ''}
            </h1>
          </div>

          <textarea
            value={value}
            className="outline-0 p-3 font-general rtl:font-almarai border border-borderBlack pb-7 h-[200px] rounded"
            onChange={(event) => handleChangeInput(event?.target.value)}
          />
          {toggle ? (
            <div className="text-red rtl:font-almarai">
              {t(`COACH_PROFILE.ENTER_REASON`)}
            </div>
          ) : (
            ''
          )}
          <div className="flex justify-center pt-7 ">
            <button
              onClick={() => {
                handleSubmit();
              }}
              className="bg-primary text-white font-satoshi rtl:font-almarai text-16  w-40 py-3 rounded flex justify-center"
            >
              {t(`SUBMIT`)}
            </button>
          </div>
        </div>
      </>
    );
  };
  coachId = searchParams.get('coachId')!;
  const changeField = async (value: string) => {
    const temp: { [key: string]: boolean } = {};
    await Object.keys(info).map((item) => {
      value === item ? (temp[value] = true) : (temp[item] = false);
    });
    await setInfo(temp);
  };

  const downloadCertificate = (id: string) => {
    dispatch(getCoachProfileCertificate(id));
  };
  const socialMediaImages: { [key: string]: string }[] = [
    {
      TWITTER: '/assets/coach/twitter.svg',
      WEBSITE: '/assets/coach/link.svg',
      FACEBOOK: '/assets/coach/facebook.svg',
      LINKEDIN: '/assets/coach/linkedIn.svg',
      YOUTUBE: '/assets/coach/youtube.svg',
    },
  ];
  const handleUnblock = () => {
    dispatch(unblockCoach(Number(searchParams.get('coachId'))));
  };

  return (
    <>
      {/* <div
        onClick={() => navigate('/kun/coach')}
        className="w-10 h-10 bg-primary cursor-pointer"
      ></div> */}
      {requestLoading ? (
        <div className=" fixed top-[50vh] left-[50vw]">
          <ClipLoader
            color={'#00a5ec'}
            loading={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="px-32 mt-10 relative overflow-hidden pb-10">
          <div
            onClick={() => {
              redirect();
            }}
            className="absolute top -10 ltr:left-11 rtl:right-11 cursor-pointer"
          >
            {lang === 'en' ? (
              <img src="/assets/coach/arrow.svg" alt="" />
            ) : (
              <img src="/assets/coach/rightArrow.svg" alt="" />
            )}
          </div>
          {(rejectToggle.leaveComments || rejectToggle.report) && (
            <ReportBlock_leaveComments />
          )}

          <div className="flex  h-[350px] ">
            {requestLoading ? (
              <ClipLoader
                color={'#00a5ec'}
                loading={true}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <div className="rounded-lg flex flex-shrink w-min-[300px] h-min-[300px]">
                <img
                  className=" w-[350px] h-[350px] rounded"
                  src={`${
                    !fileData
                      ? '/assets/coach/avatar.svg'
                      : `data:image/png;base64,${fileData}`
                  }`}
                  alt=""
                />
              </div>
            )}
            {!requestLoading ? (
              <div className="flex flex-col  ltr:ml-5  rtl:mr-5 h-full justify-between">
                <div>
                  <div className="text-primary font-bold font-satoshi rtl:font-almarai text-18">
                    {t('COACH_APPROVAL.COACH')}
                  </div>
                  <div className="text-textBlack font-bold font-satoshi rtl:font-almarai text-34 mt-2">
                    {coachProfileData && coachProfileData?.fullName}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex gap-7 text-textBlack font-normal text-16 font-general rtl:font-almarai mt-5">
                      <div className="flex gap-2">
                        <img src="/assets/coach/email.svg" alt="" />
                        <p>
                          {coachProfileData?.email
                            ? coachProfileData?.email
                            : `--${t('NA')}--`}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <img src="/assets/coach/phone.svg" alt="" />
                        <p>
                          {coachProfileData?.phoneNumber
                            ? coachProfileData?.phoneNumber
                            : `--${t('NA')}--`}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-7 text-textBlack font-normal text-16 font-general rtl:font-almarai mt-5">
                      <div className="flex gap-2">
                        <img src="/assets/coach/language.svg" alt="" />
                        <p className="capitalize">
                          {coachProfileData?.languages
                            ? [coachProfileData?.languages]?.map(
                                (item: any) => `${item + ' '} `
                              )
                            : `--${t('NA')}--`}
                        </p>
                      </div>
                      <div className="flex gap-2 ">
                        <img src="/assets/coach/dob.svg" alt="" />
                        <p>
                          {coachProfileData?.dob
                            ? coachProfileData?.dob
                            : `--${t('NA')}--`}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <img src="/assets/coach/female.svg" alt="" />
                        <p>
                          {coachProfileData?.gender
                            ? coachProfileData?.gender
                            : `--${t('NA')}--`}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-7 text-textBlack font-normal text-16 font-general rtl:font-almarai mt-5">
                      <div className="flex gap-2">
                        <img src="/assets/coach/location.svg" alt="" />
                        <p className="capitalize">
                          <span
                            className={`${
                              coachProfileData?.addressLine1
                                ? ''
                                : 'font-satoshi rtl:font-almarai text-16 text-gray'
                            }`}
                          >
                            {coachProfileData?.addressLine1
                              ? `${coachProfileData?.addressLine1},`
                              : ''}
                          </span>
                          <span
                            className={`${
                              coachProfileData?.addressLine2
                                ? ''
                                : 'font-satoshi rtl:font-almarai text-16 text-gray'
                            } ltr:pl-1 rtl:pr-1`}
                          >
                            {coachProfileData?.addressLine2
                              ? `${coachProfileData?.addressLine2},`
                              : ''}
                          </span>
                          <span
                            className={`${
                              coachProfileData?.city
                                ? ''
                                : 'font-satoshi rtl:font-almarai text-16 text-gray '
                            } ltr:pl-1 rtl:pr-1`}
                          >
                            {coachProfileData?.city
                              ? `${coachProfileData?.city},`
                              : ''}
                          </span>
                          <span
                            className={`${
                              coachProfileData?.country
                                ? ''
                                : 'font-satoshi rtl:font-almarai text-16 text-gray '
                            } ltr:pl-1 rtl:pr-1`}
                          >
                            {coachProfileData?.country
                              ? coachProfileData?.country
                              : ''}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 items-start  relative flex-col ">
                  <div className="flex gap-4 items-center relative">
                    {isReportBlockLoading ? (
                      <div className="absolute top-1.5 ltr:left-8 rtl:right-8">
                        <ClipLoader
                          color={'white'}
                          loading={true}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {!coachProfileData?.blocked ? (
                      <button
                        onClick={() => setRejectToggle({ report: true })}
                        className="py-[8px]  bg-red rounded text-white font-bold font-satoshi rtl:font-almarai text-16  w-[237px] h-11"
                      >
                        {t(`COACH_PROFILE.REPORT_BLOCK`)}
                      </button>
                    ) : (
                      <div className="relative">
                        <button
                          onClick={() => handleUnblock()}
                          className="py-[8px]  bg-red rounded text-white font-bold font-satoshi rtl:font-almarai text-16  w-[237px] h-11"
                        >
                          {t(`COACH_PROFILE.UNBLOCK`)}
                        </button>
                        {isUnblocked ? (
                          <div className="absolute top-1.5 ltr:left-8 rtl:right-8">
                            <ClipLoader
                              color={'white'}
                              loading={true}
                              size={30}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    {!isSocialMedia ? (
                      <div className="h-11 flex ml-5 gap-5">
                        {socialMediaLinks[0]?.map(
                          (value: any, index: number) => (
                            <a
                              href={`${value?.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              key={value + index}
                              className={` cursor-pointer border border-borderBlack rounded h-full flex items-center justify-center w-11`}
                            >
                              <img
                                src={`${
                                  socialMediaImages[0][value?.name as string]
                                }`}
                                alt=""
                              />
                            </a>
                          )
                        )}
                      </div>
                    ) : (
                      Object.keys(socialMediaImages[0]).map(
                        (value: any, index: number) => (
                          <div
                            key={value + index}
                            className="cursor-pointer border border-borderBlack rounded  flex items-center justify-center w-11 h-11 bg-[#f3f4f6] animate-pulse"
                          ></div>
                        )
                      )
                    )}
                  </div>
                  <div className="flex justify-start relative">
                    {isSettleCoach ? (
                      <div className="flex flex-grow w-full h-full justify-start items-center absolute left-2 ">
                        <ClipLoader
                          color={'#FFFFFF'}
                          loading={true}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    <Button>
                      <div
                        onClick={() =>
                          dispatch(
                            settleCoach(Number(searchParams.get('coachId')))
                          )
                        }
                        className="bg-primary rounded text-white px-2 py-[10px]  w-[237px] font-general rtl:font-almarai font-semibold"
                      >
                        {' '}
                        {t('COACH_PROFILE.SETTLE_COACH')}
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-grow w-full h-full justify-center items-center">
                <ClipLoader
                  color={'#00a5ec'}
                  loading={true}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
          <div className="flex mt-14 border-b-2 border-borderBlack h-8 gap-5">
            <div
              onClick={() => changeField('overview')}
              className={`  h-8 font-satoshi rtl:font-almarai text-18 cursor-pointer ${
                info.overview
                  ? 'text-primary border-b-4'
                  : 'text-gray font-normal'
              }`}
            >
              {t('OVERVIEW')}
            </div>
            <div
              onClick={() => changeField('bankingInfo')}
              className={`  h-8 font-satoshi rtl:font-almarai text-18 cursor-pointer ${
                info.bankingInfo
                  ? 'text-primary border-b-4'
                  : 'text-gray font-normal'
              }`}
            >
              {t(`COACH_PROFILE.BANKINGINFO_&_TRADE_REGISTRATION`)}
            </div>
            <div
              onClick={() => changeField('subscription')}
              className={`  h-8 font-satoshi rtl:font-almarai text-18 cursor-pointer ${
                info.subscription
                  ? 'text-primary border-b-4'
                  : 'text-gray font-normal'
              }`}
            >
              {t('SUBSCRIPTION')}
            </div>
          </div>
          {info.overview && (
            <div>
              <div className="mt-12 border-b border-borderBlack  pb-5">
                <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack">
                  {t('ABOUTME')}
                </h1>
                <p
                  className={`mt-8 font-normal text-gray text-17 font-general rtl:font-almarai `}
                >
                  {showMore
                    ? coachProfileData?.bioDescription
                    : coachProfileData?.bioDescription &&
                      coachProfileData?.bioDescription?.length >= 250
                    ? (coachProfileData?.bioDescription?.substring(0, 250),
                      '...')
                    : coachProfileData?.bioDescription}
                </p>
                {coachProfileData?.bioDescription ? (
                  coachProfileData?.bioDescription &&
                  coachProfileData?.bioDescription?.length >= 250 && (
                    <div
                      className="cursor-pointer "
                      onClick={() => {
                        setShowMore(!showMore);
                      }}
                    >
                      {!showMore ? (
                        <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-almarai transition duration-1000 ease-in-out">
                          {' '}
                          {t('SHOWMORE')}{' '}
                          <span>
                            {' '}
                            <img src="/assets/coach/showMore.svg" alt="" />
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-almarai">
                          {' '}
                          {t('SHOWLESS')}
                          <span>
                            {' '}
                            <img src="/assets/coach/showLess.svg" alt="" />
                          </span>
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  <div className="font-satoshi rtl:font-almarai text-16 text-gray">
                    --{t('NA')}--
                  </div>
                )}
              </div>
              <div className="mt-6 ">
                <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5 ">
                  {t('COACHING_ACCREDITATION')}
                </h1>
                {coachProfileData?.accreditationResponses?.length !== 0 ? (
                  <div className="">
                    {coachProfileData?.accreditationResponses?.length &&
                      coachProfileData?.accreditationResponses.map(
                        (item: { [key: string]: string }, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex items-start gap-4 pb-5"
                            >
                              <img
                                className="h-[50px] w-50px] flex "
                                src="/assets/coach/university1.svg"
                                alt=""
                              />
                              <div
                                className={`flex flex-grow flex-col ${
                                  index !==
                                  (coachProfileData?.accreditationResponses
                                    ?.length as number) -
                                    1
                                    ? 'border-b border-borderBlack'
                                    : ''
                                }   `}
                              >
                                <h1 className="text-textBlack font-bold font-satoshi rtl:font-almarai text-18">
                                  {item?.provider}
                                </h1>
                                {item?.accreditationName ? (
                                  <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                                    {item?.accreditationName}
                                  </p>
                                ) : (
                                  ''
                                )}
                                <p className="text-gray  font-normal font-general rtl:font-almarai text-13 mt-1">
                                  {`${
                                    item?.issueDate
                                      ? `${new Date(
                                          item?.issueDate
                                        ).toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'long',
                                        })} - `
                                      : `--${t('NA')}--`
                                  }  ${
                                    item?.expiration
                                      ? new Date(
                                          item?.expiration
                                        ).toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'long',
                                        })
                                      : `--${t('NA')}--`
                                  } `}
                                  <span className="pl-2">{`  | ${item?.coachingHours}`}</span>
                                </p>
                                {item?.description ? (
                                  <p className="text-textBlack font-normal text-16 font-general rtl:font-almarai mt-4">
                                    {item?.description}
                                  </p>
                                ) : (
                                  ''
                                )}
                                {item?.fileId ? (
                                  <div className="flex gap-2 items-center flex-shrink h-[40px]  pt-5 ml-[-25px]">
                                    <img
                                      onClick={() =>
                                        downloadCertificate(item.fileId)
                                      }
                                      className="flex items-center mt-4 p-0 rounded cursor-pointer"
                                      src="/assets/coach/certificate.svg"
                                      alt=""
                                    />
                                    <p>{t('CERTIFICATE_PDF')}</p>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                ) : (
                  <div className="font-satoshi rtl:font-almarai text-16 text-gray">
                    --{t('NA')}--
                  </div>
                )}
              </div>
              <div className=" border-t pt-6 border-borderBlack  ">
                <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5">
                  {t('SPECIALIZATION')}
                </h1>
              </div>
              <div className="mt-6 ">
                <div>
                  <h1 className="font-satoshi rtl:font-almarai font-bold text-18 text-textBlack mb-5">
                    {t('COACHING_CATEGORIES')}
                  </h1>
                  <div>
                    <ul className="flex flex-wrap gap-3">
                      {coachProfileData.coachingCategories?.length ? (
                        coachProfileData.coachingCategories &&
                        coachProfileData.coachingCategories.map(
                          (item: string, index: number) => (
                            <li
                              key={index}
                              className="border border-borderBlack rounded  mr-4"
                            >
                              <div className="px-3 py-1 font-general rtl:font-almarai font-normal text-16 text-textBlack">
                                {item}
                              </div>
                            </li>
                          )
                        )
                      ) : (
                        <div className="font-satoshi rtl:font-almarai text-16 text-gray">
                          --{t('NA')}--
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-6 ">
                <div>
                  <h1 className="font-satoshi rtl:font-almarai font-bold text-18 text-textBlack mb-5">
                    {t('COACHING_SPECIALIZATION')}
                  </h1>
                  <ul className="flex flex-wrap gap-3">
                    {coachProfileData.specialization ? (
                      coachProfileData.specialization.map(
                        (item: string, index: number) => (
                          <li
                            key={index}
                            className="border border-borderBlack rounded mr-4"
                          >
                            <div className="px-3 py-1 font-general rtl:font-almarai font-normal text-16 text-textBlack">
                              {item}
                            </div>
                          </li>
                        )
                      )
                    ) : (
                      <div className="font-satoshi rtl:font-almarai text-16 text-gray">
                        --{t('NA')}--
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              <div className="mt-6 border-borderBlack pt-6 border-t">
                <div></div>
                <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5">
                  {t('EDUCATION')}
                </h1>
                {coachProfileData?.educationResponses?.length !== 0 ? (
                  <div className="">
                    {coachProfileData?.educationResponses &&
                      coachProfileData?.educationResponses.map(
                        (item: { [key: string]: string }, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex items-start gap-4 pb-5"
                            >
                              <img
                                className="h-[50px] w-50px] flex "
                                src="/assets/coach/university1.svg"
                                alt=""
                              />

                              <div
                                className={`flex flex-grow flex-col ${
                                  index !==
                                  (coachProfileData?.educationResponses
                                    ?.length as number) -
                                    1
                                    ? 'border-b border-borderBlack'
                                    : ''
                                }  pb-5 `}
                              >
                                <h1 className="text-textBlack font-bold font-satoshi rtl:font-almarai text-18">
                                  {item?.university}
                                </h1>
                                <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                                  {item?.degree}
                                </p>
                                <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                                  {`${
                                    item?.startDate
                                      ? `${new Date(
                                          item?.startDate
                                        ).toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'long',
                                        })}`
                                      : `--${t('NA')}--`
                                  } - ${
                                    item?.endDate
                                      ? new Date(
                                          item?.endDate
                                        ).toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'long',
                                        })
                                      : `--${t('NA')}--`
                                  } `}
                                </p>
                                {item?.description ? (
                                  <p className="text-textBlack font-normal text-16 font-general rtl:font-almarai mt-4">
                                    {item?.description}
                                  </p>
                                ) : (
                                  ''
                                )}
                                {item?.fileId ? (
                                  <div className="flex gap-2 items-center flex-shrink h-[40px]  pt-5 ml-[-25px]">
                                    <img
                                      onClick={() =>
                                        downloadCertificate(item.fileId)
                                      }
                                      className="flex items-center mt-4 p-0 rounded cursor-pointer"
                                      src="/assets/coach/certificate.svg"
                                      alt=""
                                    />
                                    <p>{t('CERTIFICATE_PDF')}</p>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                ) : (
                  <div className="font-satoshi rtl:font-almarai pb-4 text-16 text-gray">
                    --{t('NA')}--
                  </div>
                )}
              </div>
              <div className="` border-t border-borderBlack pt-6">
                <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5">
                  {t('EXPERIENCE')}
                </h1>
                {coachProfileData?.experienceResponses?.length !== 0 ? (
                  <div className="">
                    {coachProfileData?.experienceResponses &&
                      coachProfileData?.experienceResponses.map(
                        (item: { [key: string]: string }, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex items-start gap-4 pb-5"
                            >
                              <img
                                className="h-[50px] w-50px] flex "
                                src="/assets/coach/university1.svg"
                                alt=""
                              />
                              <div
                                className={`flex flex-grow flex-col ${
                                  index !==
                                  (coachProfileData?.experienceResponses
                                    ?.length as number) -
                                    1
                                    ? 'border-b border-borderBlack'
                                    : ''
                                }  pb-5 `}
                              >
                                <h1 className="text-textBlack font-bold font-satoshi rtl:font-almarai text-18">
                                  {item?.title}
                                </h1>
                                <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                                  {item?.companyName}
                                </p>
                                <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                                  {item?.location}
                                </p>
                                <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                                  {`${
                                    item?.startDate
                                      ? `${new Date(
                                          item?.startDate
                                        ).toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'long',
                                        })}  `
                                      : `--${t('NA')}--`
                                  } - ${
                                    item?.endDate
                                      ? new Date(
                                          item?.endDate
                                        ).toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'long',
                                        })
                                      : `--${t('NA')}--`
                                  } `}
                                </p>
                                <p className="text-textBlack font-normal text-16 font-general rtl:font-almarai mt-4">
                                  {item?.description}
                                </p>
                                {item?.fileId ? (
                                  <div className="flex gap-2 items-center flex-shrink h-[40px]  pt-5 ml-[-25px]">
                                    <img
                                      onClick={() =>
                                        downloadCertificate(item.fileId)
                                      }
                                      className="flex items-center mt-4 p-0 rounded cursor-pointer"
                                      src="/assets/coach/certificate.svg"
                                      alt=""
                                    />
                                    <p>{t('CERTIFICATE_PDF')}</p>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                ) : (
                  <div className="font-satoshi rtl:font-almarai text-16 text-gray">
                    --{t('NA')}--
                  </div>
                )}
              </div>
            </div>
          )}
          {info.bankingInfo && (
            <div>
              <div className="font-satoshi rtl:font-almarai font-bold text-20 text-textBlack pt-8">
                {t('COACH_PROFILE.BANK_INFO')}
              </div>
              <div>
                <div className="flex pt-5 flex-col">
                  {!bankingInfoLoading ? (
                    bankingDetails?.length && bankingInfoSuccess ? (
                      bankingDetails?.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="flex min-w-[474px] w-fit mb-4 rounded py-3 px-[14px] gap-3 shadow-[rgba(13,_38,_76,_0.19)_0px_0px_20px]"
                        >
                          <div>
                            <img src="/assets/coach/bankInfo.svg" alt="" />
                          </div>
                          <div className={`flex flex-col gap-2`}>
                            <div className="font-general rtl:font-almarai font-semibold text-16 flex">
                              {item?.accountNumber
                                ?.split('')
                                .map((value: string, index: number) => {
                                  const i = 4;
                                  return (index + 1) % i === 0 ? (
                                    <div
                                      key={index}
                                      className="pr-2"
                                    >{`${value}  `}</div>
                                  ) : (
                                    <div key={index}>{value}</div>
                                  );
                                })}
                            </div>
                            <div className="font-normal font-almarai text-gray text-16">
                              {item?.name}
                            </div>
                            <div className="font-normal font-almarai text-gray text-16">
                              {`IBAN: ${item?.iban}`}
                            </div>
                            <div className="font-normal font-almarai text-gray text-16">
                              {`Branch: ${item?.branch}`}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex min-w-[474px] w-fit mb-4 rounded py-3 px-[14px] gap-3 shadow-[rgba(13,_38,_76,_0.19)_0px_0px_20px]">
                        <div>
                          <img src="/assets/coach/bankInfo.svg" alt="" />
                        </div>
                        <div className={`flex flex-col gap-2`}>
                          <div className="font-general rtl:font-almarai normal text-gray text-16 flex">
                            {`--${t('NA')}--`}
                          </div>
                          <div className="font-normal font-almarai text-gray text-16">
                            {`--${t('NA')}--`}
                          </div>
                          <div className="font-normal font-almarai text-gray text-16">
                            {`IBAN: --${t('NA')}--`}
                          </div>
                          <div className="font-normal font-almarai text-gray text-16">
                            {`Branch: --${t('NA')}--`}
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="flex min-w-[474px] w-fit mb-4 rounded py-3 px-[14px] gap-3 shadow-[rgba(13,_38,_76,_0.19)_0px_0px_20px] bg-[#f5f5f5]   animate-pulse">
                      <div className="h-[39px] w-[39px] rounded bg-[#e4e4e7] animate-pulse"></div>
                      <div className={`flex flex-col gap-2`}>
                        <div className="  h-[21px] w-[200px] rounded bg-[#e4e4e7] animate-pulse"></div>
                        <div className=" h-[21px] w-[160px] rounded bg-[#e4e4e7] animate-pulse "></div>
                        <div className=" h-[21px] w-[100px] rounded bg-[#e4e4e7] animate-pulse"></div>
                        <div className=" h-[21px] w-[80px] rounded bg-[#e4e4e7] animate-pulse"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="font-satoshi rtl:font-almarai font-bold text-20 text-textBlack pt-8">
                {t('COACH_PROFILE.TRADE_REGISTRATION_ID')}
              </div>
              <div>
                <div className="flex pt-5 ">
                  {tradingInfoLoading ? (
                    <div className=" w-[474px] h-[45px] animate-pulse rounded bg-[#f5f5f5] shadow-[rgba(13,_38,_76,_0.19)_0px_0px_20px] "></div>
                  ) : tradingDetails?.tradeRegistrationNumber ? (
                    <div
                      className={` flex mb-4 min-w-[474px] rounded py-3 px-[14px] gap-3 shadow-[rgba(13,_38,_76,_0.19)_0px_0px_20px]`}
                    >
                      <div
                        className={`font-general rtl:font-almarai w-full font-semibold text-16 flex  `}
                      >
                        {!tradingInfoLoading
                          ? tradingDetails?.tradeRegistrationNumber
                            ? tradingDetails?.tradeRegistrationNumber
                                ?.split('')
                                .map((value: string, index: number) => {
                                  const i = 4;
                                  return (index + 1) % i === 0 ? (
                                    <div
                                      key={index}
                                      className="pr-2"
                                    >{`${value}  `}</div>
                                  ) : (
                                    <div key={index}>{value}</div>
                                  );
                                })
                            : ''
                          : ''}
                      </div>
                    </div>
                  ) : (
                    <div className="flex w-full items-center text-gray text-16 font-general rtl:font-almarai font-normal">{`--${t(
                      'NA'
                    )}--`}</div>
                  )}
                </div>
              </div>
              <div className="ltr:ml-[-22px] rtl:mr-[-22px]">
                {tradingDetails?.id && !tradingInfoLoading && (
                  <img
                    className=" cursor-pointer"
                    onClick={() =>
                      downloadCertificate(tradingDetails?.documentId)
                    }
                    src="/assets/coach/bankingCertificate.svg"
                    alt=""
                  />
                )}
              </div>
            </div>
          )}
          {info.subscription &&
            (!isSubscription ? (
              <>
                {subscriptionData?.content?.length ? (
                  <div className="flex flex-col gap-5">
                    {subscriptionData?.content?.map(
                      (value: any, index: number) => {
                        return (
                          <SubscriptionDetails
                            key={index}
                            value={value}
                            index={index}
                            isPurchased={value?.purchased}
                            status={value?.status}
                            isWireTransfer={value?.isWireTransfer}
                            wireTransferId={value?.wireTransferId}
                          />
                        );
                      }
                    )}

                    {subscriptionData?.totalPages > 1 ? (
                      <Pagination
                        searchParams={searchParams}
                        setSearchParams={setSeaarchParams}
                        totalPagesNumber={subscriptionData?.totalPages}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              [1, 2, 3, 4, 5].map((index) => (
                <div
                  key={index}
                  className="bg-[#f5f5f5] rounded-lg mt-5 py-8 px-8 flex animate-pulse "
                >
                  <div className="flex items-center w-full gap-3">
                    <div className="w-[72px] h-[72px] animate-pulse bg-[#e4e4e7] rounded-lg"></div>
                    <div className="text-white flex-grow flex flex-col gap-1 ">
                      <div className="h-[22px] w-40 animate-pulse bg-[#e4e4e7] rounded "></div>
                      <div className="h-[30px] w-16 animate-pulse bg-[#e4e4e7] rounded"></div>
                    </div>
                    <div className="flex gap-2 items-baseline">
                      <div className=" h-[66px] w-16 animate-pulse bg-[#e4e4e7] rounded"></div>
                      <div className="h-[25px] w-16 animate-pulse bg-[#e4e4e7] rounded ml-3"></div>
                    </div>
                  </div>
                </div>
              ))
            ))}
        </div>
      )}{' '}
    </>
  );
};

export default CoachProfile;
