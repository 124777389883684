import moment from 'moment';
import {
  IassessmentPackageResponses,
  IsessionPackageResponses,
} from '../../interfaces/coachInterfaces/myUploadInterface';
import {
  assessmentValueArrayInterface,
  coachingHourPackagePayloadInterface,
  coachingHourPackageRequestsInterface,
  coachingHourResponseInterface,
  coachingHoursPackageFormValueInterface,
} from '../../interfaces/coachInterfaces/packageInterface';
import {
  coachingHourRequestsInterface,
  coachingHoursFormValueInterface,
  tagsInterface,
} from '../../interfaces/coachInterfaces/sessionInterface';

export function getRandomArbitrary(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

export const randomFontSize = (captcha: any) => {
  const randomfontSizeString = [...captcha.toString()]
    .map((c) =>
      Math.random() < 0.6 ? c : c.fontsize(getRandomArbitrary(3, 6))
    )
    .join('');
  return {
    __html: randomfontSizeString,
  };
};
export const timeArrayGenerator = () => {
  const startTime = moment('01:00', 'HH:mm');
  const endTime = moment('12:50', 'HH:mm');

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }

  const timeStops = [];

  while (startTime <= endTime) {
    timeStops.push(moment(startTime).format('HH:mm'));
    startTime.add(30, 'minutes');
  }
  const dropdownData = timeStops.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  return dropdownData;
};

export const timeArrayGenerator24Format = (limit: number) => {
  const x = 30; //minutes interval
  const times = []; // time array
  let tt = 0; // start time

  //loop to increment the time and push results in array
  for (let i = 0; tt < limit * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
  const dropdownData = times.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  return dropdownData;
};
export const getTimeGreater = (start: any, end: any) => {
  const beginningTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');

  return beginningTime.isBefore(endTime);
};
export const get12FormatTimeGreater = (start: string, end: string) => {
  const beginningTime = moment(start, 'hh:mm A');
  const endTime = moment(end, 'hh:mm A');

  return beginningTime.isBefore(endTime);
};

export function checkTimeGap(start: string, end: string, gap: any) {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();

  if (hoursGap === parseInt(gap)) {
    return true;
  } else {
    return false;
  }
}
export function checkTimeGap12Format(start: string, end: string, gap: any) {
  const startTime = moment(start, 'hh:mm A');
  const endTime = moment(end, 'hh:mm A');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();
  if (hoursGap === parseInt(gap)) {
    return true;
  } else {
    return false;
  }
}
export const getCoachingHoursPackageValidation = (
  coachingHoursFormValue: coachingHoursPackageFormValueInterface
) => {
  let isValid = false;
  const getCoachingHoursWeekValidation = (
    array: coachingHourRequestsInterface[]
  ) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      validationArray.push(item.day ? true : false);
    });

    return validationArray.includes(true);
  };
  const get12HourTimeValidation = (array: coachingHourRequestsInterface[]) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day !== '') {
        validationArray.push(
          get12FormatTimeGreater(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  if (!coachingHoursFormValue?.alternative) {
    getTimeGreater(coachingHoursFormValue?.start, coachingHoursFormValue?.end)
      ? (isValid = true)
      : (isValid = false);
  } else {
    if (
      getCoachingHoursWeekValidation(
        coachingHoursFormValue?.coachingHourRequests
      )
    ) {
      get12HourTimeValidation(coachingHoursFormValue?.coachingHourRequests)
        ? (isValid = true)
        : (isValid = false);
    } else {
      isValid = false;
    }
  }

  return isValid;
};
export const getDefaultValue = (value: any, options: any) => {
  return options?.filter((item: any) => item.value === value);
};
export const getDefaultValuePhoneCode = (value: any, options: any) => {
  return options?.filter((item: any) => item.dial_code === value);
};
export const getDefaultValueArabic = (value: any, options: any) => {
  return options?.filter((item: any) => item.value === value)[0]?.label2;
};
export function checkTimeGapMin2(start: string, end: string) {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();

  if (hoursGap >= 30) {
    return true;
  } else {
    return false;
  }
}
export function checkTimeGap12FormatMin2(start: string, end: string) {
  const startTime = moment(start, 'hh:mm A');
  const endTime = moment(end, 'hh:mm A');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();

  if (hoursGap >= 30) {
    return true;
  } else {
    return false;
  }
}

export const getCoachingHoursValidationMin2 = (coachingHoursFormValue: any) => {
  let isValid = false;
  const getCoachingHoursWeekValidation = (
    array: coachingHourRequestsInterface[]
  ) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      validationArray.push(item.day ? true : false);
    });

    return validationArray.includes(true);
  };
  const get12HourTimeValidation = (array: coachingHourRequestsInterface[]) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          get12FormatTimeGreater(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  const get2HourTimeValidationFor12HrFormat = (
    array: coachingHourRequestsInterface[]
  ) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          checkTimeGap12FormatMin2(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  if (!coachingHoursFormValue?.alternative) {
    getTimeGreater(coachingHoursFormValue?.start, coachingHoursFormValue?.end)
      ? (isValid = true)
      : (isValid = false);
    checkTimeGapMin2(coachingHoursFormValue.start, coachingHoursFormValue.end)
      ? (isValid = true)
      : (isValid = false);
  } else {
    if (
      getCoachingHoursWeekValidation(
        coachingHoursFormValue?.coachingHourAndServiceRequests
      )
    ) {
      get12HourTimeValidation(
        coachingHoursFormValue?.coachingHourAndServiceRequests
      )
        ? (isValid = true)
        : (isValid = false);
      get2HourTimeValidationFor12HrFormat(
        coachingHoursFormValue?.coachingHourAndServiceRequests
      )
        ? (isValid = true)
        : (isValid = false);
    } else {
      isValid = false;
    }
  }

  return isValid;
};

export const formDataConverter = (input: any, fileType: string) => {
  const formData = new FormData();
  formData.append('file', input);
  formData.append('file-type', fileType);
  return formData;
};
const intendedClientsEditDataPayload = [
  'CEO/ C-Suite',
  'Vice President',
  'Sr Director',
  'Director',
  'Manager',
  'Team lead',
  'Individual Contributor',
  'Business Owner',
  'Entrepreneurs',
  'To Everyone',
  'Others',
];
export const getAssessmentForEditPayload = (editData: string[]) => {
  const isValuePresent = (editValue: string) => {
    return (
      editData?.length && editData.some((value: string) => value === editValue)
    );
  };
  const data = intendedClientsEditDataPayload.map((item: string) => {
    if (isValuePresent(item)) return item;
    else return null;
  });

  return data;
};
export const getTagsFromAssessmentEdit = (editData: string[]) => {
  const missing = editData?.length
    ? editData.filter(
        (item) => intendedClientsEditDataPayload.indexOf(item) < 0
      )
    : [];
  const data = missing.map((item: string) => ({
    label: item,
    value: item,
  }));
  return data;
};
export const convert24to12Item = (object: coachingHourResponseInterface) => {
  const options: any = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const week = [
    {
      day: 'SUNDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'MONDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'TUESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'WEDNESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'THURSDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'FRIDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'SATURDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
  ];
  const startDate = new Date(`February 01, 2000 ${object?.startTime}`);
  const endDate = new Date(`February 01, 2000 ${object?.endTime}`);
  const [startTime, startLocalTime] = startDate
    .toLocaleString('en-US', options)
    .split(' ');
  const [endTime, endLocalTime] = endDate
    .toLocaleString('en-US', options)
    .split(' ');
  const addZero = (time: string) => {
    const [hour, second] = time.split(':');
    if (hour.length === 1) {
      return `0${hour}:${second}`;
    } else return time;
  };

  week.map((item: any) => {
    if (item.day === object?.day) {
      item.day = object?.day;
      item.from = addZero(startTime);
      item.localFromTime = startLocalTime;
      item.end = addZero(endTime);
      item.locaEndTime = endLocalTime;
    } else {
      item.day = '';
    }
  });

  return week;
};
export const isAlternative = (array: coachingHourResponseInterface[]) => {
  const allStartTimeEqual = (arr: coachingHourResponseInterface[]) =>
    arr?.length &&
    arr.every(
      (val: coachingHourResponseInterface) => val.startTime === arr[0].startTime
    );
  const allEndTimeEqual = (arr: coachingHourResponseInterface[]) =>
    arr?.length &&
    arr.every(
      (val: coachingHourResponseInterface) => val.endTime === arr[0].endTime
    );
  if (allStartTimeEqual(array) && allEndTimeEqual(array)) {
    return true;
  } else {
    return false;
  }
};
export const getHoursData = (array: coachingHourResponseInterface[]) => {
  const week = [
    { label: 'Sunday', value: 'SUNDAY' },
    { label: 'Monday', value: 'MONDAY' },
    { label: 'Tueday', value: 'TUESDAY' },
    { label: 'Wednesday', value: 'WEDNESDAY' },
    { label: 'Thursday', value: 'THURSDAY' },
    { label: 'Friday', value: 'FRIDAY' },
    { label: 'Saturday', value: 'SATURDAY' },
  ];
  const getHourAndMin = (time: string) => {
    const [hours, minutes, second] = time.split(':');
    return `${hours}:${minutes}`;
  };
  const getDayIndex = (day: string) => {
    let data: { index: number; label: string } = { index: 0, label: '' };
    week.map((item: { value: string; label: string }, index: number) => {
      if (day === item.value) {
        data = {
          index: index,
          label: item.label,
        };
      }
    });
    return data;
  };
  const dateArray: { index: number; label: string }[] = [];
  array.map((item: coachingHourResponseInterface) => {
    dateArray.push(getDayIndex(item.day));
  });
  const sortedArray: { index: number; label: string }[] = dateArray.sort(
    (obj1, obj2) => {
      if (obj1.index > obj2.index) {
        return 1;
      }

      if (obj1.index < obj2.index) {
        return -1;
      }

      return 0;
    }
  );
  const startDay: string = sortedArray[0].label;
  const endDay: string = sortedArray[sortedArray.length - 1].label;
  const startTime: string = getHourAndMin(array[0].startTime);
  const endTime: string = getHourAndMin(array[0].endTime);
  const payload = {
    days: `${startDay}-${endDay}`,
    start: startTime,
    end: endTime,
  };
  return payload;
};
export const convert24to12 = (array: coachingHourResponseInterface[]) => {
  const options: any = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const week = [
    {
      day: 'SUNDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'MONDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'TUESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'WEDNESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'THURSDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'FRIDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'SATURDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
  ];
  const responseArray = array.map((item: coachingHourResponseInterface) => {
    const startDate = new Date(`February 01, 2000 ${item.startTime}`);
    const endDate = new Date(`February 01, 2000 ${item.endTime}`);
    const [startTime, startLocalTime] = startDate
      .toLocaleString('en-US', options)
      .split(' ');
    const [endTime, endLocalTime] = endDate
      .toLocaleString('en-US', options)
      .split(' ');
    const addZero = (time: string) => {
      const [hour, second] = time.split(':');
      if (hour.length === 1) {
        return `0${hour}:${second}`;
      } else return time;
    };
    return {
      day: item.day,
      from: addZero(startTime),
      localFromTime: startLocalTime,
      end: addZero(endTime),
      locaEndTime: endLocalTime,
    };
  });

  week.map((item: coachingHourPackagePayloadInterface) => {
    responseArray.forEach((oItem: coachingHourPackageRequestsInterface) => {
      if (oItem.day === item.day) {
        item.day = oItem.day;
        item.from = oItem.from;
        item.localFromTime = oItem.localFromTime;
        item.end = oItem.end;
        item.locaEndTime = oItem.locaEndTime;
        item.isPresent = true;
      }
    });
  });
  week.map((item: coachingHourPackagePayloadInterface) => {
    if (!item.isPresent) {
      item.day = '';
    }
  });

  return week;
};
export const getAssessmentValuePayload = (
  assessmentPackageResponses: IassessmentPackageResponses[],
  sessionPackageResponses: IsessionPackageResponses[]
) => {
  const data: assessmentValueArrayInterface[] = [];
  assessmentPackageResponses?.length &&
    assessmentPackageResponses.map(
      (item: IassessmentPackageResponses, index: number) => {
        // if (index !== 0) {
        data.push({
          category: 'ASSESSMENT',
          assessmentId: item.assessmentId,
          // durationInWeek: item.durationInWeek,
          sessionId: '',
          // duration: '',
          // retakePeriod: '',
        });
        // }
      }
    );
  sessionPackageResponses?.length &&
    sessionPackageResponses.map(
      (item: IsessionPackageResponses, index: number) => {
        // if (index !== 0) {
        data.push({
          category: 'SESSION',
          assessmentId: '',
          // durationInWeek: '',
          sessionId: item.sessionId,
          // duration: item.duration,
          // retakePeriod: item.retakePeriod,
        });
        // }
      }
    );

  return data;
};
export const thirdPartyEditPayloadCreator = (
  fileIds: number[],
  termsOfServiceUrl: string[]
) => {
  const data: { file: any; link: string }[] = [];

  if (fileIds?.length >= termsOfServiceUrl?.length) {
    fileIds.map((id: number, i: number) => {
      data.push({
        link: termsOfServiceUrl[i] ? termsOfServiceUrl[i] : '',
        file: id,
      });
    });
  }
  if (fileIds?.length < termsOfServiceUrl?.length) {
    termsOfServiceUrl.map((link: string, i: number) => {
      data.push({
        file: fileIds[i] ? fileIds[i] : 0,
        link: link,
      });
    });
  }

  return data;
};
export const gePackageEditValuePayload = (array: any) => {
  const data: assessmentValueArrayInterface[] = [];
  array?.length &&
    array.map((item: any, index: number) => {
      if (item.serviceType === 'ASSESSMENT') {
        data.push({
          category: 'ASSESSMENT',
          assessmentId: item.id,
          // durationInWeek: item.duration,
          sessionId: '',
          // duration: '',
          // retakePeriod: '',
        });
      } else {
        data.push({
          category: 'SESSION',
          assessmentId: '',
          // durationInWeek: '',
          sessionId: item.id,
          // duration: item.duration,
          // retakePeriod: item.retakePeriod,
        });
      }
    });

  return data;
};

export const getCoachingHoursValidation = (
  coachingHoursFormValue: coachingHoursPackageFormValueInterface,
  gap: number
) => {
  let isValid = false;
  const getCoachingHoursWeekValidation = (
    array: coachingHourRequestsInterface[]
  ) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      validationArray.push(item.day ? true : false);
    });

    return validationArray.includes(true);
  };
  const get12HourTimeValidation = (array: coachingHourRequestsInterface[]) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          get12FormatTimeGreater(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  const get2HourTimeValidationFor12HrFormat = (
    array: coachingHourRequestsInterface[]
  ) => {
    const validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          checkTimeGap12Format(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`,
            gap
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  if (!coachingHoursFormValue?.alternative) {
    getTimeGreater(coachingHoursFormValue?.start, coachingHoursFormValue?.end)
      ? (isValid = true)
      : (isValid = false);
    checkTimeGap(coachingHoursFormValue.start, coachingHoursFormValue.end, gap)
      ? (isValid = true)
      : (isValid = false);
  } else {
    if (
      getCoachingHoursWeekValidation(
        coachingHoursFormValue?.coachingHourRequests
      )
    ) {
      get12HourTimeValidation(coachingHoursFormValue?.coachingHourRequests)
        ? (isValid = true)
        : (isValid = false);
      get2HourTimeValidationFor12HrFormat(
        coachingHoursFormValue?.coachingHourRequests
      )
        ? (isValid = true)
        : (isValid = false);
    } else {
      isValid = false;
    }
  }

  return isValid;
};
export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h.split(' ');

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};
export const coachingHoursPayload = (
  coachingHoursFormValue: coachingHoursFormValueInterface
) => {
  const arr: {
    day: string;
    startTime: string;
    endTime: string;
  }[] = [];
  const week = [
    { label: 'Sunday', value: 'SUNDAY' },
    { label: 'Monday', value: 'MONDAY' },
    { label: 'Tueday', value: 'TUESDAY' },
    { label: 'Wednesday', value: 'WEDNESDAY' },
    { label: 'Thursday', value: 'THURSDAY' },
    { label: 'Friday', value: 'FRIDAY' },
    { label: 'Saturday', value: 'SATURDAY' },
  ];
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [startDay, endDay] = coachingHoursFormValue?.days?.split('-');
  let startDayIndex = 0;
  let endDayIndex = 0;
  week.map((item: tagsInterface, index: number) => {
    if (startDay === item.label) {
      startDayIndex = index;
    }
    if (endDay === item.label) {
      endDayIndex = index;
    }
  });
  if (startDayIndex < endDayIndex) {
    week.map((item: tagsInterface, index: number) => {
      if (index >= startDayIndex && index <= endDayIndex) {
        arr.push({
          day: item.value,
          startTime: coachingHoursFormValue.start,
          endTime: coachingHoursFormValue.end,
        });
      }
    });
  } else {
    week.map((item: tagsInterface, index: number) => {
      if (
        (index <= endDayIndex && index <= startDayIndex) ||
        (index >= endDayIndex && index >= startDayIndex)
      ) {
        arr.push({
          day: item.value,
          startTime: coachingHoursFormValue.start,
          endTime: coachingHoursFormValue.end,
        });
      }
    });
  }
  return arr;
};
