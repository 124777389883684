import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../_helpers';
import Button from '../button';
import CarouselCard from '../carouselCard';
import Filter from '../filter';
import NoData from '../noDataComponent';
import Pagination from '../pagination';
import SearchComponent from '../searchComponent';
import Sort from '../sort';
import { getProductDetails, serviceDelete } from './slices';

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isProducts, productsData } = useAppSelector(
    (state) => state.products
  );
  const [t] = useTranslation();
  const header = [
    { title: 'NEW', value: 'PENDING' },
    // { title: 'HOLD', value: 'HOLD' },
    { title: 'APPROVED', value: 'APPROVED' },
    { title: 'REJECTED', value: 'REJECTED' },
  ];
  const subHeader = [
    { title: 'ALL', value: 'ALL' },
    { title: 'SESSIONS', value: 'SESSION' },
    { title: 'PACKAGES', value: 'PACKAGE' },
    { title: 'ASSESSMENTS', value: 'ASSESSMENT' },
  ];
  const handleparams = (data: string, category: string) => {
    const params: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    params['requestStatusFilter'] = data;

    setSearchParams(params);
  };
  const reload = async () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    await setSearchParams(params);
    // dispatch(getCoachDirectoryTableData(searchParams));
  };
  useEffect(() => {
    !searchParams.get('requestStatusFilter') &&
      setSearchParams({
        requestStatusFilter: 'PENDING',
        servicesFilter: 'ALL',
        page: '0',
      });
  }, []);
  useEffect(() => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      key !== 'sort'
        ? (params[key] = value.toUpperCase())
        : (params['sort'] = value);
    });

    Object.keys(params)?.length && dispatch(getProductDetails(params));
  }, [searchParams]);
  const handleClick = (id: number, serviceType: string) => {
    navigate(`/kun/products/service-view?id=${id}&serviceType=${serviceType}`);
  };
  const handleSearchParams = () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      key !== 'view' && (params[key] = value);
    });
    setSearchParams(params);
  };

  const dropdownListData = [
    {
      title: 'NAME',
      value: 'title',
      dir: { asc: 'asc', desc: 'desc' },
    },

    {
      title: 'ID',
      value: 'id',
      dir: { asc: 'asc', desc: 'desc' },
    },
  ];
  const filterList = [
    [
      { title: 'Male', titleValue: 'MALE' },
      { title: 'Female', titleValue: 'FEMALE' },
    ],
    [
      { title: 'All' || '', titleValue: 'ALL' },
      { title: 'Active', titleValue: 'ACTIVE' },
      { title: 'Inactive', titleValue: 'INACTIVE' },
      { title: 'Newly created', titleValue: 'NEWLY_CREATED' },
    ],
  ];
  const handleDropdownClick = (value: string, type?: string, id?: number) => {
    // if (value === 'DELETE') {
    //   switch (item?.type) {
    //     case 'ASSESSMENT':
    //       if (item?.id) dispatch(deleteAssessment(item.id));
    //       break;
    //     case 'PACKAGE':
    //       if (item?.id) dispatch(deletePackages(item.id));
    //       break;
    //     case 'SESSION':
    //       if (item?.id) dispatch(deleteSession(item.id));
    //       break;
    //   }
    // }
    if (value === 'EDIT') {
      switch (type) {
        case 'ASSESSMENT':
          if (id)
            // dispatch(fetchAssessmentById({ id: item.id, navigate: navigate }));
            navigate(`/kun/coach-assessment?form=EDIT&id=${id}`);
          break;
        case 'PACKAGE':
          // if (item?.id) dispatch(fetchPackageById(item.id));
          if (id) navigate(`/kun/coach-package?form=EDIT&id=${id}`);

          break;
        case 'SESSION':
          if (id) navigate(`/kun/coach-session?form=EDIT&id=${id}`);

          break;
      }
    }
  };
  const handleservicesFilter = (value: string) => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    params['servicesFilter'] = value;
    searchParams.get('baseFilter') ? '' : (params['baseFilter'] = 'ACTIVE');
    setSearchParams(params);
  };
  const handleDelete = async ({
    id,
    service,
  }: {
    id: number;
    service: string;
  }) => {
    try {
      service &&
        (await dispatch(
          serviceDelete({ id, service: service as string })
        ).unwrap());
      reload();
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mx-6 mt-10  flex flex-col mb-10">
      {!searchParams.get('view') && (
        <div>
          <div>
            <ul className="flex flex-row  pb-6 gap-3">
              {header.map((item, index) => (
                <li key={item.title + `${index}`}>
                  <Button
                    functionName={() => handleparams(item.value, item.title)}
                    className={` border ${
                      searchParams.get('requestStatusFilter') === item.value
                        ? 'border-primary bg-primary text-white '
                        : ' border-borderBlack '
                    }  rounded shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)] px-6 py-2 roundedtext-16 font-general rtl:font-almarai font-normal text-secondary   `}
                    data={`${t(`PRODUCTS_COMPONENT.${item.title}`)}`}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className=" flex flex-col gap-4 flex-grow">
            <div className=" ">
              <div className="flex felx-row  p-0 gap-4 ">
                <ul className="flex flex-row gap-4 items-baseline">
                  {subHeader.map((item, index) => (
                    <li key={item.title + `${index}`}>
                      <Button
                        functionName={() => handleservicesFilter(item?.value)}
                        className={` border ${
                          searchParams.get('servicesFilter') === item.value
                            ? 'border-primary bg-primary text-white '
                            : ' border-borderBlack '
                        }  rounded shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)] px-6 py-1.5 roundedtext-16 font-general rtl:font-almarai font-normal text-secondary   `}
                        // functionName={() => setParams(value.title)}
                        data={`${t(`PRODUCTS_COMPONENT.${item.title}`)}`}
                      />
                    </li>
                  ))}
                </ul>
                <SearchComponent />
                <div className="place-self-end  ">
                  <div className="flex flex-row gap-x-4 place-items-center  ">
                    <div className="z-30 h-[38px]">
                      <Sort dropdownList={dropdownListData} />
                    </div>
                    <div className="z-30 h-[38px]">
                      <Filter fields={filterList} />
                    </div>
                    <div className=" flex flex-row gap-x-6">
                      {/* <div>
                      <img
                        className="cursor-pointer"
                        src="/assets/download.svg"
                        alt=""
                      />
                    </div> */}
                      <div>
                        <img
                          onClick={() => reload()}
                          className="cursor-pointer"
                          src="/assets/reload.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isProducts && (
            <ul className=" flex flex-wrap gap-5 w-full h-auto mt-16 ">
              {[1, 2, 3, 4, 5, 7, 8, 9, 10, 11].map((item, index) => (
                <li className="min-w-[278px]" key={item + index}>
                  <CarouselCard
                    item={undefined}
                    loading={true}
                    handleFunction={() => handleClick(0, '')}
                  />
                </li>
              ))}
            </ul>
          )}
          <ul className=" flex flex-wrap gap-5 w-full mt-16 ">
            {!isProducts && productsData?.content?.length
              ? productsData?.content.map((item: any, index: number) => (
                  <li className="min-w-[278px]" key={item + index}>
                    <CarouselCard
                      item={item}
                      loading={isProducts}
                      handleFunction={() =>
                        handleClick(item?.id, item?.serviceType)
                      }
                      type={searchParams.get('type') as string}
                      dropDown={
                        <div className="absolute top-8 ltr:right-4 rtl:left-4 cursor-pointer bg-white rounded z-50 font-satoshi rtl:font-almarai">
                          <div
                            className={`flex justify-start ${
                              searchParams.get('requestStatusFilter') !==
                                'PENDING' && 'hidden'
                            }`}
                          >
                            <div
                              onClick={() =>
                                handleDropdownClick(
                                  'EDIT',
                                  item?.serviceType,
                                  item?.id
                                )
                              }
                              className="px-3 py-1"
                            >
                              {t('PRODUCT.EDIT')}
                            </div>
                          </div>
                          <div className="   flex">
                            <div
                              onClick={() =>
                                handleDelete({
                                  id: item?.id,
                                  service: item?.serviceType,
                                })
                              }
                              className="px-3 py-1"
                            >
                              {t('PRODUCT.DELETE')}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </li>
                ))
              : !isProducts && (
                  <div className="text-gray font-general rtl:font-almarai h-[50vh] w-full flex justify-center items-center">
                    <NoData />{' '}
                  </div>
                )}
          </ul>
        </div>
      )}
      {productsData?.totalPages > 1 && (
        <div className="pt-5">
          <Pagination
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            totalPagesNumber={productsData?.totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default Products;
