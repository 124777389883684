import { ResponsiveLine } from '@nivo/line';

const chartData = [
  {
    id: 'japan',
    color: '',
    data: [
      {
        x: 'jan',
        y: 10,
      },
      {
        x: 'feb',
        y: 47,
      },
      {
        x: 'mar',
        y: 71,
      },
      {
        x: 'apr',
        y: 150,
      },
      {
        x: 'may',
        y: 258,
      },
      {
        x: 'jun',
        y: 325,
      },
      {
        x: 'jul',
        y: 354,
      },
      {
        x: 'aug',
        y: 188,
      },
      {
        x: 'sep',
        y: 36,
      },

      {
        x: 'oct',
        y: 95,
      },
      {
        x: 'nov',
        y: 285,
      },
    ],
  },
  {
    id: 'china',
    color: '',
    data: [
      {
        x: 'jan',
        y: 20,
      },
      {
        x: 'feb',
        y: 100,
      },
      {
        x: 'mar',
        y: 90,
      },
      {
        x: 'apr',
        y: 300,
      },
      {
        x: 'may',
        y: 100,
      },
      {
        x: 'jun',
        y: 250,
      },
      {
        x: 'jul',
        y: 50,
      },
      {
        x: 'aug',
        y: 70,
      },
      {
        x: 'sep',
        y: 200,
      },

      {
        x: 'oct',
        y: 150,
      },
      {
        x: 'nov',
        y: 200,
      },
    ],
  },
];

const LineChart = ({ content }: any) => {
  return (
    <>
      <ResponsiveLine
        useMesh={true}
        animate={true}
        data={content}
        colors={['#00a5ec', '#9EE0E6']}
        layers={['axes', 'mesh', 'lines', 'lines', 'grid']}
        axisLeft={{
          tickPadding: 25,
          tickSize: 0,
        }}
        axisBottom={{
          tickPadding: 25,
          tickSize: 0,
        }}
        margin={{ top: 50, right: 100, bottom: 50, left: 100 }}
        lineWidth={3}
        curve={'cardinal'}
        enableGridY={false}
      />
    </>
  );
};
export default LineChart;
