import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { getImage } from './slices';

interface IImage {
  id: number | string;
  bgImgUrl: string;
  bgClass: string;
  propClass: string;
}
const Image: FC<IImage> = ({ id, bgImgUrl, bgClass, propClass }) => {
  const [imageData, setImageData] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    async function image() {
      let temp;
      id && setImageLoading(true);
      id ? (temp = await dispatch(getImage(id as string))) : '';
      temp &&
        (setImageLoading(false),
        setImageData(temp?.payload?.data[0]?.fileContent));
    }
    image();
  }, []);

  return (
    <div className={`relative  h-full w-full`}>
      {imageData && !imageLoading ? (
        <div
          className={`  w-full h-full'
          `}
        >
          <img
            className={`${propClass}  `}
            src={`${`data:image/png;base64,${imageData}`}`}
            alt="no image"
          />
        </div>
      ) : imageLoading ? (
        <div className=" flex items-center justify-center w-full h-full">
          <ClipLoader
            color={'#00a5ec'}
            loading={true}
            size={30}
            aria-label=""
            data-testid=""
            className=""
          />
        </div>
      ) : (
        <div className="   w-full h-full flex justify-center items-center ">
          <img className={`${bgClass}`} src={`${bgImgUrl}`} alt="" />
        </div>
      )}
    </div>
  );
};

export default Image;
