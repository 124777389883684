import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { decodeEmail } from '../../../_helpers/coachHelpers/localStorage';
import { deletePackage } from '../../../coachSlices/packageSlice';
import { myCoachUploadsInterface } from '../../../interfaces/coachInterfaces/myUploadInterface';
import { Image } from '../Image/Image';

interface IFaqCard {
  data: myCoachUploadsInterface;
}

const SessionsCard: FC<IFaqCard> = ({ data }): JSX.Element => {
  const [t] = useTranslation('');
  const dispatch = useDispatch<any>();
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const deleteHandleClick = async (id: string) => {
    setDeleteLoader(true);
    await dispatch(deletePackage(id));
    setDeleteLoader(false);
  };

  return (
    <div className="w-full flex rounded-lg shadow-xl min-h-[8.375rem] h-fit mt-6">
      <Image
        id={data?.imgId as number}
        bgImgUrl={'/assets/default-img-icon.svg'}
        bgClass={
          ' bg-[#F6F8F5] package-card-image flex justify-center items-center'
        }
        propClass={'package-card-image'}
      />
      <div className="w-[90%] justify-between flex flex-col min-h-[8.375rem] h-fit p-3">
        <div className="w-full flex justify-between font-bold text-lg">
          <span>{data?.title}</span>
          <span className="text-2xl package-card-days">${data?.price}</span>
        </div>
        <span className=" font-normal text-xs text-[#687E8E]">
          {data?.subTitle}
        </span>
        <span className="font-bold text-xs text-[black]">{decodeEmail()}</span>
        <span className="flex gap-2 text-xs items-center font-semibold text-[#BC455E] ">
          3.5
          <img src="/assets/rating-star-full.svg" className="" alt="" />
          <img src="/assets/rating-star-full.svg" className="" alt="" />
          <img src="/assets/rating-star-full.svg" className="" alt="" />
          <img src="/assets/rating-half-star.svg" className="" alt="" />
          <img src="/assets/rating-empty-star.svg" className="" alt="" />
          <span className="font-normal text-xs text-[#687E8E]">
            (568 {t('COACH_PACKAGE.RATINGS')})
          </span>
        </span>
        <div className="package-card-days-mobile">
          {data?.coachingHourResponses?.length}&nbsp;days
        </div>
        <div className="w-full flex justify-between items-center font-normal text-xs text-[#687E8E]">
          <span className="package-card-days">
            {data?.coachingHourResponses?.length}&nbsp;days
          </span>
          <span className="text-2xl font-bold text-lg package-card-price-mobile text-[black]">
            SAR{data?.price}
            {data?.price}
          </span>

          {/* {deleteLoader ? (
            <div className="ash-loader " />
          ) : (
            <img
              onClick={() => deleteHandleClick(data?.id as string)}
              className="cursor-pointer w-[20px] h-[20px]"
              src="/assets/homePageImages/delete-icon.svg"
              alt=""
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default SessionsCard;
