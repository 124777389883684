import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Header } from '../../components/index';
import Input from '../../components/inputComponent/index';
import { IFormValues } from '../../interfaces/index';
import { useAppSelector } from '../../_helpers/hooks';
import { loginSchema } from '../../_helpers/index';
import { getAccessToken } from '../../_helpers/token';
import { resetForggotPassword } from '../forgotPassword/slice';
import './index.scss';
import {
  authenticateUser,
  captcha,
  captchaVerification,
  resetLogin,
} from './slices/authSlice';

const Login = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { lang } = useAppSelector((state) => state.language);
  const [loginCredentials, setLoginCredentials] = useState({
    email: '',
    password: '',
    user: '',
    history: navigate,
  });
  const {
    captchaData,
    captchaVerificationSuccess,
    captchaVerificationError,
    isSuccess,
    jwtToken,
    captchaLoading,
    isLoading,
    captchaId,
    captchaVerificationPayload,
    loginSuccessPayload,
    loginErrorPayload,
    isError,
    captchaError,
    captchaVerificationLoading,
  } = useAppSelector((state) => state.auth);
  const token = getAccessToken();
  const dispatch = useDispatch<any>();
  const handleLoginSubmit = ({ email, password, captcha }: IFormValues) => {
    setLoginCredentials({
      email,
      password,
      user: 'SUPER_ADMIN',
      history: navigate,
    });
    if (captcha.length) {
      dispatch(
        captchaVerification({
          id: captchaId,
          captchaValue: captcha,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(captcha());
  }, []);
  useEffect(() => {
    captchaVerificationSuccess &&
    loginCredentials?.email &&
    loginCredentials?.password
      ? dispatch(authenticateUser(loginCredentials))
      : '';
    captchaVerificationError ? dispatch(captcha()) : '';
    captchaError ? dispatch(captcha()) : '';
  }, [captchaVerificationSuccess, captchaVerificationError, captchaError]);
  useEffect(() => {
    if (isSuccess) {
      navigate('/kun/coach');
      dispatch(resetLogin());
    }
    isError ? dispatch(captcha()) : '';
    dispatch(resetLogin());
  }, [isSuccess, isError]);

  const refresh = () => {
    dispatch(captcha());
  };

  const redirect = async () => {
    await dispatch(resetForggotPassword());
    navigate('/kun/forgotPassword');
  };

  return (
    <>
      <div className="relative bg-logo bg-cover h-screen w-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 ">
        <div className="z-10  absolute top-5  right-[15vw] rtl:right-[85vw]  ">
          <div className=" flex absolute  ">
            <Header />
          </div>
        </div>
        <div className="bg-white flex w-[820px] h-fit rounded-lg  overflow-hidden items-center ">
          <div className=" flex flex-col items-center justify-center w-1/2  ">
            <div>
              <img src="/assets/login/logo.svg" alt="logo" />
            </div>
          </div>
          <div className="py-12  mr-8 rtl:ml-8 rtl:mr-0  w-1/2">
            <div className="flex flex-col justify-items-start items-start justify-center ">
              <div className="">
                <label
                  className={`mt-6 text-center  font-bold text-textBlack text-28 ${
                    lang === 'en'
                      ? 'font-satoshi rtl:font-almarai'
                      : 'font-almarai'
                  }  `}
                >
                  {t('SIGN_IN.ADMIN')} <div className="    flex "></div>
                </label>
              </div>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  captcha: '',
                }}
                validationSchema={loginSchema}
                onSubmit={handleLoginSubmit}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  submitForm,
                }) => {
                  return (
                    <Form
                      onSubmit={handleSubmit}
                      className=" w-full space-y-6l relative"
                    >
                      <div className="text-red rtl:font-almarai text-sm pl-3 inline-block"></div>
                      <div className="rounded-md ">
                        <div className="relative cursor-pointer mb-8">
                          <Input
                            id={'formEmail'}
                            name={'email'}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={'email'}
                            placeholder={t('FORM.INPUT')}
                            classname={'input_login  placeholder-gray-300 mt-5'}
                            errors={errors.email}
                            touched={touched.email}
                            // labelChildren={
                            //   <label
                            //     htmlFor="formEmail"
                            //     className="labelInput "
                            //   >
                            //     {t('FORM.USERNAME')}
                            //     <span className="text-red rtl:font-almarai cursor-text">
                            //       *
                            //     </span>
                            //   </label>
                            // }
                          />
                          <label
                            htmlFor="formEmail"
                            className={'label-input-login '}
                          >
                            {t('FORM.USERNAME')}
                            <span className="text-red rtl:font-almarai cursor-text">
                              *
                            </span>
                          </label>
                          <div className=" px-4">
                            <div
                              className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                errors.email
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              } `}
                            >
                              {errors.email && touched.email
                                ? t(`FORM.${errors.email}`)
                                : ''}
                            </div>
                          </div>
                          {/* <div className="pb-4 px-4">
                              <div
                                className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                  errors.email ? 'text-red rtl:font-almarai' : 'text-black '
                                } `}
                              >
                                {errors.email && touched.email
                                  ? t(`FORM.${errors.email}`)
                                  : ''}
                              </div>
                            </div> */}
                        </div>

                        <div className="relative cursor-text input-text mb-8  flex-1 ">
                          <Input
                            id={'formPassword'}
                            name={'password'}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={'password'}
                            placeholder={t('FORM.PASSWORD')}
                            classname={
                              '   input_login  placeholder-gray-300 mt-5'
                            }
                            errors={errors.password}
                            touched={touched.password}
                          />
                          <label
                            htmlFor="formPassword"
                            className={'label-input-login '}
                          >
                            {t('FORM.PASSWORD')}
                            <span className="text-red rtl:font-almarai cursor-text">
                              *
                            </span>
                          </label>
                          <div className=" px-4">
                            <div
                              className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                errors.email
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              } `}
                            >
                              {errors.password && touched.password
                                ? t(`FORM.${errors.password}`)
                                : ''}
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center gap-2">
                          <div className="relative cursor-text input-text w-1/2 flex-1">
                            <Input
                              id={'formCaptcha'}
                              name={'captcha'}
                              value={values.captcha}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type={'text'}
                              placeholder={t('FORM.INPUT')}
                              classname={'input_login'}
                              errors={errors.captcha}
                              touched={touched.captcha}
                              // labelChildren={
                              //   <label
                              //     htmlFor="formCaptcha"
                              //     className=" labelInput "
                              //   >
                              //     {t('FORM.ENTER_CAPTCHA')}
                              //     <span className="text-red rtl:font-almarai cursor-text">
                              //       *
                              //     </span>
                              //   </label>
                              // }
                            />
                            <label
                              htmlFor="formCaptcha"
                              className="label-input-login "
                            >
                              {t('FORM.ENTER_CAPTCHA')}
                              <span className="text-red rtl:font-almarai cursor-text">
                                *
                              </span>
                            </label>
                            <div className=" px-4">
                              <div
                                className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                  errors.captcha
                                    ? 'text-red rtl:font-almarai'
                                    : 'text-black '
                                } `}
                              >
                                {errors.captcha && touched.captcha
                                  ? t(`FORM.${errors.captcha}`)
                                  : ''}
                              </div>
                            </div>
                            {/* <div className=" px-4">
                              <div
                                className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                  errors.email ? 'text-red rtl:font-almarai' : 'text-black '
                                } `}
                              >
                                {errors.captcha && touched.captcha
                                  ? t(`FORM.${errors.captcha}`)
                                  : ''}
                                <div>
                                  {errors.captcha
                                    ? ''
                                    : captchaVerificationError
                                    ? t(`FORM.INVALID_CAPTCHA`)
                                    : ''}
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div className="w-4/12 flex-0 bg-black h-12 rounded flex items-center justify-center  text-white font-satoshi rtl:font-almarai italic font-bold tracking-[.25em] captcha ">
                            {captchaData.__html !== '' && (
                              <div dangerouslySetInnerHTML={captchaData} />
                            )}
                          </div>
                          <div className="border cursor-pointer rounded flex items-center justify-center h-12 w-[40px] ">
                            <span onClick={() => refresh()}>
                              <img
                                className={`${
                                  captchaLoading
                                    ? ' animate-spin ease-in-out'
                                    : ''
                                }`}
                                src="/assets/login/refresh.svg"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="relative">
                        <div className="absolute top-9 ltr:left-24 rtl:right-24 z-1000">
                          {(isLoading || captchaVerificationLoading) && (
                            <ClipLoader
                              color={'white'}
                              loading={true}
                              size={30}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn w-full text-white mt-6 py-[15px] rounded  bg-primary font-satoshi rtl:font-almarai font-bold "
                        >
                          {t('SIGN_IN.LOGIN')}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div className="w-full flex felx-row mt-4  justify-end">
                <div
                  onClick={() => {
                    redirect();
                  }}
                  className="font-general rtl:font-almarai text-textBlack text-12 font-semibold cursor-pointer"
                >
                  {t('SIGN_IN.FORGOT_PASSWORD')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
