import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICoachApprove } from '../../../interfaces/index';
import {
  fetchCoachApproveReject,
  fetchCoachProfileData,
} from '../../../services/coachApproval';
import { alertActive } from '../../notification/slices';

const initialState: ICoachApprove = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  coachApproveData: {},
  alertMessage: '',
  requestLoading: false,
  requestSuccess: false,
  requestError: false,
  requestPayload: '',
};

export const coachApproveSlice = createSlice({
  name: 'coachProfile',
  initialState: initialState,
  reducers: {
    resetCoachApproval: (state) => {
      state.requestSuccess = false;
      state.requestError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoachApproveData.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getCoachApproveData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.coachApproveData = action.payload?.data[0];
        state.alertMessage = action.payload?.message;
      })
      .addCase(getCoachApproveData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(coachApprovalReject.pending, (state) => {
        state.requestLoading = true;
        state.requestSuccess = false;
      })
      .addCase(coachApprovalReject.fulfilled, (state, action) => {
        state.requestLoading = false;
        state.requestSuccess = true;
        state.requestPayload = action.payload?.message;
      })
      .addCase(coachApprovalReject.rejected, (state, action: any) => {
        state.requestLoading = false;
        state.requestError = true;
        state.requestSuccess = false;
        state.requestPayload = action.payload?.data?.message;
      });
  },
});

export const getCoachApproveData = createAsyncThunk(
  'coachApprove/getCoachApproveData',
  async (coachApprovePayload: { [key: string]: string }, thunkAPI) => {
    try {
      const { data } = await fetchCoachProfileData(coachApprovePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const coachApprovalReject = createAsyncThunk(
  'coachApproval/approveReject',
  async (coachApprovePayload: { [key: string]: string }, thunkAPI) => {
    try {
      const { data } = await fetchCoachApproveReject(coachApprovePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const { resetCoachApproval } = coachApproveSlice.actions;

export default coachApproveSlice.reducer;
