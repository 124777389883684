import moment from 'moment';
import 'moment/min/locales';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { ITableProps } from '../../interfaces/index';
import { useAppSelector } from '../../_helpers';
import Image from '../imageDisplay/index';
import NoData from '../noDataComponent';
const Table: FC<ITableProps> = ({
  header,
  data,
  loading,
  totalPages,
  category,
  profilePicture,
  profilePictureLoading,
  directFunction,
  searchParams,
  setSearchParams,
}): JSX.Element => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { lang } = useAppSelector((state) => state.language);

  moment.locale(lang === 'en' ? 'en' : 'ar');

  const list = [
    'overall_rating',
    'service',
    'package',
    'assessment',
    'subscription',
  ];
  const leftAlign = ['amount'];
  return (
    <>
      {
        <>
          <div className="overflow-hidden w-full">
            <div className="  bg-gray-100 flex  bg-gray-100 font-sans overflow-hidden   ">
              <div className="w-full">
                <div className="bg-white shadow-md  my-6 border border-borderBlack rounded-md overflow-x-scroll scroll-mt-2">
                  <table className="min-w-max w-full table-auto  ">
                    <thead>
                      <tr className="bg-darkBlue text-white text-sm leading-normal  h-10">
                        {!loading ? (
                          header && data?.length !== 0 ? (
                            header &&
                            header.map((item: any, index: number) => (
                              <th
                                key={index}
                                className={` ${
                                  item === 'id' || item === 'profilePicId'
                                    ? 'hidden'
                                    : 'py-3 px-6 text-left rtl:text-right font-general rtl:font-almarai font-semibold text-12'
                                }
                                ${
                                  ['subscription'].includes(item?.value) &&
                                  'justify-center flex flex-row'
                                }
                                
                       `}
                              >
                                {item?.header
                                  ?.replace(/([A-Z])/g, ' $1')
                                  .toUpperCase()}
                              </th>
                            ))
                          ) : (
                            <th></th>
                          )
                        ) : (
                          <th></th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light overflow-hidden">
                      {!loading ? (
                        data && data?.length !== 0 ? (
                          data.map((value: any, index: any) => {
                            return (
                              <tr
                                onClick={() => {
                                  value?.deleted
                                    ? ''
                                    : directFunction
                                    ? directFunction(
                                        value?.id,
                                        value?.requestStatus
                                      )
                                    : '';
                                }}
                                key={index}
                                className={`border-b border-borderBlack h-16 ${
                                  value?.requestStatus === 'REJECTED'
                                    ? 'bg-red border-borderBlack'
                                    : ''
                                }
                                ${
                                  value?.requestStatus === 'REAPPLY'
                                    ? 'bg-lightBlue border-borderBlack'
                                    : ''
                                }
                                ${
                                  value?.blocked
                                    ? 'bg-borderBlack border-borderBlack'
                                    : ''
                                }`}
                              >
                                {header.map((item: any, index: number) => {
                                  return (
                                    !['id', 'profilePicId'].includes(
                                      item?.value
                                    ) &&
                                    (item?.value === 'fullName' ||
                                    item?.value === 'name' ? (
                                      <td
                                        key={item?.value}
                                        className={`py-4 px-6  `}
                                      >
                                        <div
                                          className={`flex  items-center ${
                                            category === 'coach' &&
                                            (!value['deleted']
                                              ? value['requestStatus'] ===
                                                'REJECTED'
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                              : 'cursor-not-allowed')
                                          } ${
                                            list.includes(item?.value)
                                              ? 'justify-center'
                                              : ''
                                          }`}
                                        >
                                          <div
                                            className={`text-gray font-normal text-general ${
                                              value[item?.value]
                                                ? ''
                                                : ' flex justify-center w-full'
                                            }`}
                                          >
                                            <div className="flex flex-row gap-2 w-fit justify-center">
                                              <div>
                                                <Image
                                                  id={value?.profilePicId}
                                                  bgImgUrl={
                                                    '/assets/avatar.svg'
                                                  }
                                                  bgClass={
                                                    '  h-8 w-7 rounded-full  flex justify-center items-center '
                                                  }
                                                  propClass={
                                                    'object-fill  w-8 rounded-full h-8 '
                                                  }
                                                />
                                              </div>
                                              <div
                                                className={`flex items-center ${
                                                  value?.requestStatus ===
                                                  'REJECTED'
                                                    ? '!text-white'
                                                    : 'text-textBlack'
                                                }  capitalize font-semibold`}
                                              >
                                                {value[item?.value]
                                                  ? value[item?.value]
                                                  : '-'}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      <td key={index} className={`py-4 px-6  `}>
                                        <div
                                          className={`flex items-center ${
                                            category === 'coach' &&
                                            (!value['deleted']
                                              ? value['requestStatus'] ===
                                                'REJECTED'
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                              : 'cursor-not-allowed')
                                          }
                                          ${
                                            leftAlign.includes(item)
                                              ? 'flex justify-start !w-fit'
                                              : ''
                                          }
                                           ${
                                             list.includes(item?.value)
                                               ? 'justify-center'
                                               : ''
                                           }
                                           
                                           `}
                                        >
                                          <div
                                            className={`${
                                              value?.requestStatus ===
                                              'REJECTED'
                                                ? '!text-white'
                                                : 'text-gray'
                                            } font-normal text-general ${
                                              ['amount'].includes(
                                                item?.value
                                              ) &&
                                              '!font-bold !text-black flex  '
                                            }
                                            ${
                                              ['PRO'].includes(
                                                value[item?.value]
                                              ) &&
                                              '!border !border-lightBlue rounded text-lightBlue w-12  flex justify-center'
                                            }
                                            ${
                                              ['PREMIER'].includes(
                                                value[item?.value]
                                              ) &&
                                              '!border !border-primary rounded text-primary w-[68px] flex justify-center'
                                            }
                                            ${
                                              ['BASE'].includes(
                                                value[item?.value]
                                              ) &&
                                              '!border !border-black rounded text-black w-12  flex justify-center'
                                            }
                                            ${
                                              value[item?.value]
                                                ? ''
                                                : item?.value !== 'amount' &&
                                                  'flex justify-center w-full'
                                            }`}
                                          >
                                            {['date'].includes(item?.value)
                                              ? moment(
                                                  new Date(value[item?.value])
                                                ).format('MMMM DD, YYYY')
                                              : ''}
                                            {['dob', 'createdAt'].includes(
                                              item?.value
                                            )
                                              ? moment(
                                                  new Date(value[item?.value])
                                                ).format('YYYY-MM-DD')
                                              : ''}
                                            {['amount'].includes(item?.value)
                                              ? `${t('SAR')}`
                                              : ''}
                                            {['invoiceNo'].includes(
                                              item?.value
                                            ) ? (
                                              value[item?.value] !== '0' ? (
                                                value[item?.value]
                                              ) : (
                                                <span className="px-1 w-full flex flex-grow justify-center">
                                                  -
                                                </span>
                                              )
                                            ) : (
                                              ''
                                            )}

                                            {![
                                              'coachingCategories',
                                              'date',
                                              'dob',
                                              'amount',
                                              'createdAt',
                                              'invoiceNo',
                                              'languages',
                                              'specialization',
                                            ]?.includes(item?.value)
                                              ? value[item?.value]
                                                ? value[item?.value]
                                                : '-'
                                              : ''}
                                            {item?.value === 'amount' ? (
                                              <span className="px-1">
                                                {value[item?.value]}
                                              </span>
                                            ) : (
                                              ''
                                            )}
                                            {(item?.value ===
                                              'coachingCategories' ||
                                              item?.value === 'languages' ||
                                              item?.value ===
                                                'specialization') &&
                                            value[item?.value]?.length >= 1
                                              ? value[item?.value]?.map(
                                                  (
                                                    argument: string,
                                                    index: number
                                                  ) => {
                                                    return (
                                                      <div
                                                        key={argument}
                                                        className="mr-0.5"
                                                      >
                                                        {argument}
                                                        {value[item?.value]
                                                          ?.length !==
                                                        index + 1
                                                          ? ','
                                                          : ''}
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : ''}
                                          </div>
                                        </div>
                                      </td>
                                    ))
                                  );
                                })}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="flex justify-center h-56 items-center text-light-black font-general rtl:font-almarai font-normal">
                              <NoData />
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr className="">
                          <td className="flex justify-center h-56 w-full items-center text-light-black font-general rtl:font-almarai font-normal">
                            <ClipLoader
                              color={'blue'}
                              loading={true}
                              size={30}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default Table;
