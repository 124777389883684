import { FC } from 'react';
import { IInputProps } from '../../interfaces/index';
import './index.css';

const Input: FC<IInputProps> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  type,
  placeholder,
  classname,
  errors,
  touched,
  checkedToggle,
  labelChildren,
  disabled,
  ...rest
}): JSX.Element => {
  return (
    <>
      <input
        disabled={disabled}
        checked={checkedToggle}
        id={id}
        name={name}
        value={value ? value : ''}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
        className={`${classname} `}
      />
      {labelChildren}
    </>
  );
};

export default Input;
