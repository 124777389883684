import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { assessmentInfoReducer } from './coachSlices/assessmentSlice';
import { coachHomeInfoReducer } from './coachSlices/coachHomeSlice';
import { myUploadInfoReducer } from './coachSlices/myUploadSlice';
import { packageInfoReducer } from './coachSlices/packageSlice';
import { clientProfileReducer } from './coachSlices/profile';
import { sessionInfoReducer } from './coachSlices/sessionSlice';
import clientListingReducer from './components/clients/slice/index';
import coachDrectoryreducer from './components/coach/slice/index';
import coachApproveSlice from './components/coachApproval/slice/index';
import coachProfilerSlice from './components/coachProfile/slices/index';
import { languageReducer } from './components/header/slice/index';
import alertSlice from './components/notification/slices/index';
import paymentSlice from './components/payment/slices';
import productsSlice from './components/products/slices';
import subscriptionReducer from './components/scubscription/slices/index';
import serviceReducer from './components/serviceView/slices/index';
import forgotPasswordSlice from './pages/forgotPassword/slice';
import authSlice from './pages/login/slices/authSlice';
import setNewPasswordReducer from './pages/setNewPasword/slice';

const reducer = combineReducers({
  auth: authSlice,
  forgotPassword: forgotPasswordSlice,
  language: languageReducer,
  setNewPassword: setNewPasswordReducer,
  coachDirectory: coachDrectoryreducer,
  clientDirectory: clientListingReducer,
  coachApprove: coachApproveSlice,
  alert: alertSlice,
  coachProfile: coachProfilerSlice,
  subscription: subscriptionReducer,
  payment: paymentSlice,
  products: productsSlice,
  packageInfo: packageInfoReducer,
  sessionInfo: sessionInfoReducer,
  clientProfile: clientProfileReducer,
  service: serviceReducer,
  myUploadInfo: myUploadInfoReducer,
  coachHomeInfo: coachHomeInfoReducer,
  assessmentInfo: assessmentInfoReducer,
});

const persistConfig = {
  key: 'root',
  whitelist: ['language'],
  storage,
};

const persistedReducer = persistReducer<RootReducer, AnyAction>(
  persistConfig,
  reducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type RootReducer = ReturnType<typeof reducer>;
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
