import { useTranslation } from 'react-i18next';

const RevenueCount = ({
  title,
  count,
  loading,
}: {
  title: string;
  count: number | null;
  loading: boolean;
}) => {
  const [t] = useTranslation();

  return (
    <div className="flex flex-row flex-grow py-6 pl-5 pr-10 gap-4 shadow-[0_10px_25px_0px_rgba(0,0,51,0.08)] rounded-lg ">
      <div className="min-w-[45px] h-full justify-center flex items-center">
        {!loading ? (
          <img
            className="h-full w-full"
            src="/assets/payment/totalAmountIcon.svg"
            alt=""
          />
        ) : (
          <div className=" animate-pulse w-[45px] h-[45px] bg-[#f1f5f9] rounded-full"></div>
        )}
      </div>

      <div className="flex flex-col gap-[10px] w-full ">
        <h1
          className={`font-general rtl:font-almarai text-13 font-normal text-gray inline-block ${
            loading && 'animate-pulse  bg-[#f1f5f9] max-w-[10vw] h-[18px]'
          }`}
        >
          {loading ? '' : t(`PAYMENT_COMPONENT.${title}`)}
        </h1>
        {!loading ? (
          <p className={`font-bold text-24 font-satoshi rtl:font-almarai `}>
            {/* {!revenueCountsData[0][value] && <span>$</span>}
          {!revenueCountsData[0][value] ? revenueCountsData[0][value] : 'NA'} */}
            {<span>{t('SAR')} </span>}
            {count}
          </p>
        ) : (
          <p
            className={`${
              loading && 'animate-pulse  bg-[#f1f5f9] max-w-[10vw] h-[30px]'
            }`}
          ></p>
        )}
      </div>
    </div>
  );
};

export default RevenueCount;
