import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import Image from '../imageDisplay/index';
import NoData from '../noDataComponent';

interface IPaymenValue {
  header: { label: string; value: string }[];
  body: { [key: string]: string }[];
  category?: string;
  loading: boolean;
}

const PaymentTable: FC<IPaymenValue> = ({
  header,
  body,
  category,
  loading,
}) => {
  const [t] = useTranslation();
  return (
    <div>
      <table className=" w-full h-full table-auto  ">
        <thead>
          <tr
            className={`bg-white text-textBlack text-sm leading-normal w-full  h-10`}
          >
            {!loading && header.length ? (
              header.map((item, index) => (
                <th key={index}>
                  <div
                    className={` rtl:font-almarai capitalize ${
                      category === 'settlement' &&
                      (['amount'].includes(item?.value)
                        ? 'flex justify-end '
                        : 'flex justify-start')
                    } 
                  ${
                    category === 'recent' &&
                    (['subscription', 'amount'].includes(item?.value)
                      ? 'flex justify-center '
                      : 'flex justify-start')
                  } 
             
                  `}
                  >
                    {t(`PAYMENT_COMPONENT.${item?.label}`)}
                  </div>{' '}
                </th>
              ))
            ) : loading ? (
              <th className="h-[30vh] flex items-center justify-center flex-grow w-full">
                <ClipLoader
                  color={'#00a5ec'}
                  loading={true}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </th>
            ) : (
              <div className="flex  justify-center items-center h-[20vh] w-full text-gray font-general rtl:font-almarai">
                <NoData />{' '}
              </div>
            )}
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light overflow-hidden">
          {body.length ? (
            body.map((item, index) => (
              <tr key={index} className="border-b border-borderBlack h-12">
                {header.map((value, indexValue) => (
                  <td key={indexValue}>
                    <div
                      className={` flex items-center  text-13 gap-2 ${
                        category === 'settlement' &&
                        ['amount'].includes(value?.value) &&
                        'flex justify-end  py-1 font-manrope text-12 text-red rtl:font-almarai'
                      } ${
                        value?.value === 'date' &&
                        category === 'settlement' &&
                        'text-gray font-general rtl:font-almarai'
                      }
                    ${
                      category === 'recent' &&
                      ['amount', 'subscription'].includes(value?.value) &&
                      'flex justify-center px-2 py-1'
                    }`}
                    >
                      {(value?.value === 'name' ||
                        value?.value === 'beneficiaryName') && (
                        <div className="w-fit">
                          <Image
                            id={item?.profilePicId}
                            bgImgUrl={'/assets/avatar.svg'}
                            bgClass={
                              '  h-8 w-7 rounded-full  flex justify-center items-center '
                            }
                            propClass={'object-fill  w-8 rounded-full h-8 '}
                          />
                        </div>
                      )}
                      <div
                        className={`${
                          (value?.value === 'name' ||
                            value?.value === 'beneficiaryName') &&
                          'font-semibold'
                        } ${
                          category === 'settlement' &&
                          ['amount'].includes(value?.value) &&
                          'border border-red rounded flex justify-start px-2 py-1'
                        }
                      ${
                        category === 'recent' &&
                        ['amount'].includes(value?.value) &&
                        'text-13 font-bold font-general rtl:font-almarai'
                      }
                     
                      ${
                        category === 'recent' && item[value?.value] === 'BASE'
                          ? ' capitalize border rounded px-2 py-1 w-[60px] flex justify-center'
                          : item[value?.value] === 'PRO'
                          ? ' capitalize border text-primary border-[#9EE0E6] rounded px-2 py-1 w-[60px] flex justify-center'
                          : item[value?.value] === 'PREMIER'
                          ? ' capitalize border text-primary border-primary rounded px-2 py-1 w-[60px] flex justify-center'
                          : ''
                      }
                      ${
                        category === 'recent' &&
                        ['email', 'date'].includes(value?.value) &&
                        'text-gray font-general rtl:font-almarai'
                      }
                       `}
                      >
                        <div>
                          <span
                            className={`${
                              !(value?.value === 'amount') && 'hidden'
                            }`}
                          >
                            {`${t('SAR')}`}{' '}
                          </span>

                          {value?.value === 'date'
                            ? moment(item[value?.value]).format('MMMM DD YYYY')
                            : item[value?.value]}
                        </div>
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
      {!body.length && !loading && (
        <div className="min-h-[30vh] w-full flex justify-center items-center text-gray font-general rtl:font-almarai">
          <NoData />{' '}
        </div>
      )}
    </div>
  );
};

export default PaymentTable;
