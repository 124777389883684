import { useLocation, useNavigate } from 'react-router-dom';
import { IChildren } from '../interfaces/index';

const PublicRoute = ({ children }: IChildren): any => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');

  return token ? history.back() : children;
};
export default PublicRoute;
