import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ServiceBody = ({
  title,
  body,
  length,
}: {
  title: string;
  body: string[];
  length: number;
}) => {
  const [showToggle, setShowToggle] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      <div className="font-bold text-22 font-satoshi rtl:font-almarai">
        {title}
      </div>
      <ul>
        {body?.length ? (
          body.map((value: string, index: number) => (
            <li
              key={value + index}
              className={`flex  gap-4 items-start p-2 ${
                index > length - 1 && showToggle && 'hidden'
              }`}
            >
              <div className="pt-1.5">
                <div className="bg-gray h-1.5 w-1.5 rounded-full "></div>
              </div>
              <div className="font-general rtl:font-almarai text-16 text-gray">
                {value}
              </div>
            </li>
          ))
        ) : (
          <div className="text-gray font-general rtl:font-almarai ">
            --{t('NA')}--
          </div>
        )}
      </ul>
      {body?.length > length && (
        <div
          onClick={() => setShowToggle(!showToggle)}
          className="flex flex-row gap-2 cursor-pointer"
        >
          <div className="font-semibold text-16 font-general rtl:font-almarai text-primary items-center">
            {showToggle ? t('SHOWMORE') : t('SHOWLESS')}
          </div>
          <img
            className={`${!showToggle && ' rotate-180'}`}
            src="/assets/products/downArrow.svg"
            alt=""
          />
        </div>
      )}
    </>
  );
};

export default ServiceBody;
