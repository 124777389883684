import coachDirectoryEndpoints from '../constants/apiEndPoints/coachDirectory';
import { apiHeader } from '../constants/apiHeader';
import { get } from './apiService';

export const getTableData = async <T>(value: T & { id?: string }) => {
  return value?.id
    ? get(
        coachDirectoryEndpoints.TABLEDATA + value.id,
        {},
        apiHeader.APPLICATION_JSON
      )
    : get(coachDirectoryEndpoints.TABLEDATA, value, apiHeader.APPLICATION_JSON);
};
