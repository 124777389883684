import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import coachDirectoryEndpoints from '../../../constants/apiEndPoints/coachDirectory';
import {
  fetchCoachApproveReject,
  fetchCoachProfileData,
} from '../../../services/coachApproval';
import { getCoachProfileData } from '../../../services/coachProfile';
import {
  fetchApproveWireTransfer,
  fetchCoachActiveSubscription,
  fetchDownloadReceipt,
  fetchRejectWireTransfer,
  fetchReportBlock,
  fetchSettleCoach,
  fetchSocialMediaLinks,
  fetchSubscriptionDetails,
  fetchUnblock,
  getFiledata,
  getTadeInfo,
  getTadeInfoBankingInfo,
} from '../../../services/fileTransfer';
import { alertActive } from '../../notification/slices';
interface ICoachProfile {
  profileDataLoading: boolean;
  profileDataSuccess: boolean;
  profileDataError: boolean;
  coachProfileData: { [key: string]: string | string[] } & ICoachProfileData &
    IEducationalResponses &
    IAccreditationResponses &
    IExperienceResponses;
  alertMessage: string;
  requestLoading: boolean;
  requestSuccess: boolean;
  requestError: boolean;
  requestPayload: '';
  fileDataLoading: boolean;
  fileDataSuccess: boolean;
  fileDataError: boolean;
  fileData: string;
  certificateLoading: boolean;
  certificateSuccess: boolean;
  certificateError: boolean;
  tradingInfoLoading: boolean;
  tradingInfoSuccess: boolean;
  tradingInfoError: boolean;
  tradingDetails: { [key: string]: string };
  bankingInfoLoading: boolean;
  bankingInfoSuccess: boolean;
  bankingInfoError: boolean;
  bankingDetails: any;
  socialMediaLinks: any;
  isSocialMedia: boolean;
  isSubscription: boolean;
  subscriptionData: any;
  isSubscriptionDetails: boolean;
  subscriptionDetails: any;
  isReportBlockLoading: boolean;
  isReportBlockError: boolean;
  isReportBlockSucess: boolean;
  reportBlockData: any;
  isUnblocked: boolean;
  unBlockData: any;
  isSettleCoach: boolean;
  isApproveWireTransfer: boolean;
  isReceiptDownload: boolean;
  isRejectWireTransfer: boolean;
}
interface ICoachProfileData {
  coachingCategories?: string[];
  specialization?: string[];

  experienceResponses?: { [key: string]: string[] }[];

  profilePicId?: string;
  bioDescription?: string;
}
export interface IAccreditationResponses {
  accreditationResponses?: {
    [key: string]: string;
  }[];
}
export interface IEducationalResponses {
  educationResponses?: {
    [key: string]: string;
  }[];
}
export interface IExperienceResponses {
  experienceResponses?: {
    [key: string]: string;
  }[];
}

const initialState: ICoachProfile = {
  profileDataLoading: false,
  profileDataSuccess: false,
  profileDataError: false,
  coachProfileData: {},
  alertMessage: '',
  requestLoading: false,
  requestSuccess: false,
  requestError: false,
  requestPayload: '',
  fileDataLoading: false,
  fileDataSuccess: false,
  fileDataError: false,
  fileData: '',
  certificateLoading: false,
  certificateSuccess: false,
  certificateError: false,
  tradingInfoLoading: false,
  tradingInfoSuccess: false,
  tradingInfoError: false,
  tradingDetails: {},
  bankingInfoLoading: false,
  bankingInfoSuccess: false,
  bankingInfoError: false,
  bankingDetails: {},
  socialMediaLinks: [],
  isSocialMedia: false,
  isSubscription: false,
  subscriptionData: [],
  isSubscriptionDetails: false,
  subscriptionDetails: [],
  isReportBlockLoading: false,
  isReportBlockError: false,
  isReportBlockSucess: false,
  reportBlockData: [],
  isUnblocked: false,
  unBlockData: [],
  isSettleCoach: false,
  isApproveWireTransfer: false,
  isRejectWireTransfer: false,
  isReceiptDownload: false,
};

export const coachProfileSlice = createSlice({
  name: 'coachProfile',
  initialState: initialState,
  reducers: {
    resetCoachProfile: (state) => {
      (state.profileDataLoading = false),
        (state.profileDataSuccess = false),
        (state.profileDataError = false),
        (state.coachProfileData = {}),
        (state.alertMessage = ''),
        (state.requestLoading = false),
        (state.requestSuccess = false),
        (state.requestError = false),
        (state.requestPayload = ''),
        (state.fileDataLoading = false),
        (state.fileDataSuccess = false),
        (state.fileDataError = false),
        (state.fileData = ''),
        (state.certificateLoading = false),
        (state.certificateSuccess = false),
        (state.certificateError = false),
        (state.tradingInfoLoading = false),
        (state.tradingInfoSuccess = false),
        (state.tradingInfoError = false),
        (state.tradingDetails = {}),
        (state.bankingInfoLoading = false),
        (state.bankingInfoSuccess = false),
        (state.bankingInfoError = false),
        (state.bankingDetails = {});
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(coachProfileLeaveComments.pending, (state) => {
        state.requestLoading = true;
      })
      .addCase(coachProfileLeaveComments.fulfilled, (state, action) => {
        state.alertMessage = action.payload?.message;
      })
      .addCase(coachProfileLeaveComments.rejected, (state) => {
        state.requestLoading = true;
      })
      .addCase(coachProfileReport.pending, (state) => {
        state.requestLoading = true;
        state.requestSuccess = false;
      })
      .addCase(coachProfileReport.fulfilled, (state, action) => {
        state.requestLoading = false;
        state.requestSuccess = true;
        state.requestPayload = action.payload?.message;
      })
      .addCase(coachProfileReport.rejected, (state) => {
        state.requestLoading = false;
        state.requestError = true;
        state.requestSuccess = false;
      })
      .addCase(getCoachProfileContent.pending, (state) => {
        state.fileData = '';
        state.requestLoading = true;
        state.fileDataSuccess = false;
        state.requestSuccess = false;
        state.fileData = '';
      })
      .addCase(getCoachProfileContent.fulfilled, (state, action) => {
        state.requestLoading = false;
        state.requestSuccess = true;
        state.coachProfileData = action.payload?.data[0];
        action.payload?.data[0]?.profilePicId ? '' : (state.fileData = '');
      })
      .addCase(getCoachProfileContent.rejected, (state) => {
        state.requestLoading = false;
        state.requestError = true;
        state.requestSuccess = false;
      })
      .addCase(getCoachProfileFiles.pending, (state) => {
        state.fileDataLoading = true;
        state.fileDataSuccess = false;
      })
      .addCase(getCoachProfileFiles.fulfilled, (state, action) => {
        state.fileDataLoading = false;
        state.fileDataSuccess = true;
        state.fileData = action.payload?.data[0]?.fileContent;
      })
      .addCase(getCoachProfileFiles.rejected, (state) => {
        state.profileDataLoading = false;
        state.fileDataError = true;
        state.fileDataSuccess = false;
      })
      .addCase(getCoachProfileCertificate.pending, (state) => {
        state.profileDataLoading = false;
        state.fileDataError = true;
        state.fileDataSuccess = false;
      })
      .addCase(getCoachProfileCertificate.fulfilled, (state) => {
        state.profileDataLoading = false;
        state.fileDataError = true;
        state.fileDataSuccess = false;
      })
      .addCase(getCoachProfileCertificate.rejected, (state) => {
        state.profileDataLoading = false;
        state.fileDataError = true;
        state.fileDataSuccess = false;
      })
      .addCase(getCoachProfileTradeInfo.pending, (state) => {
        state.tradingInfoLoading = true;
        state.tradingInfoError = false;
        state.tradingInfoSuccess = false;
      })
      .addCase(getCoachProfileTradeInfo.fulfilled, (state, action) => {
        state.tradingInfoLoading = false;
        state.tradingInfoSuccess = true;
        state.tradingDetails = action.payload?.data[0];
      })
      .addCase(getCoachProfileTradeInfo.rejected, (state) => {
        state.tradingInfoLoading = false;
        state.tradingInfoError = true;
        state.tradingInfoSuccess = false;
      })
      .addCase(getCoachProfileBankingInfo.pending, (state) => {
        state.bankingInfoLoading = true;
        state.bankingInfoSuccess = false;
        state.bankingInfoError = false;
      })
      .addCase(getCoachProfileBankingInfo.fulfilled, (state, action) => {
        state.bankingInfoLoading = false;
        state.bankingInfoSuccess = true;
        state.bankingDetails = action.payload?.data[0]?.content;
      })
      .addCase(getCoachProfileBankingInfo.rejected, (state) => {
        state.bankingInfoLoading = false;
        state.bankingInfoError = true;
        state.bankingInfoSuccess = false;
      })
      .addCase(getSocialMediaLinks.pending, (state) => {
        state.isSocialMedia = true;
      })
      .addCase(getSocialMediaLinks.fulfilled, (state, action) => {
        state.isSocialMedia = false;
        state.socialMediaLinks = action.payload?.data;
      })
      .addCase(getSocialMediaLinks.rejected, (state) => {
        state.isSocialMedia = false;
      })
      .addCase(getActiveSubscription.pending, (state) => {
        state.isSubscription = true;
      })
      .addCase(getActiveSubscription.fulfilled, (state, action) => {
        state.isSubscription = false;
        state.subscriptionData = action.payload?.data[0];
      })
      .addCase(getActiveSubscription.rejected, (state) => {
        state.isSubscription = false;
      })
      .addCase(getSubscriptionDetails.pending, (state) => {
        state.isSubscriptionDetails = true;
      })
      .addCase(getSubscriptionDetails.fulfilled, (state, action) => {
        state.isSubscriptionDetails = false;
        state.subscriptionDetails = action.payload?.data[0];
      })
      .addCase(getSubscriptionDetails.rejected, (state) => {
        state.isSubscriptionDetails = false;
      })
      .addCase(getReportBlockDetails.pending, (state) => {
        state.isReportBlockLoading = true;
        state.isReportBlockError = false;
        state.isReportBlockSucess = false;
      })
      .addCase(getReportBlockDetails.fulfilled, (state, action) => {
        state.isReportBlockLoading = false;
        state.reportBlockData = action.payload?.message;
        state.isReportBlockSucess = true;
      })
      .addCase(getReportBlockDetails.rejected, (state) => {
        state.isReportBlockLoading = false;

        state.isReportBlockError = true;
      })
      .addCase(unblockCoach.pending, (state) => {
        state.isUnblocked = true;
      })
      .addCase(unblockCoach.fulfilled, (state) => {
        state.isUnblocked = false;
      })
      .addCase(unblockCoach.rejected, (state) => {
        state.isUnblocked = false;
      })
      .addCase(settleCoach.pending, (state) => {
        state.isSettleCoach = true;
      })
      .addCase(settleCoach.fulfilled, (state) => {
        state.isSettleCoach = false;
      })
      .addCase(settleCoach.rejected, (state) => {
        state.isSettleCoach = false;
      })
      .addCase(approveWireTransfer.pending, (state) => {
        state.isApproveWireTransfer = true;
      })
      .addCase(approveWireTransfer.fulfilled, (state) => {
        state.isApproveWireTransfer = false;
      })
      .addCase(approveWireTransfer.rejected, (state) => {
        state.isApproveWireTransfer = false;
      })
      .addCase(downloadReceipt.pending, (state) => {
        state.isReceiptDownload = true;
      })
      .addCase(downloadReceipt.fulfilled, (state) => {
        state.isReceiptDownload = false;
      })
      .addCase(downloadReceipt.rejected, (state) => {
        state.isReceiptDownload = false;
      })
      .addCase(rejectWireTransfer.pending, (state) => {
        state.isRejectWireTransfer = true;
      })
      .addCase(rejectWireTransfer.fulfilled, (state) => {
        state.isRejectWireTransfer = false;
      })
      .addCase(rejectWireTransfer.rejected, (state) => {
        state.isRejectWireTransfer = false;
      });
  },
});

export const coachProfileLeaveComments = createAsyncThunk(
  'coachProfile/coachProfileComments',
  async (coachProfilePayload: { [key: string]: string }, thunkAPI) => {
    try {
      const { data } = await fetchCoachProfileData(coachProfilePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const coachProfileReport = createAsyncThunk(
  'coachProfile/report',
  async (coachProfilePayload: { [key: string]: string }, thunkAPI) => {
    try {
      const { data } = await fetchCoachApproveReject(coachProfilePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachProfileContent = createAsyncThunk(
  'coachProfile/getCoachProfileData',
  async (coachProfilePayload: { [key: string]: string }, thunkAPI) => {
    try {
      const { data } = await getCoachProfileData(coachProfilePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachProfileTradeInfo = createAsyncThunk(
  'coachProfile/CoachProfileTradeInfo ',
  async (coachProfilePayload: number, thunkAPI) => {
    try {
      const { data } = await getTadeInfo(
        coachDirectoryEndpoints.TRADEINFO,
        coachProfilePayload
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachProfileBankingInfo = createAsyncThunk(
  'coachProfile/CoachProfileBankingInfo ',
  async (coachProfilePayload: { coachId: number }, thunkAPI) => {
    try {
      const { data } = await getTadeInfoBankingInfo(
        coachDirectoryEndpoints.BANKINGINFO,
        coachProfilePayload
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachProfileFiles = createAsyncThunk(
  'coachProfile/coachProfileFileData',
  async (coachProfilePayload: string, thunkAPI) => {
    try {
      const { data } = await getFiledata(coachProfilePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachProfileCertificate = createAsyncThunk(
  'coachProfile/CoachProfileCertificate',
  async (coachProfilePayload: string, thunkAPI) => {
    try {
      const certificateContent = await getFiledata(coachProfilePayload);
      const link = document.createElement('a');
      link.href = `data:;base64,${certificateContent.data?.data[0]?.fileContent}`;
      link.setAttribute(
        'download',
        `${certificateContent.data?.data[0]?.originalFileName}`
      );
      document.body.appendChild(link);
      link.click();
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getSocialMediaLinks = createAsyncThunk(
  'coachProfile/socialMediaLinks',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await fetchSocialMediaLinks(id);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getActiveSubscription = createAsyncThunk(
  'coachProfile/activeSubscription',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await fetchCoachActiveSubscription(id);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getSubscriptionDetails = createAsyncThunk(
  'coachProfile/subscription',
  async (
    id: number,

    thunkAPI
  ) => {
    try {
      const { data } = await fetchSubscriptionDetails(id);

      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getReportBlockDetails = createAsyncThunk(
  'coachProfile/reportBlock',
  async (
    params: { [key: string]: string },

    thunkAPI
  ) => {
    try {
      const { data } = await fetchReportBlock(params);
      thunkAPI.dispatch(getCoachProfileContent({ id: params.coachId }));
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: data?.message,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const unblockCoach = createAsyncThunk(
  'coachProfile/unblock',
  async (
    id: number,

    thunkAPI
  ) => {
    try {
      const { data } = await fetchUnblock(id);
      console;
      thunkAPI.dispatch(getCoachProfileContent({ id: `${id}` }));
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: data?.message,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const settleCoach = createAsyncThunk(
  'coachProfile/settleCoach',
  async (
    id: number,

    thunkAPI
  ) => {
    try {
      const { data } = await fetchSettleCoach(id);
      console;
      thunkAPI.dispatch(getCoachProfileContent({ id: `${id}` }));
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: data?.message,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveWireTransfer = createAsyncThunk(
  'wireTransfer/approveWireTransfer',
  async (
    value: { [key: string]: any },

    thunkAPI
  ) => {
    try {
      const { data } = await fetchApproveWireTransfer(value);
      // thunkAPI.dispatch(getCoachProfileContent({ id: `${id}` }));
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: data?.message,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const rejectWireTransfer = createAsyncThunk(
  'wireTransfer/rejectWireTransfer',
  async (
    value: { [key: string]: any },

    thunkAPI
  ) => {
    try {
      const { data } = await fetchRejectWireTransfer(value);
      // thunkAPI.dispatch(getCoachProfileContent({ id: `${id}` }));
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: data?.message,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const downloadReceipt = createAsyncThunk(
  'wireTransfer/downloadWireTransferReceipt',
  async (id: number, thunkAPI) => {
    try {
      const certificateContent = await fetchDownloadReceipt(id);
      // const value = data.arraybuffer();
      // const url = window.URL.createObjectURL(new Blob([data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', data?.data[0]?.originalFileName); //or any other extension
      // document.body.appendChild(link);
      // link.click();
      const link = document.createElement('a');
      link.href = `data:;base64,${certificateContent.data?.data[0]?.fileContent}`;
      link.setAttribute(
        'download',
        `${certificateContent.data?.data[0]?.originalFileName}`
      );
      document.body.appendChild(link);
      link.click();
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: certificateContent.data?.data[0]?.message,
        })
      );
      return certificateContent.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const { resetCoachProfile } = coachProfileSlice.actions;

export default coachProfileSlice.reducer;
