enum dashboardConstants {
  CLIENTS = 'clients',
  COACH = 'coach',
  SUBSCRIPTION = 'subscription',
  PAYMENT = 'payment',
  PRODUCTS = 'products',
  REPORTS = 'reports',
  OVERVEIW = 'overview',
  COACHAPPROVE = 'coach/coachApproval',
  COACHPROFILE = 'coach/coachProfile',
  REVIEW = 'review',
  SERVICE_VIEW = 'products/service-view',
  SERVICE_PROFILE_VIEW = 'products/service-view/profile',
  COACH_SESSION = 'coach-session',
  COACH_ASSESSMENT = 'coach-assessment',
  COACH_PACKAGE = 'coach-package',
}
export default dashboardConstants;
