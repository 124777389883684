import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
interface ISortList {
  dropdownList: {
    title: string;
    value: string;
    dir: { asc: string; desc: string };
  }[];
}

const Sort: FC<ISortList> = ({ dropdownList }): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortToggle, setSortToggle] = useState(false);

  const sortToggleFunction = () => {
    setSortToggle(!sortToggle);
  };
  const sortDirection = searchParams.get('sort')?.split(',');

  const sortTable = (item: string) => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    item! ? (params['sort'] = item) : '';
    params['page'] = '0';
    setSearchParams(params);
  };

  return (
    <div>
      <div
        onClick={() => setSortToggle(false)}
        className={`${
          sortToggle
            ? '  fixed inset-0 bg-transperant bg-opacity-75 transition-opacity z-20 '
            : ''
        }`}
      ></div>
      <div className="z-25">
        <button
          onClick={() => sortToggleFunction()}
          className=" flex flex-row gap-x-2 border border-borderBlack rounded items-center px-4 py-2.5 h-9 text-12 text-secondary font-general rtl:font-almarai font-normal"
        >
          <img className="" src="/assets/sort.svg" alt="" />
          <span>{t('COACH_DIRECTORY.Sort')}</span>
        </button>
        <div className="dropdown relative">
          <ul
            className={`
            ${sortToggle ? '' : 'hidden'} 
            dropdown-menu w-[170px] absolute  bg-white text-base z-30 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none`}
            aria-labelledby="dropdownMenuButton3"
          >
            {dropdownList.map((value, index) => (
              <li
                className="flex"
                key={index}
                onClick={() => sortToggleFunction()}
              >
                {
                  <div
                    onClick={() =>
                      sortTable(
                        `${value.value},${
                          sortDirection && sortDirection[0] === value.value
                            ? sortDirection && sortDirection[1] === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'asc'
                        }`
                      )
                    }
                    className={`flex justify-between font-normal  font-general rtl:font-almarai text-16 w-full ${
                      sortDirection && sortDirection.includes(value.value)
                        ? 'flex flex-row  cursor-pointer text-sm py-2 px-4 font-normal  bg-primary text-white hover:bg-gray-100 '
                        : 'flex flex-row  cursor-pointer text-sm py-2 px-4 font-normal bg-transparent text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    <span>{t(value.title)} </span>
                    <div
                      className={`flex items-center ${
                        sortDirection && sortDirection[0] === value.value
                          ? ''
                          : 'hidden'
                      }`}
                    >
                      <img
                        className={` ${
                          sortDirection && sortDirection[1] === 'asc'
                            ? 'rotate-180'
                            : ''
                        } `}
                        src={`${
                          sortDirection &&
                          sortDirection[0] === value.value &&
                          '/assets/descending.svg'
                        }`}
                        alt=""
                      />
                    </div>
                  </div>
                }
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sort;
