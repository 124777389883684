import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  packageInfo,
  setCoachingPricePackageFormData,
} from '../../../coachSlices/packageSlice';
import { listCurrencies, sessionInfo } from '../../../coachSlices/sessionSlice';
import { Image } from '../../../components/coachComponents/Image/Image';
import { myCoachUploadsInterface } from '../../../interfaces/coachInterfaces/myUploadInterface';
import { listInterface } from '../../../interfaces/coachInterfaces/sessionInterface';
import { useAppSelector } from '../../../_helpers';
import { coachPriceValidationSchema } from '../../../_helpers/coachHelpers';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../selectDropdown';

interface IFaqCard {
  priceFormRef: any;
}

const CoachingPackagePrice: FC<IFaqCard> = ({ priceFormRef }): JSX.Element => {
  const [t] = useTranslation('');
  const dispatch = useDispatch<any>();
  const { coachingPricePackageFormValue } = useSelector(packageInfo);
  const { currency } = useSelector(sessionInfo);
  const { assessmentSession } = useSelector(packageInfo);
  const { lang } = useAppSelector((state) => state.language);
  const currencyListEn: { label: string; value: string }[] = [];
  const currencyListAr: { label: string; value: string }[] = [];
  const [priceArray, setPriceArray] = useState(assessmentSession);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  useEffect(() => {
    let totalValue = 0;
    assessmentSession.map((item: myCoachUploadsInterface) => {
      totalValue = totalValue + (item?.price as number);
    });
    setTotalPrice(totalValue);
  }, [assessmentSession]);

  currency?.map((item: listInterface) => {
    currencyListEn.push({
      label: item.nameInEnglish as string,
      value: item.nameInEnglish,
    });
    currencyListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  useEffect(() => {
    dispatch(listCurrencies(''));
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFormSubmit = () => {};
  const getPercentageValue = (value: string, percent: number) => {
    const result = (parseFloat(value) / 100) * percent;
    return result;
  };
  const onInputChange = (value: string, index: number) => {
    const dataArray = priceArray.map(
      (item: myCoachUploadsInterface, i: number) => {
        return {
          ...item,
          price: i === index ? value : item.price,
        };
      }
    );
    setPriceArray(dataArray as myCoachUploadsInterface[]);
    let totalValue = 0;

    dataArray.map((item: any) => {
      totalValue = totalValue + (parseInt(item?.price) as number);
    });

    setTotalPrice(totalValue);
  };
  const onTotalPriceChange = (total: number) => {
    setTotalPrice(total);
    const value = total / assessmentSession.length;

    const dataArray = assessmentSession.map((item: myCoachUploadsInterface) => {
      return {
        ...item,
        price: value,
      };
    });
    setPriceArray(dataArray);
  };
  const currencyValue = [{ label: 'SR', vslue: 'SR' }];

  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-almarai font-bold text-[34px]">
        {t('COACH_SESSION.PRICING')}
      </div>
      <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_PACKAGE.PACKAGE_PRICE_TXT')}
      </div>
      <Formik
        innerRef={priceFormRef}
        initialValues={coachingPricePackageFormValue}
        validationSchema={coachPriceValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          dispatch(setCoachingPricePackageFormData(values));
          return (
            <>
              {priceArray?.length ? (
                <>
                  <div className="w-full font-satoshi rtl:font-almarai mb-4 font-bold mt-9 text-xl">
                    {t('COACH_PACKAGE.PACKAGE_PRICE')}
                  </div>
                  <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] ">
                    {t('COACH_PACKAGE.IF_ANY_TXT')}
                  </div>

                  <div className="w-full border-b-2 flex">
                    <div className="w-[30%]  p-5 rtl:font-almarai">
                      {' '}
                      {t('COACH_PACKAGE.ITEM')}
                    </div>
                    <div className="w-[50%]  p-5 rtl:font-almarai">
                      {' '}
                      {t('COACH_PACKAGE.DURATION')}
                    </div>
                    <div className="w-[20%]  p-5 rtl:font-almarai">
                      {t('COACH_PACKAGE.PRICE')}
                    </div>
                  </div>
                  {priceArray.map((item, index) => (
                    <div
                      key={`image${index}`}
                      className="w-full border-b-[1px] flex"
                    >
                      <div className="w-[30%]  p-5 flex items-center">
                        {' '}
                        <Image
                          id={
                            (item?.imageId as number) || (item?.imgId as number)
                          }
                          bgImgUrl={'/assets/default-img-icon.svg'}
                          bgClass={
                            ' bg-[#F6F8F5] h-[30px] w-[30px] rounded-lg ltr:mr-[5px] rtl:ml-[5px] flex justify-center items-center'
                          }
                          propClass={
                            ' h-[30px] w-[30px] rounded-lg ltr:mr-[5px] rtl:ml-[5px]'
                          }
                        />
                        {item?.title}
                      </div>
                      <div className="w-[50%]  p-5 flex items-center text-[#687E8E]">
                        {item?.duration}
                      </div>
                      <div className="w-[20%]  p-5 flex items-center font-bold">
                        SAR&nbsp;
                        <input
                          type="number"
                          className="w-[50px]"
                          value={totalPrice === 0 ? 0 : item?.price?.toFixed(2)}
                          onChange={(e) => {
                            onInputChange(e.target.value, index);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="w-full border-b-[1px] flex justify-between">
                    <div className="w-[80%]  p-5 rtl:font-almarai">
                      {t('COACH_PACKAGE.TOTAL')}
                    </div>
                    <div className="w-[20%] px-5  items-center  text-2xl font-bold font-satoshi rtl:font-almarai text-primary flex justify-start">
                      SAR&nbsp;
                      <input
                        onChange={(e) => {
                          onTotalPriceChange(parseInt(e.target.value));
                        }}
                        type="number"
                        className="w-[70px]  text-2xl font-bold font-satoshi rtl:font-almarai text-primary"
                        value={totalPrice?.toFixed(2)}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <div className="w-full font-satoshi rtl:font-almarai mb-4 font-bold mt-3 text-xl">
                {t('COACH_PACKAGE.NEW_PRICE')}
                <span className="mx-1 font-general rtl:font-almarai font-thin tex-base text-[#687E8E]">
                  ({t('COACH_PACKAGE.IF_ANY')})
                </span>
              </div>
              <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] ">
                {t('COACH_PACKAGE.NEW_PRICE_TXT')}
              </div>

              <div className="w-full flex gap-3 flex-row">
                <div className="price-input-container pt-[18px]">
                  <SelectDropdown
                    id="currency"
                    isMulti={false}
                    isCreatable={false}
                    isSearchable={false}
                    options={lang === 'en' ? currencyValue : currencyValue}
                    optional={1}
                    dropDown={true}
                    name="currency"
                    placeholder={t('COACH_PACKAGE.CURRENCY')}
                    onBlur={handleBlur}
                    isValue={'SAR'}
                    onChange={(opt: any) => {
                      setFieldValue('days', opt.value);
                    }}
                    className="my-3 "
                    defaultValue={{
                      value: lang === 'en' ? 'SAR' : 'ريال سعودي',
                      label: lang === 'en' ? 'SAR' : 'ريال سعودي',
                    }}
                    controlShouldRenderValue={true}
                  />
                </div>
                <div className="  price-input-container pt-[11px]">
                  <Input
                    className="!h-[40px]"
                    id="FormTitle"
                    type="number"
                    name="price"
                    placeholder={t('COACH_PACKAGE.AMOUNT')}
                    onBlur={handleBlur}
                    error={touched.price && 1}
                    onChange={(e: any) => {
                      setFieldValue('price', e.target.value);
                    }}
                    value={values.price}
                    errormessage={errors.price}
                  />
                </div>
              </div>
              {/* {values.price && (
                <>
                  <div className="w-full text-sm font-bold font-satoshi rtl:font-almarai text-[#687E8E]">
                    {t('COACH_PACKAGE.KUN_CHARGE')} &nbsp;
                    <span className="text-sm font-bold font-satoshi rtl:font-almarai text-[#000000]">
                      4% ({values.price}-{getPercentageValue(values.price, 4)})
                    </span>
                  </div>
                  <div className="w-full pt-3 text-sm font-bold font-satoshi rtl:font-almarai text-[#687E8E]">
                    {t('COACH_PACKAGE.YOU_WILL_GET')}&nbsp;
                    <span className="text-2xl font-bold font-satoshi rtl:font-almarai text-primary">
                      SAR&nbsp;
                      {parseFloat(values.price) -
                        getPercentageValue(values.price, 4)}
                    </span>
                  </div>
                </>
              )} */}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default CoachingPackagePrice;
