import { clientDirectoryEndpoints } from '../constants';
import { apiHeader } from '../constants/apiHeader';
import { get, post } from './apiService';

// export const getClientTableData = async <T>(value: T) => {
//   return post(clientDirectoryEndpoints.TABLEDATA, value);
// };
export const getClientTableData = async <T>(data: T) => {
  return get(
    clientDirectoryEndpoints.TABLEDATA,
    data,
    apiHeader.APPLICATION_JSON
  );
};
