import { FieldArray, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  sessionInfo,
  setCoachingHoursFormData,
} from '../../../coachSlices/sessionSlice';
import {
  listInterface,
  tagsInterface,
} from '../../../interfaces/coachInterfaces/assessmentInterface';
import { useAppSelector } from '../../../_helpers';
import { coachIntendValidationSchema } from '../../../_helpers/coachHelpers';
import {
  checkTimeGap,
  getTimeGreater,
  timeArrayGenerator,
  timeArrayGenerator24Format,
} from '../../../_helpers/coachHelpers/utility';
import SelectDropdown from '../selectDropdown';

interface IFaqCard {
  hoursFormRef: any;
}

const CoachingHours: FC<IFaqCard> = ({ hoursFormRef }): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch<any>();
  const { lang } = useAppSelector((state) => state.language);

  const [alternativeState, setAlternativeState] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFormSubmit = () => {};
  const { coachingHoursFormValue, timing, sessionFormValue } =
    useSelector(sessionInfo);
  const timingEn: { label: string; value: string }[] = [];
  const timingAr: { label: string; value: string }[] = [];

  timing?.map((item: listInterface) => {
    timingEn.push({
      label: item?.nameInEnglish,
      value: item?.nameInEnglish,
    });
    timingAr.push({
      label: item?.nameInArabic,
      value: item?.nameInEnglish,
    });
  });
  const localTime = [
    {
      label: 'AM',
      value: 'AM',
    },
    {
      label: 'PM',
      value: 'PM',
    },
  ];
  const weekDays = [
    {
      label: t('COACH_SESSION.SUNDAY'),
      value: 'SUNDAY',
    },
    {
      label: t('COACH_SESSION.MONDAY'),
      value: 'MONDAY',
    },
    {
      label: t('COACH_SESSION.TUESDAY'),
      value: 'TUESDAY',
    },
    {
      label: t('COACH_SESSION.WEDNESDAY'),
      value: 'WEDNESDAY',
    },
    {
      label: t('COACH_SESSION.THURSDAY'),
      value: 'THURSDAY',
    },
    {
      label: t('COACH_SESSION.FRIDAY'),
      value: 'FRIDAY',
    },
    {
      label: t('COACH_SESSION.SATURDAY'),
      value: 'SATURDAY',
    },
  ];
  const [checkedState, setCheckedState] = useState(
    new Array(weekDays.length).fill(false)
  );
  const handleOnChange = (
    position: number,
    setFieldValue: any,
    value: string
  ) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    if (coachingHoursFormValue.coachingHourRequests.length > 0) {
      if (coachingHoursFormValue.coachingHourRequests[position].day === '') {
        setFieldValue(`coachingHourRequests.${position}.day`, value);
      } else {
        setFieldValue(`coachingHourRequests.${position}.day`, '');
      }
    }

    setCheckedState(updatedCheckedState);
  };
  useEffect(() => {
    if (coachingHoursFormValue.coachingHourRequests.length > 0) {
      const updatedCheckedState: any = [];

      coachingHoursFormValue.coachingHourRequests.map(
        (item: any, index: number) => {
          updatedCheckedState.push(
            item.day === null || item.day === '' ? false : true
          );
        }
      );
      setCheckedState(updatedCheckedState);
    }
  }, [coachingHoursFormValue]);
  const onAlternateChange = (setFieldVlaue: any, name: string) => {
    setFieldVlaue(name, !alternativeState);
    setAlternativeState(!alternativeState);
  };

  const getTimingLabel = (name: string) => {
    if (lang === 'en') {
      const item: tagsInterface[] = timingEn.filter(
        (item: any) => item.value === name
      );
      return item[0]?.label;
    }
    if (lang === 'ar') {
      const item: tagsInterface[] = timingAr.filter(
        (item: any) => item.value === name
      );
      return item[0]?.label;
    }
  };
  const [gap, setGap] = useState(30);
  useEffect(() => {
    setGap(sessionFormValue?.duration);
  }, [sessionFormValue]);

  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-almarai font-bold text-[34px]">
        {t('COACH_SESSION.COACHING_HOURS')}
      </div>
      <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_SESSION.COACHING_HOURS_TXT')}
      </div>
      <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_SESSION.SESSION_TIMING')}
      </div>

      <Formik
        innerRef={hoursFormRef}
        initialValues={coachingHoursFormValue}
        validationSchema={coachIntendValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          dispatch(setCoachingHoursFormData(values));

          return (
            <>
              {!values.alternative && (
                <div className=" coaching-hr-container">
                  <div className=" coaching-hr-container-day">
                    <SelectDropdown
                      isDisabled={true}
                      id="days"
                      isMulti={false}
                      isCreatable={false}
                      isSearchable={false}
                      // options={weekDaysList()}
                      options={lang === 'en' ? timingEn : timingAr}
                      optional={1}
                      dropDown={true}
                      name="days"
                      placeholder={t('COACH_SESSION.DAYS')}
                      onBlur={handleBlur}
                      isValue={values.days}
                      onChange={(opt: any) => {
                        setFieldValue('days', opt.value);
                      }}
                      className="my-3"
                      defaultValue={{
                        label: getTimingLabel(values.days),
                        value: values.days,
                      }}
                      controlShouldRenderValue={true}
                    />
                  </div>
                  <div className="w-full flex gap-3">
                    <div className="coaching-hr-container-hour">
                      <SelectDropdown
                        isDisabled={true}
                        id="start"
                        isMulti={false}
                        isCreatable={false}
                        isSearchable={false}
                        options={timeArrayGenerator24Format(24)}
                        optional={1}
                        dropDown={true}
                        name="start"
                        placeholder={t('COACH_SESSION.START_TIME')}
                        onBlur={handleBlur}
                        onChange={(opt: any) => {
                          setFieldValue('start', opt.value);
                        }}
                        isValue={values.start}
                        className="my-3"
                        defaultValue={{
                          value: values.start,
                          label: values.start,
                        }}
                        controlShouldRenderValue={true}
                      />
                    </div>
                    <div className="coaching-hr-container-hour">
                      <SelectDropdown
                        isDisabled={true}
                        id="end"
                        isMulti={false}
                        isCreatable={false}
                        isSearchable={false}
                        options={timeArrayGenerator24Format(24)}
                        optional={1}
                        dropDown={true}
                        name="end"
                        placeholder={t('COACH_SESSION.END_TIME')}
                        onBlur={handleBlur}
                        onChange={(opt: any) => {
                          setFieldValue('end', opt.value);
                        }}
                        isValue={values.end}
                        className="my-3"
                        defaultValue={{
                          value: values.end,
                          label: values.end,
                        }}
                        controlShouldRenderValue={true}
                        error={!getTimeGreater(values.start, values.end) && 1}
                        errormessage={' '}
                      />
                      {!getTimeGreater(values.start, values.end) ? (
                        <p className="text-red rtl:font-almarai font-normal text-12 pt-1.5 ">
                          {t('HOUR_VALIDATION')}
                        </p>
                      ) : (
                        !checkTimeGap(values.start, values.end, gap) && (
                          <p className="text-red rtl:font-almarai font-normal text-12 pt-1.5 ">
                            {t('HOUR_GAP_VALIDATION')} hello
                          </p>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="w-full font-general rtl:font-almarai font-normal text-base mt-8 text-[#687E8E] flex items-center gap-3">
                <label className="switch">
                  <input
                    disabled={true}
                    name="alternative"
                    type="checkbox"
                    onChange={() =>
                      onAlternateChange(setFieldValue, 'alternative')
                    }
                    checked={values.alternative}
                  />
                  <span className="slider round"></span>
                </label>
                {t('COACH_SESSION.ALTERNATIVE_DAYS')}
              </div>
              {values.alternative && (
                <FieldArray
                  name="coachingHourRequests"
                  render={(arrayHelpers) => {
                    return (
                      <div className="flex flex-col gap-8 mt-8">
                        {weekDays.map(
                          (
                            item: { label: string; value: string },
                            i: number
                          ) => (
                            <div
                              key={`coachingHourRequests.${i}.day`}
                              className="w-full flex flex-row items-center  "
                            >
                              <>
                                <input
                                  disabled={true}
                                  id={`coachingHourRequests.${i}.day`}
                                  type="checkbox"
                                  name={`coachingHourRequests.${i}.day`}
                                  onChange={() =>
                                    handleOnChange(i, setFieldValue, item.value)
                                  }
                                  value={item.value}
                                  checked={checkedState[i]}
                                  defaultChecked={true}
                                />
                                <span className="sm:text-[16px] text-[14px] font-general rtl:font-almarai font-normal text-[#687E8E] text-base mx-3 w-[25%]">
                                  {item.label}
                                </span>
                              </>
                              <div className="hour-input-container">
                                <div className="hour-input-container-box">
                                  <SelectDropdown
                                    isDisabled={true}
                                    id={`coachingHourRequests.${i}.from`}
                                    isMulti={false}
                                    isCreatable={false}
                                    options={timeArrayGenerator()}
                                    optional={1}
                                    dropDown={true}
                                    name={`coachingHourRequests.${i}.from`}
                                    placeholder={t('COACH_SESSION.FROM')}
                                    onBlur={handleBlur}
                                    isValue={
                                      values.coachingHourRequests[i]?.from
                                    }
                                    onChange={(opt: any) => {
                                      setFieldValue(
                                        `coachingHourRequests.${i}.from`,
                                        opt.value
                                      );
                                    }}
                                    className="mb-3"
                                    defaultValue={{
                                      value:
                                        values.coachingHourRequests[i]?.from,
                                      label:
                                        values.coachingHourRequests[i]?.from,
                                    }}
                                    controlShouldRenderValue={true}
                                  />
                                </div>
                                <div className="hour-input-container-AM">
                                  <SelectDropdown
                                    isDisabled={true}
                                    id={`coachingHourRequests.${i}.localFromTime`}
                                    isMulti={false}
                                    isCreatable={false}
                                    options={localTime}
                                    isSearchable={false}
                                    optional={1}
                                    dropDown={true}
                                    name={`coachingHourRequests.${i}.localFromTime`}
                                    placeholder={''}
                                    isValue={
                                      values.coachingHourRequests[i]
                                        ?.localFromTime
                                    }
                                    onBlur={handleBlur}
                                    onChange={(opt: any) => {
                                      setFieldValue(
                                        `coachingHourRequests.${i}.localFromTime`,
                                        opt.value
                                      );
                                    }}
                                    className="mb-3"
                                    defaultValue={{
                                      value:
                                        values.coachingHourRequests[i]
                                          ?.localFromTime,
                                      label:
                                        values.coachingHourRequests[i]
                                          ?.localFromTime,
                                    }}
                                    controlShouldRenderValue={true}
                                  />
                                </div>
                                <div className="hour-input-container-box">
                                  <SelectDropdown
                                    isDisabled={true}
                                    id={`coachingHourRequests.${i}.end`}
                                    isMulti={false}
                                    isCreatable={false}
                                    options={timeArrayGenerator()}
                                    optional={1}
                                    dropDown={true}
                                    name={`coachingHourRequests.${i}.end`}
                                    placeholder={t('COACH_SESSION.TO')}
                                    onBlur={handleBlur}
                                    isValue={
                                      values.coachingHourRequests[i]?.end
                                    }
                                    onChange={(opt: any) => {
                                      setFieldValue(
                                        `coachingHourRequests.${i}.end`,
                                        opt.value
                                      );
                                    }}
                                    className="mb-3"
                                    defaultValue={{
                                      value:
                                        values.coachingHourRequests[i]?.end,
                                      label:
                                        values.coachingHourRequests[i]?.end,
                                    }}
                                    controlShouldRenderValue={true}
                                    // error={
                                    //   !get12FormatTimeGreater(
                                    //     `${values.coachingHourRequests[i]?.from} ${values.coachingHourRequests[i]?.localFromTime}`,
                                    //     `${values.coachingHourRequests[i]?.end} ${values.coachingHourRequests[i]?.locaEndTime}`
                                    //   ) && 1
                                    // }
                                    // errormessage={' '}
                                  />
                                  {/* {!get12FormatTimeGreater(
                                    `${values.coachingHourRequests[i]?.from} ${values.coachingHourRequests[i]?.localFromTime}`,
                                    `${values.coachingHourRequests[i]?.end} ${values.coachingHourRequests[i]?.locaEndTime}`
                                  ) ? (
                                    <p className="text-red rtl:font-almarai font-normal text-12 pt-1.5 ">
                                      {t('HOUR_VALIDATION')}
                                    </p>
                                  ) : (
                                    !checkTimeGap12Format(
                                      `${values.coachingHourRequests[i]?.from} ${values.coachingHourRequests[i]?.localFromTime}`,
                                      `${values.coachingHourRequests[i]?.end} ${values.coachingHourRequests[i]?.locaEndTime}`,
                                      gap
                                    ) && (
                                      <p className="text-red rtl:font-almarai font-normal text-12 pt-1.5 ">
                                        {t('HOUR_GAP_VALIDATION')}
                                      </p>
                                    )
                                  )} */}
                                </div>
                                <div className="hour-input-container-AM">
                                  <SelectDropdown
                                    isDisabled={true}
                                    id={`coachingHourRequests.${i}.locaEndTime`}
                                    isMulti={false}
                                    isCreatable={false}
                                    options={localTime}
                                    isSearchable={false}
                                    optional={1}
                                    dropDown={true}
                                    name={`coachingHourRequests.${i}.locaEndTime`}
                                    placeholder={''}
                                    onBlur={handleBlur}
                                    onChange={(opt: any) => {
                                      setFieldValue(
                                        `coachingHourRequests.${i}.locaEndTime`,
                                        opt.value
                                      );
                                    }}
                                    className="mb-3"
                                    isValue={
                                      values.coachingHourRequests[i]
                                        ?.locaEndTime
                                    }
                                    defaultValue={{
                                      value:
                                        values.coachingHourRequests[i]
                                          ?.locaEndTime,
                                      label:
                                        values.coachingHourRequests[i]
                                          ?.locaEndTime,
                                    }}
                                    controlShouldRenderValue={true}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    );
                  }}
                />
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default CoachingHours;
