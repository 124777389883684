import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchRecentTransactions,
  fetchRevenue,
  fetchRevenueCounts,
  fetchRevenueSummary,
  fetchTransactionDetails,
} from '../../../services/payment';
import { alertActive } from '../../notification/slices';

interface IPayment {
  isRevenueCounts: boolean;
  revenueCountsData: {
    [key: string]: string | number;
  }[];
  isRecentTransactions: boolean;
  recentTransactions: any;
  isRevenue: boolean;
  revenueData: any;
  isRevenueSummary: boolean;
  revenueSummary: any;
  isTransaction: boolean;
  transactionDetails: any;
}

const initialState: IPayment = {
  isRevenueCounts: false,
  revenueCountsData: [],
  isRecentTransactions: false,
  recentTransactions: [],
  isRevenue: false,
  revenueData: [],
  isRevenueSummary: false,
  revenueSummary: [],
  isTransaction: false,
  transactionDetails: [],
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRevenueCounts.pending, (state) => {
        state.isRevenueCounts = true;
      })
      .addCase(getRevenueCounts.fulfilled, (state, action) => {
        state.isRevenueCounts = false;
        state.revenueCountsData = action.payload?.data;
      })
      .addCase(getRevenueCounts.rejected, (state) => {
        state.isRevenueCounts = false;
      })
      .addCase(getRecentTransactions.pending, (state) => {
        state.isRecentTransactions = true;
      })
      .addCase(getRecentTransactions.fulfilled, (state, action) => {
        state.isRecentTransactions = false;
        state.recentTransactions = action.payload?.data[0];
      })
      .addCase(getRecentTransactions.rejected, (state) => {
        state.isRecentTransactions = false;
        state.recentTransactions = [];
      })
      .addCase(getRevenue.pending, (state) => {
        state.isRevenue = true;
      })
      .addCase(getRevenue.fulfilled, (state, action) => {
        state.isRevenue = false;
        state.revenueData = action.payload?.data[0];
      })
      .addCase(getRevenue.rejected, (state) => {
        state.isRevenue = false;
        state.revenueData = [];
      })
      .addCase(getRevenueSummary.pending, (state) => {
        state.isRevenueSummary = true;
      })
      .addCase(getRevenueSummary.fulfilled, (state, action) => {
        state.isRevenueSummary = false;
        state.revenueSummary = action.payload?.data[0];
      })
      .addCase(getRevenueSummary.rejected, (state) => {
        state.isRevenueSummary = false;
        state.revenueSummary = [];
      })
      .addCase(getTransactionsDetails.pending, (state) => {
        state.isTransaction = true;
      })
      .addCase(getTransactionsDetails.fulfilled, (state, action) => {
        state.isTransaction = false;
        state.transactionDetails = action.payload?.data[0];
      })
      .addCase(getTransactionsDetails.rejected, (state) => {
        state.isTransaction = false;
        state.transactionDetails = [];
      });
  },
});

export const getRevenueCounts = createAsyncThunk(
  'payment/revenueCounts',
  async (id, thunkAPI) => {
    try {
      const { data } = await fetchRevenueCounts();
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getRecentTransactions = createAsyncThunk(
  'payment/receentTransactions',
  async (
    params: {
      [key: string]: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await fetchRecentTransactions(params);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getRevenue = createAsyncThunk(
  'payment/revenue',
  async (
    params: {
      [key: string]: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await fetchRevenue(params);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getRevenueSummary = createAsyncThunk(
  'payment/revenueSummary',
  async (
    params: {
      [key: string]: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await fetchRevenueSummary(params);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getTransactionsDetails = createAsyncThunk(
  'get/getTransactionsDetails',
  async (params: any, thunkAPI) => {
    try {
      const { data } = await fetchTransactionDetails(params);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export default paymentSlice.reducer;
