import { Navigate, useLocation } from 'react-router-dom';
import { routeConstants } from '../constants/index';
import { IChildren } from '../interfaces/index';

const ProtectedRoute = ({ children }: IChildren) => {
  const location = useLocation();

  const token = localStorage.getItem('token');

  return token ? (
    children
  ) : (
    <Navigate
      replace
      to={routeConstants.LOGIN}
      state={{ path: location.pathname }}
    />
  );
};
export default ProtectedRoute;
