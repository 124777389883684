enum languageAndDirections {
  DIRECTIONS = 'dir',
  LEFT_TO_RIGHT = 'ltr',
  RIGHT_TO_LEFT = 'rtl',
  ARABIC_SYMBOL = 'ar',
  ENGLISH_SYMBOL = 'en',
  ARABIC_SYMBOL_TO_DISPLAY = 'Ar',
  ENGLISH_SYMBOL_TO_DISPLAY = 'En',
  ARABIC = 'Arabic',
  ENGLISH = 'English',
  ARABIC_LANGUAGE = 'عربى',
}
export default languageAndDirections;
