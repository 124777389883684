import coachDirectoryEndpoints from '../constants/apiEndPoints/coachDirectory';
import { apiHeader } from '../constants/apiHeader';
import { get } from './apiService';

export const getCoachProfileData = async <T>(data: {
  [key: string]: string;
}) => {
  const { id } = data;
  delete data.id;
  return await get(
    coachDirectoryEndpoints.TABLEDATA + id,
    data,
    apiHeader.APPLICATION_JSON
  );
};
