import { apiHeader } from '../constants/apiHeader';
import paymentEndPoints from '../constants/payment';
import { get } from './apiService';

export const fetchRevenueCounts = async <T>() => {
  return get(paymentEndPoints.REVENUE_COUNTS, {}, apiHeader.APPLICATION_JSON);
};
export const fetchRecentTransactions = async <T>(data: {
  [key: string]: string;
}) => {
  return get(
    paymentEndPoints.RECENT_TRANSACTIONS,
    data,
    apiHeader.APPLICATION_JSON
  );
};
export const fetchRevenue = async <T>(data: { [key: string]: string }) => {
  return get(paymentEndPoints.REVENUE, data, apiHeader.APPLICATION_JSON);
};
export const fetchTransactionDetails = async (params: any) => {
  return get(paymentEndPoints.WITHDRAWN, params, apiHeader.APPLICATION_JSON);
};
export const fetchRevenueSummary = async <T>(data: {
  [key: string]: string;
}) => {
  return get(
    paymentEndPoints.REVENUE_SUMMARY,
    data,
    apiHeader.APPLICATION_JSON
  );
};
