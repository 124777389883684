import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { alertActive } from '../components/notification/slices';
import { weekDaysList } from '../constants/coachConstants/coachSessionConstants';
import {
  packageCreatePayload,
  packageEditPayload,
  PackagePayloadInterface,
  packageSliceInterface,
} from '../interfaces/coachInterfaces/packageInterface';
import {
  createPackageApi,
  deletePackageApi,
  editPackageApi,
  getAssessments,
  getAssessmentsById,
  getSession,
  getSessionById,
  ImageUploadPackage,
  listDurationApi,
  saveDraftPackageApi,
} from '../services/packageService';

import { savePackageApi } from '../services/packageService';
import { downloadById } from '../services/profile';
import { RootState } from '../store';
import { getMyUploadsParams } from '../_helpers/coachHelpers/query';
import { formDataConverter } from '../_helpers/coachHelpers/utility';
import { deleteDraft } from './coachHomeSlice';

const initialState: packageSliceInterface = {
  packageImagefile: {
    url: '',
    name: '',
    file: '',
    fileId: '',
  },
  packageFormValue: {
    title: '',
    subTitle: '',
    description: '',
    // duration: '',
    language: '',
    categoryTags: [],
  },
  intendedClientPackageFormValue: {
    gender: 'BOTH',
    whatWillLearn: ['', '', ''],
    requirements: ['', '', ''],
    forWhom: [],
    tags: [],
    benefits: ['', '', ''],
  },
  coachingHoursPackageFormValue: {
    days: 'Sunday-Thursday',
    start: '00:00',
    end: '02:00',
    alternative: false,
    coachingHourRequests: weekDaysList.map(
      (item: { label: string; value: string }) => ({
        day: item.value,
        from: '12:00',
        localFromTime: 'AM',
        end: '02:00',
        locaEndTime: 'AM',
      })
    ),
  },
  coachingPricePackageFormValue: {
    price: '',
  },
  assessmentPackageFormValue: {
    // assessmentId: '',
    // durationInWeek: '',
    // sessionId: '',
    // durationInDays: '',
    // durationInHr: '',
    assessmentValue: [
      {
        category: 'ASSESSMENT',
        assessmentId: '',
        // durationInWeek: '',
        sessionId: '',
        // duration: '',
        // retakePeriod: '',
      },
    ],
  },
  isProcessingCreatePackage: false,
  currency: [],
  assessmentTitles: [],
  isProcessingAssessmentTitles: false,
  isProcessingSessionTitles: false,
  sessionTitles: [],
  isProcessingPackages: false,
  assessmentSession: [],
  isProcessingDeletePackages: false,
  duration: [],
  isProcessingSavePackage: false,
  isProcessingEditPackage: false,
};

export const packageSlice = createSlice({
  name: 'packageSlice',
  initialState,
  reducers: {
    setPackageFormData: (state, action: PayloadAction<any>) => {
      state.packageFormValue = action.payload;
    },

    clearPackageFormData: (state) => {
      state.packageFormValue.title = '';
      state.packageFormValue.subTitle = '';
      state.packageFormValue.description = '';
      // state.packageFormValue.duration = '';
      state.packageFormValue.language = '';
      state.packageFormValue.categoryTags = [];
    },
    setIntendedClientPackageFormData: (state, action: PayloadAction<any>) => {
      state.intendedClientPackageFormValue = action.payload;
    },

    clearIntendedClientPackageFormData: (state) => {
      state.intendedClientPackageFormValue = {
        gender: 'BOTH',
        whatWillLearn: ['', '', ''],
        requirements: ['', '', ''],
        forWhom: [],
        tags: [],
        benefits: ['', '', ''],
      };
    },
    setCoachingHoursPackageFormData: (state, action: PayloadAction<any>) => {
      state.coachingHoursPackageFormValue = action.payload;
    },

    clearCoachingHoursPackageFormData: (state) => {
      state.coachingHoursPackageFormValue = {
        days: 'Sunday-Thursday',
        start: '00:00',
        end: '02:00',
        alternative: false,
        coachingHourRequests: Array.from(Array(7)).map(() => ({
          day: '',
          from: '12:00',
          localFromTime: 'AM',
          end: '02:00',
          locaEndTime: 'AM',
        })),
      };
    },
    setCoachingPricePackageFormData: (state, action: PayloadAction<any>) => {
      state.coachingPricePackageFormValue = action.payload;
    },

    clearCoachingPricePackageFormData: (state) => {
      state.coachingPricePackageFormValue = {
        price: '',
      };
    },
    setPackageImageFileFormData: (state, action) => {
      state.packageImagefile.url = action.payload.url;
      state.packageImagefile.name = action.payload.name;
      state.packageImagefile.file = action.payload.file;
      state.packageImagefile.fileId = action.payload.fileId;
    },
    setPackageAssessmentSessionData: (state, action) => {
      state.assessmentSession = action.payload;
    },
    clearAssessmentSessionPrice: (state) => {
      state.assessmentSession = [];
    },
    clearPackageImageFileFormData: (state) => {
      state.packageImagefile.url = '';
      state.packageImagefile.name = '';
      state.packageImagefile.file = '';
      state.packageImagefile.fileId = '';
    },
    setAssessmentPackageFormData: (state, action: PayloadAction<any>) => {
      state.assessmentPackageFormValue = action.payload;
    },

    clearAssessmentPackageFormData: (state) => {
      state.assessmentPackageFormValue = {
        // assessmentId: '',
        // durationInWeek: '',
        // sessionId: '',
        // durationInDays: '',
        // durationInHr: '',
        assessmentValue: [
          {
            category: 'ASSESSMENT',
            assessmentId: '',
            // durationInWeek: '',
            sessionId: '',
            // duration: '',
            // retakePeriod: '',
          },
        ],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postEditPackage.pending, (state) => {
        state.isProcessingEditPackage = true;
      })
      .addCase(postEditPackage.fulfilled, (state, action) => {
        state.isProcessingEditPackage = false;
      })
      .addCase(postEditPackage.rejected, (state, action) => {
        state.isProcessingEditPackage = false;
      })
      .addCase(postCreatePackage.pending, (state) => {
        state.isProcessingCreatePackage = true;
      })
      .addCase(postCreatePackage.fulfilled, (state, action) => {
        state.isProcessingCreatePackage = false;
      })
      .addCase(postCreatePackage.rejected, (state, action) => {
        state.isProcessingCreatePackage = false;
      })
      .addCase(saveCreatePackage.pending, (state) => {
        state.isProcessingSavePackage = true;
      })
      .addCase(saveCreatePackage.fulfilled, (state, action) => {
        state.isProcessingSavePackage = false;
      })
      .addCase(saveCreatePackage.rejected, (state, action) => {
        state.isProcessingSavePackage = false;
      })
      .addCase(saveDraftPackage.pending, (state) => {
        state.isProcessingSavePackage = true;
      })
      .addCase(saveDraftPackage.fulfilled, (state, action) => {
        state.isProcessingSavePackage = false;
      })
      .addCase(saveDraftPackage.rejected, (state, action) => {
        state.isProcessingSavePackage = false;
      })
      .addCase(fetchAssessmentTitles.pending, (state) => {
        state.isProcessingAssessmentTitles = true;
      })
      .addCase(fetchAssessmentTitles.fulfilled, (state, action) => {
        state.assessmentTitles = action.payload?.data?.data[0]?.content;
        state.isProcessingAssessmentTitles = false;
      })
      .addCase(fetchAssessmentTitles.rejected, (state, action) => {
        state.isProcessingAssessmentTitles = false;
      })

      .addCase(fetchSessionTitles.pending, (state) => {
        state.isProcessingSessionTitles = true;
      })
      .addCase(fetchSessionTitles.fulfilled, (state, action) => {
        state.sessionTitles = action.payload?.data?.data[0]?.content;
        state.isProcessingSessionTitles = false;
      })
      .addCase(fetchSessionTitles.rejected, (state, action) => {
        state.isProcessingSessionTitles = false;
      })
      .addCase(fetchAssessmentSessionForPackages.pending, (state) => {
        state.isProcessingPackages = true;
      })
      .addCase(fetchAssessmentSessionForPackages.fulfilled, (state, action) => {
        state.assessmentSession = action.payload;
        state.isProcessingPackages = false;
      })
      .addCase(fetchAssessmentSessionForPackages.rejected, (state, action) => {
        state.isProcessingPackages = false;
      })
      .addCase(deletePackage.pending, (state) => {
        state.isProcessingDeletePackages = true;
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.isProcessingDeletePackages = false;
      })
      .addCase(deletePackage.rejected, (state, action) => {
        state.isProcessingDeletePackages = false;
      })
      .addCase(uploadPackageImage.pending, (state) => {
        state.isProcessingCreatePackage = true;
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(uploadPackageImage.fulfilled, (state, action) => {})
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(uploadPackageImage.rejected, (state, action) => {})
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(listPackageDuration.pending, (state) => {})
      .addCase(listPackageDuration.fulfilled, (state, action) => {
        state.duration = action.payload.data[0];
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(listPackageDuration.rejected, (state, action) => {});
  },
});
export const postCreatePackage = createAsyncThunk(
  'package/create',
  async (data: { payload: packageCreatePayload; navigate: any }, thunkAPI) => {
    const { packageInfo } = thunkAPI.getState() as {
      packageInfo: packageSliceInterface;
    };
    try {
      let photoId = '';
      if (packageInfo.packageImagefile.fileId !== '') {
        photoId = packageInfo.packageImagefile.fileId;
      } else if (packageInfo.packageImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadPackageImage({
            file: packageInfo.packageImagefile.file[0],
            fileType: 'PACKAGE_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await createPackageApi({
        ...data.payload.payload,
        imgId: photoId,
      });
      if (data.payload.draft === 'DRAFT')
        thunkAPI.dispatch(deleteDraft(data.payload.id));

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );

      thunkAPI.dispatch(clearPackageFormData());
      thunkAPI.dispatch(clearIntendedClientPackageFormData());
      thunkAPI.dispatch(clearCoachingHoursPackageFormData());
      thunkAPI.dispatch(clearAssessmentPackageFormData());
      thunkAPI.dispatch(clearAssessmentSessionPrice());
      thunkAPI.dispatch(clearCoachingPricePackageFormData());
      thunkAPI.dispatch(clearPackageImageFileFormData());
      data.navigate('/coach/create-session');
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const postEditPackage = createAsyncThunk(
  'package/edit',
  async (payload: packageEditPayload, thunkAPI) => {
    const { packageInfo } = thunkAPI.getState() as {
      packageInfo: packageSliceInterface;
    };
    try {
      let photoId = '';
      if (packageInfo.packageImagefile.fileId !== '') {
        photoId = packageInfo.packageImagefile.fileId;
      } else if (packageInfo.packageImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadPackageImage({
            file: packageInfo.packageImagefile.file[0],
            fileType: 'PACKAGE_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await editPackageApi(
        { ...payload.data, imgId: photoId },
        payload.id
      );

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );
      payload.navigate(
        '/kun/products?requestStatusFilter=PENDING&servicesFilter=ALL&page=0&baseFilter=ACTIVE'
      );
      thunkAPI.dispatch(clearPackageFormData());
      thunkAPI.dispatch(clearIntendedClientPackageFormData());
      thunkAPI.dispatch(clearCoachingHoursPackageFormData());
      thunkAPI.dispatch(clearAssessmentPackageFormData());
      thunkAPI.dispatch(clearAssessmentSessionPrice());
      thunkAPI.dispatch(clearCoachingPricePackageFormData());
      thunkAPI.dispatch(clearPackageImageFileFormData());

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveCreatePackage = createAsyncThunk(
  'package/save',
  async (
    data: { payload: PackagePayloadInterface; navigate: any },
    thunkAPI
  ) => {
    const { packageInfo } = thunkAPI.getState() as {
      packageInfo: packageSliceInterface;
    };
    try {
      let photoId = '';
      if (packageInfo.packageImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadPackageImage({
            file: packageInfo.packageImagefile.file[0],
            fileType: 'PACKAGE_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await savePackageApi({
        request: { ...data.payload, imgId: photoId },
        draftType: 'PACKAGE',
      });

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );

      thunkAPI.dispatch(clearPackageFormData());
      thunkAPI.dispatch(clearIntendedClientPackageFormData());
      thunkAPI.dispatch(clearCoachingHoursPackageFormData());
      thunkAPI.dispatch(clearAssessmentPackageFormData());
      thunkAPI.dispatch(clearAssessmentSessionPrice());
      thunkAPI.dispatch(clearCoachingPricePackageFormData());
      thunkAPI.dispatch(clearPackageImageFileFormData());
      data.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveDraftPackage = createAsyncThunk(
  'package/draft-package',
  async (
    payload: { payload: PackagePayloadInterface; id: string; navigate: any },
    thunkAPI
  ) => {
    const { packageInfo } = thunkAPI.getState() as {
      packageInfo: packageSliceInterface;
    };
    try {
      let photoId = '';
      if (packageInfo.packageImagefile.fileId !== '') {
        photoId = packageInfo.packageImagefile.fileId;
      } else if (packageInfo.packageImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadPackageImage({
            file: packageInfo.packageImagefile.file[0],
            fileType: 'PACKAGE_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await saveDraftPackageApi(
        {
          request: { ...payload.payload, imgId: photoId },
          draftType: 'PACKAGE',
        },
        payload.id
      );

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );

      thunkAPI.dispatch(clearPackageFormData());
      thunkAPI.dispatch(clearIntendedClientPackageFormData());
      thunkAPI.dispatch(clearCoachingHoursPackageFormData());
      thunkAPI.dispatch(clearAssessmentPackageFormData());
      thunkAPI.dispatch(clearAssessmentSessionPrice());
      thunkAPI.dispatch(clearCoachingPricePackageFormData());
      thunkAPI.dispatch(clearPackageImageFileFormData());
      payload.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const uploadPackageImage = createAsyncThunk(
  'packageImage/upload',
  async (payload: { fileType: string; file: any }, thunkAPI) => {
    try {
      const response = await ImageUploadPackage(
        formDataConverter(payload.file, payload.fileType)
      );

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchAssessmentTitles = createAsyncThunk(
  'get/assessments',
  async (id: string, thunkAPI) => {
    try {
      const params = getMyUploadsParams('', 'ACTIVE');
      const response = await getAssessments(id, params);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSessionTitles = createAsyncThunk(
  'get/session',
  async (id: string, thunkAPI) => {
    try {
      const params = getMyUploadsParams('', 'ACTIVE');

      const response = await getSession(id, params);

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchAssessmentSessionForPackages = createAsyncThunk(
  'get/packages',
  async (idArray: { id: string; type: string }[], thunkAPI) => {
    try {
      const array: any = [];
      const params = getMyUploadsParams('', 'ACTIVE');

      await Promise.all(
        idArray.map(async (item) => {
          if (item.type === 'ASSESSMENT') {
            const data = await getAssessmentsById(item.id, params);
            array.push(data?.data?.data[0]);
          } else if (item.type === 'SESSION') {
            const data = await getSessionById(item.id, params);
            array.push(data?.data?.data[0]);
          }
        })
      );

      return array;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deletePackage = createAsyncThunk(
  'delete/packages',
  async (id: string, thunkAPI) => {
    try {
      const response = await deletePackageApi(id);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const listPackageDuration = createAsyncThunk(
  'list/duration',
  async (id, thunkAPI) => {
    try {
      const response = await listDurationApi();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const downloadImage = createAsyncThunk(
  'downloadImage',
  async (id: number, thunkAPI) => {
    try {
      const response = await downloadById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const {
  setPackageFormData,
  clearPackageFormData,
  setIntendedClientPackageFormData,
  clearIntendedClientPackageFormData,
  setCoachingHoursPackageFormData,
  clearCoachingHoursPackageFormData,
  setCoachingPricePackageFormData,
  clearCoachingPricePackageFormData,
  setPackageImageFileFormData,
  clearPackageImageFileFormData,
  setAssessmentPackageFormData,
  clearAssessmentPackageFormData,
  clearAssessmentSessionPrice,
  setPackageAssessmentSessionData,
} = packageSlice.actions;
export const packageInfo = (state: RootState) => state.packageInfo;
export const packageInfoReducer = packageSlice.reducer;
