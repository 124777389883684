import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAppSelector } from '../../_helpers/index';
import { getCoachProfileCertificate } from '../coachProfile/slices';
import { alertActive } from '../notification/slices';
import {
  coachApprovalReject,
  getCoachApproveData,
  resetCoachApproval,
} from './slice';

const CoachApproval = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [rejectToggle, setRejectToggle] = useState(false);
  const [toggle, setToggle] = useState(0);
  const [approvalRejectToggle, setApprovalRejectToggle] = useState(false);
  const dispatch = useDispatch<any>();
  const [showMore, setShowMore] = useState(false);
  const { lang } = useAppSelector((state) => state.language);
  const { coachApproveData, requestLoading } = useAppSelector(
    (state) => state.coachApprove
  );
  const [searchParams, setSeaarchParams] = useSearchParams();
  const [data, setData] = useState<{ [key: string]: string }>({});
  let coachId: string;
  const temp: { [key: string]: string } = {};
  const {
    alertMessage,
    requestSuccess,
    requestPayload,
    requestError,
    isLoading,
  } = useAppSelector((state) => state.coachApprove);
  useEffect(() => {
    const alertComponent = async () => {
      requestError
        ? (dispatch(
            alertActive({
              alertError: requestError,
              alertPayload: requestPayload,
            })
          ),
          await dispatch(resetCoachApproval()))
        : '';
    };
    alertComponent();
  }, [requestSuccess, requestError]);
  useEffect(() => {
    async function fetchData() {
      coachId = await searchParams.get('coachId')!;
      await dispatch(getCoachApproveData({ id: coachId }));
    }
    fetchData();
  }, []);
  const handleApprovalRejectToggle = (category: string) => {
    category === 'approval'
      ? setApprovalRejectToggle(true)
      : !toggle && setApprovalRejectToggle(false);
  };

  const approveCoach = async (id: string) => {
    try {
      await dispatch(coachApprovalReject({ id, status: 'APPROVED' })).unwrap(),
        navigate(`/kun/coach/coachProfile?coachId=${coachApproveData?.id}`, {
          replace: true,
        });
    } catch (err) {
      return err;
    }
  };

  const redirect = () => {
    history.back();
  };

  const RejectPopup = () => {
    const [value, setValue] = useState('');

    const handleChangeInput = (v: any) => {
      setValue((val) => (val = v));
    };
    const handleSubmit = async () => {
      value ? setToggle(0) : setToggle(1);
      value && setRejectToggle(!rejectToggle);
      try {
        value &&
          (await dispatch(
            coachApprovalReject({
              id: coachApproveData?.id as string,
              status: 'REJECTED',
              reason: value,
            })
          ).unwrap(),
          navigate(`/kun/coach`));
      } catch (err) {
        return err;
      }
    };

    return (
      <>
        <div
          className={`fixed inset-0 bg-borderBlack bg-opacity-75 transition-opacity z-20 `}
        ></div>
        <div className="z-30  bg-white fixed top-[30vh] left-[40vw] p-11 rounded-xl flex flex-col w-[600px] h-[380px]   shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)]">
          <div className="flex justify-end">
            <img
              onClick={() => (setRejectToggle(!rejectToggle), setToggle(0))}
              className="cursor-pointer"
              src="/assets/closeButton.svg"
              alt=""
            />
          </div>
          <div className="flex items-start">
            <h1 className="font-satoshi rtl:font-almarai text-28 font-semibold py-7">
              {t(`COACH_PROFILE.ENTER_REASON`)}
            </h1>
          </div>

          <textarea
            value={value}
            className="outline-0 p-3 font-general rtl:font-almarai border border-borderBlack pb-7 h-[200px] rounded"
            onChange={(event) => handleChangeInput(event?.target.value)}
          />
          {toggle ? (
            <div className="text-red rtl:font-almarai">
              {t(`COACH_PROFILE.ENTER_REASON`)}
            </div>
          ) : (
            ''
          )}
          <div className="flex justify-center pt-7">
            <button
              onClick={() => {
                handleSubmit();
                handleApprovalRejectToggle('reject');
              }}
              className="bg-primary text-white font-satoshi rtl:font-almarai text-16  w-40 py-3 rounded flex justify-center"
            >
              {t('SUBMIT')}
            </button>
          </div>
        </div>
      </>
    );
  };
  const downloadCertificate = (id: string) => {
    dispatch(getCoachProfileCertificate(id));
  };
  return (
    <div className="px-32 mt-10 relative overflow-hidden">
      <div
        onClick={() => {
          redirect();
        }}
        className="absolute top -10 ltr:left-11 rtl:right-11 cursor-pointer"
      >
        {lang === 'en' ? (
          <img src="/assets/coach/arrow.svg" alt="" />
        ) : (
          <img src="/assets/coach/rightArrow.svg" alt="" />
        )}
      </div>
      {rejectToggle && <RejectPopup />}

      <div className="flex h-[340px] ">
        <div className="rounded-lg flex flex-shrink w-min-[300px] h-min-[300px]">
          <img
            className=" w-full h-full"
            src="/assets/coach/avatar.svg"
            alt=""
          />
        </div>
        {!isLoading ? (
          <div className="flex flex-col  ltr:ml-5  rtl:mr-5 h-full">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="text-primary font-bold font-satoshi rtl:font-almarai text-18">
                  {t('COACH_APPROVAL.COACH')}
                </div>
                <div className="text-textBlack font-bold font-satoshi rtl:font-almarai text-34 mt-2">
                  {coachApproveData && coachApproveData?.fullName}
                </div>
                <div className="flex flex-col">
                  <div className="flex gap-7 text-textBlack font-normal text-16 font-general rtl:font-almarai mt-5">
                    <div className="flex gap-2">
                      <img src="/assets/coach/email.svg" alt="" />
                      <p>
                        {coachApproveData?.email
                          ? coachApproveData?.email
                          : `--${t('NA')}--`}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <img src="/assets/coach/phone.svg" alt="" />
                      <p>
                        {coachApproveData?.phoneNumber
                          ? coachApproveData?.phoneNumber
                          : `--${t('NA')}--`}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-7 text-textBlack font-normal text-16 font-general rtl:font-almarai mt-5">
                    <div className="flex gap-2">
                      <img src="/assets/coach/language.svg" alt="" />
                      <p className="capitalize">
                        {coachApproveData?.languages
                          ? [coachApproveData?.languages]?.map(
                              (item: any) => `${item + ' '} `
                            )
                          : `--${t('NA')}--`}
                      </p>
                    </div>
                    <div className="flex gap-2 ">
                      <img src="/assets/coach/dob.svg" alt="" />
                      <p>
                        {coachApproveData?.dob
                          ? coachApproveData?.dob
                          : `--${t('NA')}--`}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <img src="/assets/coach/female.svg" alt="" />
                      <p>
                        {coachApproveData?.gender
                          ? coachApproveData?.gender
                          : `--${t('NA')}--`}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-7 text-textBlack font-normal text-16 font-general rtl:font-almarai mt-5">
                    <div className="flex gap-2">
                      <img src="/assets/coach/location.svg" alt="" />
                      <p className="capitalize">
                        <span
                          className={`${
                            coachApproveData?.addressLine1
                              ? ''
                              : 'font-satoshi rtl:font-almarai text-16 text-gray'
                          }`}
                        >
                          {coachApproveData?.addressLine1
                            ? `${coachApproveData?.addressLine1},`
                            : ''}
                        </span>
                        <span
                          className={`${
                            coachApproveData?.addressLine2
                              ? ''
                              : 'font-satoshi rtl:font-almarai text-16 text-gray'
                          } ltr:pl-1 rtl:pr-1`}
                        >
                          {coachApproveData?.addressLine2
                            ? `${coachApproveData?.addressLine2},`
                            : ''}
                        </span>
                        <span
                          className={`${
                            coachApproveData?.city
                              ? ''
                              : 'font-satoshi rtl:font-almarai text-16 text-gray '
                          } ltr:pl-1 rtl:pr-1`}
                        >
                          {coachApproveData?.city
                            ? `${coachApproveData?.city},`
                            : ''}
                        </span>
                        <span
                          className={`${
                            coachApproveData?.country
                              ? ''
                              : 'font-satoshi rtl:font-almarai text-16 text-gray '
                          } ltr:pl-1 rtl:pr-1`}
                        >
                          {coachApproveData?.country
                            ? coachApproveData?.country
                            : ''}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="relative">
                  {requestLoading && approvalRejectToggle && (
                    <div className="absolute top-1.5 left-10">
                      <ClipLoader
                        color={'white'}
                        loading={true}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}

                  <button
                    onClick={() => (
                      approveCoach(coachApproveData?.id as string),
                      handleApprovalRejectToggle('approval')
                    )}
                    className={`p-[8px] bg-primary  rounded text-white font-bold font-satoshi rtl:font-almarai text-16 w-[237px] h-11`}
                  >
                    {`${
                      coachApproveData?.requestStatus === 'PENDING'
                        ? t('COACH_APPROVAL.APPROVE')
                        : coachApproveData?.requestStatus === 'REAPPLY'
                        ? t('COACH_APPROVAL.REAPPROVE')
                        : ''
                    }`}
                  </button>
                </div>
                <div className="relative">
                  {requestLoading && !approvalRejectToggle && (
                    <div className="absolute top-1.5 left-10">
                      <ClipLoader
                        color={'white'}
                        loading={true}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}

                  <button
                    onClick={() => {
                      setRejectToggle(!rejectToggle);
                    }}
                    className="py-[8px]  bg-red rounded text-white font-bold font-satoshi rtl:font-almarai text-16  w-[237px] h-11"
                  >
                    {t('COACH_APPROVAL.REJECT')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-grow w-full h-full justify-center items-center">
            <ClipLoader
              color={'#00a5ec'}
              loading={true}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>

      <div className="mt-10 pt-5 border-t border-borderBlack">
        <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5">
          {t('COACHING_ACCREDITATION')}
        </h1>

        <div className="pb-5">
          {coachApproveData?.accreditationResponses?.length !== 0 ? (
            coachApproveData?.accreditationResponses &&
            coachApproveData?.accreditationResponses.map(
              (item: { [key: string]: string[] | string }, index: number) => {
                return (
                  <div key={index} className="flex items-start gap-4">
                    <img
                      className="h-[50px] w-50px] flex "
                      src="/assets/coach/university1.svg"
                      alt=""
                    />
                    <div
                      className={`flex flex-grow flex-col ${
                        index !==
                        (coachApproveData?.accreditationResponses
                          ?.length as number) -
                          1
                          ? 'border-b border-borderBlack'
                          : ''
                      }   `}
                    >
                      <h1 className="text-textBlack font-bold font-satoshi rtl:font-almarai text-18">
                        {item?.provider}
                      </h1>
                      <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                        {item?.accreditationName}
                      </p>
                      <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                        {item?.issueDate + ' ' + item?.expiration}{' '}
                        <span>{item?.coachingHours}</span>
                      </p>
                      {item?.description && (
                        <p className="text-textBlack font-normal text-16 font-general rtl:font-almarai mt-4">
                          {item?.description}
                        </p>
                      )}
                      {item?.fileId ? (
                        <div className="flex gap-2 items-center flex-shrink h-[40px] pt-5  ml-[-25px]  ">
                          <img
                            onClick={() =>
                              downloadCertificate(item?.fileId as string)
                            }
                            className="flex items-center mt-4 p-0 rounded"
                            src="/assets/coach/certificate.svg"
                            alt=""
                          />
                          <p>{t('CERTIFICATE_PDF')}</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div className="font-satoshi rtl:font-almarai pb-4 text-16 text-gray">
              --{t('NA')}--
            </div>
          )}
        </div>
      </div>
      <div className=" border-t pt-6 border-borderBlack ">
        <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5">
          {t('SPECIALIZATION')}
        </h1>
      </div>
      <div className="mt-6 ">
        <div>
          <h1 className="flex font-satoshi rtl:font-almarai font-bold text-18 text-textBlack mb-5">
            {t('COACHING_CATEGORIES')}
          </h1>
          <div>
            <ul className="flex flex-wrap gap-4">
              {coachApproveData.coachingCategories?.length ? (
                coachApproveData.coachingCategories &&
                coachApproveData.coachingCategories.map(
                  (item: string, index: number) => (
                    <li
                      key={index}
                      className="border border-borderBlack rounded  mr-4 flex "
                    >
                      <div className="flex px-3 py-1 font-general rtl:font-almarai font-normal text-16 text-textBlack">
                        {item}
                      </div>
                    </li>
                  )
                )
              ) : (
                <div className="font-satoshi rtl:font-almarai text-16 pb-4 text-gray">
                  --{t('NA')}--
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-6 ">
        <div>
          <h1 className="font-satoshi rtl:font-almarai font-bold text-18 text-textBlack mb-5">
            {t('COACHING_SPECIALIZATION')}
          </h1>
          <ul className="flex flex-wrap gap-4">
            {coachApproveData.specialization?.length ? (
              coachApproveData.specialization &&
              coachApproveData.specialization.map(
                (item: string, index: number) => (
                  <li
                    key={index}
                    className="border border-borderBlack rounded mr-4"
                  >
                    <div className="px-3 py-1 font-general rtl:font-almarai font-normal text-16 text-textBlack">
                      {item}
                    </div>
                  </li>
                )
              )
            ) : (
              <div className="font-satoshi rtl:font-almarai text-16 text-gray">
                --{t('NA')}--
              </div>
            )}
          </ul>
        </div>
      </div>
      <div className="mt-6 border-t pt-6 border-borderBlack ">
        <div></div>
        <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5">
          {t('EDUCATION')}
        </h1>
        {coachApproveData?.educationResponses?.length ? (
          <div className="">
            {coachApproveData?.educationResponses &&
              coachApproveData?.educationResponses.map(
                (item: { [key: string]: string[] | string }, index) => {
                  return (
                    <div key={index} className="flex items-start gap-4 pt-5">
                      <img
                        className="h-[50px] w-50px] flex "
                        src="/assets/coach/university1.svg"
                        alt=""
                      />
                      <div
                        className={`flex flex-grow flex-col ${
                          index !==
                          (coachApproveData?.educationResponses
                            ?.length as number) -
                            1
                            ? 'border-b border-borderBlack'
                            : ''
                        }  pb-5 `}
                      >
                        <h1 className="text-textBlack font-bold font-satoshi rtl:font-almarai text-18">
                          {item?.university}
                        </h1>
                        <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                          {item?.degree}
                        </p>
                        <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                          {item?.startDate + ' ' + item?.endDate}
                        </p>
                        {item?.description && (
                          <p className="text-textBlack font-normal text-16 font-general rtl:font-almarai mt-4">
                            {item?.description}
                          </p>
                        )}
                        {item?.fileId ? (
                          <div className="flex gap-2 items-center flex-shrink h-[40px]  pt-5 ml-[-25px]">
                            <img
                              onClick={() =>
                                downloadCertificate(item.fileId as string)
                              }
                              className="flex items-center mt-4 p-0 rounded"
                              src="/assets/coach/certificate.svg"
                              alt=""
                            />
                            <p>{t('CERTIFICATE_PDF')}</p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        ) : (
          <div className="font-satoshi rtl:font-almarai pb-4 text-16 text-gray">
            --{t('NA')}--
          </div>
        )}
      </div>
      <div className=" border-t border-borderBlack">
        <h1 className="font-satoshi rtl:font-almarai font-bold text-24 text-textBlack mb-5 pt-5">
          {t('EXPERIENCE')}
        </h1>
        <div className="pb-5">
          {coachApproveData?.experienceResponses?.length !== 0 ? (
            coachApproveData?.experienceResponses &&
            coachApproveData?.experienceResponses.map(
              (item: { [key: string]: string[] | string }, index) => {
                return (
                  <div key={index} className="flex items-start gap-4 pt-5">
                    <img
                      className="h-[50px] w-50px] flex "
                      src="/assets/coach/university1.svg"
                      alt=""
                    />
                    <div
                      className={`flex flex-grow flex-col ${
                        index !==
                        (coachApproveData?.experienceResponses
                          ?.length as number) -
                          1
                          ? 'border-b border-borderBlack'
                          : ''
                      }  pb-5 `}
                    >
                      <h1 className="text-textBlack font-bold font-satoshi rtl:font-almarai text-18">
                        {item?.title}
                      </h1>
                      <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                        {item?.companyName}
                      </p>
                      <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                        {item?.location}
                      </p>
                      <p className="text-gray font-normal font-general rtl:font-almarai text-13 mt-1">
                        {item?.startDate + ' ' + item?.endDate}
                      </p>
                      {item?.description && (
                        <p className="text-textBlack font-normal text-16 font-general rtl:font-almarai mt-4">
                          {item?.description}
                        </p>
                      )}
                      {item?.fileId ? (
                        <div className="flex gap-2 items-center flex-shrink h-[40px]  pt-5 ml-[-25px]">
                          <img
                            onClick={() =>
                              downloadCertificate(item.fileId as string)
                            }
                            className="flex items-center mt-4 p-0 rounded"
                            src="/assets/coach/certificate.svg"
                            alt=""
                          />
                          <p>{t('CERTIFICATE_PDF')}</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div className="font-satoshi rtl:font-almarai text-16 text-gray">
              --{t('NA')}--
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoachApproval;
