import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { languageAndDirections } from '../../constants/index';
import { logOutUser } from '../../pages/login/slices/authSlice';
import { useAppSelector } from '../../_helpers';
import { languageSelect } from '../header/slice';

const TopBar: FC = (): JSX.Element => {
  const [logoutToggle, setLogoutToggle] = useState(false);
  const { lang } = useAppSelector((state) => state.language);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const [t, i18n] = useTranslation();
  const [title, setTitle] = useState<string | undefined>('');
  const location = useLocation();
  const navigate = useNavigate();
  const sideBarList = [
    { locationPath: ['/kun/overview'], headerName: 'OVERVIEW' },
    {
      locationPath: ['/kun/coach'],
      headerName: 'COACHDIRECTORY',
    },
    { locationPath: ['/kun/clients'], headerName: 'CLIENTDIRECTORY' },
    { locationPath: ['/kun/subscription'], headerName: 'SUBSCRIPTION' },
    { locationPath: ['/kun/payment'], headerName: 'PAYMENT' },
    { locationPath: ['/kun/reports'], headerName: 'REPORTS' },
    { locationPath: ['/kun/products'], headerName: 'PRODUCTS' },
    { locationPath: ['/kun/review'], headerName: 'REVEIW' },
    {
      locationPath: ['/kun/coach/coachApproval'],
      headerName: 'COACHDIRECTORY',
    },
    {
      locationPath: ['/kun/coach/coachProfile'],
      headerName: 'COACHDIRECTORY',
    },
    { locationPath: ['/kun/products/service-view'], headerName: 'PRODUCTS' },
    { locationPath: ['/kun/coach-session'], headerName: 'PRODUCTS' },
    { locationPath: ['/kun/coach-assessment'], headerName: 'PRODUCTS' },
    { locationPath: ['/kun/coach-package'], headerName: 'PRODUCTS' },
    {
      locationPath: ['/kun/products/service-view/profile'],
      headerName: 'PRODUCTS',
    },
  ];
  const logout = () => {
    dispatch(logOutUser({ history: navigate }));
  };
  let temp: React.SetStateAction<string | undefined>;

  const handleChange = () => {
    if (lang === 'ar') {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.LEFT_TO_RIGHT
        );
      i18n.changeLanguage(languageAndDirections.ENGLISH_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ENGLISH_SYMBOL));
      document.title = t('KUN');
    } else {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.RIGHT_TO_LEFT
        );

      i18n.changeLanguage(languageAndDirections.ARABIC_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ARABIC_SYMBOL));
      document.title = t('KUN');
    }
    setLogoutToggle(false);
  };

  return (
    <div className=" flex w-full items-center h-[88px] sticky top-0 z-40 bg-white   shadow-[0_0px_1px_4px_rgba(0,0,0,0.1)]">
      <div className="text-black text-heading1 font-satoshi rtl:font-almarai text-28 font-bold flex-grow pl-4 rtl:pr-5">
        {/* {t(`${title}`)} */}
        {sideBarList.map((item) => {
          return item?.locationPath.includes(location?.pathname)
            ? t(item.headerName)
            : '';
        })}
      </div>
      <div className="flex flex-row items-center rtl:pl-5 pr-4 gap-5">
        <div className="cursor-pointer ">
          <img className="" src="/assets/notification.svg" alt="" />
        </div>

        <div className="cursor-pointer relative ">
          <img
            onClick={() => setLogoutToggle(!logoutToggle)}
            src="/assets/profileIcon.svg"
            alt=""
          />
          {logoutToggle && (
            <div className=" absolute top-16 bg-white z-10 py-2 px-6 ltr:right-1 rtl:left-1 text-16 font-normal font-general rtl:font-almarai shadow-lg rounded flex flex-col justify-center items-center w-max">
              <div
                onClick={() => setLogoutToggle(false)}
                className={`${
                  logoutToggle
                    ? '  fixed inset-0 bg-transperant bg-opacity-75 transition-opacity z-20 '
                    : ''
                }`}
              ></div>
              <ul className="w-full z-30 ">
                <li>
                  <div
                    onClick={() => {
                      handleChange();
                    }}
                    className="flex justify-center cursor-pointer gap-2"
                  >
                    <img
                      className="w-6 h-6 "
                      src="/assets/languageIcon.svg"
                      alt="logo"
                    />
                    <button
                      className="  text-textBlack pb-3"
                      id="selectMenu"
                      value={languageAndDirections.ENGLISH_SYMBOL}
                    >
                      {lang === languageAndDirections.ARABIC_SYMBOL
                        ? languageAndDirections.ENGLISH_SYMBOL_TO_DISPLAY
                        : languageAndDirections.ARABIC_LANGUAGE}
                    </button>
                  </div>
                </li>
                <li className="flex w-full" onClick={() => logout()}>
                  {t(`LOGOUT`)}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
