import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../_helpers';
import Button from '../button';
import { getCoachDirectoryTableData } from '../coach/slice';
import Filter from '../filter';
import Sort from '../sort';
import './index.scss';

const CoachHeader: FC = (): JSX.Element => {
  const [t] = useTranslation();

  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearchState] = useState<string | null>(
    searchParams.get('keyword') ? searchParams.get('keyword') : ''
  );
  const setParams = (item: string) => {
    const params: { [key: string]: string } = {};
    params['baseFilter'] = item;
    params['page'] = '0';
    params['size'] = '10';
    params['sort'] = 'created_at,desc';
    setSearchParams(params);
  };
  useEffect(() => {
    const temp: { [key: string]: string } = {};
    const baseFilter = searchParams.get('baseFilter');
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });
    Object.keys(temp).length === 0
      ? setSearchParams({
          baseFilter: 'ALL',
          page: '0',
          size: '10',
          sort: 'created_at,desc',
        })
      : dispatch(getCoachDirectoryTableData(searchParams));
  }, [searchParams]);
  useEffect(() => {
    setSearchState('');
  }, [searchParams.get('baseFilter')]);
  const buttonNames = [
    { title: 'ALL' },
    { title: 'ACTIVE' },
    { title: 'COACH_REQUEST' },
    { title: 'INACTIVE' },
  ];
  const filterList = [
    [
      { title: 'Male', titleValue: 'MALE' },
      { title: 'Female', titleValue: 'FEMALE' },
    ],
    [
      { title: 'All' || '', titleValue: 'ALL' },
      { title: 'Active', titleValue: 'ACTIVE' },
      { title: 'Newly created', titleValue: 'COACH_REQUEST' },
      { title: 'Inactive', titleValue: 'INACTIVE' },
    ],
  ];

  const dropdownListData = [
    {
      title: 'NAME',
      value: 'full_name',
      dir: { asc: 'asc', desc: 'desc' },
    },
    {
      title: 'EMAIL',
      value: 'email',
      dir: { asc: 'asc', desc: 'desc' },
    },
    {
      title: 'GENDER',
      value: 'gender',
      dir: { asc: 'asc', desc: 'desc' },
    },
    {
      title: 'DOB',
      value: 'dob',
      dir: { asc: 'asc', desc: 'desc' },
    },
  ];
  const { isTableDataLoading } = useAppSelector(
    (state) => state.coachDirectory
  );
  const reload = async () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    await setSearchParams(params);
    dispatch(getCoachDirectoryTableData(searchParams));
  };
  const handleSearch = () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    search ? (params['keyword'] = search) : delete params.keyword;
    setSearchParams(params);
  };
  const handleSetSearch = (event: string) => {
    setSearchState((value) => (value = event));
  };
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  return (
    <>
      <div className="flex  w-full relative  pl-6 mt-10 pr-6 ">
        <div className=" flex flex-col gap-y-6 flex-grow">
          <div className="flex flex-row flex-grow items-start p-0 gap-x-2">
            {buttonNames.map((value, index) => {
              return (
                <div key={index}>
                  <Button
                    category="coach"
                    className={` border  shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)] px-6 py-2 rounded ${
                      searchParams.get('baseFilter')
                        ? searchParams.get('baseFilter') === value.title
                          ? '!text-white bg-primary  border-transparent'
                          : 'border-borderBlack text-textBlack'
                        : value.title === 'ALL'
                        ? '!text-white bg-primary  border-transparent'
                        : 'border-borderBlack  text-textBlack'
                    } 
                    text-16 font-general rtl:font-almarai font-normal text-secondary `}
                    functionName={() => setParams(value.title)}
                    data={value.title}
                  />
                </div>
              );
            })}
          </div>
          <div className=" ">
            <div className="flex felx-row  p-0 gap-16 h-9">
              <div className="flex-grow relative flex items-center h-8">
                <img
                  src="/assets/search.svg"
                  alt=""
                  className=" absolute h-5 pb-0.5 pl-4 rtl:mr-3 pointer-events-none focus:hidden "
                  onClick={() => handleSearch()}
                />
                <input
                  type="search"
                  onChange={(e) => handleSetSearch(e.target.value)}
                  value={search as string}
                  onKeyUp={(e) => handleEnter(e)}
                  className=" indent-10 font-normal  bg-clip-padding border border-solid border-borderBlack rounded transition ease-in-out m-0 rtl:pl-2  w-6/12 h-[32px] focus:outline-none placeholder:pl-3  placeholder:text-borderBlack font-general rtl:font-almarai text-12  rtl:placeholder:pr-4"
                  id="search"
                  placeholder={t(`COACH_DIRECTORY.Search`)}
                ></input>
              </div>
              <div className="flex  h-[38px]">
                <div className="flex flex-row gap-x-4  h-[38px]  ">
                  <div className="h-[38px]">
                    <Sort dropdownList={dropdownListData} />
                  </div>
                  <div className="h-[38px]">
                    <Filter fields={filterList} />
                  </div>
                  <div className=" flex flex-row gap-x-6">
                    <div
                      className="flex items-center h-[38px]"
                      onClick={() => reload()}
                    >
                      <img
                        className="h-4 w-4"
                        src="/assets/reload.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoachHeader;
