enum loginConstants {
  ADMIN_USER_LOGIN_IN = 'Admin user log in',
  USER_OR_EMAIL = 'Username or Email',
  PASSWORD = 'Password',
  ENTER_CAPTCHA = 'Enter captcha',
  LOG_IN = 'Log in',
  REMEMBER_ME = 'Remember me',
  FORGOT_PASSWORD = 'Forgot password?',
}
export default loginConstants;
