import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAppSelector } from '../../_helpers';
import Button from '../coachComponents/buttons';
import {
  approveWireTransfer,
  downloadReceipt,
  getActiveSubscription,
  getSubscriptionDetails,
  rejectWireTransfer,
} from '../coachProfile/slices';
import Modal from '../modals';
import { alertActive } from '../notification/slices';

const SubscriptionDetails = ({
  value,
  index,
  isPurchased,
  status,
  isWireTransfer,
  receiptId,
  wireTransferId,
  wireTransferReceiptId,
}: {
  value: any;
  index: number;
  isPurchased?: boolean;
  status?: string;
  isWireTransfer: boolean;
  receiptId?: number;
  wireTransferId?: number;
  wireTransferReceiptId?: number;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [searchParams, setSeaarchParams] = useSearchParams();
  const [modalToggle, setModalToggle] = useState(false);
  const [rejectValue, setRejectValue] = useState('');
  const [errorToggle, setErrorToggle] = useState(false);
  const [downloadToggle, setdownloadToggle] = useState(false);
  const [acceptToggle, setacceptToggle] = useState(false);
  const [rejectToggle, setrejectToggle] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<any>([]);

  const {
    isSubscriptionDetails,
    subscriptionDetails,
    isApproveWireTransfer,
    isReceiptDownload,
    isRejectWireTransfer,
  } = useAppSelector((state) => state.coachProfile);

  useEffect(() => {
    let temp: any;
    const handleSubscriptionDetails = async () => {
      try {
        value?.subscriptionId &&
          !isSubscriptionDetails &&
          (temp = await dispatch(getSubscriptionDetails(value?.subscriptionId))
            .unwrap()
            .then((originalPromiseResult: any) => {
              setSubscriptionData(originalPromiseResult?.data[0]);
            }));
      } catch (err: any) {
        dispatch(
          alertActive({ alertError: true, alertPayload: err?.data?.message })
        );
      }
    };
    handleSubscriptionDetails();
  }, []);
  const handleApprove = async () => {
    const params = {
      paymentId: wireTransferId,
      id: searchParams?.get('coachId'),
      reviewStatus: 'APPROVE',
    };
    try {
      await dispatch(approveWireTransfer(params)).unwrap();
      dispatch(getActiveSubscription(Number(searchParams.get('coachId'))));
    } catch (err) {
      return err;
    }
    setacceptToggle(false);
  };
  const fileDownload = async (id: number) => {
    try {
      await dispatch(downloadReceipt(id)).unwrap();
    } catch (err) {
      return err;
    }
    setdownloadToggle(false);
  };
  const handleClose = () => {
    setModalToggle(false);
    setRejectValue('');
  };
  const handleChangeInput = (v: any) => {
    setRejectValue(v);
  };
  const handleSubmit = async () => {
    if (rejectValue) {
      const params = {
        paymentId: wireTransferId,
        id: searchParams?.get('coachId'),
        reviewStatus: 'REJECT',
        comments: rejectValue,
      };
      setrejectToggle(true);
      setModalToggle(false);
      try {
        await dispatch(rejectWireTransfer(params)).unwrap();
        dispatch(getActiveSubscription(Number(searchParams.get('coachId'))));
      } catch (err) {
        return err;
      }

      setErrorToggle(false);
      setRejectValue('');
    } else {
      setErrorToggle(true);
    }
    setrejectToggle(false);
  };
  return (
    <>
      {isSubscriptionDetails ? (
        <div
          key={index}
          className="bg-[#f5f5f5] rounded-lg mt-5 py-8 px-8 flex animate-pulse "
        >
          <div className="flex items-center w-full gap-3">
            <div className="w-[72px] h-[72px] animate-pulse bg-[#e4e4e7] rounded-lg"></div>
            <div className="text-white flex-grow flex flex-col gap-1 ">
              <div className="h-[22px] w-40 animate-pulse bg-[#e4e4e7] rounded "></div>
              <div className="h-[30px] w-16 animate-pulse bg-[#e4e4e7] rounded"></div>
            </div>
            <div className="flex gap-2 items-baseline">
              <div className=" h-[66px] w-16 animate-pulse bg-[#e4e4e7] rounded"></div>
              <div className="h-[25px] w-16 animate-pulse bg-[#e4e4e7] rounded ml-3"></div>
            </div>
          </div>
        </div>
      ) : (
        <div
          key={value + index}
          className="bg-darkBlue rounded-lg mt-5 py-8 px-8 flex  relative"
        >
          <Modal logOutClose={handleClose} show={modalToggle}>
            <div className="flex flex-col py-6">
              <div className=" font-bold  text-28 pb-3 font-satoshi rtl:font-almarai ">
                {t(`COACH_PROFILE.ENTER_REASON`)}
              </div>
              <textarea
                value={rejectValue}
                onChange={(event) => handleChangeInput(event?.target?.value)}
                className="border  border-borderBlack rounded outline-0  font-satoshi rtl:font-almarai font-normal"
                name=""
                id=""
                cols={20}
                rows={5}
              ></textarea>
              {errorToggle ? (
                <div className="text-red font-normal font-satoshi rtl:font-almarai">
                  {t(`COACH_PROFILE.ENTER_REASON`)}
                </div>
              ) : (
                ''
              )}
              <div className="flex flex-row justify-center pt-3 w-full ">
                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="border  font-normal rounded p-2 w-[160px] bg-primary text-white  font-satoshi rtl:font-almarai
                "
                >
                  {t(`SUBMIT`)}
                </button>
              </div>
            </div>
          </Modal>

          <div
            className={` absolute right-8 top-3  rounded text-11 p-1 w-20 flex text-center justify-center items-center pt-1  ${
              ['ACTIVE', 'UPGRADING'].includes(value?.status)
                ? ' bg-primary text-white'
                : value?.status === 'INACTIVE'
                ? 'bg-red text-white'
                : 'text-black bg-white'
            } `}
          >
            {value?.status === 'UPGRADING'
              ? 'ACTIVE'
              : value?.wireTransferStatus === 'REJECTED'
              ? 'REJECTED'
              : value?.status}
          </div>

          <div className="flex items-center w-full gap-3">
            <img src="/assets/coach/subscription.svg" alt="" />
            <div className="text-white flex-grow">
              <div className="font-general rtl:font-almarai font-semibold text-17 ">
                {subscriptionData?.headline}
              </div>
              <div className="font-satoshi rtl:font-almarai font-semibold text-28">
                {subscriptionData?.headline}
              </div>
            </div>
            <div className="text-white font-satoshi rtl:font-almarai font-normal text-54 px-3">
              {t('SAR')} {subscriptionData?.charge}
              <span className="font-satoshi rtl:font-almarai font-bold text-20">
                /{subscriptionData?.subscriptionType?.toLowerCase()}
              </span>
            </div>
            <div className="flex gap-3 font-satoshi rtl:font-almarai sm:flex-col sm:pt-3 xl:flex-row xl:pt-0">
              {value?.wireTransferReceiptId ? (
                <div className=" ">
                  <Button>
                    <div className=" bg-primary rounded px-2 py-2 w-[120px] flex gap-2 items-center h-10 justify-center">
                      {isReceiptDownload && downloadToggle ? (
                        <div className="pt-1">
                          <ClipLoader
                            color={'white'}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        onClick={() => (
                          fileDownload(value?.wireTransferReceiptId),
                          setdownloadToggle(true)
                        )}
                        className="text-white "
                      >
                        {t('COACH_PROFILE.DOWNLOAD')}
                      </div>
                    </div>
                  </Button>
                </div>
              ) : (
                ''
              )}
              {value?.wireTransferStatus === 'REJECTED' ? (
                ''
              ) : value?.status === 'INACTIVE' &&
                !isPurchased &&
                isWireTransfer ? (
                <div className=" ">
                  <Button>
                    <div className=" bg-lightBlue rounded px-2 py-2 w-[120px] flex gap-2 items-center h-10 justify-center">
                      {isApproveWireTransfer && acceptToggle ? (
                        <div className="pt-1 ">
                          <ClipLoader
                            color={'white'}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        onClick={() => (handleApprove(), setacceptToggle(true))}
                        className=" text-white "
                      >
                        {t('COACH_PROFILE.APPROVE')}
                      </div>
                    </div>
                  </Button>
                </div>
              ) : (
                ''
              )}
              {value?.wireTransferStatus === 'REJECTED' ? (
                ''
              ) : status === 'INACTIVE' && !isPurchased && isWireTransfer ? (
                <div className="">
                  <Button>
                    <div className=" bg-red rounded px-2 py-2 w-[120px] flex gap-2 items-center h-10 justify-center">
                      {isRejectWireTransfer && rejectToggle ? (
                        <div className="pt-1">
                          <ClipLoader
                            color={'white'}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        onClick={() => setModalToggle(true)}
                        className="  text-white  "
                      >
                        {t('COACH_PROFILE.REJECT')}
                      </div>
                    </div>
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionDetails;
