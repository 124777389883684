import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { navigationConstants } from '../../constants/index';

const NavigationBar: FC = (): JSX.Element => {
  const [currentRoute, setCurrentRoute] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const [t] = useTranslation();
  const redirect = (route: string, name: string) => {
    route !== location.pathname ? (navigate(route), setCurrentRoute(name)) : '';
  };
  const location = useLocation();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location.pathname, currentRoute]);
  const sideBarList = [
    // {
    //   text: navigationConstants.OVERVEIW,
    //   img: (
    //     <svg
    //       width="18"
    //       height="18"
    //       viewBox="0 0 18 18"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M1 10H7C7.55 10 8 9.55 8 9V1C8 0.45 7.55 0 7 0H1C0.45 0 0 0.45 0 1V9C0 9.55 0.45 10 1 10ZM1 18H7C7.55 18 8 17.55 8 17V13C8 12.45 7.55 12 7 12H1C0.45 12 0 12.45 0 13V17C0 17.55 0.45 18 1 18ZM11 18H17C17.55 18 18 17.55 18 17V9C18 8.45 17.55 8 17 8H11C10.45 8 10 8.45 10 9V17C10 17.55 10.45 18 11 18ZM10 1V5C10 5.55 10.45 6 11 6H17C17.55 6 18 5.55 18 5V1C18 0.45 17.55 0 17 0H11C10.45 0 10 0.45 10 1Z"
    //         fill={
    //           currentRoute ===
    //           navigationConstants.BASE_ROUTE +
    //             navigationConstants.OVERVEIW_ROUTE
    //             ? '#ffffff'
    //             : '#687E8E'
    //         }
    //       />
    //     </svg>
    //   ),
    //   route:
    //     navigationConstants.BASE_ROUTE + navigationConstants.OVERVEIW_ROUTE,
    //   name: navigationConstants.OVERVEIW_ROUTE,
    //   routeList: [
    //     navigationConstants.BASE_ROUTE + navigationConstants.OVERVEIW_ROUTE,
    //   ],
    // },
    {
      text: navigationConstants.COACH,
      img: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
            fill={
              [
                navigationConstants.BASE_ROUTE +
                  navigationConstants.COACH_ROUTE,
                navigationConstants.BASE_ROUTE +
                  navigationConstants.COACH_ROUTE +
                  '/coachApproval',
                navigationConstants.BASE_ROUTE +
                  navigationConstants.COACH_ROUTE +
                  '/coachProfile',
              ].includes(currentRoute)
                ? '#ffffff'
                : '#687E8E'
            }
          />
        </svg>
      ),
      route: navigationConstants.BASE_ROUTE + navigationConstants.COACH_ROUTE,
      name: navigationConstants.COACH_ROUTE,
      routeList: [
        navigationConstants.BASE_ROUTE + navigationConstants.COACH_ROUTE,
        navigationConstants.BASE_ROUTE +
          navigationConstants.COACH_ROUTE +
          '/coachApproval',
        navigationConstants.BASE_ROUTE +
          navigationConstants.COACH_ROUTE +
          '/coachProfile',
      ],
    },
    {
      text: navigationConstants.CLIENTS,
      img: (
        <svg
          width="24"
          height="12"
          viewBox="0 0 24 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 6.75C13.63 6.75 15.07 7.14 16.24 7.65C17.32 8.13 18 9.21 18 10.38V11C18 11.55 17.55 12 17 12H7C6.45 12 6 11.55 6 11V10.39C6 9.21 6.68 8.13 7.76 7.66C8.93 7.14 10.37 6.75 12 6.75ZM4 7C5.1 7 6 6.1 6 5C6 3.9 5.1 3 4 3C2.9 3 2 3.9 2 5C2 6.1 2.9 7 4 7ZM5.13 8.1C4.76 8.04 4.39 8 4 8C3.01 8 2.07 8.21 1.22 8.58C0.48 8.9 0 9.62 0 10.43V11C0 11.55 0.45 12 1 12H4.5V10.39C4.5 9.56 4.73 8.78 5.13 8.1ZM20 7C21.1 7 22 6.1 22 5C22 3.9 21.1 3 20 3C18.9 3 18 3.9 18 5C18 6.1 18.9 7 20 7ZM24 10.43C24 9.62 23.52 8.9 22.78 8.58C21.93 8.21 20.99 8 20 8C19.61 8 19.24 8.04 18.87 8.1C19.27 8.78 19.5 9.56 19.5 10.39V12H23C23.55 12 24 11.55 24 11V10.43ZM12 0C13.66 0 15 1.34 15 3C15 4.66 13.66 6 12 6C10.34 6 9 4.66 9 3C9 1.34 10.34 0 12 0Z"
            fill={
              currentRoute ===
              navigationConstants.BASE_ROUTE + navigationConstants.CLIENTS_ROUTE
                ? '#ffffff'
                : '#687E8E'
            }
          />
        </svg>
      ),
      route: navigationConstants.BASE_ROUTE + navigationConstants.CLIENTS_ROUTE,
      name: navigationConstants.CLIENTS_ROUTE,
      routeList: [
        navigationConstants.BASE_ROUTE + navigationConstants.CLIENTS_ROUTE,
      ],
    },
    {
      text: navigationConstants.PRODUCTS,
      img: (
        <svg
          width="22"
          height="18"
          viewBox="0 0 22 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0H2C0.9 0 0 0.9 0 2V5H2V2H20V16H13V18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM0 15V18H3C3 16.34 1.66 15 0 15ZM0 11V13C2.76 13 5 15.24 5 18H7C7 14.13 3.87 11 0 11ZM0 7V9C4.97 9 9 13.03 9 18H11C11 11.92 6.07 7 0 7ZM10 8.09V10.09L13.5 12L17 10.09V8.09L13.5 10L10 8.09ZM13.5 3L8 6L13.5 9L19 6L13.5 3Z"
            fill={
              [
                navigationConstants.BASE_ROUTE + navigationConstants.PRODUCTS,
                navigationConstants.BASE_ROUTE +
                  navigationConstants.PRODUCTS +
                  '/service-view',
                navigationConstants.BASE_ROUTE +
                  navigationConstants.PRODUCTS +
                  '/service-view/profile',
                navigationConstants.BASE_ROUTE + 'coach-session',
                navigationConstants.BASE_ROUTE + 'coach-assessment',
                navigationConstants.BASE_ROUTE + 'coach-package',
              ].includes(currentRoute)
                ? '#ffffff'
                : '#687E8E'
            }
          />
        </svg>
      ),
      route: navigationConstants.BASE_ROUTE + navigationConstants.PRODUCTS,
      name: navigationConstants.PRODUCTS,
      routeList: [
        navigationConstants.BASE_ROUTE + navigationConstants.PRODUCTS,
        navigationConstants.BASE_ROUTE +
          navigationConstants.PRODUCTS +
          '/service-view',
        navigationConstants.BASE_ROUTE +
          navigationConstants.PRODUCTS +
          '/service-view/profile',
        navigationConstants.BASE_ROUTE + 'coach-session',
        navigationConstants.BASE_ROUTE + 'coach-assessment',
        navigationConstants.BASE_ROUTE + 'coach-package',
      ],
    },
    {
      text: navigationConstants.SUBSCRIPTION,
      img: (
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.199219 12.43C0.199219 11.78 0.799219 11.3 1.43922 11.44L4.99922 12.24V1.5C4.99922 0.67 5.66922 0 6.49922 0C7.32922 0 7.99922 0.67 7.99922 1.5V7.5H8.90922C9.21922 7.5 9.52922 7.57 9.79922 7.71L13.8892 9.75C14.6592 10.13 15.0992 10.97 14.9792 11.82L14.3492 16.28C14.2092 17.27 13.3592 18 12.3692 18H6.20922C5.67922 18 4.91922 17.79 4.54922 17.41L0.479219 13.12C0.299219 12.94 0.199219 12.69 0.199219 12.43Z"
            fill={
              currentRoute ===
              navigationConstants.BASE_ROUTE +
                navigationConstants.SUBSCRIPTION_ROUTE
                ? '#ffffff'
                : '#687E8E'
            }
          />
        </svg>
      ),
      route:
        navigationConstants.BASE_ROUTE + navigationConstants.SUBSCRIPTION_ROUTE,
      name: navigationConstants.SUBSCRIPTION_ROUTE,
      routeList: [
        navigationConstants.BASE_ROUTE + navigationConstants.SUBSCRIPTION_ROUTE,
      ],
    },
    {
      text: navigationConstants.PAYMENT,
      img: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10.88 15.76V16.12C10.88 16.6 10.49 17 10 17C9.52 17 9.12 16.61 9.12 16.12V15.7C8.49 15.55 7.19 15.09 6.43 13.6C6.2 13.16 6.42 12.61 6.88 12.42L6.95 12.39C7.36 12.22 7.82 12.39 8.03 12.78C8.35 13.39 8.98 14.15 10.15 14.15C11.08 14.15 12.13 13.67 12.13 12.54C12.13 11.58 11.43 11.08 9.85 10.51C8.75 10.12 6.5 9.48 6.5 7.2C6.5 7.1 6.51 4.8 9.12 4.24V3.88C9.12 3.39 9.52 3 10 3C10.48 3 10.88 3.39 10.88 3.88V4.25C11.95 4.44 12.63 5.01 13.04 5.55C13.38 5.99 13.2 6.63 12.68 6.85C12.32 7 11.9 6.88 11.66 6.57C11.38 6.19 10.88 5.8 10.06 5.8C9.36 5.8 8.25 6.17 8.25 7.19C8.25 8.14 9.11 8.5 10.89 9.09C13.29 9.92 13.9 11.14 13.9 12.54C13.9 15.17 11.4 15.67 10.88 15.76Z"
            fill={
              currentRoute ===
              navigationConstants.BASE_ROUTE + navigationConstants.PAYMENT_ROUTE
                ? '#ffffff'
                : '#687E8E'
            }
          />
        </svg>
      ),
      route: navigationConstants.BASE_ROUTE + navigationConstants.PAYMENT_ROUTE,
      name: navigationConstants.PAYMENT_ROUTE,
      routeList: [
        navigationConstants.BASE_ROUTE + navigationConstants.PAYMENT_ROUTE,
      ],
    },
    // {
    //   text: navigationConstants.REVEIW,
    //   img: (
    //     <svg
    //       width="20"
    //       height="19"
    //       viewBox="0 0 20 19"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M18 0H2C0.9 0 0 0.9 0 2V17.59C0 18.48 1.08 18.93 1.71 18.3L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM11.57 9.57L10.45 12.01C10.27 12.4 9.72 12.4 9.54 12.01L8.42 9.57L5.98 8.45C5.59 8.27 5.59 7.72 5.98 7.54L8.42 6.42L9.54 3.98C9.72 3.59 10.27 3.59 10.45 3.98L11.57 6.42L14.01 7.54C14.4 7.72 14.4 8.27 14.01 8.45L11.57 9.57Z"
    //         fill={
    //           currentRoute ===
    //           navigationConstants.BASE_ROUTE + navigationConstants.REVEIW_ROUTE
    //             ? '#ffffff'
    //             : '#687E8E'
    //         }
    //       />
    //     </svg>
    //   ),
    //   route: navigationConstants.BASE_ROUTE + navigationConstants.REVEIW_ROUTE,
    //   name: navigationConstants.REVEIW_ROUTE,
    //   routeList: [
    //     navigationConstants.BASE_ROUTE + navigationConstants.REVEIW_ROUTE,
    //   ],
    // },
    // {
    //   text: navigationConstants.REPORTS,
    //   img: (
    //     <svg
    //       width="16"
    //       height="20"
    //       viewBox="0 0 16 20"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M10.59 0.59C10.21 0.21 9.7 0 9.17 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.42L10.59 0.59ZM11 16H5C4.45 16 4 15.55 4 15C4 14.45 4.45 14 5 14H11C11.55 14 12 14.45 12 15C12 15.55 11.55 16 11 16ZM11 12H5C4.45 12 4 11.55 4 11C4 10.45 4.45 10 5 10H11C11.55 10 12 10.45 12 11C12 11.55 11.55 12 11 12ZM9 6V1.5L14.5 7H10C9.45 7 9 6.55 9 6Z"
    //         fill={
    //           currentRoute ===
    //           navigationConstants.BASE_ROUTE + navigationConstants.REPORTS_ROUTE
    //             ? '#ffffff'
    //             : '#687E8E'
    //         }
    //       />
    //     </svg>
    //   ),
    //   route: navigationConstants.BASE_ROUTE + navigationConstants.REPORTS_ROUTE,
    //   name: navigationConstants.REPORTS_ROUTE,
    //   routeList: [
    //     navigationConstants.BASE_ROUTE + navigationConstants.REPORTS_ROUTE,
    //   ],
    // },
  ];
  const sideBarListDisplay = () => {
    return sideBarList.map((item, index) => {
      return (
        <li
          key={index}
          className="list-none "
          onClick={() => redirect(item.route, item.name)}
        >
          <div
            className={`flex flex-row  hover: cursor-pointer ${
              item.routeList.includes(currentRoute)
                ? 'bg-primary ltr:border-l-4 rtl:border-r-4 text-white border-lightBlue'
                : ''
            } `}
          >
            <div className="flex mb-2 justify-start items-center gap-4 pl-5 pb-2.5 pt-3 rtl:pr-5 ">
              <div className="w-5">{item.img} </div>
              <div className="text-normal font-general rtl:font-almarai  font-normal text-heading2">
                {t(`COACH_DIRECTORY.${item.text}`)}
              </div>
            </div>
          </div>
        </li>
      );
    });
  };
  return (
    <aside className="flex h-full w- flex-grow  bg-darkBlue fixed">
      <div className="h-full  w-full">
        <div className="h-full  w-full">
          <div className=" pl-5  pt-10 pb-20 w-full flex items-center rtl:pr-5">
            <img
              src="/assets/companyLogo.svg"
              alt=""
              className="cursor-pointer "
            />
          </div>
          <div className="w-[220px] h-screen overflow-hidden bg-darkBlue place-content-center text-gray">
            {sideBarListDisplay()}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default NavigationBar;
