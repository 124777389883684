import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { alertActive } from '../../../components/notification/slices';
import { ILogInAuth } from '../../../interfaces/index';
import { randomFontSize } from '../../../_helpers/index';
import { logoutUser } from '../../../_helpers/logout';
import { setAccessToken } from '../../../_helpers/token';

import {
  ILoginPayloadData,
  ILogOut,
} from '../../../interfaces/loginIInterface';
import {
  authentication,
  captchaGetApi,
  logOut,
  verifyCaptcha,
} from '../../../services/loginApi';

const initialState: ILogInAuth = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  loginErrorPayload: '',
  loginSuccessPayload: '',
  jwtToken: '',
  captchaLoading: false,
  captchaSuccess: false,
  captchaError: false,
  captchaData: { __html: '' },
  captchaId: '',
  captchaVerificationSuccess: false,
  captchaVerificationLoading: false,
  captchaVerificationError: false,
  captchaVerificationPayload: '',
};
interface loginUser {
  email: string;
  password: string;
}
export const authSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    resetLogin: (state) => {
      (state.isLoading = false),
        (state.isSuccess = false),
        (state.isError = false),
        (state.loginErrorPayload = ''),
        (state.loginSuccessPayload = ''),
        (state.jwtToken = ''),
        (state.captchaLoading = false),
        (state.captchaSuccess = false),
        (state.captchaError = false),
        (state.captchaData = { __html: '' }),
        (state.captchaId = ''),
        (state.captchaVerificationSuccess = false),
        (state.captchaVerificationLoading = false),
        (state.captchaVerificationError = false),
        (state.captchaVerificationPayload = '');
    },
    resetCaptcha: (state) => {
      state.captchaVerificationError = false;
      state.captchaVerificationPayload = '';
      state.captchaData = { __html: '' };
      state.captchaVerificationSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jwtToken = action.payload ? action.payload[0]?.token : '';
        state.isSuccess && state.jwtToken
          ? setAccessToken(state?.jwtToken)
          : '';
        state.loginSuccessPayload = action.payload?.message;
      })
      .addCase(authenticateUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.loginErrorPayload = action.payload?.data.message;
      })
      .addCase(captcha.pending, (state) => {
        state.captchaLoading = true;
        state.captchaSuccess = false;
      })
      .addCase(captcha.fulfilled, (state, action) => {
        state.captchaLoading = false;
        state.captchaSuccess = true;
        action.payload?.data[0].captchaValue
          ? (state.captchaData = randomFontSize(
              action.payload?.data[0]?.captchaValue
            ))
          : '';
        state.captchaId = action.payload?.data[0]?.id;
      })
      .addCase(captcha.rejected, (state) => {
        state.captchaLoading = false;
        state.captchaError = true;
        state.captchaSuccess = false;
      })
      .addCase(captchaVerification.pending, (state) => {
        state.captchaVerificationLoading = true;
        state.captchaVerificationError = false;
        state.captchaVerificationSuccess = false;
      })
      .addCase(captchaVerification.fulfilled, (state) => {
        state.captchaVerificationLoading = false;
        state.captchaVerificationSuccess = true;
      })
      .addCase(captchaVerification.rejected, (state, action: any) => {
        state.captchaVerificationLoading = false;
        state.captchaVerificationError = true;
        state.captchaVerificationSuccess = false;
        state.captchaVerificationPayload = action.payload?.data?.message;
      });
  },
});

export const authenticateUser = createAsyncThunk(
  'auth/login',
  async (userData: ILoginPayloadData, thunkAPI) => {
    try {
      const response = await authentication({
        email: userData.email,
        password: userData.password,
      });
      const decode: { userType: string } = jwt_decode(response.data[0].token);
      setAccessToken(response.data[0]?.token);
      if (decode.userType !== 'SUPER_ADMIN') {
        thunkAPI.dispatch(
          alertActive({
            alertError: true,
            alertPayload: `Tried to login as ADMIN with ${decode.userType} credentials`,
          })
        );
        await thunkAPI.dispatch(checkUserTypeLogOut());
      } else {
        userData.history('/kun/coach');
      }
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      thunkAPI.dispatch(captcha());
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const checkUserTypeLogOut = createAsyncThunk(
  'auth/logOut/usertype',
  async (userData, thunkAPI) => {
    try {
      const response = await logOut();
      logoutUser();
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const logOutUser = createAsyncThunk(
  'auth/logOut',
  async (userData: ILogOut, thunkAPI) => {
    try {
      const response = await logOut();
      logoutUser();
      userData.history('/');
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const captcha = createAsyncThunk(
  'captcha/authenticateUser',
  async (loginCaptcha, thunkAPI) => {
    try {
      const { data } = await captchaGetApi();
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const captchaVerification = createAsyncThunk(
  'captcchaVerification/authenticateUser',
  async (captchaPayload: any, thunkAPI) => {
    try {
      const captchaVerifyData = await verifyCaptcha(captchaPayload);
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const { resetLogin, resetCaptcha } = authSlice.actions;

export default authSlice.reducer;
