export function getRandomArbitrary(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

export const randomFontSize = (captcha: any) => {
  const randomfontSizeString = [...captcha.toString()]
    .map((c) =>
      Math.random() < 0.6 ? c : c.fontsize(getRandomArbitrary(3, 6))
    )
    .join('');
  return {
    __html: randomfontSizeString,
  };
};
