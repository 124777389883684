import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchProductsDetails,
  fetchServiceDelete,
  fetchServiceDetails,
} from '../../../services/products';
import { alertActive } from '../../notification/slices';

interface IProducts {
  isProducts: boolean;
  productsData: any;
  isService: boolean;
  serviceDetails: any;
  isDelete: boolean;
  deleteInfo: any;
}

const initialState: IProducts = {
  isProducts: false,
  productsData: [],
  isService: false,
  serviceDetails: [],
  isDelete: false,
  deleteInfo: '',
};

export const productsSlice = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getProductDetails.pending, (state) => {
        state.isProducts = true;
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.isProducts = false;
        state.productsData = action.payload?.data[0];
      })
      .addCase(getProductDetails.rejected, (state) => {
        state.isProducts = false;
        state.productsData = [];
      })
      .addCase(getServiceDetails.pending, (state) => {
        state.isService = true;
      })
      .addCase(getServiceDetails.fulfilled, (state, action) => {
        state.isService = false;
        state.serviceDetails = action.payload?.data[0];
      })
      .addCase(getServiceDetails.rejected, (state) => {
        state.isService = false;
      })
      .addCase(serviceDelete.pending, (state) => {
        state.isDelete = true;
      })
      .addCase(serviceDelete.fulfilled, (state, action) => {
        state.isDelete = false;
        state.deleteInfo = action.payload?.message;
      })
      .addCase(serviceDelete.rejected, (state) => {
        state.isDelete = false;
      });
  },
});

export const getProductDetails = createAsyncThunk(
  'products/productDetails',
  async (
    paramsValue: {
      [key: string]: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await fetchProductsDetails(paramsValue);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getServiceDetails = createAsyncThunk(
  'products/serviceDetails',
  async (
    value: {
      id: number;
      serviceType: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await fetchServiceDetails(value);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const serviceDelete = createAsyncThunk(
  'products/serviceDelete',
  async (params: { id: number; service: string }, thunkAPI) => {
    try {
      const { data } = await fetchServiceDelete(params);
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: data?.message,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export default productsSlice.reducer;
