export const weekDaysList = [
  {
    label: 'SUNDAY',
    value: 'SUNDAY',
  },
  {
    label: 'MONDAY',
    value: 'MONDAY',
  },
  {
    label: 'TUESDAY',
    value: 'TUESDAY',
  },
  {
    label: 'WEDNESDAY',
    value: 'WEDNESDAY',
  },
  {
    label: 'THURSDAY',
    value: 'THURSDAY',
  },
  {
    label: 'FRIDAY',
    value: 'FRIDAY',
  },
  {
    label: 'SATURDAY',
    value: 'SATURDAY',
  },
];
