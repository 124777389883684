import { Navigate } from 'react-router-dom';
import { routeConstants } from '../../constants';
import { getAccessToken } from '../../_helpers/token';

const IndexRoute = (): any => {
  const token = localStorage.getItem('token');
  const token1 = async () => await getAccessToken();
  return token ? (
    <Navigate to={'/kun/' + 'coach'} replace={true} />
  ) : (
    <Navigate to={routeConstants.LOGIN} />
  );
};

export default IndexRoute;
