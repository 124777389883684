import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { ISubscriptionFormPayload } from '../../interfaces/index';
import { useAppSelector } from '../../_helpers';
import { Button, SubscriptionForm } from '../index';
import { alertActive } from '../notification/slices';
import Pagination from '../pagination';
import SubscriptionCard from '../subscriptionCard';
import {
  getAdditionalFeatures,
  getCoreFeatures,
  getSubscription,
  resetForm,
} from './slices';

const Subscription: FC = (): JSX.Element => {
  const [formDisplay, setFormDisplay] = useState<ISubscriptionFormPayload[]>(
    []
  );
  const [t] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    getAllSubscriptionsData,
    subscriptionFormSuccess,
    subscriptionFormData,
    subscriptionFormError,
    getSubscriptionDetailsLoading,
    subscriptionCoreFeaturesData,
    subscriptionAdditionalFeaturesData,
    subscriptionData,
  } = useAppSelector((state) => state.subscription);
  const dispatch = useDispatch<any>();
  const [subscriptionFormToggle, setSubscriptionFormToggle] = useState(false);
  const subscriptionFormToggleFunction = () => {
    setSubscriptionFormToggle(!subscriptionFormToggle);
  };

  const setParams = (item?: string) => {
    const params: { [key: string]: string } = {};
    params['baseFilter'] = item ? item : 'ACTIVE';
    params['page'] = '0';
    params['size'] = '10';
    params['sort'] = 'id,desc';

    setSearchParams(params);
  };
  useEffect(() => {
    subscriptionFormToggle
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [subscriptionFormToggle]);

  useEffect(() => {
    (getAllSubscriptionsData instanceof Array ||
      subscriptionCoreFeaturesData) &&
      setFormDisplay([...getAllSubscriptionsData]);
  }, [getAllSubscriptionsData]);
  useEffect(() => {
    const temp: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });
    !searchParams.get('baseFilter') && setParams(``);
    searchParams.get('baseFilter') && dispatch(getSubscription(searchParams));
    searchParams.get('baseFilter') && dispatch(getCoreFeatures());
    searchParams.get('baseFilter') && dispatch(getAdditionalFeatures());
  }, [searchParams]);
  useEffect(() => {
    getAllSubscriptionsData?.length &&
    formDisplay != getAllSubscriptionsData &&
    subscriptionFormSuccess
      ? (dispatch(getSubscription(searchParams)),
        dispatch(
          alertActive({
            alertSuccess: subscriptionFormSuccess,
            alertPayload: subscriptionFormData,
          })
        ))
      : '';
    subscriptionFormError
      ? dispatch(
          alertActive({
            alertError: subscriptionFormError,
            alertPayload: subscriptionFormData,
          })
        )
      : '';
    (subscriptionFormSuccess || subscriptionFormError) && dispatch(resetForm());
  }, [subscriptionFormSuccess, subscriptionFormError]);

  return (
    <>
      {!getSubscriptionDetailsLoading && (
        <div className="pb-10">
          <div>
            <Button
              className={
                'bg-primary text-white font-general rtl:font-almarai text-16 font-normal px-6 py-2 rounded  fixed ltr:right-6 rtl:left-6 mt-8'
              }
              data={`+ ${t('SUBSCRIPTION_PORTAL.ADD_NEW')}`}
              functionName={subscriptionFormToggleFunction}
            />
          </div>
          <div className={`${subscriptionFormToggle && 'relative'}`}>
            {subscriptionFormToggle && (
              <SubscriptionForm
                toggle={subscriptionFormToggle}
                subscriptionFunction={subscriptionFormToggleFunction}
                formSaveFunction={setFormDisplay}
                formContent={formDisplay}
                coreFeaturesData={subscriptionCoreFeaturesData}
                additionalFeaturesData={subscriptionAdditionalFeaturesData}
              />
            )}
          </div>

          <div className="">
            {(subscriptionFormToggle ||
              getAllSubscriptionsData ||
              formDisplay) && <SubscriptionCard formValue={formDisplay} />}
          </div>
        </div>
      )}
      {getSubscriptionDetailsLoading && (
        <div className="absolute top-[55vh] left-[60vw]">
          <ClipLoader
            color={'#00a5ec'}
            loading={true}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="  justify-center"
          />
        </div>
      )}

      {getSubscriptionDetailsLoading ? (
        ''
      ) : subscriptionData?.totalPages > 1 ? (
        <Pagination
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          totalPagesNumber={subscriptionData?.totalPages}
          isLoading={getSubscriptionDetailsLoading}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Subscription;
