import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { alertActive } from '../../../components/notification/slices';
import {
  ISetNewPasswordPayload,
  ISetNewPasswordSlice,
} from '../../../interfaces/index';
import { authenticateSetNewPassword } from '../../../services/setNewPassword';

const initialState: ISetNewPasswordSlice = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  setNewPasswordPayload: '',
};

export const setNewPsswordSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    resetSetNewPassword: (state) => {
      state.isSuccess = false;
      state.setNewPasswordPayload = '';
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setNewPassword.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(setNewPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.setNewPasswordPayload = action.payload?.message;
      })
      .addCase(setNewPassword.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.setNewPasswordPayload = action.payload?.data?.message;
      });
  },
});

export const setNewPassword = createAsyncThunk(
  'setNewPassword/authenticateSetNewPassword',
  async (userSetNewPassword: ISetNewPasswordPayload, thunkAPI) => {
    try {
      const setNewPasswordPayload = await authenticateSetNewPassword(
        userSetNewPassword
      );
      return setNewPasswordPayload;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const { resetSetNewPassword } = setNewPsswordSlice.actions;
export default setNewPsswordSlice.reducer;
