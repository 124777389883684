import { getAccessToken } from '../token/index';

export const getHeaderDetails = async function (value: string) {
  const token = await getAccessToken();
  return {
    headers: {
      'Content-Type': value,
      Authorization: `Bearer ${token}`,
    },
  };
};
