import PercentIcon from '@mui/icons-material/Percent';
import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../_helpers';
import { sunscriptionFormSchema } from '../../_helpers/validation';
import {
  ISubscriptionForm,
  ISubscriptionFormPayload,
} from '../../interfaces/index';
import { IFeaturesPayload } from '../../interfaces/subscription';
import Button from '../button';
import Input from '../inputComponent';
import {
  getSubscription,
  subscriptionFormsubmit,
} from '../scubscription/slices';
import './index.scss';
interface IHandleSubscriptionForm {
  title: string;
  headline: string;
  description?: string;
  monthlyCharge?: number | null;
  annualCharge?: number | null;
  coreFeatures?: string[];
  additionalFeatures?: string[];
  createdAt?: string;
  createdBy?: string;
  icon?: string;
  id?: number | null;
  isPublished?: boolean;
  subscriptionType?: string;
  charge?: number | null;
  sessionCount: number | null;
  assessmentCount: number | null;
  packageCount: number | null;
  [key: string]: any;
}

const SubscriptionForm: FC<ISubscriptionForm> = ({
  toggle,
  subscriptionFunction,
  formSaveFunction,
  formContent,
  formValue,
  coreFeaturesData,
  additionalFeaturesData,
}) => {
  const { getAllSubscriptionsData } = useAppSelector(
    (state) => state.subscription
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { lang } = useAppSelector((state) => state.language);
  const dispatch = useDispatch<any>();
  const [t] = useTranslation();
  const [selectIcon, setSelectIcon] = useState({
    base: false,
    pro: false,
    premier: false,
  });
  const [selectCharge, setSelectCharge] = useState({
    monthlySubscriptionCharge: false,
    annualSubscriptionCharge: false,
  });

  const [chargeError, setChargeError] = useState('');
  const [iconError, setIconError] = useState('');
  useEffect(() => {
    formValue?.icon === 'PRO'
      ? setSelectIcon({ base: false, pro: true, premier: false })
      : formValue?.icon === 'BASE'
      ? setSelectIcon({ base: true, pro: false, premier: false })
      : formValue?.icon === 'PREMIER'
      ? setSelectIcon({ base: false, pro: false, premier: true })
      : '';
  }, []);
  const handleSelectIcon = (iconPayload: {
    base: boolean;
    pro: boolean;
    premier: boolean;
  }) => {
    setSelectIcon({
      base: iconPayload.base,
      pro: iconPayload.pro,
      premier: iconPayload.premier,
    });
  };
  const subscriptionSubmit = async (value: any) => {
    try {
      await dispatch(subscriptionFormsubmit(value)).unwrap(),
        dispatch(getSubscription(searchParams));
    } catch (err) {
      return err;
    }
  };
  const handleSubscriptionForm = (value: IHandleSubscriptionForm) => {
    const handleValue: any = {};
    Object.keys(value).map((item) => {
      handleValue[`${item}`] = value[item];
    });
    value?.coreFeatures &&
      (handleValue['coreFeatures'] = value.coreFeatures.map((item) =>
        parseInt(item)
      ));
    value?.additionalFeatures &&
      (handleValue['additionalFeatures'] = value.additionalFeatures.map(
        (item) => parseInt(item)
      ));
    if (
      selectIcon.base ||
      selectIcon.premier ||
      selectIcon.pro ||
      formValue?.icon
    ) {
      setIconError('');
      if (!(handleValue === formValue) && formValue) {
        handleValue.id = formValue?.id;
        formContent?.includes(formValue)
          ? (formContent[
              formContent.indexOf(formValue as ISubscriptionFormPayload)
            ] = handleValue)
          : formSaveFunction([
              ...(formContent as ISubscriptionFormPayload[]),
              value,
            ]);
      } else {
        !getAllSubscriptionsData.includes(handleValue)
          ? subscriptionSubmit(handleValue)
          : '';
      }

      subscriptionFunction(!toggle);
    } else {
      selectIcon.base || selectIcon.premier || selectIcon.pro || formValue?.icon
        ? ''
        : setIconError('Select any one icon');
    }
  };
  return (
    <div className=" fixed top-0 left-0 right-0 bottom-0 bg-borderBlack flex items-center justify-center z-50">
      <div className="">
        <Formik
          initialValues={{
            title: formValue?.title ? (formValue?.title as string) : '',
            headline: formValue?.headline
              ? (formValue?.headline as string)
              : '',
            description: formValue?.description
              ? (formValue?.description as string)
              : '',
            subscriptionType: formValue?.subscriptionType
              ? formValue?.subscriptionType
              : '',
            commission: formValue?.commission ? formValue?.commission : '',
            fixedCommission: formValue?.fixedCommission
              ? formValue?.fixedCommission
              : 0,
            charge: formValue?.charge ? formValue?.charge : null,
            coreFeatures: formValue?.coreFeatures
              ? [
                  ...(formValue?.coreFeatures.map((value) =>
                    value.toString()
                  ) as string[]),
                ]
              : [],
            additionalFeatures: formValue?.additionalFeatures
              ? [
                  ...(formValue?.additionalFeatures.map((value) =>
                    value.toString()
                  ) as string[]),
                ]
              : [],
            icon: formValue?.icon ? (formValue?.icon as string) : '',
            sessionCount: formValue?.sessionCount
              ? formValue?.sessionCount
              : '',
            assessmentCount: formValue?.assessmentCount
              ? formValue?.assessmentCount
              : '',
            packageCount: formValue?.packageCount
              ? formValue?.packageCount
              : '',
          }}
          validationSchema={sunscriptionFormSchema}
          onSubmit={handleSubscriptionForm}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            submitForm,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="    space-y-6l  ">
                <div className=" max-h-[90vh] z-50 bg-white  w-[35vw] flex flex-col justify-between rounded  mx-auto  px-9   ">
                  <div className="relative  flex pt-10">
                    <div className="flex justify-between ">
                      <div className="font-satoshi rtl:font-almarai font-bold text-34 text-textBlack">
                        {formValue?.id
                          ? t('SUBSCRIPTION_PORTAL.EDIT_SUBSCRIPTION')
                          : t('SUBSCRIPTION_PORTAL.ADD_NEW_PLAN')}
                      </div>
                    </div>
                    <button
                      onClick={() => subscriptionFunction()}
                      className=" absolute top-[15px] ltr:right-[-20px] rtl:left-[-10px] flex place-items-end"
                    >
                      <span className="text-white h-6 w-6 rounded-3xl flex  text-2">
                        <img src="/assets/closeButton.svg" alt="" />
                      </span>
                    </button>{' '}
                  </div>
                  <div className="">
                    <div className="">
                      <div className="font-general rtl:font-almarai font-normal text-17 text-gray mt-4 mb-10 md_max:truncate">
                        {t('SUBSCRIPTION_PORTAL.INFO')}
                      </div>
                    </div>

                    <div className="subscriptionBody overflow-y-scroll max-h-[42vh] xl:max-h-[45vh] thin_scrollBar pr-3 ">
                      <div className="relative mt-1">
                        <Input
                          id={'formTitle'}
                          name={'title'}
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={'text'}
                          placeholder={'Title'}
                          classname={'input_login border-gray'}
                          errors={errors.title}
                          touched={touched.title}
                        />
                        <label
                          htmlFor="formTitle"
                          className="label-input-login "
                        >
                          {t('SUBSCRIPTION_PORTAL.TITLE')}
                          <span className="text-red rtl:font-almarai cursor-text">
                            *
                          </span>
                        </label>
                        <div className="pb-4 px-4">
                          <div
                            className={`text-red rtl:font-almarai text-sm absolute inline-block${
                              errors.title
                                ? 'text-red rtl:font-almarai'
                                : 'text-black '
                            } `}
                          >
                            {errors.title && touched.title
                              ? t(`SUBSCRIPTION_PORTAL.${errors.title}`)
                              : ''}
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-8">
                        <Input
                          id={'formHeadLine'}
                          name={'headline'}
                          value={values.headline}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={'text'}
                          placeholder={'Headline'}
                          classname={'input_login border-gray'}
                          errors={errors.headline}
                          touched={touched.headline}
                        />
                        <label
                          htmlFor="formHeadLine"
                          className="label-input-login "
                        >
                          {t('SUBSCRIPTION_PORTAL.HEADLINE')}
                          <span className="text-red rtl:font-almarai cursor-text">
                            *
                          </span>
                        </label>
                        <div className="pb-4 px-4">
                          <div
                            className={`text-red rtl:font-almarai text-sm absolute inline-block${
                              errors.headline
                                ? 'text-red rtl:font-almarai'
                                : 'text-black '
                            } `}
                          >
                            {errors.headline && touched.headline
                              ? t(`SUBSCRIPTION_PORTAL.${errors.headline}`)
                              : ''}
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-8">
                        <Input
                          id={'formDescription'}
                          name={'description'}
                          value={values.description as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={'text'}
                          placeholder={'Description'}
                          classname={'input_login h-[104px] border-gray'}
                          errors={undefined}
                          touched={undefined}
                        />
                        <label
                          htmlFor="formDescription"
                          className="label-input-login "
                        >
                          {t('SUBSCRIPTION_PORTAL.DESCRIPTION')}
                        </label>
                      </div>
                      <div className="relative mt-12">
                        <Input
                          id={'formCommission'}
                          name={'commission'}
                          value={values.commission}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={'text'}
                          placeholder={'Commission'}
                          classname={'input_login border-gray'}
                          errors={errors.headline}
                          touched={touched.headline}
                        />
                        <label
                          htmlFor="formCommission"
                          className="label-input-login "
                        >
                          {t('SUBSCRIPTION_PORTAL.COMMISSION')}
                          <span className="text-red rtl:font-almarai cursor-text">
                            *
                          </span>
                        </label>
                        <PercentIcon
                          className="absolute top-3 ltr:right-4 rtl:left-4"
                          sx={{ color: '#00a5ec' }}
                        />
                        <div className="pb-4 px-4">
                          <div
                            className={`text-red rtl:font-almarai text-sm absolute inline-block${
                              errors.headline
                                ? 'text-red rtl:font-almarai'
                                : 'text-black '
                            } `}
                          >
                            {errors.commission && touched.commission
                              ? t(`SUBSCRIPTION_PORTAL.${errors.commission}`)
                              : ''}
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-8">
                        <Input
                          id={'formfixedCommission'}
                          name={'fixedCommission'}
                          value={values.fixedCommission}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={'text'}
                          placeholder={'Fixed Commission'}
                          classname={'input_login border-gray'}
                          errors={errors.headline}
                          touched={touched.headline}
                        />
                        <label
                          htmlFor="formfixedCommission"
                          className="label-input-login "
                        >
                          {t('SUBSCRIPTION_PORTAL.FIXED_COMMISSION')}
                        </label>
                        <div className="pb-4 px-4">
                          <div
                            className={`text-red rtl:font-almarai text-sm absolute inline-block${
                              errors.headline
                                ? 'text-red rtl:font-almarai'
                                : 'text-black '
                            } `}
                          >
                            {errors.fixedCommission && touched.fixedCommission
                              ? t(
                                  `SUBSCRIPTION_PORTAL.${errors.fixedCommission}`
                                )
                              : ''}
                          </div>
                        </div>
                        <div className="absolute top-3 ltr:right-2 rtl:left-2 text-primary font-bold">
                          {t('SAR')}
                        </div>
                      </div>

                      <div
                        className={`flex lg:flex-row flex-col  justify-between`}
                      >
                        <div>
                          <div className="flex  items-center  gap-5  pt-10">
                            <div className="flex gap-2">
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.subscriptionType === 'MONTHLY'}
                                id={'monthlyCharge'}
                                type="radio"
                                className="text-textBlack  cursor-pointer"
                                value={'MONTHLY'}
                                name="subscriptionType"
                              ></input>
                              <label
                                htmlFor={`monthlyCharge`}
                                className="text-textBlack cursor-pointer font-satoshi rtl:font-almarai"
                              >
                                {t(`SUBSCRIPTION_PORTAL.MONTHLY_CHARGE`)}
                                <span className="text-red rtl:font-almarai cursor-text">
                                  *
                                </span>
                              </label>
                            </div>
                            <div className="flex gap-2">
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.subscriptionType === 'YEARLY'}
                                id={'annualCharge'}
                                type="radio"
                                className="text-textBlack  cursor-pointer"
                                value={'YEARLY'}
                                name="subscriptionType"
                              ></input>
                              <label
                                htmlFor={`annualCharge`}
                                className="text-textBlack cursor-pointer font-satoshi rtl:font-almarai "
                              >
                                {t(`SUBSCRIPTION_PORTAL.ANNUAL_CHARGE`)}
                                <span className="text-red rtl:font-almarai cursor-text">
                                  *
                                </span>
                              </label>
                            </div>
                          </div>
                          {errors.subscriptionType &&
                          touched.subscriptionType ? (
                            <div
                              className={` pl-5 mt-3 text-sm ${
                                errors.subscriptionType &&
                                'text-red rtl:font-almarai'
                              }`}
                            >
                              {t(
                                `SUBSCRIPTION_PORTAL.${errors.subscriptionType}`
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className="relative mt-8 lg:w-[50%]">
                          <Input
                            id={'chargeValue'}
                            name={'charge'}
                            value={values.charge as number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={'text'}
                            placeholder={'charge'}
                            classname={'input_login border-gray w-[80%]'}
                            errors={errors.charge}
                            touched={touched.charge}
                          />
                          <label
                            htmlFor="chargeValue"
                            className="label-input-login  "
                          >
                            {t(`SUBSCRIPTION_PORTAL.CHARGE`)}
                            <span className="text-red rtl:font-almarai cursor-text">
                              *
                            </span>
                          </label>
                          <div className="pb-4 px-4">
                            <div
                              className={`text-red rtl:font-almarai text-sm absolute inline-block ${
                                errors.charge
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              } `}
                            >
                              {errors.charge && touched.charge ? (
                                <div
                                  className={` pl-0  ${
                                    errors.charge && 'text-red rtl:font-almarai'
                                  }`}
                                >
                                  {t(`SUBSCRIPTION_PORTAL.${errors.charge}`)}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="absolute top-3 ltr:right-2 rtl:left-2 text-primary font-bold">
                            {t('SAR')}
                          </div>
                        </div>
                      </div>

                      <div className=" border-b border-borderBlack pt-7">
                        <h1 className="font-satoshi rtl:font-almarai font-bold text-18 text-textBlack">
                          {t('SUBSCRIPTION_PORTAL.SELECT_ICON')}
                          <span className="text-red rtl:font-almarai cursor-text">
                            *
                          </span>
                        </h1>
                        <div className="flex relative gap-4 pt-4 pb-8 items-center">
                          <div
                            onClick={() =>
                              handleSelectIcon({
                                base: true,
                                premier: false,
                                pro: false,
                              })
                            }
                            className={`${
                              selectIcon.base
                                ? 'border-2 border-secondary rounded-lg '
                                : 'border-2 border-transparent'
                            }`}
                          >
                            <Input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="basic"
                              type={'radio'}
                              value="BASE"
                              name="icon"
                              classname={'visibility: hidden'}
                            />
                            <label htmlFor="basic">
                              <img
                                className="cursor-pointer  w-10"
                                src="/assets/subscription/subscriptionIconBasic.svg"
                                alt=""
                              />
                            </label>
                          </div>
                          <div
                            onClick={() =>
                              handleSelectIcon({
                                base: false,
                                premier: false,
                                pro: true,
                              })
                            }
                            className={`${
                              selectIcon.pro
                                ? 'border-2 border-secondary rounded-lg'
                                : 'border-2 border-transparent'
                            }`}
                          >
                            <Input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="pro"
                              type={'radio'}
                              value="PRO"
                              name="icon"
                              classname={'visibility: hidden'}
                            />
                            <label htmlFor="pro">
                              <img
                                className="cursor-pointer  w-10"
                                src="/assets/subscription/subscriptionIconPro.svg"
                                alt=""
                              />
                            </label>
                          </div>
                          <div
                            onClick={() =>
                              handleSelectIcon({
                                base: false,
                                premier: true,
                                pro: false,
                              })
                            }
                            className={`${
                              selectIcon.premier
                                ? 'border-2 border-secondary rounded-lg'
                                : 'border-2 border-transparent'
                            }`}
                          >
                            <Input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="premier"
                              type={'radio'}
                              value="PREMIER"
                              name="icon"
                              classname={'visibility: hidden'}
                            />
                            <label htmlFor="premier">
                              <img
                                className="cursor-pointer  w-10"
                                src="/assets/subscription/subscriptionIconBasic.svg"
                                alt=""
                              />
                            </label>
                          </div>
                        </div>
                        {!(
                          selectIcon.base ||
                          selectIcon.premier ||
                          selectIcon.pro
                        ) && (
                          <div className="text-red rtl:font-almarai text-sm  pb-3">
                            {iconError &&
                              t(`SUBSCRIPTION_PORTAL.SELECT_ANY_ONE_ICON`)}
                          </div>
                        )}
                      </div>
                      <div className="border-b border-borderBlack pb-8">
                        <h1 className="font-satoshi rtl:font-almarai font-bold text-20 text-textBlack pt-8 pb-7">
                          {t('SUBSCRIPTION_PORTAL.CORE_FEATURES')}
                          <span className="text-red rtl:font-almarai cursor-text">
                            *
                          </span>
                        </h1>
                        <ul className="flex flex-col gap-4 text-gray items-start">
                          {coreFeaturesData &&
                            coreFeaturesData?.map(
                              (item: IFeaturesPayload, index: number) => (
                                <li
                                  className={` rtl:font-almarai" flex  `}
                                  key={index}
                                >
                                  <div className={`flex  items-start pt-1.5 `}>
                                    <Input
                                      checkedToggle={values.coreFeatures
                                        ?.map((coreFeaturesValue) =>
                                          coreFeaturesValue.toString()
                                        )
                                        ?.includes(`${item.id as number}`)}
                                      id={`coreFeatures${index}`}
                                      name={`coreFeatures`}
                                      value={item.id as number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type={'checkbox'}
                                      classname={''}
                                      errors={errors.coreFeatures}
                                      touched={touched.coreFeatures}
                                    />
                                  </div>

                                  <label
                                    className="ltr:pl-2 rtl:pr-2 font-general rtl:font-almarai font-normal text-21 "
                                    htmlFor={`coreFeatures${index} `}
                                  >
                                    {' '}
                                    {lang === 'en'
                                      ? item.nameInEnglish
                                      : item.nameInArabic}
                                  </label>
                                </li>
                              )
                            )}
                        </ul>
                        <div className="pt-3 text-red rtl:font-almarai text-sm  inline-block">
                          {errors.coreFeatures && touched.coreFeatures
                            ? t(`SUBSCRIPTION_PORTAL.${errors?.coreFeatures}`)
                            : ''}
                        </div>
                      </div>
                      <div className="py-8 border-b border-borderBlack  gap-4 flex flex-col">
                        <div>
                          <div className="flex  items-center">
                            <label
                              htmlFor="sessionCount"
                              className={` font-satoshi rtl:font-almarai text-21 w-[150px] ${
                                values?.coreFeatures?.includes('4')
                                  ? ''
                                  : 'text-borderBlack  cursor-not-allowed'
                              }`}
                            >
                              {t('SUBSCRIPTION_PORTAL.SESSION_COUNT')}
                            </label>

                            <div className="w-20">
                              {' '}
                              <Input
                                disabled={!values?.coreFeatures?.includes('4')}
                                id={'sessionCount'}
                                name={'sessionCount'}
                                value={
                                  !values?.coreFeatures?.includes('4')
                                    ? ''
                                    : (values.sessionCount as number)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type={'number'}
                                placeholder={'sessionCount'}
                                classname={`input_login ${
                                  values?.coreFeatures?.includes('4')
                                    ? 'border-gray'
                                    : 'border-borderBlack  cursor-not-allowed'
                                }   w-10`}
                                errors={errors.sessionCount}
                                touched={touched.sessionCount}
                              />
                            </div>
                          </div>
                          <div className="pt-2">
                            <div
                              className={` text-red rtl:font-almarai text-sm  inline-block${
                                errors.sessionCount
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              }  `}
                            >
                              {errors.sessionCount && touched.sessionCount
                                ? t(
                                    `SUBSCRIPTION_PORTAL.${errors.sessionCount}`
                                  )
                                : ''}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center">
                            <label
                              htmlFor="assessmentCount"
                              className={` font-satoshi rtl:font-almarai text-21 w-[150px] ${
                                values?.coreFeatures?.includes('4')
                                  ? ''
                                  : 'text-borderBlack  cursor-not-allowed'
                              }`}
                            >
                              {t('SUBSCRIPTION_PORTAL.ASSESSMENT_COUNT')}
                            </label>
                            <div className="w-20">
                              {' '}
                              <Input
                                disabled={!values?.coreFeatures?.includes('5')}
                                id={'assessmentCount'}
                                name={'assessmentCount'}
                                value={
                                  !values?.coreFeatures?.includes('5')
                                    ? ''
                                    : (values.assessmentCount as number)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type={'number'}
                                placeholder={'assessmentCount'}
                                classname={`input_login ${
                                  values?.coreFeatures?.includes('5')
                                    ? 'border-gray'
                                    : 'border-borderBlack  cursor-not-allowed'
                                }   w-10`}
                                errors={errors.assessmentCount}
                                touched={touched.assessmentCount}
                              />
                            </div>
                          </div>
                          <div className=" pt-2">
                            <div
                              className={` text-red rtl:font-almarai text-sm  inline-block${
                                errors.assessmentCount
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              }  `}
                            >
                              {errors.assessmentCount &&
                              touched.assessmentCount &&
                              values?.coreFeatures?.includes('5')
                                ? t(
                                    `SUBSCRIPTION_PORTAL.${errors.assessmentCount}`
                                  )
                                : ''}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center ">
                            <label
                              htmlFor="packageCount"
                              className={` font-satoshi rtl:font-almarai text-21 w-[150px] ${
                                values?.coreFeatures?.includes('4')
                                  ? ''
                                  : 'text-borderBlack  cursor-not-allowed'
                              }`}
                            >
                              {t('SUBSCRIPTION_PORTAL.PACKAGE_COUNT')}
                            </label>
                            <div className="w-20">
                              {' '}
                              <Input
                                disabled={!values?.coreFeatures?.includes('6')}
                                id={'packageCount'}
                                name={'packageCount'}
                                value={
                                  !values?.coreFeatures?.includes('6')
                                    ? ''
                                    : (values.packageCount as number)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type={'number'}
                                placeholder={'packageCount'}
                                classname={`input_login ${
                                  values?.coreFeatures?.includes('6')
                                    ? 'border-gray'
                                    : 'border-borderBlack  cursor-not-allowed'
                                }   w-10`}
                                errors={errors.packageCount}
                                touched={touched.packageCount}
                              />
                            </div>
                          </div>
                          <div className="pt-2">
                            <div
                              className={` text-red rtl:font-almarai text-sm  inline-block${
                                errors.packageCount
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              } `}
                            >
                              {errors.packageCount &&
                              touched.packageCount &&
                              values?.coreFeatures?.includes('6')
                                ? t(
                                    `SUBSCRIPTION_PORTAL.${errors.packageCount}`
                                  )
                                : ''}
                            </div>
                          </div>
                        </div>
                        {values?.sessionCount &&
                          !values?.coreFeatures?.includes('4')}
                      </div>

                      <div className=" pb-8">
                        <h1 className="font-satoshi rtl:font-almarai font-bold text-20 text-textBlack pt-8 pb-7">
                          {t(
                            'SUBSCRIPTION_PORTAL.ADDITIONAL_FEATURES_INCLUDED'
                          )}
                        </h1>
                        <ul className="flex flex-col gap-4 ite number[])ms-start">
                          {additionalFeaturesData &&
                            additionalFeaturesData.map(
                              (item: IFeaturesPayload, index: number) => (
                                <li
                                  className={` text-gray 
                                `}
                                  key={index}
                                >
                                  <Input
                                    checkedToggle={values.additionalFeatures
                                      ?.map((additionalFeaturesValue) =>
                                        additionalFeaturesValue.toString()
                                      )
                                      ?.includes(`${item.id as number}`)}
                                    id={`additionalFeatures${index}`}
                                    name={'additionalFeatures'}
                                    value={item.id as number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type={'checkbox'}
                                    classname={''}
                                    errors={undefined}
                                    touched={undefined}
                                  />
                                  <label
                                    className="pl-2 font-general rtl:font-almarai font-normal text-21 "
                                    htmlFor={`additionalFeatures${index} `}
                                  >
                                    {' '}
                                    {lang === 'en'
                                      ? item.nameInEnglish
                                      : item.nameInArabic}
                                  </label>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className=" border-t border-borderBlack mt-8 h-[100px]">
                    <div className="h-full flex justify-between items-center ">
                      <Button
                        buttonType="button"
                        functionName={subscriptionFunction}
                        className={
                          'text-textBlack  text-18 font-satoshi rtl:font-almarai font-bold '
                        }
                        data={t('SUBSCRIPTION_PORTAL.CANCEL')}
                      />
                      <Button
                        buttonType="submit"
                        className={
                          'bg-primary  text-white rounded text-18 font-satoshi rtl:font-almarai font-bold py-4 px-[5vw] lg:px-[75px]'
                        }
                        data={t('SUBSCRIPTION_PORTAL.SUBMIT')}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SubscriptionForm;
