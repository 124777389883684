import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IButton {
  className: string;
  functionName?: any;
  data: string;
  valueName?: string;
  paramsValue?: string | null;
  category?: string;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
}

const Button: FC<IButton> = ({
  className,
  functionName,
  data,
  category,
  buttonType,
  ...rest
}): JSX.Element => {
  const [t] = useTranslation();

  return (
    <div>
      <button
        type={buttonType}
        onClick={functionName && (() => functionName())}
        className={` ${className} `}
      >
        {category ? '' : data}
        {category === 'coach' ? t(`COACH_DIRECTORY.${data}`) : ''}
        {category === 'client' ? t(`${data}`) : ''}
      </button>
    </div>
  );
};

export default Button;
