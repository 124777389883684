import { apiHeader } from '../constants/apiHeader';
import productConstants from '../constants/products';
import { deleteApi, get, post } from './apiService';

export const fetchProductsDetails = async <T>(data: {
  [key: string]: string;
}) => {
  return get(
    `${productConstants.ALL}?activityStatus=LIVE`,
    data,
    apiHeader.APPLICATION_JSON
  );
};

export const fetchServiceDetails = async <T>({
  id,
  serviceType,
}: {
  id: number;
  serviceType: string;
}) => {
  const apiEndPointSelect = (value: string) => {
    let temp;
    switch (value) {
      case 'SESSION':
        temp = productConstants.SESSION + '/' + id;
        break;
      case 'PACKAGE':
        temp = productConstants.PACKAGES + '/' + id;
        break;
      case 'ASSESSMENT':
        temp = productConstants.ASSESSMENTS + '/' + id;
        break;
    }
    return temp;
  };
  return get(
    apiEndPointSelect(serviceType) as string,
    [],
    apiHeader.APPLICATION_JSON
  );
};
export const fetchServiceDelete = async <T>({
  id,
  service,
}: {
  id: number;
  service: string;
}) => {
  const apiEndPointSelect = (value: string) => {
    let temp;
    switch (value) {
      case 'SESSION':
        temp = productConstants.SESSION + '/' + id;
        break;
      case 'PACKAGE':
        temp = productConstants.PACKAGES + '/' + id;
        break;
      case 'ASSESSMENT':
        temp = productConstants.ASSESSMENTS + '/' + id;
        break;
    }
    return temp;
  };

  return deleteApi(
    apiEndPointSelect(service as string) as string,
    apiHeader.APPLICATION_JSON
  );
};
export const fetchServiceApprove = async <T>({
  id,
  params,
}: {
  id: number;
  params: { [key: string]: string };
}) => {
  return post(
    productConstants.ACCEPT_REJECT + '/' + id,
    params,
    apiHeader.APPLICATION_JSON
  );
};

export const fetchServiceReject = async <T>({
  id,
  params,
}: {
  id: number;
  params: { [key: string]: string };
}) => {
  return post(
    productConstants.ACCEPT_REJECT + '/' + id,
    params,
    apiHeader.APPLICATION_JSON
  );
};
