import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILogInAuth } from '../../../interfaces/index';
import {
  authentication,
  captchaGetApi,
  verifyCaptcha,
} from '../../../services/loginApi';
import { randomFontSize } from '../../../_helpers/index';
import { setAccessToken } from '../../../_helpers/token';
interface IAlertSlice {
  alertSuccess?: boolean;
  alertError?: boolean;
  alertPayload: string;
}

const initialState: IAlertSlice = {
  alertSuccess: false,
  alertError: false,
  alertPayload: '',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    alertReset: (state) => {
      state.alertSuccess = false;
      (state.alertError = false), (state.alertPayload = '');
    },
    alertActive: (state, action: PayloadAction<IAlertSlice>) => {
      state.alertSuccess = action.payload?.alertSuccess;
      state.alertError = action.payload?.alertError;
      state.alertPayload = action.payload?.alertPayload;
    },
  },
});

export const { alertReset, alertActive } = alertSlice.actions;

export default alertSlice.reducer;
