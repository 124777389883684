export const getAccessToken = () => {
  const token = localStorage.getItem('token')?.slice(1, -1);
  return token;
};

export const setAccessToken = (token: string) => {
  localStorage.setItem('token', JSON.stringify(token));
};
export const clearLocalStorage = () => {
  localStorage.clear();
};
