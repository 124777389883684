import { Field, FieldProps, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectClientProfile } from '../../../coachSlices/profile';
import {
  clearSessionImageFileFormData,
  sessionInfo,
  setSessionFormData,
  setSessionImageFileFormData,
} from '../../../coachSlices/sessionSlice';
import Input from '../../../components/coachComponents/inputs';
import { listInterface } from '../../../interfaces/coachInterfaces/sessionInterface';
import { useAppSelector } from '../../../_helpers';
import { coachSessionValidationSchema } from '../../../_helpers/coachHelpers';
import Button from '../../coachComponents/buttons';
import SelectDropdown from '../selectDropdown';
import './index.css';

interface IFaqCard {
  sessionFormRef: any;
}

const CreateCoachSession: FC<IFaqCard> = ({ sessionFormRef }): JSX.Element => {
  const dispatch = useDispatch<any>();

  const [t] = useTranslation('');
  const [monthInputValue, setMonthInputValue] = useState('');
  const [hourInputValue, setHourInputValue] = useState('');
  const [languageInputValue, setLanguageInputValue] = useState('');
  const [categoryInputValue, setCategoryInputValue] = useState('');

  const [sessionVideoName, setSessionVideoName] = useState('');
  const [sessionVideoUrl, setSessionVideoUrl] = useState('');
  const {
    sessionFormValue,
    sessionImagefile,
    months,
    hours,
    categories,
    categoriesTag,
  } = useSelector(sessionInfo);

  const { languages } = useSelector(selectClientProfile);
  const { lang } = useAppSelector((state) => state.language);

  const languageListEn: { label: ''; value: '' }[] = [];
  const languageListAr: { label: ''; value: '' }[] = [];

  languages?.map((item: any, index: number) => {
    languageListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    languageListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  const hourDuration: { label: string; value: number }[] = [];
  const hoursOption: number[] = [30, 60, 90, 120, 150];
  hoursOption?.map((item: number) => {
    hourDuration.push({
      label: `${item} mins`,
      value: item,
    });
  });
  const categoryEn: { label: string; value: string }[] = [];
  const categoryAr: { label: string; value: string }[] = [];

  const categoryTagEn: { label: string; value: string }[] = [];

  categories?.map((item: listInterface) => {
    categoryEn.push({
      label: item?.nameInEnglish,
      value: item?.nameInEnglish,
    });
    categoryAr.push({
      label: item?.nameInArabic,
      value: item?.nameInEnglish,
    });
  });
  categoriesTag?.map((item: any) => {
    categoryTagEn.push({
      label: item,
      value: item,
    });
  });
  const retakePeriodOptions: { label: string; value: string }[] = [];
  const array = Array.from({ length: 90 }, (_, index) => index + 1);
  array?.map((item: any) => {
    retakePeriodOptions.push({
      label: `${item} ${t('PREFORMANCE.DAYS')}`,
      value: item,
    });
  });
  const handleFormSubmit = (data: any) => {
    // eslint-disable-next-line no-empty
    if (data.description !== '<p><br></p>') {
    }
  };
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'right'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };
  const formats = ['bold', 'italic', 'list', 'bullet'];

  const handleMonthInputChange = (event: any) => {
    setMonthInputValue(event);
  };
  const handleHourInputChange = (event: any) => {
    setHourInputValue(event);
  };
  const handleLanguageInputChange = (event: any) => {
    setLanguageInputValue(event);
  };
  const handleCategoryInputChange = (event: any) => {
    setCategoryInputValue(event);
  };
  const handleTagsInputChange = (event: any, setFieldValue: any) => {
    setFieldValue('categoryTags', event);
  };

  const uploadImageFiles = (e: any) => {
    let fileUrl = '';
    fileUrl = URL.createObjectURL(e.target.files[0]);
    dispatch(
      setSessionImageFileFormData({
        url: fileUrl,
        name: e.target.files[0].name,
        file: Array.from(e.target.files),
        fileId: '',
      })
    );
  };

  const uploadVideoFiles = (e: any) => {
    let fileUrl = '';
    fileUrl = URL.createObjectURL(e.target.files[0]);
    setSessionVideoUrl(fileUrl);
    setSessionVideoName(e.target.files[0].name);
  };
  const removeImage = () => {
    dispatch(clearSessionImageFileFormData());
  };
  const removeVideo = () => {
    setSessionVideoUrl('');
    setSessionVideoName('');
  };
  // const getMonthLabel = (name: string) => {
  //   if (lang === 'en') {
  //     const item: tagsInterface[] = monthEn.filter(
  //       (item: any) => item.value === name
  //     );
  //     return item[0]?.label;
  //   }
  //   if (lang === 'ar') {
  //     const item: tagsInterface[] = monthAr.filter(
  //       (item: any) => item.value === name
  //     );
  //     return item[0]?.label;
  //   }
  // };
  // const getHoursLabel = (name: string) => {
  //   if (lang === 'en') {
  //     const item: tagsInterface[] = hoursEn.filter(
  //       (item: any) => item.value === name
  //     );
  //     return item[0]?.label;
  //   }
  //   if (lang === 'ar') {
  //     const item: tagsInterface[] = hoursAr.filter(
  //       (item: any) => item.value === name
  //     );
  //     return item[0]?.label;
  //   }
  // };

  return (
    <div className="   session-form-container  ">
      <div className="w-full font-satoshi rtl:font-almarai font-bold text-[34px]">
        {t('COACH_SESSION.SESSION_LANDING')}
      </div>
      <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_SESSION.SESSION_LANDING_DESC')}
      </div>
      <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_SESSION.BASIC_INFO')}
      </div>
      <Formik
        innerRef={sessionFormRef}
        initialValues={sessionFormValue}
        validationSchema={coachSessionValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          dispatch(setSessionFormData(values));

          return (
            <Form>
              <Input
                id="FormTitle"
                type="text"
                name="title"
                placeholder={t('COACH_SESSION.TITLE')}
                onBlur={handleBlur}
                error={touched.title && 1}
                onChange={handleChange}
                value={values.title}
                errormessage={errors.title as string}
              />
              <Input
                id="FormSubTitle"
                type="text"
                name="subTitle"
                placeholder={t('COACH_SESSION.SUB_TITLE')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.subTitle && 1}
                value={values.subTitle}
                errormessage={errors.subTitle as string}
              />
              <Field name="description">
                {(field: FieldProps) => (
                  <div className="relative">
                    {field?.field?.value === '<p><br></p>' ||
                    field?.field?.value === '' ? (
                      <span className="text-red rtl:font-almarai top-[11px] ltr:left-[15px] rtl:right-[15px] absolute cursor-text mb-[-5px]">
                        <span className="input-text cursor-text font-general rtl:font-almarai  text-gray text-17  font-normal text-opacity-80">
                          {' '}
                          {t('COACH_SESSION.DESCRIPTION')}
                        </span>{' '}
                        *
                      </span>
                    ) : (
                      <label className="font-bold font-general rtl:font-almarai text-xs text-secondary bg-white absolute ltr:left-3 rtl:right-3 top-[-10px] px-1 transition duration-200 label">
                        {t('COACH_SESSION.DESCRIPTION')}

                        <span className="text-red rtl:font-almarai cursor-text ml-1">
                          *
                        </span>
                      </label>
                    )}

                    <ReactQuill
                      // style={{direction}}
                      value={field?.field?.value}
                      onChange={(e: any) => {
                        setFieldValue(
                          'description',
                          e === '<p><br></p>' ? '' : e
                        );
                        setFieldTouched(field?.field?.name);
                      }}
                      className={
                        field.meta.touched &&
                        (field?.field?.value === '<p><br></p>' ||
                          field?.field?.value === '')
                          ? 'description-box-error'
                          : ''
                      }
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />

                    {field.meta.touched &&
                      (field?.field?.value === '<p><br></p>' ||
                        field?.field?.value === '') && (
                        <p className="text-red rtl:font-almarai font-normal text-12 pt-1.5 ">
                          {t('COACH_SESSION.DESCRIPTION_REQUIRED')}
                        </p>
                      )}
                  </div>
                )}
              </Field>
              <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
                {t('COACH_SESSION.SESSION_DETAILS')}
              </div>
              <div className="session-dropdown-row ">
                <div className="mb-8">
                  <SelectDropdown
                    isDisabled={true}
                    id="Formduration"
                    isSearchable={false}
                    name="duration"
                    placeholder={t('COACH_PACKAGE.DURATION')}
                    options={hourDuration}
                    error={touched.duration && 1}
                    errormessage={errors.duration}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue('duration', opt.value);
                    }}
                    onInputChange={handleMonthInputChange}
                    inputValue={monthInputValue}
                    controlShouldRenderValue={true}
                    optional={0}
                    isValue={values.duration}
                    defaultValue={
                      values.duration !== '' && {
                        label: `${values.duration} ${t('MINS')}`,
                        value: values.duration,
                      }
                    }
                  />
                </div>
                <div className="mb-8">
                  <SelectDropdown
                    isDisabled={true}
                    id="FormretakePeriod"
                    name="retakePeriod"
                    placeholder={t('COACH_SESSION.TIME_GAP')}
                    isSearchable={false}
                    options={retakePeriodOptions}
                    error={touched.retakePeriod && 1}
                    errormessage={errors.retakePeriod}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue('retakePeriod', opt.value);
                    }}
                    onInputChange={handleHourInputChange}
                    inputValue={hourInputValue}
                    controlShouldRenderValue={true}
                    optional={0}
                    isValue={values.retakePeriod}
                    defaultValue={
                      values.retakePeriod !== '' && {
                        label: `${values.retakePeriod} ${t(
                          'PREFORMANCE.DAYS'
                        )}`,
                        value: values.retakePeriod,
                      }
                    }
                  />
                </div>
                <div className="mb-8">
                  <SelectDropdown
                    id="FormLanguage"
                    name="language"
                    placeholder={t('COACH_SESSION.LANGUAGE')}
                    isSearchable={false}
                    options={lang === 'en' ? languageListEn : languageListAr}
                    error={touched.language && 1}
                    errormessage={errors.language}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue('language', opt.value);
                    }}
                    onInputChange={handleLanguageInputChange}
                    inputValue={languageInputValue}
                    controlShouldRenderValue={true}
                    isValue={values.language}
                    optional={0}
                    defaultValue={
                      values.language !== '' && {
                        label: values.language,
                        value: values.language,
                      }
                    }
                  />
                </div>
              </div>

              <div className="mb-8">
                <SelectDropdown
                  id="FormCategory"
                  name="category"
                  placeholder={t('COACH_SESSION.CATEGORY')}
                  isSearchable={false}
                  options={lang === 'en' ? categoryEn : categoryAr}
                  error={touched.category && 1}
                  errormessage={errors.category}
                  onBlur={handleBlur}
                  onChange={(opt: any) => {
                    setFieldValue('category', opt.value);
                  }}
                  onInputChange={handleCategoryInputChange}
                  inputValue={categoryInputValue}
                  controlShouldRenderValue={true}
                  isValue={values.category}
                  optional={0}
                  defaultValue={
                    values.category !== '' && {
                      label: values.category,
                      value: values.category,
                    }
                  }
                />
              </div>
              <div className="react-multi-react-tags">
                <div className="mb-8">
                  <SelectDropdown
                    id="Formtags"
                    isMulti={true}
                    isCreatable={true}
                    isSearchable={true}
                    options={categoryTagEn}
                    optional={1}
                    dropDown={true}
                    name="categoryTags"
                    placeholder={t('COACH_SESSION.TAGS')}
                    onBlur={handleBlur}
                    inn={
                      values.categoryTags?.length > 0 &&
                      values.categoryTags[0]?.value
                    }
                    onChange={(opt: any) => {
                      handleTagsInputChange(opt, setFieldValue);
                    }}
                    className="my-3"
                    isValue={
                      values.categoryTags?.length > 0 &&
                      values.categoryTags[0]?.value
                    }
                    defaultValue={
                      values.categoryTags?.length > 0 && values.categoryTags
                    }
                    controlShouldRenderValue={true}
                  />
                </div>
              </div>

              <div
                id="submitBtm"
                className="flex justify-center items-center mt-2"
              >
                <Button type="submit" className="hidden">
                  <div className="loader" />
                  {t('LOGIN.LOG_IN')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_SESSION.SESSION_IMAGE')}
      </div>
      <div className=" session-image-container ">
        {sessionImagefile.url === '' ? (
          <div className=" session-image-default">
            <img
              className=""
              src="/assets/default-img-icon.svg"
              alt="bell-icon"
            />
          </div>
        ) : (
          <div className=" session-image h-56 relative">
            <div
              onClick={removeImage}
              className="w-5 cursor-pointer h-5 bg-[#ffffffbf] absolute top-[10px] ltr:right-[10px] rtl:left-[10px] rounded-[50%] flex justify-center items-center"
            >
              <img src="/assets/subscriptionPage/plusIcon.svg" alt="" />
            </div>
            <img
              className="session-image h-56 object-cover rounded"
              src={sessionImagefile.url}
              alt="bell-icon"
            />
          </div>
        )}
        <div className="session-image-desc rtl:font-almarai">
          {t('COACH_SESSION.UPLOAD_IMAGE_TXT')}

          <div className=" session-upload">
            <label
              // onClick={uploadPicHandler('sessionImageUploadDiv')}
              htmlFor="sessionImageUploadDiv"
              className="bg-[#9EE0E6] !cursor-pointer border-[#9EE0E6] text-black btn-small w-fit"
            >
              {t('COACH_SESSION.UPLOAD_IMAGE')}
            </label>
            <input
              className="hidden"
              id="sessionImageUploadDiv"
              accept="image/png, image/jpeg"
              type="file"
              onChange={(e: any) => uploadImageFiles(e)}
            />
            <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
              {sessionImagefile.name !== ''
                ? sessionImagefile.name
                : t('COACHING_DETAILS.NO_IMAGE_ADDED')}
            </span>
          </div>
        </div>
      </div>
      <div className="session-upload-mobile mt-4">
        <label
          // onClick={uploadPicHandler('sessionImageUploadDiv')}
          htmlFor="sessionImageUploadDiv"
          className="bg-[#9EE0E6] !cursor-pointer border-[#9EE0E6] text-black btn-small w-fit"
        >
          {t('COACH_SESSION.UPLOAD_IMAGE')}
        </label>
        <input
          className="hidden"
          id="sessionImageUploadDiv"
          accept="image/png, image/jpeg"
          type="file"
          onChange={(e: any) => uploadImageFiles(e)}
        />
        <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
          {sessionImagefile.name !== ''
            ? sessionImagefile.name
            : t('COACHING_DETAILS.NO_IMAGE_ADDED')}
        </span>
      </div>
      <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_SESSION.UPLOAD_VIDEO')}
        <span className="mx-1 font-general rtl:font-almarai font-thin tex-base text-[#687E8E]">
          {t('COACH_SESSION.OPTIONAL')}
        </span>
      </div>
      <div className="session-image-container ">
        {/* <div className="w-[30rem] h-56 rounded bg-[#F6F8F5] flex justify-center items-center"> */}
        {sessionVideoUrl === '' ? (
          <div className="session-image-default ">
            <img
              className=""
              src="/assets/default-img-icon.svg"
              alt="bell-icon"
            />
          </div>
        ) : (
          <div className="session-image h-56 relative">
            <div
              onClick={removeVideo}
              className="w-5 z-50 cursor-pointer h-5 bg-[#ffffffbf] absolute top-[10px] ltr:right-[10px] rtl:left-[10px] rounded-[50%] flex justify-center items-center"
            >
              <img src="/assets/subscriptionPage/plusIcon.svg" alt="" />
            </div>
            <video
              className="session-image h-56 object-cover z-10 rounded"
              controls
            >
              <source src={sessionVideoUrl} />
            </video>
          </div>
        )}

        {/* </div> */}
        <div className=" session-image-desc rtl:font-almarai">
          {t('COACH_SESSION.UPLOAD_VIDEO_TXT')}
          <div className="session-upload">
            <label
              htmlFor="sessionVideoUploadDiv"
              className="bg-[#9EE0E6] cursor-pointer border-[#9EE0E6] text-black btn-small w-fit"
            >
              {t('COACH_SESSION.UPLOAD_VIDEO')}
            </label>
            <input
              className="hidden"
              id="sessionVideoUploadDiv"
              // accept="image/png, image/jpeg"
              accept="video/*"
              type="file"
              onChange={(e: any) => uploadVideoFiles(e)}
            />
            <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
              {sessionVideoName !== ''
                ? sessionVideoName
                : t('COACHING_DETAILS.NO_VIDEO_ADDED')}
            </span>
          </div>
        </div>
      </div>
      <div className="session-upload-mobile mt-4">
        <label
          htmlFor="sessionVideoUploadDiv"
          className="bg-[#9EE0E6] cursor-pointer border-[#9EE0E6] text-black btn-small w-fit"
        >
          {t('COACH_SESSION.UPLOAD_VIDEO')}
        </label>
        <input
          className="hidden"
          id="sessionVideoUploadDiv"
          accept="video/*"
          type="file"
          onChange={(e: any) => uploadVideoFiles(e)}
        />
        <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
          {sessionVideoName !== ''
            ? sessionVideoName
            : t('COACHING_DETAILS.NO_VIDEO_ADDED')}
        </span>
      </div>
    </div>
  );
};

export default CreateCoachSession;
