import { Route, Routes } from 'react-router-dom';
import { routeConstants } from '../constants/index';
import DashBoard from '../pages/dashboard';
import ForgotPassword from '../pages/forgotPassword';
import Info from '../pages/info';
import Login from '../pages/login';
import SetNewPassword from '../pages/setNewPasword';
import IndexRoute from './indexRoute';
import ProtectedRoute from './protectedRoute';
import PublicRoute from './publicRoute';
const RouteContainer = () => {
  const routeProperty = [
    {
      path: routeConstants.BASE,
      component: <IndexRoute />,
    },
    {
      path: routeConstants.LOGIN,
      component: (
        <PublicRoute>
          <Login />
        </PublicRoute>
      ),
    },
    {
      path: routeConstants.FORGOT_PASSWORD,
      component: (
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      ),
    },
    {
      path: routeConstants.SET_NEW_PASSWORD,
      component: (
        <PublicRoute>
          <SetNewPassword />
        </PublicRoute>
      ),
    },
    {
      path: routeConstants.DASHBOARD_BASE_ROUTE,
      component: (
        <ProtectedRoute>
          <DashBoard />
        </ProtectedRoute>
      ),
    },
    {
      path: '/kun/*',
      component: (
        <ProtectedRoute>
          <DashBoard />
        </ProtectedRoute>
      ),
    },
    {
      path: routeConstants.INFO,
      component: (
        <PublicRoute>
          <Info />
        </PublicRoute>
      ),
    },

    {
      path: '*',
      component: <h1>{routeConstants.PAGE_NOT_FOUND}</h1>,
    },
  ];
  return (
    <Routes>
      {routeProperty.map((route) => {
        return (
          <Route key={route.path}>
            {route.path !== '/kun/' ? (
              <Route path={route.path} element={route.component} />
            ) : (
              <Route path={route.path} element={route.component}>
                <Route
                  path={routeConstants.NAVIGATION}
                  element={<DashBoard />}
                ></Route>
              </Route>
            )}
          </Route>
        );
      })}
    </Routes>
  );
};

export default RouteContainer;
