import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Header, Input } from '../../components/index';
import { alertActive } from '../../components/notification/slices';
import { forgotPasswordConstants, routeConstants } from '../../constants/index';
import { IForgotPasswordForm } from '../../interfaces/index';
import { useAppSelector } from '../../_helpers';
import { forgotPasswordSchema } from '../../_helpers/validation';
import { forgotPassword } from './slice';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { isSuccess, isLoading, isError, forgotPasswordPayLoad } =
    useAppSelector((state) => state.forgotPassword);
  const handleForgotPassword = ({ email }: IForgotPasswordForm) => {
    ('');
    dispatch(
      forgotPassword({
        email,
        userType: forgotPasswordConstants.SUPER_ADMIN,
      })
    );
  };
  useEffect(() => {
    isSuccess
      ? (dispatch(
          alertActive({
            alertSuccess: isSuccess,
            alertPayload: forgotPasswordPayLoad,
          })
        ),
        navigate(routeConstants.LOGIN))
      : '';
    isError
      ? dispatch(
          alertActive({
            alertError: isError,
            alertPayload: forgotPasswordPayLoad,
          })
        )
      : '';
  }, [isSuccess, isError]);

  const redirect = () => {
    navigate(routeConstants.LOGIN);
  };
  return (
    <div className="relative bg-logo bg-cover h-screen w-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 ">
      <div className="z-10  absolute top-5  right-[15vw] rtl:right-[85vw]  ">
        <div className=" flex absolute  ">
          <Header />
        </div>
      </div>

      <div className="bg-white pb-8 flex w-[820px] h-fit rounded-lg  overflow-hidden items-center ">
        <div className=" flex flex-col items-center justify-center w-1/2  ">
          <div>
            <img src="/assets/login/logo.svg" alt="logo" />
          </div>
        </div>
        <div className="pt-12 mr-8 rtl:ml-8 rtl:mr-0  w-1/2">
          <div className="flex flex-col justify-items-start items-start justify-center ">
            <div className="pb-4">
              <label className="mt-6 text-center  font-bold text-textBlack text-28 font-satoshi rtl:font-almarai  ">
                {t('FORGOT_PASSWORD.PASSWORD_RECOVERY')}{' '}
                <div className="    flex "></div>
              </label>
            </div>
            <div className="text-gray font-general rtl:font-almarai font-normal  ">
              {t('FORGOT_PASSWORD.CONTENT')}
            </div>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={forgotPasswordSchema}
              onSubmit={handleForgotPassword}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                return (
                  <Form
                    onSubmit={handleSubmit}
                    className="mt-5 w-full space-y-6l relative"
                  >
                    <div className="rounded-md ">
                      <div>
                        <div className="relative cursor-pointer">
                          <Input
                            id={'formEmail'}
                            name={'email'}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={'email'}
                            placeholder={'Input text'}
                            classname={
                              'input_login placeholder-gray-300 mt-5 h-11 placeholder:invisible'
                            }
                            errors={errors.email}
                            touched={touched.email}
                          />

                          <label
                            htmlFor="formEmail"
                            className="label-input-login "
                          >
                            {t('FORGOT_PASSWORD.EMAIL')}
                            <span className="text-red rtl:font-almarai cursor-text">
                              *
                            </span>
                          </label>
                          <div className=" px-4">
                            <div
                              className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                errors.email && touched.email
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              } `}
                            >
                              {errors.email && touched.email
                                ? t(`FORM.${errors.email}`)
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative">
                      <div className="absolute top-8 ltr:left-24 rtl:right-24 z-1000">
                        {isLoading && (
                          <ClipLoader
                            color={'white'}
                            loading={true}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="btn w-full text-white mt-7 py-2 rounded  bg-primary font-satoshi rtl:font-almarai font-bold "
                      >
                        {t('FORGOT_PASSWORD.RECOVER_PASSWORD')}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <button
              type="submit"
              onClick={() => redirect()}
              className="btn w-full text-black  font-normal hover:text-textBlack mt-4 py-2 rounded  font-satoshi rtl:font-almarai hover:font-bold "
            >
              {t('FORGOT_PASSWORD.CANCEL')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
