import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { alertActive } from '../../../components/notification/slices';
import { IForgotPassword } from '../../../interfaces/index';
import { forgotPasswordAuthentication } from '../../../services/forgotPasswordApi';

const initialState: IForgotPassword = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  forgotPasswordPayLoad: '',
};
interface userForgotPassword {
  email: string;
  userType: string;
}
export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: initialState,
  reducers: {
    resetForggotPassword: (state) => {
      state.isSuccess = false;
      state.isLoading = false;
      state.isError = false;
      state.forgotPasswordPayLoad = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.forgotPasswordPayLoad = action.payload?.message;
      })
      .addCase(forgotPassword.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.forgotPasswordPayLoad = action.payload?.data?.message;
      });
  },
});

export const forgotPassword = createAsyncThunk(
  'forgotPasswordauthenticateUser',
  async (userForgotPassword: userForgotPassword, thunkAPI) => {
    try {
      const forgotPasswordPayload = await forgotPasswordAuthentication(
        userForgotPassword
      );
      return forgotPasswordPayload;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const { resetForggotPassword } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
