import { Field, FieldProps, Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../_helpers';
import { coachPackageValidationSchema } from '../../../_helpers/coachHelpers';
import {
  clearPackageImageFileFormData,
  packageInfo,
  setPackageFormData,
  setPackageImageFileFormData,
} from '../../../coachSlices/packageSlice';
import {
  listLanguages,
  selectClientProfile,
} from '../../../coachSlices/profile';
import {
  listCoachingTiming,
  sessionInfo,
} from '../../../coachSlices/sessionSlice';
import { tagsInterface } from '../../../interfaces/coachInterfaces/sessionInterface';
import Input from '../../clientComponents/inputs';
import Button from '../buttons';
import SelectDropdown from '../selectDropdown';

interface IFaqCard {
  sessionFormRef: any;
}

const CreateCoachPackage: FC<IFaqCard> = ({ sessionFormRef }): JSX.Element => {
  const dispatch = useDispatch<any>();

  const [t] = useTranslation();
  const [monthInputValue, setMonthInputValue] = useState('');
  const [languageInputValue, setLanguageInputValue] = useState('');
  const [packageVideoName, setPackageVideoName] = useState('');
  const [packageVideoUrl, setPackageVideoUrl] = useState('');

  const { packageFormValue, packageImagefile, duration } =
    useSelector(packageInfo);
  const { languages } = useSelector(selectClientProfile);
  const { lang } = useAppSelector((state) => state.language);
  const { categoriesTag } = useSelector(sessionInfo);
  useEffect(() => {
    dispatch(listLanguages());
    dispatch(listCoachingTiming());
  }, []);
  const languageListEn: { label: ''; value: '' }[] = [];
  const languageListAr: { label: ''; value: '' }[] = [];

  languages?.map((item: any, index: number) => {
    languageListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    languageListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  const durationtEn: { label: string; value: string }[] = [];
  const durationAr: { label: string; value: string }[] = [];

  duration?.map((item: any, index: number) => {
    durationtEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    durationAr.push({
      label: item.nameInArabic,
      value: item.nameInEnglish,
    });
  });
  const categoryTagEn: { label: string; value: string }[] = [];
  categoriesTag?.map((item: any) => {
    categoryTagEn.push({
      label: item,
      value: item,
    });
  });
  const handleFormSubmit = (data: any) => {
    // eslint-disable-next-line no-empty
    if (data.description !== '<p><br></p>') {
    }
  };
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };
  const formats = ['bold', 'italic', 'list', 'bullet'];

  const handleMonthInputChange = (event: any) => {
    setMonthInputValue(event);
  };

  const handleLanguageInputChange = (event: any) => {
    setLanguageInputValue(event);
  };
  const handleTagsInputChange = (event: any, setFieldValue: any) => {
    setFieldValue('categoryTags', event);
  };

  const uploadImageFiles = (e: any) => {
    let fileUrl = '';
    fileUrl = URL.createObjectURL(e.target.files[0]);
    dispatch(
      setPackageImageFileFormData({
        url: fileUrl,
        name: e.target.files[0].name,
        file: Array.from(e.target.files),
        fileId: '',
      })
    );
  };

  const uploadVideoFiles = (e: any) => {
    let fileUrl = '';
    fileUrl = URL.createObjectURL(e.target.files[0]);
    setPackageVideoUrl(fileUrl);
    setPackageVideoName(e.target.files[0].name);
  };
  const removeImage = () => {
    dispatch(clearPackageImageFileFormData());
  };
  const removeVideo = () => {
    setPackageVideoUrl('');
    setPackageVideoName('');
  };
  const getDurationLabel = (name: string) => {
    if (lang === 'en') {
      const item: tagsInterface[] = durationtEn.filter(
        (item: any) => item.value === name
      );
      return item[0]?.label;
    }
    if (lang === 'ar') {
      const item: tagsInterface[] = durationAr.filter(
        (item: any) => item.value === name
      );
      return item[0]?.label;
    }
  };
  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-almarai font-bold text-[34px]">
        {t('COACH_PACKAGE.PACKAGE_LANDING')}
      </div>
      <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_PACKAGE.PACKAGE_TEXT')}
      </div>
      <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_SESSION.BASIC_INFO')}
      </div>
      <Formik
        innerRef={sessionFormRef}
        initialValues={packageFormValue}
        validationSchema={coachPackageValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          dispatch(setPackageFormData(values));

          return (
            <Form>
              <Input
                id="FormTitle"
                type="text"
                name="title"
                placeholder={t('COACH_SESSION.TITLE')}
                onBlur={handleBlur}
                error={touched.title}
                onChange={handleChange}
                value={values.title}
                errormessage={errors.title}
              />
              <Input
                id="FormSubTitle"
                type="text"
                name="subTitle"
                placeholder={t('COACH_SESSION.SUB_TITLE')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.subTitle}
                value={values.subTitle}
                errormessage={errors.subTitle}
              />
              <Field name="description">
                {(field: FieldProps) => (
                  <div className="relative">
                    {field?.field?.value === '<p><br></p>' ||
                    field?.field?.value === '' ? (
                      <span className="text-red rtl:font-almarai top-[11px] ltr:left-[15px] rtl:right-[15px] absolute cursor-text mb-[-5px]">
                        <span className="input-text cursor-text font-general rtl:font-almarai  text-gray text-17  font-normal text-opacity-80">
                          {' '}
                          {t('COACH_SESSION.DESCRIPTION')}
                        </span>{' '}
                        *
                      </span>
                    ) : (
                      <label className="font-bold font-general rtl:font-almarai text-xs text-secondary bg-white absolute ltr:left-3 rtl:right-3 top-[-10px] px-1 transition duration-200 label">
                        {t('COACH_SESSION.DESCRIPTION')}
                        <span className="text-red rtl:font-almarai cursor-text ml-1">
                          *
                        </span>
                      </label>
                    )}

                    <ReactQuill
                      value={field?.field?.value}
                      onChange={(e: any) => {
                        setFieldValue(
                          'description',
                          e === '<p><br></p>' ? '' : e
                        );
                        setFieldTouched(field?.field?.name);
                      }}
                      className={
                        field.meta.touched &&
                        (field?.field?.value === '<p><br></p>' ||
                          field?.field?.value === '')
                          ? 'description-box-error'
                          : ''
                      }
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />

                    {field.meta.touched &&
                      (field?.field?.value === '<p><br></p>' ||
                        field?.field?.value === '') && (
                        <p className="text-red rtl:font-almarai font-normal text-12 pt-1.5 ">
                          {t('COACH_SESSION.DESCRIPTION_REQUIRED')}
                        </p>
                      )}
                  </div>
                )}
              </Field>
              <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
                {t('COACH_PACKAGE.PACKAGE_DETAILS')}
              </div>
              {/* <div className="package-dropdown-row "> */}
              <div className="mb-8">
                <SelectDropdown
                  id="FormLanguage"
                  name="language"
                  placeholder={t('COACH_SESSION.LANGUAGE')}
                  options={lang === 'en' ? languageListEn : languageListAr}
                  error={touched.language && 1}
                  isSearchable={false}
                  errormessage={errors.language}
                  onBlur={handleBlur}
                  onChange={(opt: any) => {
                    setFieldValue('language', opt.value);
                  }}
                  onInputChange={handleLanguageInputChange}
                  inputValue={languageInputValue}
                  controlShouldRenderValue={true}
                  isValue={values.language}
                  optional={0}
                  defaultValue={
                    values.language !== '' && {
                      label: values.language,
                      value: values.language,
                    }
                  }
                />
              </div>
              {/* <div className="mb-8">
                  <SelectDropdown
                    id="FormMonth"
                    name="duration"
                    placeholder={t('COACH_PACKAGE.PACKAGE_DURATION')}
                    // options={monthsOption}
                    isSearchable={false}
                    options={lang === 'en' ? durationtEn : durationAr}
                    error={touched.duration && 1}
                    errormessage={errors.duration}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue('duration', opt.value);
                    }}
                    onInputChange={handleMonthInputChange}
                    inputValue={monthInputValue}
                    controlShouldRenderValue={true}
                    optional={0}
                    isValue={values.duration}
                    defaultValue={
                      values.duration !== '' && {
                        label: getDurationLabel(values.duration),
                        value: values.duration,
                      }
                    }
                  />
                </div> */}
              {/* </div> */}
              <div className="react-multi-react-tags">
                <div className="mb-8">
                  <SelectDropdown
                    id="Formtags"
                    isMulti={true}
                    isCreatable={true}
                    options={categoryTagEn}
                    optional={1}
                    dropDown={true}
                    name="categoryTags"
                    placeholder={t('COACH_SESSION.TAGS')}
                    onBlur={handleBlur}
                    inn={values.categoryTags[0]?.value}
                    onChange={(opt: any) => {
                      handleTagsInputChange(opt, setFieldValue);
                    }}
                    className="my-3"
                    isValue={values.categoryTags[0]?.value}
                    defaultValue={
                      values.categoryTags.length > 0 && values.categoryTags
                    }
                    controlShouldRenderValue={true}
                  />
                </div>
              </div>

              <div
                id="submitBtm"
                className="flex justify-center items-center mt-2"
              >
                <Button type="submit" className="hidden">
                  <div className="loader" />
                  {t('LOGIN.LOG_IN')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_PACKAGE.PACKAGE_IMAGE')}
      </div>
      <div className=" session-image-container ">
        {packageImagefile.url === '' ? (
          <div className=" session-image-default">
            <img
              className=""
              src="/assets/default-img-icon.svg"
              alt="bell-icon"
            />
          </div>
        ) : (
          <div className=" session-image h-56 relative">
            <div
              onClick={removeImage}
              className="w-5 cursor-pointer h-5 bg-[#ffffffbf] absolute top-[10px] ltr:right-[10px] rtl:left-[10px] rounded-[50%] flex justify-center items-center"
            >
              <img src="/assets/subscriptionPage/plusIcon.svg" alt="" />
            </div>
            <img
              className="session-image h-56 object-cover rounded"
              src={packageImagefile.url}
              alt="bell-icon"
            />
          </div>
        )}
        <div className="session-image-desc rtl:font-almarai">
          {t('COACH_SESSION.UPLOAD_IMAGE_TXT')}

          <div className=" session-upload">
            <label
              // onClick={uploadPicHandler('sessionImageUploadDiv')}
              htmlFor="sessionImageUploadDiv"
              className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
            >
              {t('COACH_SESSION.UPLOAD_IMAGE')}
            </label>
            <input
              className="hidden"
              id="sessionImageUploadDiv"
              accept="image/png, image/jpeg"
              type="file"
              onChange={(e: any) => uploadImageFiles(e)}
            />
            <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
              {packageImagefile.name !== ''
                ? packageImagefile.name
                : t('COACHING_DETAILS.NO_IMAGE_ADDED')}
            </span>
          </div>
        </div>
      </div>
      <div className="session-upload-mobile mt-4">
        <label
          // onClick={uploadPicHandler('sessionImageUploadDiv')}
          htmlFor="sessionImageUploadDiv"
          className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
        >
          {t('COACH_SESSION.UPLOAD_IMAGE')}
        </label>
        <input
          className="hidden"
          id="sessionImageUploadDiv"
          accept="image/png, image/jpeg"
          type="file"
          onChange={(e: any) => uploadImageFiles(e)}
        />
        <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
          {packageImagefile.name !== ''
            ? packageImagefile.name
            : t('COACHING_DETAILS.NO_IMAGE_ADDED')}
        </span>
      </div>
      <div className="w-full font-satoshi rtl:font-almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_PACKAGE.PACKAGE_VIDEO')}

        <span className="mx-1 font-general rtl:font-almarai font-thin tex-base text-[#687E8E]">
          {t('COACH_SESSION.OPTIONAL')}
        </span>
      </div>
      <div className="session-image-container ">
        {/* <div className="w-[30rem] h-56 rounded bg-[#F6F8F5] flex justify-center items-center"> */}
        {packageVideoUrl === '' ? (
          <div className="session-image-default ">
            <img
              className=""
              src="/assets/default-img-icon.svg"
              alt="bell-icon"
            />
          </div>
        ) : (
          <div className="session-image h-56 relative">
            <div
              onClick={removeVideo}
              className="w-5 z-50 cursor-pointer h-5 bg-[#ffffffbf] absolute top-[10px] ltr:right-[10px] rtl:left-[10px] rounded-[50%] flex justify-center items-center"
            >
              <img src="/assets/subscriptionPage/plusIcon.svg" alt="" />
            </div>
            <video
              className="session-image h-56 object-cover z-10 rounded"
              controls
            >
              <source src={packageVideoUrl} />
            </video>
          </div>
        )}

        {/* </div> */}
        <div className=" session-image-desc rtl:font-almarai">
          {t('COACH_SESSION.UPLOAD_VIDEO_TXT')}
          <div className="session-upload">
            <label
              htmlFor="sessionVideoUploadDiv"
              className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
            >
              {t('COACH_SESSION.UPLOAD_VIDEO')}
            </label>
            <input
              className="hidden"
              id="sessionVideoUploadDiv"
              // accept="image/png, image/jpeg"
              accept="video/*"
              type="file"
              onChange={(e: any) => uploadVideoFiles(e)}
            />
            <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
              {packageVideoName !== ''
                ? packageVideoName
                : t('COACHING_DETAILS.NO_VIDEO_ADDED')}
            </span>
          </div>
        </div>
      </div>
      <div className="session-upload-mobile mt-4">
        <label
          htmlFor="sessionVideoUploadDiv"
          className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
        >
          {t('COACH_SESSION.UPLOAD_VIDEO')}
        </label>
        <input
          className="hidden"
          id="sessionVideoUploadDiv"
          accept="video/*"
          type="file"
          onChange={(e: any) => uploadVideoFiles(e)}
        />
        <span className="mx-3 font-general rtl:font-almarai font-normal tex-base text-[#687E8E]">
          {packageVideoName !== ''
            ? packageVideoName
            : t('COACHING_DETAILS.NO_VIDEO_ADDED')}
        </span>
      </div>
    </div>
  );
};

export default CreateCoachPackage;
