import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { useEffect, useRef, useState } from 'react';
// import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  coachHomeInfo,
  fetchPackageDraftById,
} from '../../coachSlices/coachHomeSlice';
import {
  fetchPackageById,
  myUploadInfo,
} from '../../coachSlices/myUploadSlice';
import {
  clearAssessmentPackageFormData,
  clearAssessmentSessionPrice,
  clearCoachingPricePackageFormData,
  clearIntendedClientPackageFormData,
  clearPackageFormData,
  clearPackageImageFileFormData,
  listPackageDuration,
  packageInfo,
  postCreatePackage,
  postEditPackage,
  saveCreatePackage,
  saveDraftPackage,
} from '../../coachSlices/packageSlice';
import {
  listCategoriesTag,
  listCurrencies,
} from '../../coachSlices/sessionSlice';
import Button from '../../components/coachComponents/buttons';
import CoachingPackagePrice from '../../components/coachComponents/createPackage/CoachPackagePricing';
import IntendedClientsPackage from '../../components/coachComponents/createPackage/IntendedClientsPackage';
import CreateCoachPackage from '../../components/coachComponents/createPackage/PackageLanding';
import SessionsAndAssessment from '../../components/coachComponents/createPackage/SessionAssessment';
import Modal from '../../components/modals';
import { alertActive } from '../../components/notification/slices';
import {
  assessmentPackageFormValueInterface,
  assessmentValueArrayInterface,
  coachingPricePackageFormValueInterface,
  intendedClientPackageFormValueInterface,
  packageInterface,
  PackagePayloadInterface,
  tagsInterface,
} from '../../interfaces/coachInterfaces/packageInterface';
import { useAppSelector } from '../../_helpers';

import { CustomIcon } from '../coachSession';
import './index.css';

const CoachPackage = () => {
  const sessionFormRef: any = useRef();
  const intendFormRef: any = useRef();
  const hoursFormRef: any = useRef();
  const priceFormRef: any = useRef();
  const dispatch = useDispatch<any>();
  const assessmentFormRef: any = useRef();
  const [closeToggle, setCloseToggle] = useState(false);

  const [t] = useTranslation();
  const [menu, setMenu] = useState<string>('PACKAGE_LANDING');
  const { lang } = useAppSelector((state) => state.language);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [menuName, setMenuName] = useState<string>(
    lang === 'en' ? 'Package landing page' : 'صفحة الباقة'
  );
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const { isProcessingPackageById } = useSelector(myUploadInfo);
  const { isProcessingfetchPackageDraftById } = useSelector(coachHomeInfo);

  const [isCleared, setIsCleared] = useState<boolean>(false);

  const {
    packageFormValue,
    intendedClientPackageFormValue,
    coachingHoursPackageFormValue,
    coachingPricePackageFormValue,
    isProcessingCreatePackage,
    assessmentPackageFormValue,
    packageImagefile,
    isProcessingSavePackage,
    isProcessingEditPackage,
  } = useSelector(packageInfo);
  useEffect(() => {
    const callEdit = async () => {
      setIsCleared(false);

      if (
        searchParams.get('form') === 'EDIT' ||
        searchParams.get('form') === 'DRAFT'
      ) {
        if (searchParams.get('id') && searchParams.get('form') === 'EDIT') {
          dispatch(fetchPackageById(searchParams.get('id') as string));
        }
        if (searchParams.get('id') && searchParams.get('form') === 'DRAFT') {
          dispatch(fetchPackageDraftById(searchParams.get('id') as string));
        }
      } else {
        dispatch(clearPackageFormData());
        dispatch(clearIntendedClientPackageFormData());
        // dispatch(clearCoachingHoursPackageFormData());
        dispatch(clearCoachingPricePackageFormData());
        dispatch(clearPackageImageFileFormData());
      }
      setIsCleared(true);
    };
    callEdit();
  }, []);

  useEffect(() => {
    // dispatch(fetchAssessmentTitles());
    // dispatch(fetchSessionTitles());
    dispatch(listPackageDuration());
    dispatch(listCurrencies(''));
    dispatch(listCategoriesTag());
  }, []);
  useEffect(() => {
    if (
      packageFormValue.title !== '' ||
      packageFormValue.subTitle !== '' ||
      packageFormValue.description !== '' ||
      // packageFormValue.duration !== '' ||
      // assessmentPackageFormValue.assessmentId !== '' ||
      // assessmentPackageFormValue.durationInDays !== '' ||
      // assessmentPackageFormValue.durationInHr !== '' ||
      // assessmentPackageFormValue.sessionId !== '' ||
      // assessmentPackageFormValue.durationInWeek !== '' ||
      coachingPricePackageFormValue.price !== ''
    ) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [
    packageFormValue,
    assessmentPackageFormValue,
    coachingPricePackageFormValue,
  ]);
  const getTagsCondition = (forWhom: string[], tags: tagsInterface[]) => {
    const isPresent = forWhom?.some((item: string) => item === 'Others');

    if (isPresent) {
      return tags?.length > 0 ? true : false;
    } else return true;
  };
  const handleSaveClick = () => {
    const payload: PackagePayloadInterface = createPackagePayloadCreator(
      packageFormValue,
      intendedClientPackageFormValue,
      // coachingHoursPackageFormValue,
      coachingPricePackageFormValue,
      assessmentPackageFormValue
    );
    if (searchParams.get('id') && searchParams.get('form') === 'DRAFT') {
      dispatch(
        saveDraftPackage({
          payload: payload,
          id: searchParams.get('id') as string,
          navigate: navigate,
        })
      );
    } else dispatch(saveCreatePackage({ payload, navigate }));
  };
  const handleEditClick = () => {
    setCloseToggle(false);
    if (sessionFormRef.current) {
      sessionFormRef.current?.handleSubmit();
    }
    if (intendFormRef.current) {
      intendFormRef.current?.handleSubmit();
    }
    // if (hoursFormRef.current) {
    //   hoursFormRef.current?.handleSubmit();
    // }
    if (priceFormRef.current) {
      priceFormRef.current?.handleSubmit();
    }
    if (assessmentFormRef.current) {
      assessmentFormRef.current?.handleSubmit();
    }
    if (
      packageFormValue.title !== '' &&
      packageFormValue.subTitle !== '' &&
      packageFormValue.description !== ''
      // packageFormValue.duration !== ''
    ) {
      if (
        intendedClientPackageFormValue.gender !== '' &&
        getTagsCondition(
          intendedClientPackageFormValue.forWhom,
          intendedClientPackageFormValue.tags
        )
      ) {
        // if (getCoachingHoursPackageValidation(coachingHoursPackageFormValue)) {
        if (
          (assessmentPackageFormValue?.assessmentValue[0]?.category ===
            'ASSESSMENT' &&
            assessmentPackageFormValue?.assessmentValue[0]?.assessmentId !==
              '') ||
          // assessmentPackageFormValue?.assessmentValue[0]?.durationInWeek !==
          //   ''
          (assessmentPackageFormValue?.assessmentValue[0]?.category ===
            'SESSION' &&
            // assessmentPackageFormValue?.assessmentValue[0]?.duration !== '' &&
            // assessmentPackageFormValue?.assessmentValue[0]?.retakePeriod !==
            //   '' &&
            assessmentPackageFormValue?.assessmentValue[0]?.sessionId !== '')
        ) {
          if (coachingPricePackageFormValue.price !== '') {
            if (
              packageImagefile.file !== '' ||
              packageImagefile.fileId !== ''
            ) {
              const payload: PackagePayloadInterface =
                createPackagePayloadCreator(
                  packageFormValue,
                  intendedClientPackageFormValue,
                  // coachingHoursPackageFormValue,
                  coachingPricePackageFormValue,
                  assessmentPackageFormValue
                );
              if (searchParams.get('id'))
                dispatch(
                  postEditPackage({
                    data: payload,
                    id: searchParams.get('id') as string,
                    navigate: navigate,
                  })
                );
            } else {
              setMenu('PACKAGE_LANDING');
              setMenuName(t('COACH_PACKAGE.PACKAGE_LANDING'));

              dispatch(
                alertActive({
                  alertError: true,
                  alertPayload: 'Please add an image',
                })
              );
            }
          } else {
            setMenu('PRICING');
            setMenuName(t('COACH_SESSION.PRICING'));
          }
        } else {
          setMenu('SESSION_ASSESSMENT');
          setMenuName(t('COACH_PACKAGE.SESSION_AND_ASSESSMENT'));
        }
        // } else {
        //   setMenu('COACHING_HOURS');
        //   setMenuName(t('COACH_SESSION.COACHING_HOURS'));
        // }
      } else {
        setMenu('INTENDED_CLIENTS');
        setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
      }
    } else {
      setMenu('PACKAGE_LANDING');
      setMenuName(t('COACH_PACKAGE.PACKAGE_LANDING'));
    }
  };
  const handleClick = () => {
    if (sessionFormRef.current) {
      sessionFormRef.current?.handleSubmit();
    }
    if (intendFormRef.current) {
      intendFormRef.current?.handleSubmit();
    }
    // if (hoursFormRef.current) {
    //   hoursFormRef.current?.handleSubmit();
    // }
    if (priceFormRef.current) {
      priceFormRef.current?.handleSubmit();
    }
    if (assessmentFormRef.current) {
      assessmentFormRef.current?.handleSubmit();
    }
    if (
      packageFormValue.title !== '' &&
      packageFormValue.subTitle !== '' &&
      packageFormValue.description !== ''
      // packageFormValue.duration !== ''
    ) {
      if (
        intendedClientPackageFormValue.gender !== '' &&
        getTagsCondition(
          intendedClientPackageFormValue.forWhom,
          intendedClientPackageFormValue.tags
        )
      ) {
        // if (getCoachingHoursPackageValidation(coachingHoursPackageFormValue)) {
        if (
          (assessmentPackageFormValue?.assessmentValue[0]?.category ===
            'ASSESSMENT' &&
            assessmentPackageFormValue?.assessmentValue[0]?.assessmentId !==
              '') ||
          // assessmentPackageFormValue?.assessmentValue[0]?.durationInWeek !==
          //   ''
          (assessmentPackageFormValue?.assessmentValue[0]?.category ===
            'SESSION' &&
            // assessmentPackageFormValue?.assessmentValue[0]?.duration !== '' &&
            // assessmentPackageFormValue?.assessmentValue[0]?.retakePeriod !==
            //   '' &&
            assessmentPackageFormValue?.assessmentValue[0]?.sessionId !== '')
        ) {
          if (coachingPricePackageFormValue.price !== '') {
            if (
              packageImagefile.file !== '' ||
              packageImagefile.fileId !== ''
            ) {
              const payload: PackagePayloadInterface =
                createPackagePayloadCreator(
                  packageFormValue,
                  intendedClientPackageFormValue,
                  // coachingHoursPackageFormValue,
                  coachingPricePackageFormValue,
                  assessmentPackageFormValue
                );

              dispatch(
                postCreatePackage({
                  payload: {
                    payload: payload,
                    draft: searchParams.get('form') === 'DRAFT' ? 'DRAFT' : '',
                    id: searchParams.get('id')
                      ? (searchParams.get('id') as string)
                      : ('' as string),
                  },
                  navigate,
                })
              );
            } else {
              setMenu('PACKAGE_LANDING');
              setMenuName(t('COACH_PACKAGE.PACKAGE_LANDING'));

              dispatch(
                alertActive({
                  alertError: true,
                  alertPayload: 'Please add an image',
                })
              );
            }
          } else {
            setMenu('PRICING');
            setMenuName(t('COACH_SESSION.PRICING'));
          }
        } else {
          setMenu('SESSION_ASSESSMENT');
          setMenuName(t('COACH_PACKAGE.SESSION_AND_ASSESSMENT'));
        }
        // } else {
        //   setMenu('COACHING_HOURS');
        //   setMenuName(t('COACH_SESSION.COACHING_HOURS'));
        // }
      } else {
        setMenu('INTENDED_CLIENTS');
        setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
      }
    } else {
      setMenu('PACKAGE_LANDING');
      setMenuName(t('COACH_PACKAGE.PACKAGE_LANDING'));
    }
  };
  const createPackagePayloadCreator = (
    packageFormValue: packageInterface,
    intendedClientPackageFormValue: intendedClientPackageFormValueInterface,
    // coachingHoursPackageFormValue: coachingHoursPackageFormValueInterface,
    coachingPricePackageFormValue: coachingPricePackageFormValueInterface,
    assessmentPackageFormValue: assessmentPackageFormValueInterface
  ) => {
    const whatWillLearn: string[] = [];
    const requirements: string[] = [];
    const benefits: string[] = [];
    const forWhom: string[] = [];
    intendedClientPackageFormValue?.whatWillLearn?.map((item: string) => {
      if (item !== '') {
        whatWillLearn.push(item);
      }
    });
    intendedClientPackageFormValue?.requirements?.map((item: string) => {
      if (item !== '') {
        requirements.push(item);
      }
    });
    intendedClientPackageFormValue?.benefits?.map((item: string) => {
      if (item !== '') {
        benefits.push(item);
      }
    });
    intendedClientPackageFormValue.forWhom?.map((item: string) => {
      if (item !== null) {
        forWhom.push(item);
      }
    });
    if (
      intendedClientPackageFormValue?.forWhom.some(
        (item: string) => item === 'Others'
      )
    ) {
      intendedClientPackageFormValue.tags?.map((item: tagsInterface) => {
        forWhom.push(item.value);
      });
    }
    // let coachingHourRequests: any = [];
    // if (coachingHoursPackageFormValue.alternative === true) {
    //   coachingHoursPackageFormValue.coachingHourRequests.map(
    //     (item: coachingHourRequestsInterface) => {
    //       if (item.day !== '') {
    //         coachingHourRequests.push({
    //           day: item.day,
    //           startTime: convertTime12to24(
    //             `${item.from} ${item.localFromTime}`
    //           ),
    //           endTime: convertTime12to24(`${item.end} ${item.locaEndTime}`),
    //         });
    //       }
    //     }
    //   );
    // }
    // if (coachingHoursPackageFormValue.alternative === false) {
    //   coachingHoursPayload(coachingHoursPackageFormValue).map(
    //     (item: { day: string; startTime: string; endTime: string }) => {
    //       coachingHourRequests.push(item);
    //     }
    //   );
    // }
    const assessmentPackageRequests: any = [];
    const sessionPackageRequests: any = [];

    // assessmentPackageRequests.push({
    //   assessmentId: assessmentPackageFormValue?.assessmentId,
    //   durationInWeek: assessmentPackageFormValue?.durationInWeek,
    // });
    // sessionPackageRequests.push({
    //   sessionId: assessmentPackageFormValue?.sessionId,
    //   durationInDays: assessmentPackageFormValue?.durationInDays,
    //   durationInHr: assessmentPackageFormValue?.durationInHr,
    // });
    assessmentPackageFormValue.assessmentValue.length > 0 &&
      assessmentPackageFormValue.assessmentValue.map(
        (item: assessmentValueArrayInterface) => {
          if (
            item.category === 'ASSESSMENT' &&
            item.assessmentId !== ''
            // item?.durationInWeek !== ''
          ) {
            assessmentPackageRequests.push({
              assessmentId: item?.assessmentId,
              // durationInWeek: item?.durationInWeek,
            });
          }
          if (
            item.category === 'SESSION' &&
            item.sessionId !== ''
            // item.duration !== '' &&
            // item?.retakePeriod !== ''
          ) {
            sessionPackageRequests.push({
              sessionId: item?.sessionId,
              // duration: item?.duration,
              // retakePeriod: item?.retakePeriod,
            });
          }
        }
      );
    const payloadData = {
      ...coachingPricePackageFormValue,
      ...packageFormValue,
      categoryTags: packageFormValue.categoryTags?.map(
        (item: tagsInterface) => {
          return item.value;
        }
      ),
      intendedClientRequest: {
        gender: intendedClientPackageFormValue?.gender,
        whatWillLearn: whatWillLearn,
        requirements: requirements,
        benefits: benefits,
        forWhom: forWhom,
      },
      // coachingHourRequests: coachingHourRequests,
      assessmentAndSessionRequest: {
        assessmentPackageRequests: assessmentPackageRequests,
        sessionPackageRequests: sessionPackageRequests,
      },
    };

    return payloadData;
  };

  const CoachPackageContent = [
    {
      title: t('COACH_PACKAGE.PACKAGE_LANDING'),
      value: 'PACKAGE_LANDING',
      icon: SchoolOutlinedIcon,
    },
    {
      title: t('COACH_SESSION.INTENDED_CLIENTS'),
      value: 'INTENDED_CLIENTS',
      icon: PeopleAltOutlinedIcon,
    },
    {
      title: t('COACH_PACKAGE.SESSION_AND_ASSESSMENT'),
      value: 'SESSION_ASSESSMENT',
      icon: CastForEducationOutlinedIcon,
    },
    // {
    //   title: t('COACH_SESSION.COACHING_HOURS'),
    //   value: 'COACHING_HOURS',
    //   icon: ScheduleOutlinedIcon,
    // },
    {
      title: t('COACH_SESSION.PRICING'),
      value: 'PRICING',
      icon: SellOutlinedIcon,
    },
  ];

  const CoachPackageComponent = [
    {
      component: <CreateCoachPackage sessionFormRef={sessionFormRef} />,
      value: 'PACKAGE_LANDING',
    },
    {
      component: <IntendedClientsPackage intendFormRef={intendFormRef} />,
      value: 'INTENDED_CLIENTS',
    },
    {
      component: (
        <SessionsAndAssessment assessmentFormRef={assessmentFormRef} />
      ),
      value: 'SESSION_ASSESSMENT',
    },
    // {
    //   component: <CoachingPackageHours hoursFormRef={hoursFormRef} />,
    //   value: 'COACHING_HOURS',
    // },
    {
      component: <CoachingPackagePrice priceFormRef={priceFormRef} />,
      value: 'PRICING',
    },
  ];
  const handleNextClick = () => {
    switch (menu) {
      case 'PACKAGE_LANDING':
        if (sessionFormRef.current) {
          sessionFormRef.current?.handleSubmit();
        }
        if (
          packageFormValue.title !== '' &&
          packageFormValue.subTitle !== '' &&
          packageFormValue.description !== ''
          // packageFormValue.duration !== ''
        ) {
          setMenu('INTENDED_CLIENTS');
          setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
        }
        break;
      case 'INTENDED_CLIENTS':
        if (intendFormRef.current) {
          intendFormRef.current?.handleSubmit();
        }
        if (
          intendedClientPackageFormValue.gender !== '' &&
          getTagsCondition(
            intendedClientPackageFormValue.forWhom,
            intendedClientPackageFormValue.tags
          )
        ) {
          setMenu('SESSION_ASSESSMENT');
          setMenuName(t('COACH_PACKAGE.SESSION_AND_ASSESSMENT'));
        }
        break;
      case 'SESSION_ASSESSMENT':
        if (assessmentFormRef.current) {
          assessmentFormRef.current?.handleSubmit();
        }
        if (
          (assessmentPackageFormValue?.assessmentValue[0]?.category ===
            'ASSESSMENT' &&
            assessmentPackageFormValue?.assessmentValue[0]?.assessmentId !==
              '') ||
          // assessmentPackageFormValue?.assessmentValue[0]?.durationInWeek !==
          //   ''
          (assessmentPackageFormValue?.assessmentValue[0]?.category ===
            'SESSION' &&
            // assessmentPackageFormValue?.assessmentValue[0]?.duration !== '' &&
            // assessmentPackageFormValue?.assessmentValue[0]?.retakePeriod !==
            //   '' &&
            assessmentPackageFormValue?.assessmentValue[0]?.sessionId !== '')
        ) {
          setMenu('PRICING');
          setMenuName(t('COACH_SESSION.PRICING'));
        }
        break;
      // case 'COACHING_HOURS':
      //   if (hoursFormRef.current) {
      //     hoursFormRef.current?.handleSubmit();
      //   }
      //   if (getCoachingHoursPackageValidation(coachingHoursPackageFormValue)) {
      //     setMenu('PRICING');
      //     setMenuName(t('COACH_SESSION.PRICING'));
      //   }
      //   break;
    }
  };
  const onCancelClick = () => {
    navigate(-1);
    if (sessionFormRef.current) {
      sessionFormRef.current?.resetForm();
    }
    dispatch(clearPackageFormData());
    dispatch(clearPackageImageFileFormData());
    if (intendFormRef.current) {
      intendFormRef.current?.resetForm();
    }
    dispatch(clearIntendedClientPackageFormData());
    if (assessmentFormRef.current) {
      assessmentFormRef.current?.resetForm();
    }
    dispatch(clearAssessmentPackageFormData());
    dispatch(clearAssessmentSessionPrice());
    // if (hoursFormRef.current) {
    //   hoursFormRef.current?.resetForm();
    // }
    // dispatch(clearCoachingHoursPackageFormData());
    if (priceFormRef.current) {
      priceFormRef.current?.resetForm();
    }
    dispatch(clearCoachingPricePackageFormData());
  };
  return (
    <>
      {/* <Helmet>
        <title>{t('KUN_COACH')}</title> */}
      {/* <meta name="description" content="Helmet application" /> */}
      {/* </Helmet> */}

      <Modal
        show={closeToggle}
        logOutClose={() => {
          setCloseToggle(false);
        }}
      >
        <div
          // onClick={() => deleteAccount()}
          className=" h-fit w-full flex flex-col md:mt-11 mt-[72px]  items-center gap-7 "
        >
          <img
            className="md:h-[73px] md:w-[73px] h-[60px] w-[60px]"
            src="/assets/closeAccount.svg"
            alt=""
          />
          <div className="h-10 w-full md:text-24 text-22 flex justify-center text-center rtl:font-almarai ">
            {t('CONTINUE')}
          </div>
          <div className="w-full flex md:flex-row flex-col-reverse justify-center gap-5 pb-12">
            <div>
              <Button className={'w-full'}>
                <div
                  onClick={() => setCloseToggle(false)}
                  className="h-12 border  text-gray  font-satoshi rtl:font-almarai font-bold text-18 flex flex-grow items-center justify-center rounded  w-[100%] md:w-[200px]"
                >
                  {t('CANCEL')}
                </div>
              </Button>
            </div>
            <div>
              <Button className={'w-full'}>
                <div
                  onClick={() => handleEditClick()}
                  className="h-12 bg-red text-white  font-satoshi rtl:font-almarai font-bold text-18 flex  items-center justify-center rounded  flex-grow w-full md:w-[200px]"
                >
                  {t('CONFIRM')}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="coach-session-container ">
        <div className="coach-session-nav ">
          <div>
            <div className="h-14 w-full p-5 mb-3 font-satoshi rtl:font-almarai  font-bold text-2xl">
              {t('COACH_PACKAGE.PACKAGE')}
            </div>
            {CoachPackageContent.map(({ title, value, icon }) =>
              value === menu ? (
                <div className="h-14 w-full flex flex-row">
                  <div className="h-full w-[3px] bg-[#02A4EA]" />
                  <div className="h-full w-full  flex items-center  bg-[#00a5ec1a] rtl:font-almarai">
                    <CustomIcon Icon={icon} color={'#153243'} />
                    {title}
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => setMenu(value)}
                  className="h-14 w-full flex flex-row cursor-pointer"
                >
                  <div className="h-full w-[3px] " />
                  <div className="h-full w-full flex items-center  rtl:font-almarai">
                    <CustomIcon Icon={icon} color={'#687E8E'} />

                    {title}
                  </div>
                </div>
              )
            )}
          </div>
          <div>
            {searchParams.get('form') === 'EDIT' ? (
              <Button
                htmlFor="submitSessionButton"
                form="my-form"
                type="submit"
                onClick={() => setCloseToggle(true)}
                className="bg-primary text-white btn-small m-2 w-full flex justify-around h-fit overflow-hidden"
              >
                <>
                  {isProcessingEditPackage && <div className="loader" />}
                  {t('UPDATE')}
                </>
              </Button>
            ) : (
              <>
                <Button
                  htmlFor="submitSessionButton"
                  form="my-form"
                  type="submit"
                  onClick={handleClick}
                  className="bg-primary text-white btn-small m-2 w-full flex justify-around h-fit overflow-hidden"
                >
                  <>
                    {isProcessingCreatePackage && <div className="loader" />}
                    {t('COACH_SESSION.SUBMIT_REVIEW')}
                  </>
                </Button>
                {isSaveDisabled && (
                  <Button
                    onClick={() => handleSaveClick()}
                    className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small m-2 w-full flex justify-around h-fit overflow-hidden"
                  >
                    {isProcessingSavePackage && <div className="loader" />}
                    {t('COACH_SESSION.SAVE_DRAFT')}
                  </Button>
                )}
              </>
            )}
          </div>
          <Button
            onClick={() => onCancelClick()}
            className="btn-small m-2 w-full mb-6 text-secondary bg-white h-fit overflow-hidden min-h-[40px]"
          >
            {t('CREATE_SESSION.CANCEL')}
          </Button>
        </div>
        <div className="session-mobile-menu-container">
          <div className="session-mobile-menu ">
            {t('COACH_PACKAGE.PACKAGE')}

            {!mobileMenu ? (
              <img
                onClick={() => setMobileMenu(true)}
                className="mt-auto mb-auto cursor-pointer"
                src="/assets/homePageImages/menu-dots-icon.svg"
                alt="bell-icon"
              />
            ) : (
              <img
                onClick={() => setMobileMenu(false)}
                className="mt-auto mb-auto cursor-pointer"
                src="/assets/subscriptionPage/plusIcon.svg"
                alt="bell-icon"
              />
            )}
            {mobileMenu && (
              <div className="coach-session-dropdown-container shadow-lg ltr:right-0 rtl:left-0 z-50  cursor-pointer">
                {CoachPackageContent.map(
                  ({ title, value, icon }) =>
                    value !== menu && (
                      <div
                        onClick={() => {
                          setMenu(value);
                          setMenuName(title);
                          setMobileMenu(false);
                        }}
                        className="h-10 border-b-[1px] border-[#38383840] w-full flex flex-row"
                      >
                        <div className="h-full w-[3px] " />
                        <div className="h-full font-general rtl:font-almarai font-normal text-base w-full  flex items-center ">
                          <CustomIcon Icon={icon} color={'#687E8E'} />
                          {title}
                        </div>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          <div className="h-14 w-full mb-10 flex flex-row">
            <div className="h-full w-[3px] bg-[#02A4EA]" />
            <div className="h-full w-full px-3 flex items-center  bg-[#00a5ec1a]">
              <CustomIcon
                Icon={
                  CoachPackageContent.filter(
                    ({ value }: any) => menu === value
                  )[0].icon as any
                }
                color={'#153243'}
              />
              {menuName}
            </div>
          </div>
        </div>
        {isCleared ? (
          (isProcessingPackageById && searchParams.get('form') === 'EDIT') ||
          (isProcessingfetchPackageDraftById &&
            searchParams.get('form') === 'DRAFT') ? (
            <div className=" session-form-container h-[90vh] justify-center items-center flex">
              <div className="loader" />
            </div>
          ) : (
            <div className="lggmd:h-[calc(100vh-270px)] w-full  lggmd:overflow-scroll  lggmd:shadow-custom lggmd:rounded-lg">
              {CoachPackageComponent.map(
                (item: any) => item.value === menu && item.component
              )}
            </div>
          )
        ) : null}
        <div className="mobile-btn-div">
          {menu !== 'PRICING' ? (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleNextClick}
              className="bg-white border-primary text-primary btn-small mt-6 w-full h-fit overflow-hidden"
            >
              {t('COACH_PACKAGE.NEXT')}
            </Button>
          ) : searchParams.get('form') !== 'EDIT' ? (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleClick}
              className="bg-primary text-white btn-small  w-full flex justify-around h-fit overflow-hidden"
            >
              <>
                {isProcessingCreatePackage && <div className="loader" />}
                {t('COACH_SESSION.SUBMIT_REVIEW')}
              </>
            </Button>
          ) : (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleEditClick}
              className="bg-primary text-white btn-small w-full flex justify-around h-fit overflow-hidden"
            >
              <>
                {isProcessingEditPackage && <div className="loader" />}
                {t('UPDATE')}
              </>
            </Button>
          )}
          {isSaveDisabled && searchParams.get('form') !== 'EDIT' && (
            <Button
              onClick={() => handleSaveClick()}
              className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small mt-2 w-full flex justify-around h-fit overflow-hidden"
            >
              {isProcessingSavePackage && <div className="loader" />}

              {t('COACH_SESSION.SAVE_DRAFT')}
            </Button>
          )}

          <Button
            onClick={() => onCancelClick()}
            className="btn-small mt-2 w-full mb-6 text-secondary bg-white h-fit overflow-hidden"
          >
            {t('CREATE_SESSION.CANCEL')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CoachPackage;
