import { apiHeader } from '../constants/apiHeader';
import coachtDirectoryEndpoints from '../constants/coach';
import { get, post } from './apiService';

export const fetchCoachProfileData = async <T>(data: {
  [key: string]: string;
}) => {
  const { id } = data;
  const paramsBody = data.status ? data : delete data.id;
  return await get(
    `${coachtDirectoryEndpoints.APPROVE_REJECT}${id}`,
    data.status ? paramsBody : {},
    apiHeader.APPLICATION_JSON
  );
};
export const fetchCoachApproveReject = async <T>(data: {
  [key: string]: string;
}) => {
  const { id } = data;
  delete data.id;
  return await post(
    `${coachtDirectoryEndpoints.APPROVE_REJECT}${id}`,
    data,
    apiHeader.APPLICATION_JSON
  );
};
