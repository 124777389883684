import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

interface IFilterProps {
  fields?: { [value: string]: string }[][];
  category?: string;
}
interface IFilterFields {
  genderFilter?: string;
  activePackages?: string;
  baseFilter?: string;
}

const FIlter: FC<IFilterProps> = ({ fields, category }): JSX.Element => {
  const dispatch = useDispatch<any>();
  const [t] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterToggle, setFilterToggle] = useState(false);
  const filterToggleFunction = () => {
    setFilterToggle(!filterToggle);
  };

  const [genderFilter, setGenderFilter] = useState<string>('');
  const [baseFilter, setBaseFilter] = useState<string>('');

  const handleFilterFunction = () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    genderFilter ? (params['genderFilter'] = genderFilter) : '';
    baseFilter ? (params['baseFilter'] = baseFilter) : '';
    params['page'] = '0';
    !searchParams.get('sort') ? (params['sort'] = 'created_at,desc') : '';

    setSearchParams(params);
    setFilterToggle(!filterToggle);
  };
  useEffect(() => {
    searchParams.get('genderFilter') ? '' : setGenderFilter('');
    const value = searchParams.get('baseFilter');

    searchParams.get('baseFilter') != baseFilter
      ? setBaseFilter(searchParams.get('baseFilter')!)
      : '';
  }, [searchParams.get('genderFilter'), searchParams.get('baseFilter')]);
  return (
    <div>
      <div
        onClick={() => filterToggleFunction()}
        className={`${
          filterToggle
            ? '  fixed inset-0 bg-transperant bg-opacity-75 transition-opacity z-21 '
            : ''
        }`}
      ></div>
      <div className="z-25">
        <button
          onClick={() => filterToggleFunction()}
          className=" flex flex-row gap-x-2 border  border-borderBlack rounded items-center px-4 py-2.5 h-9 text-12 text-secondary font-general rtl:font-almarai font-normal"
        >
          <img className="" src="/assets/filter.svg" alt="" />
          <span>{t('COACH_DIRECTORY.FILTER')}</span>
        </button>
        <div className="dropdown relative">
          <div
            className={`${
              filterToggle ? '' : 'hidden'
            } dropdown-menu min-w-max absolute ltr:right-0 rtl:left-0 px-5  bg-white text-base z-9 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none`}
            aria-labelledby="dropdownMenuButton3"
          >
            <div className="cursor-pointer border-b border-borderBlack py-4">
              <div className="font-satoshi rtl:font-almarai font-semibold text-textBlack text-20 pb-2">
                {t('ADVANCED_FILTERS')}
              </div>
              {/* <div className="text-gray font-general rtl:font-almarai font-normal text-16">
                1024 results
              </div> */}
            </div>

            <div className="border-b border-borderBlack py-4">
              <div
                className={`${
                  category === 'SETTLEMENT_REQUESTS' && 'hidden'
                } font-satoshi rtl:font-almarai font-semibold text-17 text-textBlack pb-6 flex justify-start`}
              >
                {t('GENDER')}
              </div>
              <div className=" flex flex-col gap-4 ">
                {fields &&
                  fields[0]?.map((value, index) => (
                    <div
                      className="flex  gap-4 font-normal  font-general rtl:font-almarai text-16  "
                      key={index}
                    >
                      <input
                        checked={
                          genderFilter === value.titleValue ? true : false
                        }
                        onChange={(e) => setGenderFilter(e.target.value)}
                        id={`${value.title}`}
                        type="radio"
                        className="text-textBlack  cursor-pointer"
                        value={value.titleValue}
                        name="genderFilter"
                      ></input>
                      <label
                        htmlFor={`${value.title}`}
                        className="text-textBlack cursor-pointer"
                      >
                        {t(`${value.titleValue}`)}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className=" flex flex-col gap-4 pt-4 ">
              {fields &&
                fields[1] &&
                fields[1].map((value, index) => (
                  <div
                    className="flex  gap-4 font-normal  font-general rtl:font-almarai text-16  "
                    key={index}
                  >
                    <input
                      checked={
                        // baseFilter != searchParams.get('baseFilter')
                        //   ? baseFilter
                        //     ? baseFilter === value.titleValue
                        //     : searchParams.get('baseFilter') ===
                        //       value.titleValue
                        //   : searchParams.get('baseFilter') === value.titleValue
                        baseFilter
                          ? baseFilter === value.titleValue
                          : searchParams.get('baseFilter') === value.titleValue
                      }
                      onChange={(e) => setBaseFilter(e.target.value)}
                      id={`${value.title}`}
                      type="radio"
                      className="text-textBlack  cursor-pointer"
                      value={value.titleValue}
                      name="baseFilter"
                    ></input>
                    <label
                      htmlFor={`${value.title}`}
                      className="text-textBlack cursor-pointer"
                    >
                      {' '}
                      {t(`${value.titleValue}`)}{' '}
                      {/* <span className="text-gray">{'(20)'}</span> */}
                    </label>
                  </div>
                ))}
            </div>
            {/* <div className="border-b py-4 border-borderBlack ">
              <div className="font-satoshi rtl:font-almarai font-semibold text-17 text-textBlack pb-6">
                Active packages
              </div>
              <div
                 onChange={handleChange}
                className=" flex flex-col gap-4"
              >
                {fields! &&
                  fields[2].map((value, index) => (
                    <div
                      className="flex  gap-4 font-general rtl:font-almarai font-normal text-16 text-textBlack"
                      key={index}
                    >
                      <input
                        id={`${value.title}`}
                        type="radio"
                        className="text-textBlack cursor-pointer"
                        value={value.titleValue}
                        name="activePackages"
                      ></input>
                      <label
                        htmlFor={`${value.title}`}
                        className="text-textBlack cursor-pointer"
                      >
                        {' '}
                        {value.title}{' '}
                        <span className="text-gray">{'(20)'}</span>
                      </label>
                    </div>
                  ))}
              </div>
            </div> */}
            {/* <div className="border-b py-4 border-borderBlack font-satoshi rtl:font-almarai font-semibold text-17 text-textBlack">
              <div>Overall purchases</div>
            </div>
            <div className="border-b py-4 border-borderBlack font-satoshi rtl:font-almarai font-semibold text-17 text-textBlack">
              <div>Coachable rating</div>
            </div> */}
            <div className="flex justify-center py-3">
              <button
                onClick={() => handleFilterFunction()}
                className="bg-primary text-white rounded text-18 font-satoshi rtl:font-almarai font-semibold w-full py-4"
              >
                {t('APPLY')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FIlter;
