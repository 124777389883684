import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFiledata } from '../../../services/fileTransfer';
import { alertActive } from '../../notification/slices';

export const getImage = createAsyncThunk(
  'image/imageData',
  async (coachProfilePayload: string, thunkAPI) => {
    try {
      const { data } = await getFiledata(coachProfilePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
