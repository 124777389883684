import React from 'react';
import { useDispatch } from 'react-redux';
import './index.css';

type InputFieldProps = {
  show: boolean;
  children: any;
  logOutClose?: () => void;
  title?: string;
  titleChildren?: JSX.Element;
};

const Modal: React.FC<InputFieldProps> = ({
  show,
  children,
  logOutClose,
  title,
  titleChildren,
}): JSX.Element => {
  const showHideClassName = show ? 'modal block ' : 'modal hidden';
  const dispatch = useDispatch();
  const handleClose = () => {
    if (logOutClose) {
      logOutClose();
    }
  };
  return (
    <div className={showHideClassName}>
      <section
        className={
          'modal-main three flex flex-col max-h-[80vh] md:w-[47.5rem]  w-[19rem]  '
        }
      >
        <div className={'modal-header flex-grow justify-end flex flex-row'}>
          {!titleChildren ? (
            <div
              className={`
                'modal-header-title ',
                
                  'bg-[var(--primary)]',
                `}
            >
              {title}
            </div>
          ) : (
            titleChildren
          )}
          <div
            className={`modal-header-close  cursor-pointer  
              
                bg-[var(--primary)]': title,
            `}
            onClick={handleClose}
          >
            <img src="/assets/cancel.svg" className="" alt="logo" />
          </div>
        </div>
        <div className={'modal-content '}>{children}</div>
      </section>
    </div>
  );
};

export default Modal;
