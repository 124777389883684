import axios, { AxiosResponse } from 'axios';
import { env } from '../config/env';
import { getHeaderDetails } from '../_helpers/apiHeaderCreator';
import { getAccessToken } from '../_helpers/token';

const handleResponse = (resp: AxiosResponse) => {
  if (resp.status === 401) {
    localStorage.clear();
    window.location.replace('/kun/login');
    return Promise.reject(resp.data);
  }
  if (resp.data.success) {
    return resp.data;
  }
  return Promise.reject(resp);
};

export const post = async <T>(url: string, body: T, headerContent: string) => {
  const header = await getHeaderDetails(headerContent);
  const token = await getAccessToken();
  try {
    if (token && token !== 'undefined') {
      const resp = await axios.post('/api' + url, body, header);
      return resp;
    } else {
      const resp = await axios.post('/api' + url, body);
      return handleResponse(resp);
    }
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const put = async <T>(url: string, body: T, headerContent: string) => {
  const header = await getHeaderDetails(headerContent);
  const token = await getAccessToken();
  try {
    if (token && token !== 'undefined') {
      const resp = await axios.put('/api' + url, body, header);
      return resp;
    } else {
      const resp = await axios.put('/api' + url, body);
      return handleResponse(resp);
    }
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const deleteApi = async <T>(url: string, headerContent: string) => {
  const header = await getHeaderDetails(headerContent);
  const token = await getAccessToken();
  try {
    if (token && token !== 'undefined') {
      const resp = await axios.delete('/api' + url, header);
      return resp;
    } else {
      const resp = await axios.delete('/api' + url);
      return handleResponse(resp);
    }
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const get = async <T>(
  url: string,
  params: any = {},
  headerContent = ''
) => {
  const header = await getHeaderDetails(headerContent);
  const token = await getAccessToken();
  let requestBody;
  if (token && token !== 'undefined') {
    requestBody = {
      ...header,
      params,
    };
  } else {
    requestBody = {
      params,
    };
  }

  try {
    const resp = await axios.get('/api' + url, requestBody);
    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getImage = async <T>(url: string) => {
  const header = await getHeaderDetails('image/png');
  const token = await getAccessToken();
  let requestBody;
  if (token && token !== 'undefined') {
    requestBody = {
      ...header,
    };
  }
  try {
    const resp = await axios.get(
      // 'https://source.unsplash.com/random/500x500',
      '/api' + url,
      {
        // responseType: 'arraybuffer',
        ...header,
      }
    );
    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getFile = async <T>(url: string) => {
  const token = await getAccessToken();
  try {
    const resp = await axios.get(
      // 'https://source.unsplash.com/random/500x500',
      '/api' + url,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const getFormFileHeader = async function () {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};
export const postProfileFile = async function (uploadFile: any, type: string) {
  const header = await getFormFileHeader();
  try {
    const formData: any = new FormData();
    formData.append('file-type', type);
    formData.append('file', uploadFile[0]);
    const res = await axios.post(`api/files`, formData, header);
    return handleResponse(res);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const postFile = async function (url: string, body: any) {
  const header = await getFormFileHeader();
  try {
    const resp = await axios.post(url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const getImageHeaderInfo = async function () {
  const token = await getAccessToken();
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
};
export const postImageFileWithToken = async function (url: string, body: any) {
  const header = await getImageHeaderInfo();
  try {
    const resp = await axios.post('/api' + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const getHeaderInfo = async function () {
  const token = await getAccessToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};
export const postDataWithToken = async function (url: string, body: any) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.post('/api' + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const putDataWithToken = async function (url: string, body: any) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.put('/api' + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
