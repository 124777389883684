import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { alertActive } from '../components/notification/slices';
import { weekDaysList } from '../constants/coachConstants/coachSessionConstants';
import {
  myCoachUploadsInterface,
  myUploadsSliceInterface,
} from '../interfaces/coachInterfaces/myUploadInterface';
import {
  assessmentById,
  deleteAssessmentApi,
  deletePackageApi,
  deleteSessionApi,
  getAssessments,
  getPackage,
  getSession,
  packageById,
  sessionById,
} from '../services/packageService';
import {
  decodeUserId,
  getAccessToken,
} from '../_helpers/coachHelpers/localStorage';
import { getMyUploadsParams } from '../_helpers/coachHelpers/query';
import {
  convert24to12,
  convert24to12Item,
  gePackageEditValuePayload,
  getAssessmentForEditPayload,
  getHoursData,
  getTagsFromAssessmentEdit,
  isAlternative,
  thirdPartyEditPayloadCreator,
} from '../_helpers/coachHelpers/utility';

import {
  setAssessmentFormData,
  setAssessmentImageFileFormData,
  setCoachingPriceAssessmentFormData,
  setIntendedClientAssessmentFormData,
  setThirdPartyAssessmentFormData,
  setThirdPartyFileIds,
} from './assessmentSlice';
import {
  downloadImage,
  fetchAssessmentTitles,
  fetchSessionTitles,
  listPackageDuration,
  setAssessmentPackageFormData,
  setCoachingPricePackageFormData,
  setIntendedClientPackageFormData,
  setPackageAssessmentSessionData,
  setPackageFormData,
  setPackageImageFileFormData,
} from './packageSlice';
import { listLanguages } from './profile';
import {
  listCoachingTiming,
  listHour,
  listMonths,
  setCoachingHoursFormData,
  setCoachingPriceFormData,
  setIntendedClientFormData,
  setSessionFormData,
  setSessionImageFileFormData,
} from './sessionSlice';

const initialState: myUploadsSliceInterface = {
  myCoachUploads: [],
  isProcessingMyCoachUploads: false,
  isProcessingMyCoachUploadsDelete: false,
  deletedId: '',
  isProcessingAssessmentById: false,
  isProcessingPackageById: false,
  isProcessingSessionById: false,
};

export const myUploadSlice = createSlice({
  name: 'uploadSlice',
  initialState,
  reducers: {
    setDeleteId: (state, action: PayloadAction<string>) => {
      state.deletedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyUploads.pending, (state) => {
        state.isProcessingMyCoachUploads = true;
      })
      .addCase(fetchMyUploads.fulfilled, (state, action) => {
        state.myCoachUploads = action.payload;
        state.isProcessingMyCoachUploads = false;
      })
      .addCase(fetchMyUploads.rejected, (state, action) => {
        state.isProcessingMyCoachUploads = false;
      })
      .addCase(deleteAssessment.pending, (state) => {
        state.isProcessingMyCoachUploadsDelete = true;
      })
      .addCase(deleteAssessment.fulfilled, (state, action) => {
        state.isProcessingMyCoachUploadsDelete = false;
      })
      .addCase(deleteAssessment.rejected, (state, action) => {
        state.isProcessingMyCoachUploadsDelete = false;
      })
      .addCase(deleteSession.pending, (state) => {
        state.isProcessingMyCoachUploadsDelete = true;
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        state.isProcessingMyCoachUploadsDelete = false;
      })
      .addCase(deleteSession.rejected, (state, action) => {
        state.isProcessingMyCoachUploadsDelete = false;
      })
      .addCase(deletePackages.pending, (state) => {
        state.isProcessingMyCoachUploadsDelete = true;
      })
      .addCase(deletePackages.fulfilled, (state, action) => {
        state.isProcessingMyCoachUploadsDelete = false;
      })
      .addCase(deletePackages.rejected, (state, action) => {
        state.isProcessingMyCoachUploadsDelete = false;
      })
      .addCase(fetchAssessmentById.pending, (state) => {
        state.isProcessingAssessmentById = true;
      })
      .addCase(fetchAssessmentById.fulfilled, (state, action) => {
        state.isProcessingAssessmentById = false;
      })
      .addCase(fetchAssessmentById.rejected, (state, action) => {
        state.isProcessingAssessmentById = false;
      })
      .addCase(fetchPackageById.pending, (state) => {
        state.isProcessingPackageById = true;
      })
      .addCase(fetchPackageById.fulfilled, (state, action) => {
        state.isProcessingPackageById = false;
      })
      .addCase(fetchPackageById.rejected, (state, action) => {
        state.isProcessingPackageById = false;
      })
      .addCase(fetchSessionById.pending, (state) => {
        state.isProcessingSessionById = true;
      })
      .addCase(fetchSessionById.fulfilled, (state, action) => {
        state.isProcessingSessionById = false;
      })
      .addCase(fetchSessionById.rejected, (state, action) => {
        state.isProcessingSessionById = false;
      });
  },
});
export const fetchMyUploads = createAsyncThunk(
  'uploads/me',
  async (payload: string, thunkAPI) => {
    try {
      const params = getMyUploadsParams(payload, 'ACTIVE');
      const array = [];
      const assessmentResponse = await getAssessments(
        decodeUserId(getAccessToken() as string),
        params
      );
      const assessmentArray: myCoachUploadsInterface[] = [];
      assessmentResponse?.data[0]?.content.map(
        (item: myCoachUploadsInterface) =>
          assessmentArray.push({ ...item, type: 'ASSESSMENT' })
      );
      array.push(...assessmentArray);
      const sessionResponse = await getSession(
        decodeUserId(getAccessToken() as string),
        params
      );
      const sessionArray: myCoachUploadsInterface[] = [];
      sessionResponse?.data[0]?.content.map((item: myCoachUploadsInterface) =>
        sessionArray.push({ ...item, type: 'SESSION' })
      );
      array.push(...sessionArray);

      const packageResponse = await getPackage(
        decodeUserId(getAccessToken() as string),
        params
      );
      const packageArray: myCoachUploadsInterface[] = [];
      packageResponse?.data[0]?.content.map((item: myCoachUploadsInterface) =>
        packageArray.push({ ...item, type: 'PACKAGE' })
      );
      array.push(...packageArray);

      return array;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteAssessment = createAsyncThunk(
  'assessment/delete',
  async (id: string, thunkAPI) => {
    try {
      const response = await deleteAssessmentApi(id);
      thunkAPI.dispatch(setDeleteId(id));
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteSession = createAsyncThunk(
  'session/delete',
  async (id: string, thunkAPI) => {
    try {
      const response = await deleteSessionApi(id);
      thunkAPI.dispatch(setDeleteId(id));

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deletePackages = createAsyncThunk(
  'package/delete',
  async (id: string, thunkAPI) => {
    try {
      const response = await deletePackageApi(id);
      thunkAPI.dispatch(setDeleteId(id));

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchAssessmentById = createAsyncThunk(
  'assessment/byId',
  async (payload: { id: string; navigate: any }, thunkAPI) => {
    try {
      const response = await assessmentById(payload.id);
      const data: myCoachUploadsInterface = response?.data?.data[0];
      thunkAPI.dispatch(listLanguages());
      thunkAPI.dispatch(listCoachingTiming());
      thunkAPI.dispatch(
        setAssessmentFormData({
          title: data.title,
          subTitle: data.subTitle,
          description: data.description,
          // deliveryDuration: data.deliveryDuration,
          language: data.language,
          category: data.category,
          categoryTags: data.categoryTags?.map((item: string) => ({
            label: item,
            value: item,
          })),
        })
      );
      thunkAPI.dispatch(
        setIntendedClientAssessmentFormData({
          gender: data?.intendedClientResponse?.gender,
          whatWillLearn: data?.intendedClientResponse?.whatWillLearn?.length
            ? data?.intendedClientResponse?.whatWillLearn
            : ['', '', ''],
          requirements: data?.intendedClientResponse?.requirements?.length
            ? data?.intendedClientResponse?.requirements
            : ['', '', ''],
          forWhom: getAssessmentForEditPayload(
            data?.intendedClientResponse?.forWhom as string[]
          ),
          benefits: data?.intendedClientResponse?.benefits?.length
            ? data?.intendedClientResponse?.benefits
            : ['', '', ''],
          tags: getTagsFromAssessmentEdit(
            data?.intendedClientResponse?.forWhom as string[]
          ),
        })
      );

      const fileIds: any = [];
      const termsOfServiceUrl: any = [];
      data?.thirdPartyProviderResponse?.materialResponses.map((item: any) => {
        if (item.id) fileIds.push(item.id);
        if (item.url) termsOfServiceUrl.push(item.url);
      });
      thunkAPI.dispatch(
        setThirdPartyAssessmentFormData({
          title: data?.thirdPartyProviderResponse?.title,
          provider: data?.thirdPartyProviderResponse?.provider,
          deliveryMethod: data?.thirdPartyProviderResponse?.deliveryMethod,
          thirdParty: thirdPartyEditPayloadCreator(
            fileIds as number[],
            termsOfServiceUrl as string[]
          ),
        })
      );
      thunkAPI.dispatch(setThirdPartyFileIds(fileIds));

      thunkAPI.dispatch(
        setCoachingPriceAssessmentFormData({
          price: data?.price,
        })
      );
      const imageData = await thunkAPI.dispatch(
        downloadImage(data?.imgId as number)
      );
      if (imageData?.payload?.data?.data?.length)
        thunkAPI.dispatch(
          setAssessmentImageFileFormData({
            url: `data:image/png;base64,${imageData?.payload?.data?.data[0]?.fileContent}`,
            name: imageData?.payload?.data?.data[0]?.originalFileName,
            file: '',
            fileId: data?.imgId,
          })
        );

      // payload.navigate(`/coach/coach-assessment?form=EDIT&id=${data?.id}`);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSessionById = createAsyncThunk(
  'session/fetchSessionById',
  async (id: string, thunkAPI) => {
    try {
      const response = await sessionById(id);
      const data: myCoachUploadsInterface = response?.data?.data[0];

      thunkAPI.dispatch(listLanguages());
      thunkAPI.dispatch(listMonths());
      thunkAPI.dispatch(listHour());
      thunkAPI.dispatch(listCoachingTiming());
      thunkAPI.dispatch(
        setSessionFormData({
          title: data?.title,
          subTitle: data?.subTitle,
          description: data?.description,
          duration: data?.duration,
          retakePeriod: data?.retakePeriod,
          language: data?.language,
          category: data?.category,

          // ...data,
          categoryTags: data?.tags?.map((item: string) => ({
            label: item,
            value: item,
          })),
        })
      );

      thunkAPI.dispatch(
        setIntendedClientFormData({
          gender: data?.intendedClientResponse?.gender,
          whatWillLearn: data?.intendedClientResponse?.whatWillLearn?.length
            ? data?.intendedClientResponse?.whatWillLearn
            : ['', '', ''],
          requirements: data?.intendedClientResponse?.requirements?.length
            ? data?.intendedClientResponse?.requirements
            : ['', '', ''],
          forWhom: getAssessmentForEditPayload(
            data?.intendedClientResponse?.forWhom as string[]
          ),
          benefits: data?.intendedClientResponse?.benefits?.length
            ? data?.intendedClientResponse?.benefits
            : ['', '', ''],
          tags: getTagsFromAssessmentEdit(
            data?.intendedClientResponse?.forWhom as string[]
          ),
        })
      );
      if (data?.coachingHourResponse?.length === 1) {
        thunkAPI.dispatch(
          setCoachingHoursFormData({
            days: 'Sunday-Thursday',
            start: '00:00',
            end: '02:00',
            alternative: true,
            coachingHourRequests: convert24to12Item(
              data?.coachingHourResponse?.length &&
                (data?.coachingHourResponse[0] as any)
            ),
          })
        );
      } else if (isAlternative(data?.coachingHourResponse as any[])) {
        const hoursData = getHoursData(data?.coachingHourResponse as any[]);
        thunkAPI.dispatch(
          setCoachingHoursFormData({
            days: hoursData.days,
            start: hoursData.start,
            end: hoursData.end,
            alternative: false,
            coachingHourRequests: weekDaysList.map(
              (item: { label: string; value: string }) => ({
                day: item.value,
                from: '12:00',
                localFromTime: 'AM',
                end: '02:00',
                locaEndTime: 'AM',
              })
            ),
          })
        );
      } else {
        thunkAPI.dispatch(
          setCoachingHoursFormData({
            days: 'Sunday-Thursday',
            start: '00:00',
            end: '02:00',
            alternative: true,
            coachingHourRequests: convert24to12(
              data?.coachingHourResponse?.length
                ? (data?.coachingHourResponse as any[])
                : []
            ),
          })
        );
      }
      thunkAPI.dispatch(
        setCoachingPriceFormData({
          price: data?.price,
        })
      );
      const imageData = await thunkAPI.dispatch(
        downloadImage(data?.imgId as number)
      );
      if (imageData?.payload?.data?.data?.length)
        thunkAPI.dispatch(
          setSessionImageFileFormData({
            url: `data:image/png;base64,${imageData?.payload?.data?.data[0]?.fileContent}`,
            name: imageData?.payload?.data?.data[0]?.originalFileName,
            file: '',
            fileId: data?.imgId,
          })
        );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchPackageById = createAsyncThunk(
  'package/byId',
  async (id: string, thunkAPI) => {
    try {
      const response = await packageById(id);
      const data: myCoachUploadsInterface = response?.data?.data[0];

      thunkAPI.dispatch(fetchAssessmentTitles(data?.createdBy as any));
      thunkAPI.dispatch(fetchSessionTitles(data?.createdBy as any));
      thunkAPI.dispatch(listPackageDuration());
      thunkAPI.dispatch(
        setPackageFormData({
          title: data.title,
          subTitle: data.subTitle,
          description: data.description,
          // duration: data.duration,
          language: data.language,
          categoryTags: data.categoryTags?.map((item: string) => ({
            label: item,
            value: item,
          })),
        })
      );
      thunkAPI.dispatch(
        setPackageAssessmentSessionData(data?.serviceShortDetailsResponses)
      );

      thunkAPI.dispatch(
        setIntendedClientPackageFormData({
          gender: data?.intendedClientResponse?.gender,
          whatWillLearn: data?.intendedClientResponse?.whatWillLearn?.length
            ? data?.intendedClientResponse?.whatWillLearn
            : ['', '', ''],
          requirements: data?.intendedClientResponse?.requirements?.length
            ? data?.intendedClientResponse?.requirements
            : ['', '', ''],
          forWhom: getAssessmentForEditPayload(
            data?.intendedClientResponse?.forWhom as string[]
          ),
          benefits: data?.intendedClientResponse?.benefits?.length
            ? data?.intendedClientResponse?.benefits
            : ['', '', ''],
          tags: getTagsFromAssessmentEdit(
            data?.intendedClientResponse?.forWhom as string[]
          ),
        })
      );

      // if (data?.coachingHourResponses?.length === 1) {
      //   thunkAPI.dispatch(
      //     setCoachingHoursPackageFormData({
      //       days: 'Sunday-Thursday',
      //       start: '00:00',
      //       end: '02:00',
      //       alternative: true,
      //       coachingHourRequests: convert24to12Item(
      //         data?.coachingHourResponses?.length &&
      //           (data?.coachingHourResponses[0] as any)
      //       ),
      //     })
      //   );
      // } else if (
      //   isAlternative(
      //     data?.coachingHourResponses as coachingHourResponseInterface[]
      //   )
      // ) {
      //   const hoursData = getHoursData(
      //     data?.coachingHourResponses as coachingHourResponseInterface[]
      //   );
      //   thunkAPI.dispatch(
      //     setCoachingHoursPackageFormData({
      //       days: hoursData.days,
      //       start: hoursData.start,
      //       end: hoursData.end,
      //       alternative: false,
      //       coachingHourRequests: weekDaysList.map(
      //         (item: { label: string; value: string }) => ({
      //           day: item.value,
      //           from: '12:00',
      //           localFromTime: 'AM',
      //           end: '02:00',
      //           locaEndTime: 'AM',
      //         })
      //       ),
      //     })
      //   );
      // } else {
      //   thunkAPI.dispatch(
      //     setCoachingHoursPackageFormData({
      //       days: 'Sunday-Thursday',
      //       start: '00:00',
      //       end: '02:00',
      //       alternative: true,
      //       coachingHourRequests: convert24to12(
      //         data?.coachingHourResponses as coachingHourResponseInterface[]
      //       ).map((item: coachingHourPackageRequestsInterface) => {
      //         return {
      //           day: item.day,
      //           from: item.from,
      //           localFromTime: item.localFromTime,
      //           end: item.end,
      //           locaEndTime: item.locaEndTime,
      //         };
      //       }),
      //     })
      //   );
      // }
      thunkAPI.dispatch(
        setCoachingPricePackageFormData({
          price: data?.price,
        })
      );
      thunkAPI.dispatch(
        setAssessmentPackageFormData({
          // assessmentId:
          //   data?.assessmentAndSessionResponse?.assessmentPackageResponses[0]
          //     ?.assessmentId,
          // durationInWeek:
          //   data?.assessmentAndSessionResponse?.assessmentPackageResponses[0]
          //     ?.durationInWeek,
          // sessionId:
          //   data?.assessmentAndSessionResponse?.sessionPackageResponses[0]
          //     ?.sessionId,
          // durationInDays:
          //   data?.assessmentAndSessionResponse?.sessionPackageResponses[0]
          //     ?.durationInDays,
          // durationInHr:
          //   data?.assessmentAndSessionResponse?.sessionPackageResponses[0]
          //     ?.durationInHr,
          // assessmentValue: getAssessmentValuePayload(
          //   data?.assessmentAndSessionResponse?.assessmentPackageResponses,
          //   data?.assessmentAndSessionResponse?.sessionPackageResponses
          // ),
          assessmentValue: gePackageEditValuePayload(
            data?.serviceShortDetailsResponses
          ),
        })
      );
      const imageData = await thunkAPI.dispatch(
        downloadImage(data?.imageId as number)
      );
      if (imageData?.payload?.data?.data?.length)
        thunkAPI.dispatch(
          setPackageImageFileFormData({
            url: `data:image/png;base64,${imageData?.payload?.data?.data[0]?.fileContent}`,
            name: imageData?.payload?.data?.data[0]?.originalFileName,
            file: '',
            fileId: data?.imageId,
          })
        );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const { setDeleteId } = myUploadSlice.actions;
export const myUploadInfo = (state: any) => state.myUploadInfo;
export const myUploadInfoReducer = myUploadSlice.reducer;
