import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from '../imageDisplay/index';

const CarouselCard = ({
  item,
  loading,
  handleFunction,
  type,
  dropDown,
}: {
  item: any;
  loading?: boolean;
  handleFunction: (id: number) => void;
  type?: string;
  dropDown?: ReactNode;
}) => {
  const [t] = useTranslation();
  const [removeToggle, setRemoveToggle] = useState<any>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        removeToggle &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setRemoveToggle(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [removeToggle]);

  return (
    <div className="w-full hover:shadow-custom2 transition ease-in-out rounded-lg overflow-hidden shadow-custom1 min-h-[325px] h-[100%] relative  cursor-pointer">
      <div
        ref={modalRef}
        onClick={() => setRemoveToggle(!removeToggle)}
        className={` absolute top-2 ltr:right-2 rtl:left-2  cursor-pointer w-fit   z-10
          `}
      >
        <MoreVertOutlinedIcon sx={{ color: 'darkgray' }} />
      </div>
      {removeToggle && (
        <div
          ref={modalRef}
          onClick={() => setRemoveToggle(false)}
          className={`  `}
        >
          {dropDown}
        </div>
      )}

      <div onClick={() => !loading && handleFunction(item?.id)}>
        <div
          className={` object-fill bg-[#f3f4f6]   flex justify-center items-center  rounded-t h-40 w-full ${
            loading ? ' animate-pulse' : ''
          }`}
        >
          <Image
            id={(item?.imgId as number) || (item?.imageId as number)}
            bgImgUrl={'/assets/default-img-icon.svg'}
            bgClass={'   rounded-t-lg flex justify-center items-center '}
            propClass={'object-fill aspect-video w-full rounded-t h-40'}
          />
        </div>

        <div className="w-full min-h-[160px] h-fit p-3 flex flex-col justify-between ">
          <div
            className={`${
              loading && 'animate-pulse bg-[#f3f4f6] h-7 rounded '
            }  w-full   flex justify-between font-bold text-lg`}
          >
            {item?.title}
          </div>
          <div
            className={`${
              loading && 'animate-pulse bg-[#f3f4f6] h-4 rounded'
            } font-normal text-xs text-[#687E8E]`}
          >
            {item?.subTitle}
          </div>
          <div
            className={`${
              loading && 'animate-pulse bg-[#f3f4f6] h-4 rounded'
            } font-semibold text-12 font-general rtl:font-almarai `}
          >
            {item?.coachName}
          </div>

          <div
            className={`${
              loading && 'animate-pulse bg-[#f3f4f6] h-s  rounded'
            } w-full flex justify-between items-center font-normal text-xs text-[#687E8E]`}
          >
            {type === 'sessions' && item?.durationInMonth}{' '}
            {type === 'packages' && item?.duration}{' '}
            {type === 'assessments' && item?.deliveryDuration}{' '}
            {!loading && type === 'sessions' && 'service |'}{' '}
            {item?.durationPerDay}
          </div>
          <div
            className={`${
              loading && 'animate-pulse bg-[#f3f4f6] h-[32px] rounded'
            } text-2xl font-bold text-lg w-full text-[black] flex  items-center`}
          >
            {loading ? (
              ''
            ) : (
              <span className="ltr:pr-0.5  rtl:pl-0.5 ">{t('SAR')} </span>
            )}

            {item?.price}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
