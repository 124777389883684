enum apiEndpoints {
  AUTH = '/auth/login',
  IMAGE = '/captcha/captcha.jpg',
  FORGOT_PASSWORD = '/auth/forgot-password',
  SET_NEW_PASSWORD = '/auth/change-password',
  GET_CAPTCHA = '/auth/generate-captcha',
  VERIFY_CAPTCHA = '/auth/verify-captcha',
  AUTH_LOGOUT = '/auth/logout',
}
export default apiEndpoints;
