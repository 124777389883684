import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Header, Input } from '../../components/index';
import { alertActive } from '../../components/notification/slices';
import { routeConstants } from '../../constants';
import { ISetNewPasswordForm } from '../../interfaces';
import { useAppSelector } from '../../_helpers';
import { setNewPasswordSchema } from '../../_helpers/validation';
import { resetSetNewPassword, setNewPassword } from './slice';

const SetNewPassword = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { isSuccess, isError, setNewPasswordPayload } = useAppSelector(
    (state) => state.setNewPassword
  );
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleSetNewPassword = ({
    password,
    confirmPassword,
  }: ISetNewPasswordForm) => {
    const newPassword = password;

    token
      ? dispatch(
          setNewPassword({
            newPassword,
            confirmPassword,
            token,
          })
        )
      : '';
  };
  useEffect(() => {
    isSuccess
      ? (dispatch(
          alertActive({
            alertSuccess: isSuccess,
            alertPayload: setNewPasswordPayload,
          })
        ),
        navigate(routeConstants.LOGIN),
        dispatch(resetSetNewPassword()))
      : '';
    isError
      ? (dispatch(
          alertActive({
            alertError: isError,
            alertPayload: setNewPasswordPayload,
          })
        ),
        dispatch(resetSetNewPassword()))
      : '';
  }, [isSuccess, isError]);
  const togglePassword = () => {
    passwordVisibility
      ? setPasswordVisibility(false)
      : setPasswordVisibility(true);
  };
  return (
    <div className="relative bg-logo bg-cover h-screen w-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 ">
      <div className="z-10  absolute top-5  right-[15vw] rtl:right-[85vw]  ">
        <div className=" flex absolute  ">
          <Header />
        </div>
      </div>

      <div className="bg-white flex w-[820px] h-[500px] rounded-lg  overflow-hidden items-center ">
        <div className=" flex flex-col items-center justify-center w-1/2  ">
          <div>
            <img src="/assets/login/logo.svg" alt="logo" />
          </div>
        </div>
        <div className="pt-12 mr-8 rtl:ml-8 rtl:mr-0  w-1/2">
          <div className="flex flex-col justify-items-start items-start justify-center ">
            <div className="pb-4">
              <label className="mt-6 text-center  font-bold text-textBlack text-28 font-satoshi rtl:font-almarai  ">
                {t('SET_NEW_PASSWORD.SET_NEW_PASSWORD_TITLE')}
                <div className="    flex "></div>
              </label>
            </div>
            <div className="text-gray font-general rtl:font-almarai font-normal  ">
              {t('SET_NEW_PASSWORD.CONTENT')}
            </div>
            <Formik
              initialValues={{
                password: '',
                confirmPassword: '',
              }}
              validationSchema={setNewPasswordSchema}
              onSubmit={handleSetNewPassword}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                return (
                  <Form
                    onSubmit={handleSubmit}
                    className="mt-8 w-full space-y-6l relative"
                  >
                    <div className="rounded-md ">
                      <div>
                        <div className="relative cursor-pointer">
                          <Input
                            id={'formPassword'}
                            name={'password'}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={`${passwordVisibility ? 'text' : 'password'}`}
                            placeholder={t('SET_NEW_PASSWORD.PASSWORD')}
                            classname={
                              'input_login  placeholder-gray-300 mt-5 '
                            }
                            errors={errors.password}
                            touched={touched.password}
                          />

                          <label
                            htmlFor="formPassword"
                            className="label-input-login "
                          >
                            {t('SET_NEW_PASSWORD.PASSWORD')}
                            <span className="text-red rtl:font-almarai cursor-text">
                              *
                            </span>
                          </label>
                          <div className="absolute top-0 h-10 ltr:right-2 rtl:left-2 flex items-center">
                            {!passwordVisibility && (
                              <img
                                onClick={() => togglePassword()}
                                src="/assets/showPassword.svg"
                                alt=""
                              />
                            )}
                            {passwordVisibility && (
                              <img
                                onClick={() => togglePassword()}
                                src="/assets/hidePassword.svg"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="pb-4 px-4">
                            <div
                              className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                errors.password
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              } `}
                            >
                              {errors.password && touched.password
                                ? t(`FORM.${errors.password}`)
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="relative cursor-pointer">
                          <Input
                            id={'formRe-enter'}
                            name={'confirmPassword'}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={'password'}
                            placeholder={t(
                              'SET_NEW_PASSWORD.RE-ENTER_PASSWORD'
                            )}
                            classname={
                              'input_login  placeholder-gray-300 mt-5 '
                            }
                            errors={errors.confirmPassword}
                            touched={touched.confirmPassword}
                          />

                          <label
                            htmlFor="formRe-enter"
                            className="label-input-login "
                          >
                            {t('SET_NEW_PASSWORD.RE-ENTER_PASSWORD')}
                            <span className="text-red rtl:font-almarai cursor-text">
                              *
                            </span>
                          </label>
                          <div className="pb-4 px-4">
                            <div
                              className={`text-red rtl:font-almarai text-sm absolute inline-block${
                                errors.confirmPassword
                                  ? 'text-red rtl:font-almarai'
                                  : 'text-black '
                              } `}
                            >
                              {errors.confirmPassword && touched.confirmPassword
                                ? t(`FORM.${errors.confirmPassword}`)
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn w-full text-white mt-3 py-2 rounded  bg-primary font-satoshi rtl:font-almarai font-bold "
                      >
                        {t('SET_NEW_PASSWORD.SET_NEW_PASSWORD_BUTTON')}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
