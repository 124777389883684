import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { languageAndDirections } from '../../constants/index';
import { useAppDispatch, useAppSelector } from '../../_helpers/index';
import { languageSelect } from './slice/index';

const lngs = {
  en: { nativeName: languageAndDirections.ENGLISH },
  ar: { nativeName: languageAndDirections.ARABIC },
};
const Header: FC = (): JSX.Element => {
  const { lang } = useAppSelector((state) => state.language);
  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation();
  const handleChange = () => {
    if (lang === 'ar') {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.LEFT_TO_RIGHT
        );
      i18n.changeLanguage(languageAndDirections.ENGLISH_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ENGLISH_SYMBOL));
      document.title = t('KUN');
    } else {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.RIGHT_TO_LEFT
        );

      i18n.changeLanguage(languageAndDirections.ARABIC_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ARABIC_SYMBOL));
      document.title = t('KUN');
    }
  };
  useEffect(() => {
    if (lang === languageAndDirections.ENGLISH_SYMBOL) {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.LEFT_TO_RIGHT
        );
      i18n.changeLanguage(languageAndDirections.ENGLISH_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ENGLISH_SYMBOL));
    } else {
      document
        .querySelector('html')
        ?.setAttribute(
          languageAndDirections.DIRECTIONS,
          languageAndDirections.RIGHT_TO_LEFT
        );
      i18n.changeLanguage(languageAndDirections.ARABIC_SYMBOL);
      dispatch(languageSelect(languageAndDirections.ARABIC_SYMBOL));
    }
  }, []);
  return (
    <div
      onClick={() => {
        handleChange();
      }}
      className="flex gap-2 cursor-pointer"
    >
      <img className="w-6 h-6" src="/assets/login/language.svg" alt="logo" />
      <button
        className=" appearance-none border-0 bg-transparentColor text-white"
        id="selectMenu"
        value={languageAndDirections.ENGLISH_SYMBOL}
      >
        {lang === languageAndDirections.ARABIC_SYMBOL
          ? languageAndDirections.ENGLISH_SYMBOL_TO_DISPLAY
          : languageAndDirections.ARABIC_LANGUAGE}
      </button>
    </div>
  );
};
export default Header;
