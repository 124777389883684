import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { ISubscriptionFormPayload } from '../../interfaces/index';
import { IFeaturesPayload } from '../../interfaces/subscription';
import { useAppSelector } from '../../_helpers';
import Button from '../button';
import {
  subscriptionDelete,
  subscriptionFormsubmit,
} from '../scubscription/slices';
import SubscriptionForm from '../subscriptionForm';
import Tooltip from '../tooltip/input';

const SubscriptionCard = (props: any) => {
  const [t] = useTranslation();
  const { formValue } = props;
  const { lang } = useAppSelector((state) => state.language);
  const dispatch = useDispatch<any>();
  const [selectCard, setSelectCard] = useState<number>(-1);
  const [dropDown, setDropDown] = useState<number>(-1);
  const [subscriptionFormToggle, setSubscriptionFormToggle] =
    useState<number>(-1);

  const {
    getAllSubscriptionsData,
    subscriptionFormLoading,
    getSubscriptionDetailsLoading,
    subscriptionCoreFeaturesData,
    subscriptionAdditionalFeaturesData,
  } = useAppSelector((state) => state.subscription);
  const [formToggle, setFormToggle] = useState(false);
  const handleEdit = (index: number, data: ISubscriptionFormPayload) => {
    setDropDown(-1);
    setSubscriptionFormToggle(index);
    setFormToggle(true);
  };

  const handleSubmit = (value: ISubscriptionFormPayload) => {
    !getAllSubscriptionsData.includes(value)
      ? dispatch(subscriptionFormsubmit(value))
      : '';
  };
  const handleDelete = (id: number) => {
    dispatch(subscriptionDelete(id));
    setDropDown(-1);
  };
  useEffect(() => {
    formToggle
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [formToggle]);

  return (
    <>
      <div className="">
        <ul className="flex flex-wrap">
          {formValue.map((item: ISubscriptionFormPayload, index: number) => (
            <li key={index} className="px-5 pt-20 gap-5 flex flex-wrap">
              <div className=" z-50">
                {formToggle && subscriptionFormToggle === index && (
                  <div>
                    <SubscriptionForm
                      toggle={formToggle}
                      subscriptionFunction={setFormToggle}
                      formContent={formValue}
                      formValue={item}
                      coreFeaturesData={subscriptionCoreFeaturesData}
                      additionalFeaturesData={
                        subscriptionAdditionalFeaturesData
                      }
                    />
                  </div>
                )}
              </div>
              <div className="rounded-xl flex flex-col justify-between w-[377px]  p-5 shadow-[rgba(13,_38,_76,_0.19)_0px_0px_20px] min-h-[800px]">
                <div className="">
                  <div className="flex gap-4 relative">
                    <div className="flex  place-items-start ">
                      <img
                        className="w-[72px] h-[72px]"
                        src="/assets/subscription/basic.svg"
                        alt=""
                      />
                    </div>

                    <div className="flex flex-col flex-grow flex-shrink w-[249px]">
                      <h1 className="font-general rtl:font-almarai font-semibold text-17 text-gray">
                        {item?.title && item?.title}
                      </h1>
                      <h1 className="font-satoshi rtl:font-almarai font-bold text-28 text-textBlack">
                        {item?.headline && item?.headline}
                      </h1>
                    </div>
                    <div className="h-full  justify-start pt-2 flex">
                      <div className="flex w-6 place-items-end flex-grow">
                        <img
                          onClick={() =>
                            dropDown != index
                              ? setDropDown(index)
                              : setDropDown(-1)
                          }
                          className="flex cursor-pointer w-4 "
                          src="/assets/subscription/dropDown.svg"
                          alt=""
                        />
                      </div>

                      {dropDown === index && (
                        <div>
                          <div
                            onClick={() => setDropDown(-1)}
                            className={`'  fixed inset-0 bg-opacity-75 transition-opacity z-40  '
                        `}
                          ></div>
                          <div className="absolute bg drop-shadow-lg bg-white flex flex-col font-general rtl:font-almarai font-normal text-16 text-textBlack  top-4 ltr:right-4 rtl:left-4 w-[137px] z-50">
                            <div
                              onClick={() => handleEdit(index, item)}
                              className="border-b cursor-pointer ltr:pl-4 rtl:pr-4  py-2 border-borderBlack "
                            >
                              {t('SUBSCRIPTION_PORTAL.EDIT')}
                            </div>
                            <div
                              onClick={() => handleDelete(item?.id as number)}
                              className="py-2 cursor-pointer ltr:pl-4 rtl:pr-4 "
                            >
                              {t('SUBSCRIPTION_PORTAL.DELETE')}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="border-b border-borderBlack">
                    <div className="font-satoshi rtl:font-almarai text-54 font-normal pt-6 flex items-baseline justify-between gap-1 ">
                      <div className="w-[calc(100%-70px)]">
                        <Tooltip
                          className="text-12"
                          content={`SAR ${item.charge && item.charge}`}
                        >
                          <div className={` w-full truncate`}>
                            {t('SAR')}&nbsp;
                            {item.charge && item.charge}
                          </div>
                        </Tooltip>
                      </div>

                      <div className="font-satoshi rtl:font-almarai text-20 font-bold text-gray lowercase">
                        /
                        {t(
                          `SUBSCRIPTION_PORTAL.${
                            item.subscriptionType && item.subscriptionType
                          }`
                        )}
                      </div>
                    </div>
                    <div className="font-general rtl:font-almarai font-normal text-gray text-16 mb-4 mt-2">
                      {item.description && item.description}
                    </div>
                  </div>
                  <div className="border-b border-borderBlack">
                    <div className="font-satoshi rtl:font-almarai text-18 font-normal pt-6 flex">
                      <div className="w-[180px] font-semibold">
                        {t('SUBSCRIPTION_PORTAL.COMMISSION')}{' '}
                      </div>
                      : <span className="px-1">{item?.commission}</span> %
                    </div>
                    <div className="font-satoshi rtl:font-almarai text-18 font-normal py-6 flex">
                      <div className="w-[180px] font-semibold">
                        {t('SUBSCRIPTION_PORTAL.FIXED_COMMISSION')}
                      </div>{' '}
                      : {t(`SAR`)}
                      <span className="ltr:pl-2 rtl:pr-2">
                        {item?.fixedCommission}
                      </span>
                    </div>
                  </div>
                  <div className=" font-satoshi rtl:font-almarai py-5 border-b  border-borderBlack">
                    <div className="flex">
                      <div className=" w-[180px] text-18 font-semibold">
                        {t('SUBSCRIPTION_PORTAL.SESSION_COUNT')}
                      </div>
                      <div>: {item?.sessionCount}</div>
                    </div>
                    <div className="flex">
                      <div className=" w-[180px] text-18 font-semibold ">
                        {t('SUBSCRIPTION_PORTAL.ASSESSMENT_COUNT')}
                      </div>
                      <div>: {item?.assessmentCount}</div>
                    </div>
                    <div className="flex">
                      <div className=" w-[180px] text-18 font-semibold">
                        {t('SUBSCRIPTION_PORTAL.PACKAGE_COUNT')}
                      </div>
                      <div>: {item?.packageCount}</div>
                    </div>
                  </div>
                  <div className="border-b border-borderBlack pt-4 pb-6">
                    <div className="font-satoshi rtl:font-almarai text-18 font-semibold text-textBlack">
                      {t('SUBSCRIPTION_PORTAL.CORE_FEATURES')}
                    </div>
                    <ul className="flex flex-col font-general rtl:font-almarai text-16 font-normal text-gray pt-3 gap-4">
                      {item.coreFeatures &&
                        item.coreFeatures instanceof Array &&
                        item.coreFeatures.map(
                          (value: number | string, index: number) => {
                            return subscriptionCoreFeaturesData.map(
                              (item: IFeaturesPayload, index: number) => {
                                return (
                                  item?.id === value && (
                                    <li key={index} className={`flex gap-2 `}>
                                      <div className="flex justify-start items-start w-5">
                                        <img
                                          src="/assets/subscription/tick.svg"
                                          alt=""
                                          className=""
                                        />
                                      </div>

                                      <div className="w-[309px]">
                                        {' '}
                                        {lang === 'en'
                                          ? item.nameInEnglish
                                          : item.nameInArabic}
                                      </div>
                                    </li>
                                  )
                                );
                              }
                            );
                          }
                        )}
                    </ul>
                  </div>

                  <div className="pt-4 pb-6">
                    <div className="font-satoshi rtl:font-almarai text-18 font-semibold text-textBlack">
                      {t('SUBSCRIPTION_PORTAL.ADDITIONAL_FEATURES_INCLUDED')}
                    </div>
                    <ul className="flex flex-col font-general rtl:font-almarai text-16 font-normal text-gray pt-3 gap-4 ">
                      {item.additionalFeatures &&
                        item.additionalFeatures instanceof Array &&
                        item.additionalFeatures.map(
                          (value: number | string, index: number) => {
                            return subscriptionAdditionalFeaturesData.map(
                              (item: IFeaturesPayload, index: number) => {
                                return (
                                  item?.id === value && (
                                    <li key={index} className={`flex gap-2 `}>
                                      <div className="flex justify-start items-start w-5">
                                        <img
                                          src="/assets/subscription/tick.svg"
                                          alt=""
                                          className=""
                                        />
                                      </div>

                                      <div className="w-[309px]">
                                        {' '}
                                        {lang === 'en'
                                          ? item.nameInEnglish
                                          : item.nameInArabic}
                                      </div>
                                    </li>
                                  )
                                );
                              }
                            );
                          }
                        )}
                    </ul>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setSelectCard(index);
                  }}
                  className="relative"
                >
                  {subscriptionFormLoading && selectCard === index && (
                    <ClipLoader
                      color={'white'}
                      loading={true}
                      size={30}
                      className="absolute top-3 ltr:left-24 rtl:right-24"
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                  <Button
                    functionName={() => handleSubmit(item)}
                    className={`text-white font-satoshi rtl:font-almarai font-semibold text-18 h-full ${
                      (getAllSubscriptionsData instanceof Array &&
                        getAllSubscriptionsData.includes(item)) ||
                      getSubscriptionDetailsLoading
                        ? 'bg-borderBlack cursor-not-allowed'
                        : 'bg-primary'
                    }  rounded py-4 w-full`}
                    data={t('SUBSCRIPTION_PORTAL.PUBLISH')}
                    category={''}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SubscriptionCard;
