import { apiHeader } from '../constants/apiHeader';
import { IUpdatePassword } from '../interfaces/coachInterfaces/profile';
import { deleteApi, get, post, put } from './apiService';

export const profileView = async () => {
  return await get('/clients/me');
};

export const languages = async () => {
  return await get('/languages');
};

export const cities = async (lang: any) => {
  return await get(`/cities?sortBy=${lang}`);
};

export const countries = async () => {
  return await get('/countries');
};

export const updateClient = async (data: any) => {
  return await put(
    `/clients/${data.id}`,
    data.payload,
    apiHeader.APPLICATION_JSON
  );
};

export const deleteClient = async (id: number) => {
  return await deleteApi(`/clients/${id}`, apiHeader.APPLICATION_JSON);
};

export const updatePassword = async (data: IUpdatePassword) => {
  return await post(`/auth/update-password`, data, apiHeader.APPLICATION_JSON);
};

export const downloadById = async (id: number) => {
  return await get(`/files/${id}`);
};
