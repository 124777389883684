import { subscriptionConstants } from '../constants';
import { apiHeader } from '../constants/apiHeader';
import { deleteApi, get, post, put } from './apiService';

export const subscriptionFormSubmit = async <T>(value: any) => {
  return value?.id
    ? put(
        subscriptionConstants.SUBSCRIPTIONFORM + '/' + value.id,
        value,
        apiHeader.APPLICATION_JSON
      )
    : post(
        subscriptionConstants.SUBSCRIPTIONFORM,
        value,
        apiHeader.APPLICATION_JSON
      );
};
export const deleteSubscription = async <T>(id: any) => {
  return deleteApi(
    subscriptionConstants.SUBSCRIPTIONFORM + '/' + id,
    apiHeader.APPLICATION_JSON
  );
};
export const getAllSubscriptions = async <T>(value: T) =>
  get(
    subscriptionConstants.SUBSCRIPTIONFORM,
    value,
    apiHeader.APPLICATION_JSON
  );
export const getSubscriptionCoreFeatures = async <T>() => {
  return get(
    subscriptionConstants.SUBSCRIPTION_COREFEATURES,
    '',
    apiHeader.APPLICATION_JSON
  );
};

export const getSubscriptionAdditionalFeatures = async <T>() => {
  return get(
    subscriptionConstants.SUBSCRIPTION_ADDITIONALFEATURES,
    '',
    apiHeader.APPLICATION_JSON
  );
};
