import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { dashboardConstants } from '../../constants';
import { useAppSelector } from '../../_helpers';
import CoachHeader from '../coachHeader';
import { resetCoachProfile } from '../coachProfile/slices';
import Pagination from '../pagination';
import Table from '../table';
import {
  coachDirectoryData,
  getCoachProfilePicture,
  resetProfilePicData,
} from './slice';

const Coach: FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const {
    coachTableData,
    isTableDataSuccess,
    isTableDataLoading,
    isTableDataError,
    coachTotalPages,
    profilePictureData,
    getProfilePictureLoading,
  } = useAppSelector(coachDirectoryData);
  useEffect(() => {
    isTableDataSuccess &&
      (dispatch(resetProfilePicData()),
      coachTableData &&
        coachTableData?.map((item: { [key: string]: string | number }) => {
          return (
            item?.profilePicId &&
            dispatch(getCoachProfilePicture(item?.profilePicId as string))
          );
        }));
  }, [isTableDataSuccess]);
  let coachListingHeader: string[];
  const data = () => {
    if (coachTableData?.length && isTableDataSuccess) {
      coachListingHeader = Object.keys(coachTableData[0]);
      coachTableData &&
        coachTableData?.map((item: { [key: string]: number | string }) => {
          Object.keys(item).map((value) => {
            coachListingHeader.includes(value)
              ? ''
              : coachListingHeader.push(value);
          });
        });
    }
    return coachListingHeader;
  };

  const navigate = useNavigate();

  const coachProfile = async (id: string, requestStatus: string) => {
    await dispatch(resetCoachProfile());
    requestStatus === 'PENDING' || requestStatus === 'REAPPLY'
      ? navigate(`/kun/coach/coachApproval?coachId=${id}`)
      : requestStatus === 'APPROVED'
      ? navigate(`/kun/coach/coachProfile?coachId=${id}`)
      : '';
  };
  const headerData = [
    { header: t(`COACH_DIRECTORY.FULL_NAME`), value: 'fullName' },
    { header: t(`COACH_DIRECTORY.EMAIL`), value: 'email' },
    { header: t(`COACH_DIRECTORY.DOB`), value: 'dob' },
    { header: t(`COACH_DIRECTORY.GENDER`), value: 'gender' },
    { header: t(`COACH_DIRECTORY.PHONE_NUMBER`), value: 'phoneNumber' },
    { header: t(`COACH_DIRECTORY.COUNTRY_CODE`), value: 'countryCode' },
    { header: t(`COACH_DIRECTORY.ADDRESS1`), value: 'addressLine1' },
    { header: t(`COACH_DIRECTORY.CITY`), value: 'city' },
    { header: t(`COACH_DIRECTORY.COUNTRY`), value: 'country' },
    { header: t(`COACH_DIRECTORY.SPECIALIZATION`), value: 'specialization' },
    {
      header: t(`COACH_DIRECTORY.COACHING_CATEGORIES`),
      value: 'coachingCategories',
    },
    { header: t(`COACH_DIRECTORY.LANGUAGES`), value: 'languages' },
    { header: t(`COACH_DIRECTORY.REQUEST_STATUS`), value: 'requestStatus' },
    { header: t(`COACH_DIRECTORY.CLIENT_COUNT`), value: 'clientCount' },
    { header: t(`COACH_DIRECTORY.SERVICE_COUNT`), value: 'serviceCount' },
    {
      header: t(`COACH_DIRECTORY.COUNT_COACHING_HOURS`),
      value: 'countOfCoachingHours',
    },
  ];
  const highlightedDetails = [
    { color: '#bc455e', text: 'REJECTED' },
    { color: '#9EE0E6', text: 'REAPPLY' },
    { color: 'rgba(56, 56, 56, 0.25)', text: 'BLOCKED' },
  ];
  return (
    <div>
      <CoachHeader />
      <div className="mx-6">
        <Table
          header={headerData}
          data={coachTableData}
          loading={isTableDataLoading}
          totalPages={coachTotalPages && coachTotalPages}
          category={dashboardConstants.COACH}
          directFunction={coachProfile}
          profilePicture={profilePictureData}
          profilePictureLoading={getProfilePictureLoading}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </div>

      {coachTableData.length ? (
        <Pagination
          isLoading={false}
          totalPagesNumber={coachTotalPages}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ) : (
        ''
      )}
      <div className="mx-6 flex gap-4 flex-row pb-5">
        {highlightedDetails.map((value, index) => (
          <div
            key={index + '1'}
            className="flex gap-1 items-center font-satoshi rtl:font-almarai"
          >
            <div
              style={{ backgroundColor: value.color }}
              className={`w-5 h-5 rounded-full `}
            ></div>
            <div>{t(`COACHING_DETAILS.${value?.text}`)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Coach;
