import apiEndpoints from '../constants/apiEndPoints/authentication';
import { apiHeader } from '../constants/apiHeader';
import { get, post } from './apiService';

export const authentication = async <T>(data: T) => {
  return post(apiEndpoints.AUTH, data, apiHeader.APPLICATION_JSON);
};
export const captchaGetApi = async () => {
  return get(apiEndpoints.GET_CAPTCHA);
};
export const verifyCaptcha = async (body: any) => {
  return post(apiEndpoints.VERIFY_CAPTCHA, body, apiHeader.IMAGE);
};
export const logOut = async () => {
  return await post(apiEndpoints.AUTH_LOGOUT, {}, apiHeader.APPLICATION_JSON);
};
