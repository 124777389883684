import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../_helpers';
import { alertReset } from './slices';

const Alert: FC = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch<any>();
  const { alertSuccess, alertPayload, alertError } = useAppSelector(
    (state) => state.alert
  );
  useEffect(() => {
    async function alert() {
      setTimeout(() => {
        dispatch(alertReset());
      }, 3000);
    }
    alert();
  }, [alertError, alertSuccess]);

  return (
    <>
      {(alertSuccess || alertError) && (
        <div className=" fixed top-28 z-50 ">
          <div className="flex z-40 items-center justify-center min-h-screen bg-gray-200">
            <div
              className={`fixed top-20 ltr:right-8 rtl:left-8  transition ease-in-out duration-700 ${
                alertSuccess ? 'bg-primary' : alertError ? 'bg-red' : ''
              } w-[324px] h-20  rounded-md gap-4 p-4 flex flex-row items-center justify-center`}
            >
              <section className=" h-full flex flex-col items-center justify-start ">
                {alertSuccess && (
                  <img
                    className="flex flex-grow"
                    src="/assets/success.svg"
                    alt=""
                  />
                )}
                {alertError && (
                  <img
                    className="flex flex-grow"
                    src="/assets/error.svg"
                    alt=""
                  />
                )}
              </section>
              <section className="h-full flex flex-col items-start justify-end gap-1 flex-grow">
                <h1 className="text-white font-semibold text-17 font-general rtl:font-almarai">
                  {alertSuccess && t('NOTIFICATION.SUCCESS')}
                  {alertError && t('NOTIFICATION.ERROR')}
                </h1>
                <p className="text-white font-general rtl:font-almarai text-13 font-normal ">
                  {alertPayload}
                </p>
              </section>
              <section className="w-5 h-full flex flex-col items-center justify-start">
                <div
                  className="cursor-pointer"
                  onClick={() => dispatch(alertReset())}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.9987 0.333435C3.31203 0.333435 0.332031 3.31343 0.332031 7.0001C0.332031 10.6868 3.31203 13.6668 6.9987 13.6668C10.6854 13.6668 13.6654 10.6868 13.6654 7.0001C13.6654 3.31343 10.6854 0.333435 6.9987 0.333435ZM10.332 9.39343L9.39203 10.3334L6.9987 7.9401L4.60536 10.3334L3.66536 9.39343L6.0587 7.0001L3.66536 4.60677L4.60536 3.66677L6.9987 6.0601L9.39203 3.66677L10.332 4.60677L7.9387 7.0001L10.332 9.39343Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
