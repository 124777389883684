import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { useTranslation } from 'react-i18next';

const NoData = () => {
  const [t] = useTranslation();

  return (
    <>
      <div className="flex justify-center relative ">
        <div className=" rotate-[270deg]">
          <NoteOutlinedIcon
            sx={{ color: '#5d707b', width: '100px', height: '80px' }}
          ></NoteOutlinedIcon>
        </div>

        <span className="absolute top-[30px] w-[30px] flex text-center text-12  text-light-black">
          {t('NO_DATA')}
        </span>
      </div>
    </>
  );
};

export default NoData;
