import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ISubscriptionFormPayload,
  ISubscriptionInitialState,
} from '../../../interfaces/index';
import {
  getAllSubscriptionsPayload,
  subscriptionModify,
} from '../../../interfaces/subscription';
import {
  deleteSubscription,
  getAllSubscriptions,
  getSubscriptionAdditionalFeatures,
  getSubscriptionCoreFeatures,
  subscriptionFormSubmit,
} from '../../../services/subscription';
import { alertActive } from '../../notification/slices';

const initialState: ISubscriptionInitialState = {
  subscriptionFormLoading: false,
  subscriptionFormSuccess: false,
  subscriptionFormError: false,
  getAllSubscriptionsData: [],
  subscriptionFormData: '',
  getSubscriptionDetailsLoading: false,
  getSubscriptionDetailsSuccess: false,
  getSubscriptionDetailsError: false,
  subscriptionCoreFeaturesLoading: false,
  subscriptionCoreFeaturesSuccess: false,
  subscriptionCoreFeaturesError: false,
  subscriptionCoreFeaturesData: [],
  subscriptionAdditionalFeaturesLoading: false,
  subscriptionAdditionalFeaturesSuccess: false,
  subscriptionAdditionalFeaturesError: false,
  subscriptionAdditionalFeaturesData: [],
  subscriptionData: [],
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: initialState,
  reducers: {
    resetForm: (state) => {
      (state.subscriptionFormSuccess = false),
        (state.subscriptionFormError = false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionFormsubmit.pending, (state) => {
        state.subscriptionFormLoading = true;
        state.subscriptionFormSuccess = false;
        state.subscriptionFormError = false;
      })
      .addCase(
        subscriptionFormsubmit.fulfilled,
        (state, action: PayloadAction<subscriptionModify>) => {
          state.subscriptionFormLoading = false;
          state.subscriptionFormSuccess = true;
          state.subscriptionFormError = false;
          state.subscriptionFormData = action.payload?.data?.message;
        }
      )
      .addCase(subscriptionFormsubmit.rejected, (state, action: any) => {
        state.subscriptionFormLoading = false;
        state.subscriptionFormError = true;
        state.subscriptionFormData = action.payload?.data?.message;
      })
      .addCase(subscriptionDelete.pending, (state) => {
        state.subscriptionFormLoading = true;
        state.subscriptionFormSuccess = false;
        state.subscriptionFormError = false;
      })
      .addCase(
        subscriptionDelete.fulfilled,
        (state, action: PayloadAction<subscriptionModify>) => {
          state.subscriptionFormLoading = false;
          state.subscriptionFormSuccess = true;
          state.subscriptionFormError = false;
          state.subscriptionFormData = action.payload?.data?.message;
        }
      )
      .addCase(subscriptionDelete.rejected, (state, action: any) => {
        state.subscriptionFormLoading = false;
        state.subscriptionFormError = true;
        state.subscriptionFormData = action.payload;
      })
      .addCase(getSubscription.pending, (state) => {
        state.getSubscriptionDetailsLoading = true;
        state.getSubscriptionDetailsSuccess = false;
        state.getSubscriptionDetailsError = false;
      })
      .addCase(
        getSubscription.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.getSubscriptionDetailsLoading = false;
          state.getSubscriptionDetailsSuccess = true;
          state.getSubscriptionDetailsError = false;
          state.getAllSubscriptionsData = action.payload?.data[0]?.content;
          state.subscriptionData = action.payload?.data[0];
        }
      )
      .addCase(getSubscription.rejected, (state) => {
        state.getSubscriptionDetailsLoading = false;
        state.getSubscriptionDetailsError = true;
      })
      .addCase(getCoreFeatures.pending, (state) => {
        state.subscriptionCoreFeaturesLoading = true;
        state.subscriptionCoreFeaturesSuccess = false;
        state.subscriptionCoreFeaturesError = false;
      })
      .addCase(
        getCoreFeatures.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.subscriptionCoreFeaturesLoading = false;
          state.subscriptionCoreFeaturesSuccess = true;
          state.subscriptionCoreFeaturesError = false;
          state.subscriptionCoreFeaturesData = action.payload?.data[0];
        }
      )
      .addCase(getCoreFeatures.rejected, (state) => {
        state.subscriptionCoreFeaturesLoading = false;
        state.subscriptionCoreFeaturesError = true;
      })
      .addCase(getAdditionalFeatures.pending, (state) => {
        state.subscriptionAdditionalFeaturesLoading = true;
        state.subscriptionAdditionalFeaturesSuccess = false;
        state.subscriptionAdditionalFeaturesError = false;
      })
      .addCase(
        getAdditionalFeatures.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.subscriptionAdditionalFeaturesLoading = false;
          state.subscriptionAdditionalFeaturesSuccess = true;
          state.subscriptionAdditionalFeaturesError = false;
          state.subscriptionAdditionalFeaturesData = action.payload?.data[0];
        }
      )
      .addCase(getAdditionalFeatures.rejected, (state) => {
        state.subscriptionAdditionalFeaturesLoading = false;
        state.subscriptionAdditionalFeaturesError = true;
      });
  },
});

export const subscriptionFormsubmit = createAsyncThunk(
  'subscriptionForm/subscriptionFormSubmitting',
  async (subscriptionFormPayload: ISubscriptionFormPayload, thunkAPI) => {
    try {
      const data = await subscriptionFormSubmit(subscriptionFormPayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const subscriptionDelete = createAsyncThunk(
  'deleteSubscription/subscriptionDelete',
  async (id: number, thunkAPI) => {
    try {
      const data = await deleteSubscription(id);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getSubscription = createAsyncThunk(
  'subscription/getAllSubscriptions',
  async (subscriptionPayload: getAllSubscriptionsPayload, thunkAPI) => {
    try {
      const { data } = await getAllSubscriptions(subscriptionPayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoreFeatures = createAsyncThunk(
  'subscriptionCoreFeatures/getSubscriptionCoreFeatures',
  async (value, thunkAPI) => {
    try {
      const { data } = await getSubscriptionCoreFeatures();
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getAdditionalFeatures = createAsyncThunk(
  'subscriptionAdditionalFeatures/getSubscriptionAdditionalFeatures',
  async (value, thunkAPI) => {
    try {
      const { data } = await getSubscriptionAdditionalFeatures();
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export default subscriptionSlice.reducer;
export const { resetForm } = subscriptionSlice.actions;
