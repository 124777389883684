import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formDataConverter } from '../_helpers/coachHelpers/utility';
import { alertActive } from '../components/notification/slices';
import { weekDaysList } from '../constants/coachConstants/coachSessionConstants';
import {
  SessionCreatePayload,
  SessionEditPayload,
  sessionPayloadInterface,
  sessionSliceInterface,
} from '../interfaces/coachInterfaces/sessionInterface';
import {
  categoriesApi,
  categoriesTagApi,
  createSessionApi,
  currency,
  editSessionApi,
  hoursApi,
  ImageUploadSession,
  months,
  saveDraftSessionApi,
  saveSessionApi,
  timingApi,
} from '../services/sessionService';
import { deleteDraft } from './coachHomeSlice';

const initialState: sessionSliceInterface = {
  sessionImagefile: {
    url: '',
    name: '',
    file: '',
    fileId: '',
  },
  sessionFormValue: {
    title: '',
    subTitle: '',
    description: '',
    durationInMonth: '',
    durationPerDay: '',
    language: '',
    category: '',
    categoryTags: [],
  },
  intendedClientFormValue: {
    gender: 'BOTH',
    whatWillLearn: ['', '', ''],
    requirements: ['', '', ''],
    forWhom: [],
    tags: [],
    benefits: ['', '', ''],
  },
  coachingHoursFormValue: {
    days: 'Sunday-Thursday',
    start: '00:00',
    end: '01:00',
    alternative: false,
    coachingHourRequests: weekDaysList.map(
      (item: { label: string; value: string }) => ({
        day: item.value,
        from: '12:00',
        localFromTime: 'AM',
        end: '12:00',
        locaEndTime: 'PM',
      })
    ),
  },
  coachingPriceFormValue: {
    price: '',
  },
  isProcessingCreateSession: false,
  isProcessingSaveSession: false,
  currency: [],
  months: [],
  hours: [],
  timing: [],
  categories: [],
  categoriesTag: [],
  isProcessingEditSession: false,
};

export const sessionSlice = createSlice({
  name: 'alesfvervrtItems',
  initialState,
  reducers: {
    setSessionFormData: (state, action: PayloadAction<any>) => {
      state.sessionFormValue = action.payload;
    },

    clearSessionFormData: (state) => {
      state.sessionFormValue = {
        title: '',
        subTitle: '',
        description: '',
        durationInMonth: '',
        durationPerDay: '',
        language: '',
        categoryTags: [],
        category: '',
      };
    },
    setIntendedClientFormData: (state, action: PayloadAction<any>) => {
      state.intendedClientFormValue = action.payload;
    },

    clearIntendedClientFormData: (state) => {
      state.intendedClientFormValue = {
        gender: 'BOTH',
        whatWillLearn: ['', '', ''],
        requirements: ['', '', ''],
        forWhom: [],
        tags: [],
        benefits: ['', '', ''],
      };
    },
    setCoachingHoursFormData: (state, action: PayloadAction<any>) => {
      state.coachingHoursFormValue = action.payload;
    },

    clearCoachingHoursFormData: (state) => {
      state.coachingHoursFormValue = {
        days: 'Sunday-Thursday',
        start: '00:00',
        end: '02:00',
        alternative: false,
        coachingHourRequests: Array.from(Array(7)).map(() => ({
          day: '',
          from: '12:00',
          localFromTime: 'AM',
          end: '02:00',
          locaEndTime: 'AM',
        })),
      };
    },
    setCoachingPriceFormData: (state, action: PayloadAction<any>) => {
      state.coachingPriceFormValue = action.payload;
    },

    clearCoachingPriceFormData: (state) => {
      state.coachingPriceFormValue = {
        price: '',
      };
    },
    setSessionImageFileFormData: (state, action) => {
      state.sessionImagefile.url = action.payload.url;
      state.sessionImagefile.name = action.payload.name;
      state.sessionImagefile.file = action.payload.file;
      state.sessionImagefile.fileId = action.payload.fileId;
    },
    clearSessionImageFileFormData: (state) => {
      state.sessionImagefile.url = '';
      state.sessionImagefile.name = '';
      state.sessionImagefile.file = '';
      state.sessionImagefile.fileId = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postEditSession.pending, (state) => {
        state.isProcessingEditSession = true;
      })
      .addCase(postEditSession.fulfilled, (state, action) => {
        state.isProcessingEditSession = false;
      })
      .addCase(postEditSession.rejected, (state, action) => {
        state.isProcessingEditSession = false;
      })
      .addCase(postCreateSession.pending, (state) => {
        state.isProcessingCreateSession = true;
      })
      .addCase(postCreateSession.fulfilled, (state, action) => {
        state.isProcessingCreateSession = false;
      })
      .addCase(postCreateSession.rejected, (state, action) => {
        state.isProcessingCreateSession = false;
      })
      .addCase(saveCreateSession.pending, (state) => {
        state.isProcessingSaveSession = true;
      })
      .addCase(saveCreateSession.fulfilled, (state, action) => {
        state.isProcessingSaveSession = false;
      })
      .addCase(saveCreateSession.rejected, (state, action) => {
        state.isProcessingSaveSession = false;
      })
      .addCase(saveDraftSession.pending, (state) => {
        state.isProcessingSaveSession = true;
      })
      .addCase(saveDraftSession.fulfilled, (state, action) => {
        state.isProcessingSaveSession = false;
      })
      .addCase(saveDraftSession.rejected, (state, action) => {
        state.isProcessingSaveSession = false;
      })
      .addCase(listCurrencies.fulfilled, (state, action) => {
        state.currency = action.payload.data.data[0];
      })
      .addCase(listMonths.fulfilled, (state, action) => {
        state.months = action.payload.data.data[0];
      })
      .addCase(listHour.fulfilled, (state, action) => {
        state.hours = action.payload.data.data[0];
      })
      .addCase(listCoachingTiming.fulfilled, (state, action) => {
        state.timing = action.payload?.data?.data[0];
      })
      .addCase(listCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data.data[0];
      })
      .addCase(listCategoriesTag.fulfilled, (state, action) => {
        state.categoriesTag = action.payload.data.data[0];
      });
  },
});
export const postCreateSession = createAsyncThunk(
  'session/create',
  async (data: { payload: SessionCreatePayload; navigate: any }, thunkAPI) => {
    const { sessionInfo } = thunkAPI.getState() as {
      sessionInfo: sessionSliceInterface;
    };
    try {
      let photoId = '';
      if (sessionInfo.sessionImagefile.fileId !== '') {
        photoId = sessionInfo.sessionImagefile.fileId;
      } else if (sessionInfo.sessionImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadSessionImage({
            file: sessionInfo.sessionImagefile.file[0],
            fileType: 'SESSION_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await createSessionApi({
        ...data.payload.payload,
        imgId: photoId,
      });
      if (data.payload.draft === 'DRAFT')
        thunkAPI.dispatch(deleteDraft(data.payload.id));
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );

      thunkAPI.dispatch(clearSessionFormData());
      thunkAPI.dispatch(clearIntendedClientFormData());
      thunkAPI.dispatch(clearCoachingHoursFormData());
      thunkAPI.dispatch(clearCoachingPriceFormData());
      thunkAPI.dispatch(clearSessionImageFileFormData());
      data.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const postEditSession = createAsyncThunk(
  'session/edit',
  async (payload: SessionEditPayload, thunkAPI) => {
    const { sessionInfo } = thunkAPI.getState() as {
      sessionInfo: sessionSliceInterface;
    };
    try {
      let photoId = '';
      if (sessionInfo.sessionImagefile.fileId !== '') {
        photoId = sessionInfo.sessionImagefile.fileId;
      } else if (sessionInfo.sessionImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadSessionImage({
            file: sessionInfo.sessionImagefile.file[0],
            fileType: 'SESSION_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await editSessionApi(
        { ...payload.data, imgId: photoId },
        payload.id
      );

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );
      payload.navigate(
        '/kun/products?requestStatusFilter=PENDING&servicesFilter=ALL&page=0&baseFilter=ACTIVE'
      );
      thunkAPI.dispatch(clearSessionFormData());
      thunkAPI.dispatch(clearIntendedClientFormData());
      thunkAPI.dispatch(clearCoachingHoursFormData());
      thunkAPI.dispatch(clearCoachingPriceFormData());
      thunkAPI.dispatch(clearSessionImageFileFormData());

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveCreateSession = createAsyncThunk(
  'session/save',
  async (
    data: { payload: sessionPayloadInterface; navigate: any },
    thunkAPI
  ) => {
    const { sessionInfo } = thunkAPI.getState() as {
      sessionInfo: sessionSliceInterface;
    };
    try {
      let photoId = '';
      if (sessionInfo.sessionImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadSessionImage({
            file: sessionInfo.sessionImagefile.file[0],
            fileType: 'SESSION_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await saveSessionApi({
        request: { ...data.payload, imgId: photoId },
        draftType: 'SESSION',
      });

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );

      thunkAPI.dispatch(clearSessionFormData());
      thunkAPI.dispatch(clearIntendedClientFormData());
      thunkAPI.dispatch(clearCoachingHoursFormData());
      thunkAPI.dispatch(clearCoachingPriceFormData());
      data.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveDraftSession = createAsyncThunk(
  'session/save-draft',
  async (
    payload: { payload: sessionPayloadInterface; id: string; navigate: any },
    thunkAPI
  ) => {
    const { sessionInfo } = thunkAPI.getState() as {
      sessionInfo: sessionSliceInterface;
    };
    try {
      let photoId = '';
      if (sessionInfo.sessionImagefile.fileId !== '') {
        photoId = sessionInfo.sessionImagefile.fileId;
      } else if (sessionInfo.sessionImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadSessionImage({
            file: sessionInfo.sessionImagefile.file[0],
            fileType: 'SESSION_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      const response = await saveDraftSessionApi(
        {
          request: { ...payload.payload, imgId: photoId },
          draftType: 'SESSION',
        },
        payload.id
      );

      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: response?.data?.message,
        })
      );

      thunkAPI.dispatch(clearSessionFormData());
      thunkAPI.dispatch(clearIntendedClientFormData());
      thunkAPI.dispatch(clearCoachingHoursFormData());
      thunkAPI.dispatch(clearCoachingPriceFormData());
      payload.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const listCurrencies = createAsyncThunk(
  'auth/currency',
  async (id: string, thunkAPI) => {
    try {
      const response = await currency();
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const listMonths = createAsyncThunk(
  'list/month',
  async (id, thunkAPI) => {
    try {
      const response = await months();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const listHour = createAsyncThunk('list/hour', async (id, thunkAPI) => {
  try {
    const response = await hoursApi();

    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const listCoachingTiming = createAsyncThunk(
  'list/timing',
  async (id, thunkAPI) => {
    try {
      const response = await timingApi();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const listCategories = createAsyncThunk(
  'list/categories',
  async (id, thunkAPI) => {
    try {
      const response = await categoriesApi();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const listCategoriesTag = createAsyncThunk(
  'list/categoriesTag',
  async (id, thunkAPI) => {
    try {
      const response = await categoriesTagApi();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const uploadSessionImage = createAsyncThunk(
  'image/upload',
  async (payload: { fileType: string; file: any }, thunkAPI) => {
    try {
      const response = await ImageUploadSession(
        formDataConverter(payload.file, payload.fileType)
      );

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({
          alertError: true,
          alertPayload: err?.data?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const {
  setSessionFormData,
  clearSessionFormData,
  setIntendedClientFormData,
  clearIntendedClientFormData,
  setCoachingHoursFormData,
  clearCoachingHoursFormData,
  setCoachingPriceFormData,
  clearCoachingPriceFormData,
  setSessionImageFileFormData,
  clearSessionImageFileFormData,
} = sessionSlice.actions;
export const sessionInfo = (state: any) => state.sessionInfo;
export const sessionInfoReducer = sessionSlice.reducer;
