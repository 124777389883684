import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const PaymentSearchComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearchState] = useState<string | null>(
    searchParams.get('userName') ? searchParams.get('userName') : ''
  );
  const [t] = useTranslation();

  const handleSearch = () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    search ? (params['userName'] = search) : delete params.userName;
    setSearchParams(params);
  };
  const handleSetSearch = (event: string) => {
    setSearchState((value) => (value = event));
  };
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  useEffect(() => {
    searchParams.get('userName') ? '' : setSearchState('');
  }, [searchParams]);
  return (
    <div className="flex-grow relative flex items-center h-[38px]">
      <img
        src="/assets/search.svg"
        alt=""
        className=" absolute cursor-pointer h-5 pb-0.5 pl-4 rtl:mr-3 pointer-events-none focus:hidden "
        onClick={() => handleSearch()}
      />
      <input
        onChange={(e) => handleSetSearch(e.target.value)}
        value={search as string}
        onKeyUp={(e) => handleEnter(e)}
        type="search"
        className=" indent-10 h-full rtl:pl-2 font-normal flex flex-grow bg-clip-padding border border-solid border-borderBlack rounded transition ease-in-out m-0  max-w-[95%]  focus:outline-none placeholder:pl-3 placeholder:text-borderBlack font-general rtl:font-almarai text-12  rtl:placeholder:pr-4"
        id="search"
        placeholder={t(`COACH_DIRECTORY.Search`)}
      ></input>
    </div>
  );
};

export default PaymentSearchComponent;
