/* eslint-disable react/react-in-jsx-scope */
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import './index.css';
interface ErrorType {
  error?: boolean | 1 | undefined;
  errormessage?: string | undefined;
  optional?: boolean;
  togglePassword?(): Promise<void> | void;
  showEyeStrike?: boolean;
  showEye?: boolean;
  clearText?(): Promise<void> | void;
  icon?: any;
  cart?: any;
}
const Input = ({
  className,
  onChange,
  error,
  value,
  placeholder,
  optional,
  name,
  errormessage,
  onBlur,
  type,
  id,
  togglePassword,
  showEyeStrike,
  showEye,
  disabled,
  icon,
  cart,
  clearText,
  maxLength,
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement> & ErrorType) => {
  const attributes = {
    className,
    onChange,
    error,
    value,
    optional,
    placeholder,
    name,
    errormessage,
    onBlur,
    type,
    id,
    maxLength,
  };
  const [t] = useTranslation('');

  return (
    <div
      {...rest}
      className="relative cursor-pointer w-full mb-8 overflow-x-clip
      "
    >
      {icon}
      {name === 'phoneNumber' && icon ? (
        <span className=" absolute top-2.5 ltr:left-[41px] rtl:right-[41px] text-[#687E8E] text-xl top-[9px]">
          +966{' '}
        </span>
      ) : null}
      {icon && name !== 'password' && value ? (
        <ClearIcon
          className="absolute top-2.5 ltr:right-4 rtl:left-4"
          sx={{ color: '#687E8E' }}
          onClick={clearText}
        />
      ) : null}
      <input
        {...attributes}
        id={id}
        // required={optional?false:true}
        disabled={disabled}
        className={
          className +
          ` inputs  ${
            errormessage !== undefined &&
            error &&
            'border border-solid border-red'
          } ${disabled && 'cursor-not-allowed'} h-[46px]`
        }
      />

      <label
        htmlFor={id}
        className={` sample  input-text cursor-text font-satoshi rtl:font-almarai    text-gray text-17  font-normal text-opacity-80 bg-white absolute   rtl:w-fit top-2.5 px-1 transition duration-200 label ${
          icon
            ? 'left-10 rtl:right-[41px] icon'
            : 'ltr:left-3 inputlabel rtl:right-3'
        } ${
          name === 'phoneNumber' && icon
            ? 'ltr:left-[90px] rtl:right-[90px] phone-label'
            : ''
        }`}
      >
        {placeholder}
        {cart ? null : (
          <>
            {!optional ? (
              <span className="text-red rtl:font-almarai cursor-text">*</span>
            ) : (
              <span> {t('SIGNUP.OPTIONAL')}</span>
            )}
          </>
        )}
      </label>

      {showEyeStrike && (
        <img
          src="/assets/signup/eye.svg"
          alt="eye"
          className="absolute top-[17px] ltr:right-3 rtl:top-[17px] rtl:left-[0.625rem] "
          onClick={togglePassword}
        />
      )}
      {showEye && (
        <img
          src="/assets/signup/eyestrikeicon.svg"
          alt="eye"
          className="absolute top-[14px] ltr:right-3 rtl:top-[14px] rtl:left-[0.625rem] "
          onClick={togglePassword}
        />
      )}
      <div className="">
        {error && (
          <p className="text-red rtl:font-almarai font-normal text-12 pt-1.5">
            {t(errormessage as string)}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
