import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchServiceApprove,
  fetchServiceReject,
} from '../../../services/products';
import { alertActive } from '../../notification/slices';
import { getServiceDetails } from '../../products/slices';

interface IProducts {
  isApprove: boolean;
  approveData: any;
  isReject: boolean;
  rejectData: any;
}

const initialState: IProducts = {
  isApprove: false,
  approveData: [],
  isReject: false,
  rejectData: [],
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(serviceApprove.pending, (state) => {
        state.isApprove = true;
      })
      .addCase(serviceApprove.fulfilled, (state, action) => {
        state.isApprove = false;
        state.approveData = action.payload?.data[0];
      })
      .addCase(serviceApprove.rejected, (state) => {
        state.isApprove = false;
      })

      .addCase(serviceReject.pending, (state) => {
        state.isReject = true;
      })
      .addCase(serviceReject.fulfilled, (state, action) => {
        state.isReject = false;
        state.rejectData = action.payload?.message;
      })
      .addCase(serviceReject.rejected, (state) => {
        state.isReject = false;
      });
  },
});

export const serviceApprove = createAsyncThunk(
  'products/serviceApprove',
  async (
    value: { id: number; params: { status: 'APPROVED'; productType: string } },
    thunkAPI
  ) => {
    try {
      const { data } = await fetchServiceApprove(value);
      thunkAPI.dispatch(
        alertActive({ alertSuccess: true, alertPayload: data?.message })
      );
      thunkAPI.dispatch(
        getServiceDetails({
          id: value?.id,
          serviceType: value?.params?.productType,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const serviceReject = createAsyncThunk(
  'products/serviceReject',
  async (
    value: { id: number; params: { [key: string]: string } },
    thunkAPI
  ) => {
    try {
      const { data } = await fetchServiceReject(value);
      thunkAPI.dispatch(
        alertActive({
          alertSuccess: true,
          alertPayload: data?.message,
        })
      );
      thunkAPI.dispatch(
        getServiceDetails({
          id: value?.id,
          serviceType: value?.params?.productType,
        })
      );
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export default serviceSlice.reducer;
