import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { dashboardConstants } from '../../constants';
import { useAppSelector } from '../../_helpers';
import ClientHeader from '../clientHeader';
import Pagination from '../pagination';
import Table from '../table';
import { getClientProfilePicture, resetClientProfilePicData } from './slice';

const Clients: FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    clientListingErrorPayload,
    clientListingData,
    isSuccess,
    isLoading,
    isError,
    clientTotalPages,
    clientProfilePictureData,
    getClientProfilePictureLoading,
  } = useAppSelector((state) => state.clientDirectory);
  let clientListingHeader: string[];

  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  let coachListingHeader: string[];
  useEffect(() => {
    const profilePicFunction = async () => {
      await dispatch(resetClientProfilePicData());
      clientListingData &&
        clientListingData?.map(
          (item: { [key: string]: number | string | null | undefined }) => {
            return (
              item?.profilePicId &&
              dispatch(getClientProfilePicture(item?.profilePicId as string))
            );
          }
        );
    };
    isSuccess && profilePicFunction();
  }, [isSuccess]);

  // const headerData = () => {
  //   clientListingData?.length && isSuccess
  //     ? ((clientListingHeader = Object.keys(clientListingData[0])),
  //       clientListingData.map(
  //         (
  //           item: { [key: string]: string | number | null | undefined },
  //           index: number
  //         ) => {
  //           Object.keys(item).map((value) => {
  //             clientListingHeader.includes(value)
  //               ? ''
  //               : clientListingHeader.push(value);
  //           });
  //         }
  //       ))
  //     : '';
  //   return clientListingHeader;
  // };

  const headerData = [
    { header: t(`CLIENT_DIRECTORY.FULL_NAME`), value: 'fullName' },
    { header: t(`CLIENT_DIRECTORY.EMAIL`), value: 'email' },
    { header: t(`CLIENT_DIRECTORY.DOB`), value: 'dob' },
    { header: t(`CLIENT_DIRECTORY.GENDER`), value: 'gender' },
    { header: t(`CLIENT_DIRECTORY.PHONE_NUMBER`), value: 'phoneNumber' },
    { header: t(`CLIENT_DIRECTORY.COUNTRY_CODE`), value: 'countryCode' },
    { header: t(`CLIENT_DIRECTORY.OCCUPATION`), value: 'occupation' },

    { header: t(`CLIENT_DIRECTORY.COMPANY_NAME`), value: 'companyNmae' },
  ];
  const highlightedDetails = [
    { color: '#bc455e', text: 'Rejected' },
    { color: '#9EE0E6', text: 'Reapply' },
    { color: 'rgba(56, 56, 56, 0.25)', text: 'Blocked' },
  ];
  return (
    <div>
      <ClientHeader />
      <div className="mx-6">
        <Table
          header={headerData}
          data={clientListingData && clientListingData}
          loading={isLoading}
          totalPages={clientTotalPages && clientTotalPages}
          category={dashboardConstants.CLIENTS}
          profilePicture={clientProfilePictureData}
          profilePictureLoading={getClientProfilePictureLoading}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </div>

      {clientListingData.length ? (
        <Pagination
          isLoading={false}
          totalPagesNumber={clientTotalPages}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ) : (
        ''
      )}
      <div className="mx-6 flex gap-4 flex-row pb-5">
        {highlightedDetails.map((value, index) => (
          <div
            key={index + '1'}
            className="flex gap-1 items-center font-satoshi rtl:font-almarai"
          >
            <div
              style={{ backgroundColor: value.color }}
              className={`w-5 h-5 rounded-full `}
            ></div>
            <div>{value?.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;
