import { useNavigate } from 'react-router-dom';

const Info = () => {
  const navigate = useNavigate();

  return (
    <>
      <nav className="bg-red-500 border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="https://flowbite.com/" className="flex items-center">
            <span className="self-center text-xl text-white font-semibold whitespace-nowrap dark:text-white">
              Info
            </span>
          </a>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <button
              onClick={() => navigate('/')}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Go To Sign In Page
              </span>
            </button>
          </div>
        </div>
      </nav>
      <div className=" bg-red-500 mt-20 m-auto  p-6 max-w-sm rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
        <h1>This Is Public Page</h1>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Here are the biggest enterprise technology acquisitions of 2021 so
          far, in reverse chronological order.
        </p>
      </div>
    </>
  );
};

export default Info;
