import jwt_decode from 'jwt-decode';

export const getAccessToken = () => localStorage.getItem('token')?.slice(1, -1);
export const setTokens = <T>(authRes: T) => {
  localStorage.setItem('token', JSON.stringify(authRes));
};
export const clearStorage = () => {
  localStorage.clear();
};
export const isAuthenticated = () => (getAccessToken() ? true : false);
export const decodeUserType = (token: string) => {
  const decoded: any = jwt_decode(token);

  return decoded?.userType;
};
export const decodeUserId = (token: string) => {
  const decoded: any = jwt_decode(token);

  return decoded?.id;
};
export const decodeEmail = () => {
  const decoded: any = jwt_decode(getAccessToken() as string);

  return decoded?.email;
};
