import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IClientListing } from '../../../interfaces/index';
import { getClientTableData } from '../../../services/clientDirectory';
import { getFiledata } from '../../../services/fileTransfer';
import { alertActive } from '../../notification/slices';

const initialState: IClientListing = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  clientListingErrorPayload: '',
  clientListingData: [],
  clientTotalPages: 0,
  getClientProfilePictureLoading: false,
  getClientProfilePictureSuccess: false,
  getClientProfilePictureError: false,
  clientProfilePictureData: [],
};

export const clientListingsSlice = createSlice({
  name: 'clientListing',
  initialState: initialState,
  reducers: {
    resetClientProfilePicData: (state) => {
      state.clientProfilePictureData = [];
      state.getClientProfilePictureLoading = false;
      state.getClientProfilePictureError = false;
      state.getClientProfilePictureSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientListing.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getClientListing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.clientListingData = action.payload.data[0].content;
        state.clientTotalPages = action.payload.data[0]?.totalPages;
      })
      .addCase(getClientListing.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.clientListingErrorPayload = action.payload?.message;
      })
      .addCase(getClientProfilePicture.pending, (state) => {
        state.getClientProfilePictureLoading = true;
        state.getClientProfilePictureSuccess = false;
        state.getClientProfilePictureError = false;
      })
      .addCase(getClientProfilePicture.fulfilled, (state, action) => {
        state.getClientProfilePictureLoading = false;
        state.getClientProfilePictureSuccess = true;

        !state.clientProfilePictureData.includes(action.payload?.data[0]) &&
          state.clientProfilePictureData.push(action.payload?.data[0]);
      })
      .addCase(getClientProfilePicture.rejected, (state) => {
        state.getClientProfilePictureLoading = false;
        state.getClientProfilePictureError = true;
        state.getClientProfilePictureSuccess = false;
      });
  },
});

export const getClientListing = createAsyncThunk(
  'coachList/getCoachlisting',
  async (clientListingPayload: any, thunkAPI) => {
    try {
      const { data } = await getClientTableData(clientListingPayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getClientProfilePicture = createAsyncThunk(
  'clientProfile/clientProfileFileData',
  async (clientProfilePayload: string, thunkAPI) => {
    try {
      const { data } = await getFiledata(clientProfilePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export default clientListingsSlice.reducer;
export const { resetClientProfilePicData } = clientListingsSlice.actions;
