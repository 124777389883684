import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('INVAILD_EMAIL').required('ENTER_EMAIL'),
  password: Yup.string()
    .required('ENTER_PASSWORD')
    .matches(/^\S*$/, 'WHITE_SPACE')
    .min(5, 'MINIMUM')
    .max(15, 'MAXIMUM'),
  captcha: Yup.string().required('ENTER_CAPTCHA'),
});
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('INVAILD_EMAIL').required('ENTER_EMAIL'),
});
export const setNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('ENTER_PASSWORD')
    .matches(/^.*[0-9].*/, 'ATLEAST_ONE_NUMBER')
    .matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, 'ATLEAST_ONE_SYMBOL')
    .matches(/.*[A-Z].*/, 'ATLEAST_ONE_UPPERCASE')
    .matches(/.*[a-z].*/, 'ATLEAST_ONE_LOWERCASE')
    .matches(/^\S*$/, 'WHITE_SPACE')
    .min(5, 'MINIMUM')
    .max(15, 'MAXIMUM'),
  confirmPassword: Yup.string()
    .required('ENTER_CONFIRM_PASSWORD')
    .oneOf([Yup.ref('password')], 'YOUR_PASSWORDS_DONOT_MATCH'),
});

export const sunscriptionFormSchema = Yup.object().shape({
  title: Yup.string().required('ENTER_TITLE'),
  headline: Yup.string().required('ENTER_HEADLINE'),
  commission: Yup.number()
    .required('ENTER_COMMISSION')
    .typeError('ONLY_NUMBER')
    .min(0, 'MINIMUM')
    .max(100, 'MAXIMUM')
    .nullable(),
  fixedCommission: Yup.string().matches(/^.*[0-9].*/, 'ONLY_NUMBER'),
  subscriptionType: Yup.string().required('ENTER_SUBSCRIPTION_TYPE'),
  charge: Yup.string()
    .required('ENTER_CHARGE')
    .matches(/^.*[0-9].*/, 'ONLY_NUMBER')
    .nullable(),
  sessionCount: Yup.number()
    .when('coreFeatures', (coreFeatures, schema) => {
      if (coreFeatures?.includes('4')) {
        return schema.required('ENTER_SESSION_COUNT');
      }
      return schema;
    })
    .typeError('ONLY_NUMBER')
    .min(0, 'MINIMUM')
    .max(30, 'MAX_30'),
  assessmentCount: Yup.number()
    .when('coreFeatures', (coreFeatures, schema) => {
      if (coreFeatures?.includes('5')) {
        return schema.required('ENTER_ASSESSMENT_COUNT');
      }
      return schema;
    })
    .typeError('ONLY_NUMBER')
    .min(0, 'MINIMUM')
    .max(30, 'MAX_30'),
  packageCount: Yup.number()
    .when('coreFeatures', (coreFeatures, schema) => {
      if (coreFeatures?.includes('6')) {
        return schema.required('ENTER_PACKAGE_COUNT');
      }
      return schema;
    })
    .typeError('ONLY_NUMBER')
    .min(0, 'MINIMUM')
    .max(30, 'MAX_30'),
  coreFeatures: Yup.array()
    .required('SELECT_ATLEAST_ONE_CHECKBOX')
    .min(1, 'SELECT_ATLEAST_ONE_CHECKBOX'),
});
