enum navigationConstants {
  OVERVEIW = 'Overview',
  COACH = 'Coach',
  CLIENTS = 'Clients',
  PRODUCTS = 'products',
  SUBSCRIPTION = 'Subscription',
  PAYMENT = 'Payment',
  REVEIW = 'Review',
  REPORTS = 'Reports',
  BASE_ROUTE = '/kun/',
  OVERVEIW_ROUTE = 'overview',
  COACH_ROUTE = 'coach',
  CLIENTS_ROUTE = 'clients',
  SUBSCRIPTION_ROUTE = 'subscription',
  PAYMENT_ROUTE = 'payment',
  REPORTS_ROUTE = 'reports',
  REVEIW_ROUTE = 'review',
}
export default navigationConstants;
