import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const DropDown = ({
  className,
  values,
  handleFunction,
  initialValue,
  translateFunction,
  searchParamsTag,
}: {
  className?: string;
  values: any;
  handleFunction: (value: any) => void;
  initialValue: string;
  translateFunction: (value: any) => string;
  searchParamsTag?: string;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropDownCurrentValue, setDropDownCurrentValue] = useState(
    `${
      searchParams.get(`${searchParamsTag}`)
        ? searchParams.get(`${searchParamsTag}`)
        : initialValue
    }`
  );
  const [dropDownToggle, setDropDownToggle] = useState(false);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [dropDownToggle]);

  const handleClickOutside = (event: any) => {
    if (
      dropDownToggle &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setDropDownToggle(false);
    }
  };
  return (
    <div ref={wrapperRef} className="relative">
      {' '}
      <div
        onClick={() => setDropDownToggle(!dropDownToggle)}
        className="text-12 font-general rtl:font-almarai border-borderBlack border flex items-center px-[10px] py-2 rounded gap-2 cursor-pointer  justify-between"
      >
        {translateFunction(dropDownCurrentValue)}
        <span>
          <img
            className={`${dropDownToggle ? 'rotate-180' : ''}`}
            src="/assets/downArrow.svg"
            alt=""
          />
        </span>
      </div>
      {dropDownToggle && (
        <div className="flex flex-col gap-2 text-12 font-general rtl:font-almarai shadow-md p-4 absolute top-10 z-20 bg-white">
          {values.map((items: { [key: string]: string }, index: number) => (
            <div
              onClick={() => (
                setDropDownCurrentValue(items?.value),
                setDropDownToggle(false),
                handleFunction(items?.value)
              )}
              className={`cursor-pointer ${className} ${
                dropDownCurrentValue === items?.value && 'hidden'
              }`}
              key={items?.value + index}
            >
              {translateFunction(items?.value)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
