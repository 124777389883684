import { Formik } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  assessmentInfo,
  setCoachingPriceAssessmentFormData,
} from '../../../coachSlices/assessmentSlice';
import { listCurrencies, sessionInfo } from '../../../coachSlices/sessionSlice';
import { useAppSelector } from '../../../_helpers';
import { coachPriceValidationSchema } from '../../../_helpers/coachHelpers';
import Input from '../../clientComponents/inputs';

import SelectDropdown from '../selectDropdown';

interface IFaqCard {
  priceFormRef: any;
}

const CoachingAssessmentPrice: FC<IFaqCard> = ({
  priceFormRef,
}): JSX.Element => {
  const [t] = useTranslation('');
  const dispatch = useDispatch<any>();
  const { currency } = useAppSelector(sessionInfo);
  const { coachingPriceAssessmentFormValue } = useSelector(assessmentInfo);
  const { lang } = useAppSelector((state: { language: any }) => state.language);
  const currencyListEn: { label: ''; value: '' }[] = [];
  const currencyListAr: { label: ''; value: '' }[] = [];

  currency?.map((item: any, index: number) => {
    currencyListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    currencyListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  useEffect(() => {
    dispatch(listCurrencies(''));
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFormSubmit = () => {};
  const getPercentageValue = (value: string, percent: number) => {
    const result = (parseFloat(value) / 100) * percent;
    return result;
  };
  const currencyValue = [{ label: 'SR', vslue: 'SR' }];

  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-almarai font-bold text-[34px]">
        {t('COACH_SESSION.PRICING')}
      </div>
      <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_SESSION.PRICING_TEXT')}
      </div>
      <div className="w-full font-satoshi rtl:font-almarai mb-4 font-bold mt-9 text-xl">
        {t('COACH_ASSESSMENT.ASSESSMENT_PRICE')}
      </div>
      <div className="w-full font-general rtl:font-almarai font-normal text-base h-fit pb-4 text-[#687E8E] ">
        {t('COACH_SESSION.SESSION_PRICE_TEXT')}
      </div>
      <Formik
        innerRef={priceFormRef}
        initialValues={coachingPriceAssessmentFormValue}
        validationSchema={coachPriceValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          dispatch(setCoachingPriceAssessmentFormData(values));

          return (
            <>
              <div className="w-full flex gap-3 flex-row">
                <div className="price-input-container">
                  <SelectDropdown
                    id="currency"
                    isMulti={false}
                    isCreatable={false}
                    isSearchable={false}
                    options={lang === 'en' ? currencyValue : currencyValue}
                    optional={1}
                    dropDown={true}
                    name="currency"
                    placeholder={t('COACH_ASSESSMENT.CURRENCY')}
                    onBlur={handleBlur}
                    isValue={'SAR'}
                    onChange={(opt: any) => {
                      setFieldValue('days', opt.value);
                    }}
                    className="my-3 "
                    defaultValue={{
                      value: lang === 'en' ? 'SAR' : 'ريال سعودي',
                      label: lang === 'en' ? 'SAR' : 'ريال سعودي',
                    }}
                    controlShouldRenderValue={true}
                  />
                </div>
                <div className="  mt-[-8px] ">
                  <Input
                    className="!h-[40px]"
                    id="FormTitle"
                    type="number"
                    name="price"
                    placeholder={t('COACH_PACKAGE.AMOUNT')}
                    onBlur={handleBlur}
                    error={touched.price && 1}
                    onChange={(e: any) => {
                      setFieldValue('price', e.target.value);
                    }}
                    errormessage={errors.price as string}
                    value={values.price}
                  />
                </div>
              </div>
              {/* {values.price && (
                <>
                  <div className="w-full text-sm font-bold font-satoshi rtl:font-almarai text-[#687E8E]">
                    {t('COACH_PACKAGE.KUN_CHARGE')}&nbsp;
                    <span className="text-sm font-bold font-satoshi rtl:font-almarai text-[#000000]">
                      4% ({values.price}-{getPercentageValue(values.price, 4)})
                    </span>
                  </div>
                  <div className="w-full pt-3 text-sm font-bold font-satoshi rtl:font-almarai text-[#687E8E]">
                    {t('COACH_PACKAGE.YOU_WILL_GET')} &nbsp;
                    <span className="text-2xl font-bold font-satoshi rtl:font-almarai text-primary">
                      SAR&nbsp;
                      {parseFloat(values.price) -
                        getPercentageValue(values.price, 4)}
                    </span>
                  </div>
                </>
              )} */}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default CoachingAssessmentPrice;
