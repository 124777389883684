import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAppSelector } from '../../_helpers';
import Button from '../button';
import DropDown from '../dropDown';
import LineChart from '../lineChart';
import NoData from '../noDataComponent';
import Pagination from '../pagination';
import PaymentSearchComponent from '../paymentSearchComponent';
import PaymentTable from '../paymentTable';
import PaymentTableBody from '../paymentTableBody';
import RevenueCount from '../revenueCount';
import Sort from '../sort';
import {
  getRecentTransactions,
  getRevenue,
  getRevenueCounts,
  getRevenueSummary,
  getTransactionsDetails,
} from './slices';

const Payment: FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const [t] = useTranslation();
  const [search, setSearchState] = useState<string | null>(
    searchParams.get('userName') ? searchParams.get('userName') : ''
  );

  useEffect(() => {
    searchParams.get('filter') &&
      dispatch(
        getRevenueSummary({
          filter: `${searchParams.get('filter')?.toUpperCase()}`,
        })
      );
  }, [searchParams.get('filter')]);
  const handleParams = (value: string) => {
    switch (value) {
      case 'SETTLEMENT_REQUESTS':
        dispatch(
          getTransactionsDetails({
            withdrawnStatus: 'ALL',
            sort: 'id,desc',
          })
        );
        break;
      case 'CLOSED_SETTLEMENTS':
        dispatch(
          getTransactionsDetails({
            withdrawnStatus: 'SUCCESS',
            sort: 'id,desc',
          })
        );

        break;
    }
  };
  useEffect(() => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    searchParams.get('type') &&
      searchParams.get('type') === 'OVERVIEW' &&
      dispatch(getTransactionsDetails({ WithdrawnStatus: 'ALL' }));

    searchParams.get('type') && searchParams.get('type') !== 'OVERVIEW'
      ? searchParams.get('sort') ||
        searchParams.get('genderFilter') ||
        searchParams.get('baseFilter') ||
        searchParams.get('userName') ||
        searchParams.get('page')
        ? searchParams.get('type') === 'COACHES' ||
          searchParams.get('type') === 'CLIENTS'
          ? dispatch(getRevenue(params))
          : handleParams(searchParams.get('type') as string)
        : dispatch(
            getRevenue({ type: `${searchParams.get('type')?.toUpperCase()}` })
          )
      : '';
  }, [
    searchParams.get('type'),
    searchParams.get('sort'),
    searchParams.get('genderFilter'),
    searchParams.get('baseFilter'),
    searchParams.get('userName'),
    searchParams.get('page'),
  ]);

  useEffect(() => {
    searchParams.get('types') &&
      dispatch(
        getRecentTransactions({
          types: `${searchParams.get('types')?.toUpperCase()}`,
        })
      );
  }, [searchParams.get('types')]);
  useEffect(() => {
    if (searchParams.get('type')) {
      searchParams.get('type') === 'OVERVIEW' && dispatch(getRevenueCounts());
    } else {
      dispatch(getRevenueCounts());
    }
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    Object.keys(params)?.length
      ? ''
      : setSearchParams({
          types: 'COACHES',
          type: 'OVERVIEW',
          filter: 'LAST_ONE_MONTH',
        });
  }, []);

  const {
    revenueCountsData,
    isRevenueCounts,
    isRecentTransactions,
    recentTransactions,
    isRevenue,
    revenueData,
    isRevenueSummary,
    revenueSummary,
    isTransaction,
    transactionDetails,
  } = useAppSelector((state) => state.payment);
  const recenttransactionsHeader: string[] = [];

  const revenueCountsList: { [key: string]: string }[] = [
    {
      totalAmount: 'TOTAL_AMOUNT',
      clientsAmount: 'CLIENTS_AMOUNT',
      coachesAmount: 'COACHES_AMOUNT',
    },
  ];
  const header = [
    { title: 'OVERVIEW', value: 'overview' },
    { title: 'COACHES', value: 'coaches' },
    { title: 'CLIENTS', value: 'clients' },
    { title: 'SETTLEMENT_REQUESTS', value: 'settlement&Requests' },
    { title: 'CLOSED_SETTLEMENTS', value: 'closed-settlements' },
  ];

  const filterList = (value: string) => {
    let temp;
    switch (value) {
      case 'SETTLEMENT_REQUESTS':
        temp = [
          [
            { title: 'All' || '', titleValue: 'ALL' },
            { title: 'Initiated', titleValue: 'INITIATED' },
            { title: 'Success', titleValue: 'SUCCESS' },
            { title: 'Failed', titleValue: 'FAILED' },
          ],
        ];
        break;
    }
    return temp;
    // [
    //   [
    //     { title: 'Male', titleValue: 'MALE' },
    //     { title: 'Female', titleValue: 'FEMALE' },
    //   ],
    //   [
    //     { title: 'All' || '', titleValue: 'ALL' },
    //     { title: 'Active', titleValue: 'ACTIVE' },
    //     { title: 'Newly created', titleValue: 'COACH_REQUEST' },
    //   ],
    // ];
  };

  const dropdownListData = [
    {
      title: t('PAYMENT_COMPONENT.CREATED_BY'),
      value: 'created_by',
      dir: { asc: 'asc', desc: 'desc' },
    },
    {
      title: t('PAYMENT_COMPONENT.AMOUNT_SORT'),
      value: 'amount',
      dir: { asc: 'asc', desc: 'desc' },
    },
    {
      title: t('PAYMENT_COMPONENT.ID'),
      value: 'id',
      dir: { asc: 'asc', desc: 'desc' },
    },
    {
      title: t('PAYMENT_COMPONENT.ORDER_TYPE'),
      value: 'order_type',
      dir: { asc: 'asc', desc: 'desc' },
    },
  ];
  const dropdownList = [{ value: 'COACHES' }, { value: 'CLIENTS' }];
  const dropdownListGraph = [
    { value: 'LAST_SEVEN_DAYS' },
    { value: 'LAST_ONE_MONTH' },
    { value: 'LAST_THREE_MONTHS' },
    { value: 'LAST_SIX_MONTHS' },
    { value: 'LAST_ONE_YEAR' },
  ];

  const handleparams = (data: string) => {
    data === 'OVERVIEW'
      ? (setSearchParams({
          types: 'COACHES',
          type: 'OVERVIEW',
          filter: 'LAST_ONE_MONTH',
        }),
        dispatch(getRevenueCounts()))
      : setSearchParams({ type: data, page: '0' });
  };
  const reload = async () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    dispatch(getRevenue(params));
  };
  const handleSearch = () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    search ? (params['userName'] = search) : delete params.userName;
    setSearchParams(params);
  };

  const handleRecentTransactionsSelect = (value: string) => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    params['types'] = value;
    setSearchParams(params);
  };
  const handleRevenueSummarySelect = (value: string) => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    params['filter'] = value;
    setSearchParams(params);
  };
  const linechartData = (value: string) => {
    const dataConversionFunction = (range: string, date: string) => {
      let temp;
      switch (range) {
        case 'LAST_SEVEN_DAYS':
          temp = moment(date).format('MMM D');
          break;
        case 'LAST_ONE_MONTH':
          temp = moment(date).format('MMM D');
          break;
        case 'LAST_THREE_MONTHS':
          temp = moment(date).format('MMM D');
          break;
        case 'LAST_SIX_MONTHS':
          temp = moment(date).format('MMM D');
          break;
        case 'LAST_ONE_YEAR':
          temp = moment(date).format('MMM');
          break;
      }
      return temp;
    };
    const graphData = ['coach', 'client'].map((graphvalue) => {
      const revenueDummy = [...revenueSummary];

      revenueDummy?.length &&
        revenueDummy.sort(
          (
            a: {
              date: string;
              coachAmount: number;
              clientAmount: number;
            },
            b: {
              date: string;
              coachAmount: number;
              clientAmount: number;
            }
          ) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA.getTime() - dateB.getTime();
          }
        );
      return {
        id: graphvalue,
        color: '',
        data: revenueDummy?.length
          ? revenueDummy?.map(
              (tempvalue: {
                date: string;
                coachAmount: number;
                clientAmount: number;
              }) => ({
                x: dataConversionFunction(value, tempvalue.date),
                y:
                  graphvalue === 'coach'
                    ? tempvalue.coachAmount
                    : tempvalue.clientAmount,
              })
            )
          : [],
      };
    });
    return graphData;
  };
  const translate = (value: string) => {
    return t(`PAYMENT_COMPONENT.${value}`);
  };
  return (
    <div className="px-6 h-[calc(100%-220px)] flex-grow">
      <div>
        <ul className="flex flex-row py-10 gap-3">
          {header.map((item, index) => (
            <li key={item + `${index}`}>
              <Button
                functionName={() => handleparams(item.title)}
                className={` border ${
                  searchParams.get('type') === item.title
                    ? 'border-primary bg-primary text-white '
                    : ' border-borderBlack '
                }  rounded shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)] px-6 py-2 roundedtext-16 font-general rtl:font-almarai font-normal text-secondary   `}
                data={`${t(`PAYMENT_COMPONENT.${item.title}`)}`}
              />
            </li>
          ))}
        </ul>
      </div>
      {searchParams.get('type') === 'OVERVIEW' && (
        <div className="flex gap-5 h-full pb-10">
          <div className="flex flex-col w-[70vw] h-full flex-grow gap-8">
            <div className=" flex gap-5 pb-5 ">
              {Object.keys(revenueCountsList[0]).map(
                (value: string, index: number) => (
                  <RevenueCount
                    key={index}
                    title={revenueCountsList[0][value]}
                    count={
                      !isRevenueCounts && revenueCountsData.length
                        ? (revenueCountsData[0][value] as number)
                        : null
                    }
                    loading={isRevenueCounts}
                  />
                )
              )}
            </div>
            <div className="border border-borderBlack rounded-lg h-[50vh] pt-5">
              <div
                className="flex justify-between mx-6
            "
              >
                <div className="text-18 font-satoshi rtl:font-almarai font-bold">
                  {t(`PAYMENT_COMPONENT.SUMMARY`)}
                </div>
                <div className="flex gap-6">
                  <div className="flex gap-4">
                    {' '}
                    <div className="flex items-center gap-2">
                      <div className=" h-2 w-2 rounded-[4px] bg-primary"></div>{' '}
                      <p className="text-12 font-general rtl:font-almarai text-gray">
                        {t(`PAYMENT_COMPONENT.COACH`)}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className=" h-2 w-2 rounded-[4px] bg-[#9EE0E6]"></div>{' '}
                      <p className="text-12 font-general rtl:font-almarai text-gray">
                        {t(`PAYMENT_COMPONENT.CLIENT`)}
                      </p>
                    </div>
                  </div>
                  <div className="w-[190px]">
                    <DropDown
                      values={dropdownListGraph}
                      handleFunction={handleRevenueSummarySelect}
                      initialValue={'LAST_ONE_MONTH'}
                      translateFunction={translate}
                      searchParamsTag={'filter'}
                    />
                  </div>
                </div>
              </div>
              <div className="h-[40vh] flex justify-center items-center">
                {!isRevenueSummary && revenueSummary?.length ? (
                  <LineChart
                    content={linechartData(
                      searchParams.get('filter') as string
                    )}
                  />
                ) : (
                  ''
                )}{' '}
                {isRevenueSummary ? (
                  <div className="h-full w-full flex justify-center items-center text-gray font-general rtl:font-almarai">
                    <ClipLoader
                      color={'#00a5ec'}
                      loading={true}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  !revenueSummary?.length && (
                    <div className="h-full w-full flex justify-center items-center text-gray font-general rtl:font-almarai">
                      {' '}
                      <NoData />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="flex gap-5">
              {' '}
              <div className="w-full border-borderBlack rounded-lg border h-full pb-5">
                <div className="flex justify-between px-6 py-5 ">
                  <div className="font-bold text-18 font-satoshi rtl:font-almarai">
                    {t(`PAYMENT_COMPONENT.RECENT_TRANSACTIONS`)}
                  </div>
                  <div className="w-[190px]">
                    <DropDown
                      className="w-[150px] "
                      values={dropdownList}
                      handleFunction={handleRecentTransactionsSelect}
                      initialValue={'COACHES'}
                      translateFunction={translate}
                      searchParamsTag={'types'}
                    />
                  </div>
                </div>
                <div className="px-5">
                  <PaymentTable
                    header={
                      !isRecentTransactions
                        ? [
                            { value: 'name', label: 'NAME' },
                            { value: 'email', label: 'EMAIL' },
                            { value: 'subscription', label: 'SUBSCRIPTION' },
                            { value: 'amount', label: 'AMOUNT' },
                            { value: 'date', label: 'DATE' },
                          ]
                        : []
                    }
                    body={recentTransactions}
                    category={'recent'}
                    loading={isRecentTransactions}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[30vw] border-borderBlack rounded-lg border h-auto  px-6 pb-5">
            <div className="flex justify-between py-5 ">
              <div className="font-bold text-18 font-satoshi rtl:font-almarai">
                {t(`PAYMENT_COMPONENT.SETTLEMENT_REQUESTS`)}
              </div>
              <div
                onClick={() =>
                  setSearchParams({ type: 'SETTLEMENT_REQUESTS', page: '0' })
                }
                className="text-primary font-general rtl:font-almarai text-13 flex items-center gap-2 cursor-pointer"
              >
                {t(`PAYMENT_COMPONENT.VIEW_ALL`)}{' '}
                <span>
                  <img
                    className={`${
                      document.querySelector('html')?.getAttribute('dir') ===
                      'ltr'
                        ? ' '
                        : 'rotate-180'
                    }`}
                    src="/assets/rightArrow.svg"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div className="h-full  ">
              <PaymentTable
                header={
                  !isRevenue
                    ? [
                        { value: 'beneficiaryName', label: 'NAME' },
                        { value: 'date', label: 'DATE' },
                        { value: 'amount', label: 'AMOUNT' },
                      ]
                    : []
                }
                body={
                  transactionDetails?.content ? transactionDetails?.content : []
                }
                category={'settlement'}
                loading={isRevenue}
              />
            </div>
          </div>
        </div>
      )}
      {[
        'COACHES',
        'CLIENTS',
        'SETTLEMENT_REQUESTS',
        'CLOSED_SETTLEMENTS',
      ].includes(searchParams.get('type') as string) ? (
        <div className="flex flex-col  w-full relative   ">
          <div className=" flex flex-col gap-y-6 flex-grow ">
            <div className=" ">
              <div className="flex felx-row  p-0 gap-16 h-[38px] ">
                {['COACHES', 'CLIENTS'].includes(
                  searchParams.get('type') as string
                ) ? (
                  <PaymentSearchComponent />
                ) : (
                  <div className=" flex flex-grow"></div>
                )}
                <div className="place-self-end">
                  <div className="flex flex-row gap-x-4 place-items-center   ">
                    <div className="h-[38px] pr-10">
                      {!['SETTLEMENT_REQUESTS', 'CLOSED_SETTLEMENTS'].includes(
                        searchParams.get('type') as string
                      ) ? (
                        <Sort dropdownList={dropdownListData} />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="h-[38px]">
                      {/* {['SETTLEMENT_REQUESTS'].includes(
                        searchParams.get('type') as string
                      ) ? (
                        <Filter
                          category={searchParams.get('type') as string}
                          fields={filterList(
                            searchParams.get('type') as string
                          )}
                        />
                      ) : (
                        ''
                      )} */}
                    </div>
                    <div className=" flex flex-row gap-x-6">
                      {/* <div>
                      <img
                        className="cursor-pointer"
                        src="/assets/download.svg"
                        alt=""
                      />
                    </div> */}
                      <div>
                        <img
                          onClick={() => reload()}
                          className="cursor-pointer"
                          src="/assets/reload.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {['COACHES', 'CLIENTS'].includes(
              searchParams.get('type') as string
            ) ? (
              <div className="mt-5  ">
                <PaymentTableBody
                  header={revenueData?.content && revenueData?.content}
                  body={revenueData?.content && revenueData?.content}
                  category={searchParams.get('type') as string}
                  loading={isRevenue}
                />
                {revenueData?.totalPages > 1 ? (
                  <Pagination
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                    totalPagesNumber={revenueData?.totalPages}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            {['SETTLEMENT_REQUESTS', 'CLOSED_SETTLEMENTS'].includes(
              searchParams.get('type') as string
            ) ? (
              <div className={`mt-5 `}>
                <PaymentTableBody
                  header={
                    transactionDetails?.content && transactionDetails?.content
                  }
                  body={
                    transactionDetails?.content && transactionDetails?.content
                  }
                  category={searchParams.get('type') as string}
                  loading={isTransaction}
                />

                {transactionDetails?.totalPages > 1 ? (
                  <Pagination
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                    totalPagesNumber={transactionDetails?.totalPages}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Payment;
