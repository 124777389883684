import moment from 'moment';
import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const nameRegExp = /^[^0-9]+$/;
export const signInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('ERRORS.LOGIN.EMPTY_EMAIL')
    .email('ERRORS.LOGIN.WRONG_EMAIL'),
  password: Yup.string().required('ERRORS.LOGIN.EMPTY_PASSWORD'),
  // .matches(/^.*[0-9].*/, 'Atleast one number')
  // .matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, 'Atleast one symbol')
  // .matches(/.*[A-Z].*/, 'Atleast one uppercase')
  // .matches(/.*[a-z].*/, 'Atleast one lowercase')
  // .min(7, 'ERRORS.LOGIN.INVALID_PASSWORD'),
  captcha: Yup.string().required('ERRORS.LOGIN.EMPTY_CAPTCHA'),
});
export const coachSessionValidationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('COACH_SESSION.TITLE_REQ'),
    subTitle: Yup.string().required('COACH_SESSION.SUB_REQ'),
    duration: Yup.string().required('COACH_SESSION.DURATION_REQ'),
    retakePeriod: Yup.string().required('COACH_SESSION.TIME_GAP_REQ'),
    language: Yup.string().required('COACH_SESSION.LANG_REQ'),
    category: Yup.string().required('COACH_SESSION.CATE_REQ'),

    description: Yup.string().required('COACH_SESSION.DESC_REQ'),
  });
export const socialMediaValidationSchema = () =>
  Yup.object().shape({
    website: Yup.string(),
    twitter: Yup.string(),
    facebook: Yup.string(),
    linkedin: Yup.string(),
    youtube: Yup.string(),
  });

export const coachIntendValidationSchema = () =>
  Yup.object().shape({
    gender: Yup.string().required('COACH_SESSION.GENDER_REQ'),
  });
export const coachPriceValidationSchema = () =>
  Yup.object().shape({
    price: Yup.string().required('COACH_SESSION.PRICE_VALIDATION'),
  });
export const passwordRecoveryValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .required('ERRORS.FORGOT_PASSWORD.EMPTY_EMAIL')
      .email('ERRORS.FORGOT_PASSWORD.INVALID_EMAIL'),
  });

export const setNewPasswordValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .required('ERRORS.FORGOT_PASSWORD.EMPTY_PASSWORD')
      .matches(/^.*[0-9].*/, 'ERRORS.FORGOT_PASSWORD.ATLEAST_ONE_NUMBER')
      .matches(
        /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g,
        'ERRORS.FORGOT_PASSWORD.ATLEAST_ONE_SYMBOL'
      )
      .matches(/.*[A-Z].*/, 'ERRORS.FORGOT_PASSWORD.ATLEAST_ONE_UPPERCASE')
      .min(8, 'ERRORS.FORGOT_PASSWORD.MIN_PASSWORD'),
    reEnterPassword: Yup.string()
      .required('ERRORS.FORGOT_PASSWORD.EMPTY_PASSWORD')
      .matches(/^.*[0-9].*/, 'ERRORS.FORGOT_PASSWORD.ATLEAST_ONE_NUMBER')
      .matches(
        /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g,
        'ERRORS.FORGOT_PASSWORD.ATLEAST_ONE_SYMBOL'
      )
      .matches(/.*[A-Z].*/, 'ERRORS.FORGOT_PASSWORD.ATLEAST_ONE_UPPERCASE')
      .min(8, 'ERRORS.FORGOT_PASSWORD.MIN_PASSWORD'),
  });

export const profileInfoValidationSchema = () =>
  Yup.object().shape({
    fullName: Yup.string()
      .required('ERRORS.PROFILE.FULL_NAME')
      .matches(nameRegExp, 'ERRORS.PERSONAL_DETAILS.ONLY_STRING'),
    phoneNumber: Yup.string()
      .required('ERRORS.PROFILE.PHONE_NUMBER')
      .matches(phoneRegExp, 'ERRORS.PROFILE.WRONG_PHONE')
      .min(9, 'ERRORS.PERSONAL_DETAILS.TOO_SHORT')
      .max(12, 'ERRORS.PERSONAL_DETAILS.TOO_LONG'),
    address01: Yup.string().required('ERRORS.PROFILE.ADDRESS1'),
    address02: Yup.string().required('ERRORS.PROFILE.ADDRESS2'),
    country: Yup.string(),
    city: Yup.string(),
    dateOfBirth: Yup.string()
      .required('ERRORS.PERSONAL_DETAILS.EMPTY_DOB')
      .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
      .test(
        'dateOfBirth',
        'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE',
        (value) => moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day')
      )
      .test('dateOfBirth', 'ERRORS.PERSONAL_DETAILS.INVALID_AGE', (value) => {
        const currentDate = moment();
        const minimumDate = currentDate.clone().subtract(80, 'years');
        const maximumDate = currentDate.clone().subtract(10, 'years');

        return moment(value, 'YYYY-MM-DD').isBetween(
          minimumDate,
          maximumDate,
          'day',
          '[]'
        );
      }),
    gender: Yup.string(),
    description: Yup.string(),
    language: Yup.array()
      .required('ERRORS.SPECIALIZATION_DETAILS.EMPTY_LANGUAGE')
      .min(1, 'ERRORS.SPECIALIZATION_DETAILS.EMPTY_LANGUAGE'),
    fileId: Yup.string(),
  });

export const bankingInfoValidationSchema = () =>
  Yup.object().shape({
    accountNumber: Yup.string()
      .required('ERRORS.PROFILE.ACCOUNT')
      .matches(/^[0-9]+$/, 'ERRORS.PROFILE.ACCOUNT_INVALID')
      .max(25, 'ERRORS.PROFILE.ACCOUNT_INVALID'),
    name: Yup.string()
      .required('ERRORS.PROFILE.NAME')
      .matches(/^[A-Za-z\s]+$/, 'ERRORS.PROFILE.NAME_INVALID'),
    ifsc: Yup.string().required('ERRORS.PROFILE.IFSC'),
    branch: Yup.string().required('ERRORS.PROFILE.BRANCH_NAME'),
  });

export const tradeValidationSchema = () =>
  Yup.object().shape({
    tradeRegistrationNumber: Yup.string(),
  });

export const personalValidationSchema = () =>
  Yup.object().shape({
    fullName: Yup.string()
      .required('ERRORS.PERSONAL_DETAILS.EMPTY_FULL_NAME')
      .matches(nameRegExp, 'ERRORS.PERSONAL_DETAILS.ONLY_STRING'),
    email: Yup.string()
      .required('ERRORS.PERSONAL_DETAILS.EMPTY_EMAIL')
      .email('ERRORS.PERSONAL_DETAILS.INVALID_EMAIL'),
    phoneNumber: Yup.string()
      .required('ERRORS.PERSONAL_DETAILS.EMPTY_NUMBER')
      .matches(phoneRegExp, 'ERRORS.PERSONAL_DETAILS.WRONG_NUMBER')
      .min(9, 'ERRORS.PERSONAL_DETAILS.TOO_SHORT')
      .max(12, 'ERRORS.PERSONAL_DETAILS.TOO_LONG'),
    address01: Yup.string()
      .test(
        'notEmpty',
        'ERRORS.PERSONAL_DETAILS.EMPTY_ADDRESS1',
        (value: any) => {
          // Trim the value to remove leading and trailing spaces
          const trimmedValue = value?.trim();

          // Check if the trimmed value is empty
          if (trimmedValue?.length === 0) {
            return false; // Reject only white spaces
          }

          return true; // Accept if the field has characters or white spaces
        }
      )
      .required('ERRORS.PERSONAL_DETAILS.EMPTY_ADDRESS1'),
    address02: Yup.string(),
    country: Yup.string().required('ERRORS.PERSONAL_DETAILS.EMPTY_COUNTRY'),
    city: Yup.string(),
    dateOfBirth: Yup.string()
      .required('ERRORS.PERSONAL_DETAILS.EMPTY_DOB')
      .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
      .test('dateOfBirth', 'ERRORS.PERSONAL_DETAILS.INVALID_DOB', (value) =>
        moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day')
      )
      .test('dateOfBirth', 'ERRORS.PERSONAL_DETAILS.INVALID_AGE', (value) => {
        const currentDate = moment();
        const minimumDate = currentDate.clone().subtract(80, 'years');
        const maximumDate = currentDate.clone().subtract(10, 'years');

        return moment(value, 'YYYY-MM-DD').isBetween(
          minimumDate,
          maximumDate,
          'day',
          '[]'
        );
      }),
    gender: Yup.string().required('ERRORS.PERSONAL_DETAILS.EMPTY_GENDER'),
  });

export const coachingValidationSchema = () =>
  Yup.object().shape({
    accreditationRequest: Yup.array().of(
      Yup.object().shape({
        provider: Yup.string()
          .required('ERRORS.COACHING_DETAILS.EMPTY_PROVIDER')
          .matches(nameRegExp, 'ERRORS.PERSONAL_DETAILS.ONLY_STRING'),
        accreditationName: Yup.string()
          .required('ERRORS.COACHING_DETAILS.ACCREDITATION_NAME')
          .matches(nameRegExp, 'ERRORS.PERSONAL_DETAILS.ONLY_STRING'),
        coachingHours: Yup.string().required(
          'ERRORS.COACHING_DETAILS.COACHING_ERROR'
        ),
        issueDate: Yup.string()
          .required('ERRORS.COACHING_DETAILS.EMPTY_ISSUE_DATE')
          .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
          .test(
            'issueDate',
            'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE',
            (value) => moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day')
          ),
        expiration: Yup.string()
          .nullable()
          .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
          .test(
            'expiration',
            'ERRORS.PERSONAL_DETAILS.INVALID_EXP_DATE',
            (value: any) =>
              value
                ? moment(value, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day')
                : true
          ),
        certificates: Yup.string().required(
          'ERRORS.COACHING_DETAILS.EMPTY_CERTIFICATE'
        ),

        uploadCertificate: Yup.string(),
      })
    ),
  });
export const coachingHoursValidationSchema = () =>
  Yup.object().shape({
    accreditationRequest: Yup.array().of(
      Yup.object().shape({
        provider: Yup.string()
          .required('ERRORS.COACHING_DETAILS.EMPTY_PROVIDER')
          .matches(nameRegExp, 'ERRORS.PERSONAL_DETAILS.ONLY_STRING'),
        accreditationName: Yup.string()
          .required('ERRORS.COACHING_DETAILS.ACCREDITATION_NAME')
          .matches(nameRegExp, 'ERRORS.PERSONAL_DETAILS.ONLY_STRING'),
        coachingHours: Yup.string().required(
          'ERRORS.COACHING_DETAILS.COACHING_ERROR'
        ),
        issueDate: Yup.date().required(
          'ERRORS.COACHING_DETAILS.EMPTY_ISSUE_DATE'
        ),
        expiration: Yup.date(),
        certificates: Yup.string(),
        uploadCertificate: Yup.string(),
      })
    ),
  });

export const educationalValidationSchema = Yup.object().shape({
  educationRequests: Yup.array().of(
    Yup.object().shape({
      university: Yup.string().nullable(),
      degree: Yup.string().nullable(),
      fieldOfStudy: Yup.string().nullable(),
      // startDate: Yup.date(),
      // endDate: Yup.date(),
      description: Yup.string().nullable(),
      uploadDocument: Yup.string().nullable(),

      startDate: Yup.string()
        .nullable()
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
        .test(
          'startDate',
          'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE',
          (value) =>
            value ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day') : true
        ),
      endDate: Yup.string()
        .nullable()
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
        .test(
          'endDate',
          'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE',
          (value) =>
            value ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day') : true
        )
        .test(
          'endDate',
          'ERRORS.PERSONAL_DETAILS.INVALID_END_DATE',
          (value, { parent }) =>
            value && parent.startDate
              ? moment(value).isAfter(parent.startDate, 'day')
              : true
        ),
    })
  ),
});

export const addCoachValidationSchema = Yup.object().shape({
  university: Yup.string(),
  degree: Yup.string(),
  fieldOfStudy: Yup.string(),
  // startDate: Yup.date(),
  // endDate: Yup.date(),
  description: Yup.string(),
  fileId: Yup.string(),
  startDate: Yup.string()
    .required('ERRORS.PERSONAL_DETAILS.INVALID_DOB')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
    .test('startDate', 'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE', (value) =>
      value ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day') : true
    ),
  endDate: Yup.string()
    .required('ERRORS.PERSONAL_DETAILS.INVALID_DOB')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
    .test('endDate', 'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE', (value) =>
      value ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day') : true
    )
    .test(
      'endDate',
      'ERRORS.PERSONAL_DETAILS.INVALID_END_DATE',
      (value, { parent }) =>
        value && parent.startDate
          ? moment(value).isAfter(parent.startDate, 'day')
          : true
    ),
});

export const experienceValidationSchema = () =>
  Yup.object().shape({
    experienceRequests: Yup.array().of(
      Yup.object().shape({
        title: Yup.string(),
        companyName: Yup.string(),
        location: Yup.string(),
        // startDate: Yup.date(),
        // endDate: Yup.date(),
        description: Yup.string(),
        uploadDocument: Yup.string(),
        role: Yup.boolean(),
        startDate: Yup.string()
          .nullable()
          .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
          .test(
            'startDate',
            'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE',
            (value) =>
              value
                ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day')
                : true
          ),
        endDate: Yup.string()
          .nullable()
          .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
          .test(
            'endDate',
            'ERRORS.PERSONAL_DETAILS.INVALID_END_DATE',
            (value, { parent }) =>
              value && parent.startDate && !parent.role
                ? moment(value).isAfter(parent.startDate, 'day')
                : true
          ),
      })
    ),
  });
export const assessmentThirdPartySchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .required('COACH_ASSESSMENT.TITLE_REQ')
      .matches(/^[a-zA-Z ]*$/, 'ACCEPTS_ALPHABETS'),
    provider: Yup.string()
      .required('COACH_ASSESSMENT.PROVIDER_REQ')
      .matches(/^[a-zA-Z ]*$/, 'ACCEPTS_ALPHABETS'),

    thirdParty: Yup.array().of(
      Yup.object().shape({
        link: Yup.string().required('COACH_ASSESSMENT.LINK_REQ'),
        file: Yup.string().required('COACH_ASSESSMENT.FILE_REQ'),
      })
    ),
  });
export const specializationValidationSchema = () =>
  Yup.object().shape({
    language: Yup.array()
      .required('ERRORS.SPECIALIZATION_DETAILS.EMPTY_LANGUAGE')
      .min(1, 'ERRORS.SPECIALIZATION_DETAILS.EMPTY_LANGUAGE'),
    coachingCategories: Yup.array()
      .required('ERRORS.SPECIALIZATION_DETAILS.EMPTY_CATEGORIES')
      .min(1, 'ERRORS.SPECIALIZATION_DETAILS.EMPTY_CATEGORIES'),
    coachingSpecialization: Yup.array()
      .required('ERRORS.SPECIALIZATION_DETAILS.EMPTY_SPECIALIZATION')
      .min(1, 'ERRORS.SPECIALIZATION_DETAILS.EMPTY_SPECIALIZATION'),
  });
export const addExperienceValidationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('emptyTitle'),
    companyName: Yup.string().required('emptyCompanyName'),
    location: Yup.string().required('emptyLocation'),
    // startDate: Yup.date(),
    // endDate: Yup.date(),
    startDate: Yup.string()
      .required('ERRORS.PERSONAL_DETAILS.INVALID_DOB')
      .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
      .test(
        'startDate',
        'ERRORS.PERSONAL_DETAILS.INVALID_ISSUE_DATE',
        (value) =>
          value ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day') : true
      ),
    endDate: Yup.string()
      .nullable()
      .test(
        'endDate',
        'ERRORS.PERSONAL_DETAILS.INVALID_DOB',
        function (value, { parent }) {
          if (!parent.role) {
            return Yup.string()
              .required('ERRORS.PERSONAL_DETAILS.INVALID_DOB')
              .isValidSync(value);
          }
          return true;
        }
      )
      .test(
        'endDate',
        'ERRORS.PERSONAL_DETAILS.INVALID_END_DATE',
        function (value, { parent }) {
          if (!parent.role) {
            return Yup.string()
              .matches(
                /^\d{4}-\d{2}-\d{2}$/,
                'ERRORS.PERSONAL_DETAILS.INVALID_DOB'
              )
              .isValidSync(value);
          }
          return true;
        }
      )
      .test(
        'endDate',
        'ERRORS.PERSONAL_DETAILS.INVALID_END_DATE',
        (value, { parent }) =>
          value && parent.startDate && !parent.role
            ? moment(value).isAfter(parent.startDate, 'day')
            : true
      ),
    description: Yup.string(),
    fileId: Yup.string(),
    role: Yup.boolean(),
  });
export const packageAssessdfvementValidationSchema = () =>
  Yup.object().shape({
    assessmentValue: Yup.array().of(
      Yup.object().shape({
        assessmentId: Yup.string().required(
          'COACH_PACKAGE.ASSESSMENT_TITLE_VALIDATION'
        ),
        durationInWeek: Yup.string().required('COACH_PACKAGE.WEEK_VALIDATION'),
        sessionId: Yup.string().required(
          'COACH_PACKAGE.SESSION_TITLE_VALIDATION'
        ),
        durationInDays: Yup.string().required('COACH_PACKAGE.DAY_VALIDATION'),
        durationInHr: Yup.string().required('COACH_PACKAGE.HOURS_VALIDATION'),
      })
    ),
  });

export const packageAssessmentValidationSchema = () =>
  Yup.object().shape({
    assessmentValue: Yup.array()
      .of(
        Yup.object().shape({
          category: Yup.string()
            .oneOf(['ASSESSMENT', 'SESSION'])
            .required('Category is required'),
          assessmentId: Yup.string()
            .when('category', {
              is: 'ASSESSMENT',
              then: Yup.string().required(
                'COACH_PACKAGE.ASSESSMENT_TITLE_VALIDATION'
              ),
            })
            .nullable(),
          // durationInWeek: Yup.string()
          //   .when('category', {
          //     is: 'ASSESSMENT',
          //     then: Yup.string().required('COACH_PACKAGE.WEEK_VALIDATION'),
          //   })
          //   .nullable(),
          sessionId: Yup.string()
            .when('category', {
              is: 'SESSION',
              then: Yup.string().required(
                'COACH_PACKAGE.SESSION_TITLE_VALIDATION'
              ),
            })
            .nullable(),
          // duration: Yup.string()
          //   .when('category', {
          //     is: 'SESSION',
          //     then: Yup.string().required('COACH_SESSION.DURATION_REQ'),
          //   })
          //   .nullable(),
          // retakePeriod: Yup.string()
          //   .when('category', {
          //     is: 'SESSION',
          //     then: Yup.string().required('COACH_SESSION.TIME_GAP_REQ'),
          //   })
          //   .nullable(),
        })
      )
      .test(
        'is-first-index',
        'Validation rules apply only to first index',
        (value: any) => {
          return value.length === 1;
        }
      ),
  });
export const coachPackageValidationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('COACH_SESSION.TITLE_REQ'),
    subTitle: Yup.string().required('COACH_SESSION.SUB_REQ'),
    // durationInMonth: Yup.string().required('COACH_SESSION.MONTH_REQ'),
    // durationPerDay: Yup.string().required('COACH_SESSION.HOUR_REQ'),
    language: Yup.string().required('COACH_SESSION.LANG_REQ'),

    // duration: Yup.string().required(
    //   'COACH_PACKAGE.PACKAGE_DURATION_VALIDATION'
    // ),
  });
export const coachAssessmentValidationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('COACH_SESSION.TITLE_REQ'),
    subTitle: Yup.string().required('COACH_SESSION.SUB_REQ'),
    // deliveryDuration: Yup.string().required('COACH_ASSESSMENT.DELIVERY_REQ'),
    language: Yup.string().required('COACH_SESSION.LANG_REQ'),
    category: Yup.string().required('COACH_SESSION.CATE_REQ'),

    duration: Yup.string().required(
      'COACH_PACKAGE.PACKAGE_DURATION_VALIDATION'
    ),
  });
