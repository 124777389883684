import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from '../button';
import Filter from '../filter';
import Input from '../inputComponent';
import MyResponsivePie from '../pieChart';
import ReviewCard from '../reviewCard';
import SearchComponent from '../searchComponent';
import Sort from '../sort';

const Review: FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [t] = useTranslation();

  const header = [
    { title: 'OVERVIEW', value: 'overview' },
    { title: 'DETRACTORS', value: 'detractors' },
    { title: 'PASSIVES', value: 'passives' },
    { title: 'PROMOTERS', value: 'promoters' },
  ];
  const subHeader = [
    { title: 'COACH', value: 'coaches' },
    { title: 'CLIENT', value: 'clients' },
  ];
  const handleparams = (data: string, category: string) => {
    const setParams = () => {
      const params: { [key: string]: string } = {};
      params['category'] = searchParams.get('category') as string;
      params['subcategory'] = data;
      setSearchParams(params);
    };
    ['OVERVIEW', 'DETRACTORS', 'PASSIVES', 'PROMOTERS'].includes(category)
      ? setSearchParams({ category: data })
      : setParams();
  };
  const reload = async () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    await setSearchParams(params);
    // dispatch(getCoachDirectoryTableData(searchParams));
  };
  useEffect(() => {
    setSearchParams({ category: 'overview' });
  }, []);
  return (
    <div className="px-6 h-[calc(100%-220px)] flex-grow pb-10">
      <div>
        <ul className="flex flex-row pt-10 pb-6 gap-3">
          {header.map((item, index) => (
            <li key={item.title + `${index}`}>
              <Button
                functionName={() => handleparams(item.value, item.title)}
                className={` border ${
                  searchParams.get('category') === item.value
                    ? 'border-primary bg-primary text-white '
                    : ' border-borderBlack '
                }  rounded shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)] px-6 py-2 roundedtext-16 font-general rtl:font-almarai font-normal text-secondary   `}
                data={`${t(`REVIEW.${item.title}`)}`}
              />
            </li>
          ))}
        </ul>
      </div>
      {searchParams.get('category') === 'overview' && (
        <div>
          <div className="flex gap-5 h-full pb-10">
            <div className="flex flex-col w-[60.5vw] h-full flex-grow gap-5">
              <div className=" flex gap-5 pb-5 ">
                <div className="flex flex-row flex-grow py-6 pl-5 pr-10 gap-4 shadow-[0_10px_25px_0px_rgba(0,0,51,0.08)] rounded-lg ">
                  <div className="w-[45px] h-full justify-center flex">
                    <img
                      className="h-full w-full"
                      src="/assets/review/cardImage.svg"
                      alt=""
                    />
                  </div>

                  <div className="flex flex-col gap-[10px]">
                    <h1 className="font-general rtl:font-almarai text-13 font-normal text-gray inline-block">
                      {t(`REVIEW.DETRACTORS`)}
                    </h1>
                    <p className="font-bold text-24 font-satoshi rtl:font-almarai">
                      76
                    </p>
                  </div>
                </div>
                <div className="flex flex-row flex-grow py-6 pl-5 pr-10 gap-4 shadow-[0_10px_25px_0px_rgba(0,0,51,0.08)] rounded-lg ">
                  <div className="w-[45px] h-full justify-center flex">
                    <img
                      className="h-full w-full"
                      src="/assets/review/cardImage.svg"
                      alt=""
                    />
                  </div>

                  <div className="flex flex-col gap-[10px]">
                    <h1 className="font-general rtl:font-almarai text-13 font-normal text-gray inline-block">
                      {t(`REVIEW.PASSIVES`)}
                    </h1>
                    <p className="font-bold text-24 font-satoshi rtl:font-almarai">
                      100
                    </p>
                  </div>
                </div>
                <div className="flex flex-row flex-grow py-6 pl-5 pr-10 gap-4 shadow-[0_10px_25px_0px_rgba(0,0,51,0.08)] rounded-lg ">
                  <div className="w-[45px] h-full justify-center flex">
                    <img
                      className="h-full w-full"
                      src="/assets/review/cardImage.svg"
                      alt=""
                    />
                  </div>

                  <div className="flex flex-col gap-[10px]">
                    <h1 className="font-general rtl:font-almarai text-13 font-normal text-gray inline-block">
                      {t(`REVIEW.PROMOTERS`)}
                    </h1>
                    <p className="font-bold text-24 font-satoshi rtl:font-almarai">
                      450
                    </p>
                  </div>
                </div>
              </div>
              <div className="border border-borderBlack rounded-lg h-[50vh] pt-5">
                <div
                  className="flex justify-between mx-6
            "
                >
                  <div className="text-18 font-satoshi rtl:font-almarai font-bold">
                    {t(`REVIEW.SUMMARY`)}
                  </div>
                  <div className="flex gap-6 items-center">
                    <div className="flex gap-4 text=13 font-medium font-general rtl:font-almarai">
                      {' '}
                      <span className="flex items-center">
                        <img
                          className="  ltr:rotate-180 cursor-pointer"
                          src="/assets/review/forwardArrow.svg"
                          alt=""
                        />
                      </span>
                      2023
                      <span className="flex items-center">
                        <img
                          className="rtl:rotate-180 cursor-pointer"
                          src="/assets/review/forwardArrow.svg"
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="text-12 font-general rtl:font-almarai border-borde rBlack border flex items-center px-[10px] py-2 rounded gap-2 cursor-pointer">
                      {t(`REVIEW.YEAR_VIEW`)}
                      <span>
                        <img src="/assets/downArrow.svg" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="h-[40vh]">{/* <LineChart /> */}</div>
              </div>
            </div>
            <div className="w-[30vw] border-borderBlack rounded-lg border h-auto  px-6 pb-5">
              <div className="flex justify-between py-5 ">
                <div className="font-bold text-18 font-satoshi rtl:font-almarai">
                  {t(`REVIEW.OVER_ALL_SCORE_PERCENTAGE`)}
                </div>
                {/* <div className="text-primary font-general rtl:font-almarai text-13 flex items-center gap-2 cursor-pointer">
              View All{' '}
              <span>
                <img src="/assets/rightArrow.svg" alt="" />
              </span>
            </div> */}
              </div>
              <div className=" h-[50vh] ">
                <MyResponsivePie
                  data={[
                    { id: 1, value: 10 },
                    { id: 2, value: 20 },
                    { id: 3, value: 30 },
                    { id: 4, value: 40 },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <ReviewCard category={'DETRACTORS'} />
            <ReviewCard category={'PASSIVES'} />
            <ReviewCard category={'PROMOTERS'} />
          </div>
        </div>
      )}
      {['detractors', 'passives', 'promoters'].includes(
        searchParams.get('category') as string
      ) && (
        <div className="flex flex-col  w-full relative  ">
          <div className=" flex flex-col gap-4 flex-grow">
            <div className=" ">
              <div className="flex felx-row  p-0 gap-4 ">
                <ul className="flex flex-row gap-4 items-baseline">
                  {subHeader.map((item, index) => (
                    <li key={item.title + `${index}`}>
                      <Button
                        functionName={() =>
                          handleparams(item.value, item.title)
                        }
                        className={` border ${
                          searchParams.get('subcategory') === item.value
                            ? 'border-primary bg-primary text-white '
                            : ' border-borderBlack '
                        }  rounded shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)] px-6 py-1.5 roundedtext-16 font-general rtl:font-almarai font-normal text-secondary   `}
                        // functionName={() => setParams(value.title)}
                        data={`${t(`REVIEW.${item.title}`)}`}
                      />
                    </li>
                  ))}
                </ul>
                <SearchComponent />
                <div className="place-self-end">
                  <div className="flex flex-row gap-x-4 place-items-center  ">
                    <Sort dropdownList={[]} />
                    <div>
                      <Filter fields={[]} />{' '}
                    </div>
                    <div className=" flex flex-row gap-x-6">
                      {/* <div>
                      <img
                        className="cursor-pointer"
                        src="/assets/download.svg"
                        alt=""
                      />
                    </div> */}
                      <div>
                        <img
                          onClick={() => reload()}
                          className="cursor-pointer"
                          src="/assets/reload.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5  rounded border-borderBlack border overflow-hidden h-auto">
            <table className="h-auto">
              <thead className="border bg-darkBlue ">
                <tr className=" text-white  ">
                  <th className="w-[60vw] text-12 font-general rtl:font-almarai font-semibold rtl:pr-4  ltr:pl-4 py-3">
                    <div className="flex w-full items-start">
                      {t(`REVIEW.COACH_FEEDBACKS`)}
                    </div>
                  </th>
                  <th className="w-[40vw] text-12 font-general rtl:font-almarai font-semibold ">
                    <div className="flex w-full  items-start rtl:pr-4  ltr:pl-4">
                      {t(`REVIEW.QUESTIONS`)}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className=" mx-5">
                <tr className="">
                  <td className="pl-5">
                    <div className="flex pr-8 flex-grow border-b border-r border-borderBlack  flex-col   overflow-y-scroll review ">
                      <div className="flex flex-row justify-between pb-6"></div>
                      <div className="flex flex-row gap-4 border-b border-borderBlack">
                        <img
                          src="/assets/profilePic.svg"
                          className="w-10 h-10 rounded-[20px]"
                          alt=""
                        />
                        <div className="flex flex-col gap-1">
                          {' '}
                          <div className="text-14 font-bold font-satoshi rtl:font-almarai">
                            Daniel Walter Scott
                          </div>
                          <div className="flex flex-row gap-2">
                            {' '}
                            <div
                              className={` 
                              text-8 font-general rtl:font-almarai  rounded flex justify-center items-center px-2`}
                            >
                              2-Detractor
                            </div>{' '}
                            <div className="text-12 font-general rtl:font-almarai text-gray">
                              10 min
                            </div>
                          </div>
                          <div className="font-general rtl:font-almarai text-13 ">
                            Aliquam porta nisl dolor, molestie pellentesque elit
                            molestie in. Morbi metus neque, elementum
                            ullamcorper hendrerit eget, tincidunt et nisi. Sed
                            magna nunc, consequat vel aliquam vitae, porta ac
                            mi. Integer commodo sapien lacus, nec interdum nisi
                            vehicula aliquam. Aliquam enim lorem, laoreet ut
                            egestas quis, rutrum sed lectus.
                          </div>
                          <div className="flex gap-4">
                            <div className="text-13 font-semibold font-general rtl:font-almarai text-primary">
                              Reply
                            </div>
                            <div className="text-13 font-general rtl:font-almarai text-gray">
                              {' '}
                              0 replies{' '}
                            </div>
                          </div>
                          <Input
                            placeholder="write a reply"
                            id={''}
                            name={''}
                            value={''}
                            classname={
                              '!border w-full !border-borderBlack py-4 indent-5'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-5 flex items-start pt-5 text-14 font-bold font-satoshi rtl:font-almarai">
                    consectetur adipiscing elit duis tristique sollicitudin nibh
                    sit amet
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Review;
