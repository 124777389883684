/* eslint-disable react/react-in-jsx-scope */
import { useDispatch } from 'react-redux';
import { Outlet, Route, Routes } from 'react-router-dom';
import CoachApproval from '../../components/coachApproval';
import CoachProfile from '../../components/coachProfile';
import {
  Clients,
  Coach,
  Payment,
  Reports,
  Subscription,
} from '../../components/index';
import NavigationBar from '../../components/navigationBar';
import Overveiw from '../../components/overVeiw';
import Products from '../../components/products';
import Review from '../../components/review';
import ServiceView from '../../components/serviceView/indext';
import TopBar from '../../components/topBar';
import { dashboardConstants } from '../../constants/index';
import CoachAssessment from '../coachAssessment';
import CoachPackage from '../coachPackage';
import CoachSession from '../coachSession';

const DashBoard = () => {
  const dispatch = useDispatch();
  const route = [
    { path: dashboardConstants.CLIENTS, component: <Clients /> },
    { path: dashboardConstants.COACH, component: <Coach /> },
    { path: dashboardConstants.SUBSCRIPTION, component: <Subscription /> },
    { path: dashboardConstants.PAYMENT, component: <Payment /> },
    { path: dashboardConstants.REPORTS, component: <Reports /> },
    { path: dashboardConstants.OVERVEIW, component: <Overveiw /> },
    { path: dashboardConstants.COACHAPPROVE, component: <CoachApproval /> },
    { path: dashboardConstants.COACHPROFILE, component: <CoachProfile /> },
    { path: dashboardConstants.REVIEW, component: <Review /> },
    { path: dashboardConstants.PRODUCTS, component: <Products /> },
    { path: dashboardConstants.SERVICE_VIEW, component: <ServiceView /> },
    {
      path: dashboardConstants.SERVICE_PROFILE_VIEW,
      component: <CoachProfile />,
    },
    {
      path: dashboardConstants.COACH_SESSION,
      component: <CoachSession />,
    },
    {
      path: dashboardConstants.COACH_ASSESSMENT,
      component: <CoachAssessment />,
    },
    {
      path: dashboardConstants.COACH_PACKAGE,
      component: <CoachPackage />,
    },
  ];

  return (
    <>
      <div className="flex row">
        <div className="">
          <NavigationBar />
        </div>

        <div className="w-[calc(100%-220px)] h-full ltr:ml-[220px] rtl:mr-[220px]">
          <TopBar />
          <Outlet />
          <Routes>
            {route.map((item, index) => {
              return (
                <Route key={index} path={item.path} element={item.component} />
              );

              // item.path === dashboardConstants.COACH ? (
              //   <Route key={index} path={item.path} element={item.component}>
              //     <Route
              //       path={dashboardConstants.COACHAPPROVE}
              //       element={<CoachApproval />}
              //     />
              //   </Route>
              // ) 220%: (
              //   <Route key={index} path={item.path} element={item.component} />
              // );
            })}
          </Routes>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
