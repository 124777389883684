import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICoachdirectory } from '../../../interfaces/index';
import { getTableData } from '../../../services/coachDirecory';
import { getFiledata } from '../../../services/fileTransfer';
import { RootState } from '../../../store';
import { alertActive } from '../../notification/slices';

const initialState: ICoachdirectory = {
  isTableDataLoading: false,
  isTableDataSuccess: false,
  isTableDataError: false,
  coachTableData: [],
  coachTotalPages: 0,
  getProfilePictureLoading: false,
  getProfilePictureSuccess: false,
  getProfilePictureError: false,
  profilePictureData: [],
};

export const CoachDirectorySlice = createSlice({
  name: 'coachDirectoryData',
  initialState: initialState,
  reducers: {
    resetProfilePicData: (state) => {
      state.profilePictureData = [];
      state.getProfilePictureSuccess = false;
      state.getProfilePictureError = false;
      state.getProfilePictureLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoachDirectoryTableData.pending, (state) => {
        state.isTableDataLoading = true;
        state.coachTableData = [];
      })
      .addCase(getCoachDirectoryTableData.fulfilled, (state, action) => {
        state.isTableDataLoading = false;
        state.isTableDataSuccess = true;
        action?.payload?.data[0]?.content
          ? (state.coachTableData = action?.payload?.data[0]?.content)
          : '';
        action.payload?.data
          ? (state.coachTotalPages = action.payload?.data[0]?.totalPages)
          : '';
      })
      .addCase(getCoachDirectoryTableData.rejected, (state) => {
        state.isTableDataLoading = false;
        state.isTableDataError = true;
        state.coachTableData = [];
      })
      .addCase(getCoachProfilePicture.pending, (state) => {
        state.getProfilePictureLoading = true;
        state.getProfilePictureSuccess = false;
        state.getProfilePictureError = false;
      })
      .addCase(getCoachProfilePicture.fulfilled, (state, action) => {
        state.getProfilePictureLoading = false;
        state.getProfilePictureSuccess = true;
        !state.profilePictureData.includes(action.payload?.data[0]) &&
          state.profilePictureData.push(action.payload?.data[0]);
      })
      .addCase(getCoachProfilePicture.rejected, (state) => {
        state.getProfilePictureLoading = false;
        state.getProfilePictureError = true;
        state.getProfilePictureSuccess = false;
      });
  },
});

export const getCoachDirectoryTableData = createAsyncThunk(
  'coachTableData/getCoachTableData',
  async (tableDataPayload: any, thunkAPI) => {
    try {
      const { data } = await getTableData(tableDataPayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachProfilePicture = createAsyncThunk(
  'coachProfile/coachProfileFileData',
  async (coachProfilePayload: string, thunkAPI) => {
    try {
      const { data } = await getFiledata(coachProfilePayload);
      return data;
    } catch (err: any) {
      thunkAPI.dispatch(
        alertActive({ alertError: true, alertPayload: err?.data?.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const coachDirectoryData = (state: RootState) => state.coachDirectory;
export const { resetProfilePicData } = CoachDirectorySlice.actions;

export default CoachDirectorySlice.reducer;
