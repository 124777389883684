import coachDirectoryEndpoints from '../constants/apiEndPoints/coachDirectory';
import { apiHeader } from '../constants/apiHeader';
import { get, getFile, getImage, post } from './apiService';

export const getFiledata = async <T>(id: string) => {
  return await getImage(coachDirectoryEndpoints.FILE_TRANSFER + id);
};
export const getTadeInfoBankingInfo = async <T>(
  url: string,
  id: { coachId: number }
) => {
  return await get(url, id, apiHeader.APPLICATION_JSON);
};
export const getTadeInfo = async <T>(url: string, id: number) => {
  return await get(url + id, {}, apiHeader.APPLICATION_JSON);
};
export const fetchSocialMediaLinks = async <T>(id: number) => {
  return await get(
    coachDirectoryEndpoints.SOCIAL_MEDIA + id,
    {},
    apiHeader.APPLICATION_JSON
  );
};
export const fetchCoachActiveSubscription = async <T>(id: number) => {
  return await get(
    coachDirectoryEndpoints.SUBSCRIPTION + id,
    {},
    apiHeader.APPLICATION_JSON
  );
};
export const fetchSubscriptionDetails = async <T>(id: number) => {
  return await get(
    coachDirectoryEndpoints.SUBSCRIPTION_DETAILS + id,
    {},
    apiHeader.APPLICATION_JSON
  );
};
export const fetchReportBlock = async <T>(data: { [key: string]: string }) => {
  return await post(
    `${coachDirectoryEndpoints.REPORT_BLOCK}`,
    data,
    apiHeader.APPLICATION_JSON
  );
};
export const fetchUnblock = async <T>(id: number) => {
  return await post(
    `${coachDirectoryEndpoints.UNBLOCK_COACH + id}`,
    {},
    apiHeader.APPLICATION_JSON
  );
};
export const fetchSettleCoach = async <T>(id: number) => {
  return await post(
    `${coachDirectoryEndpoints.SETTLE_COACH + id}`,
    {},
    apiHeader.APPLICATION_JSON
  );
};
export const fetchApproveWireTransfer = async <T>(value: {
  [key: string]: string | number;
}) => {
  const id = value?.id;
  delete value?.id;
  return await post(
    `${coachDirectoryEndpoints.APPROVE_REJECT_SUBSCRIPTION}` + id,
    value,
    apiHeader.APPLICATION_JSON
  );
};
export const fetchRejectWireTransfer = async <T>(value: {
  [key: string]: string | number;
}) => {
  const id = value?.id;
  delete value?.id;
  return await post(
    `${coachDirectoryEndpoints.APPROVE_REJECT_SUBSCRIPTION}` + id,
    value,
    apiHeader.APPLICATION_JSON
  );
};

export const fetchDownloadReceipt = async <T>(id: number) => {
  return await getFile(`${coachDirectoryEndpoints.RECEIPT_DOWNLOAD}` + id);
};
